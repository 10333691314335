import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import axios from "axios";
import CheckBeforeRequest from "../../../Common/CheckBeforeRequest";
import { SetTripDocsDoc } from "../../../../Actions/Menu";

type Callback = (data: Blob) => Promise<void>

export function useUploadRoadbook(): Callback {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const trip_docs = useSelector(store => store.menu.trip_docs);
    const trip_info = useSelector(store => store.menu.trip_info);
    const to_show_version = useSelector(store => store.menu.to_show_version);
    const trip_displayed_version = to_show_version !== null ? to_show_version : (trip_info !== null ? trip_info.current_version : null);

    const handleReceived = async () => {
        let { pass_check, headers } = CheckBeforeRequest();
        if (pass_check) {
            const documentObject = trip_docs.find((item) => item.type === 'TRAVELBOOK');
            let request = {
                received: !documentObject.received
            };
            try {
                const response = await axios({
                    method: "PATCH",
                    url: `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/versions/${trip_displayed_version}/file/${documentObject?.id}/`,
                    headers: headers,
                    data: JSON.stringify(request)
                });
                dispatch(SetTripDocsDoc(response.data));
                const quotation_code = JSON.parse(localStorage.getItem("config")).quotation_code;
                if (quotation_code === "cercledesvoyages") {
                    let data_version = trip_info.data.find((version) => version.id === trip_displayed_version);
                    let request1 = {
                        has_roadbook: !data_version.has_roadbook
                    };
                    const versionResponse = await axios({
                        method: "PATCH",
                        url: `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/versions/${trip_displayed_version}/`,
                        headers: headers,
                        data: JSON.stringify(request1)
                    });
                    dispatch({
                        type: "MENU_SET_TRIP_INFO_VERSION",
                        payload: {
                            version: versionResponse.data
                        }
                    });
                    dispatch({
                        type: "TRIP_SET_DATA_TRIP",
                        payload: {
                            data_trip: versionResponse.data
                        }
                    });
                }
            } catch (error) {
                console.error(error);
            }
        }
    };

    return async (data) => {
        const documentObject = trip_docs.find((item) => item.type === 'TRAVELBOOK');
        let formData = new FormData();
        const bucketName = "facilitatrip-documents";
        const domain = window.location.host.replace("www.", "").split('.');
        formData.append("file_object", data, 'Roadbook');
        formData.append("bucket_name", bucketName);
        formData.append("prefix", `${domain[0]}-${domain[2] === 'com' ? 'prod' : 'pre-prod'}`);
        formData.append("type", documentObject.type);
        formData.append("tripdocument_id", documentObject.id);
        formData.append("file_name", 'Roadbook.pdf');

        let { pass_check, headers } = CheckBeforeRequest();
        headers["Content-Type"] = "multipart/form-data";

        if (pass_check) {
            try {
                const response = await axios({
                    method: "POST",
                    url: `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/versions/${trip_displayed_version}/file/upload_to_aws/`,
                    headers: headers,
                    data: formData
                });
                enqueueSnackbar(t("menu.trip_list.success_doc_upload"), { variant: "success", disableWindowBlurListener: true });
                dispatch(SetTripDocsDoc(response.data));
                await handleReceived();
            } catch (error) {
                console.error(error);
                enqueueSnackbar(t("menu.trip_list.failed_doc_upload"), { variant: "error", disableWindowBlurListener: true });
            }
        }
    };
}
