import React, { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { DatePicker, LocalizationProvider, PickersDay, PickersDayProps } from "@material-ui/pickers";
import MomentAdapter from "@material-ui/pickers/adapter/moment";
import TextField from '@material-ui/core/TextField';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';

import clsx from "clsx";
import { useSelector } from 'react-redux';
import RenderTime from './RenderTime';
import GetTime from './GetTime';
import moment from "moment";

const useStyles = makeStyles(theme => ({
    formControlRoot: {
        "& .mui-jss-MuiInputLabel-outlined": {
            transform: 'translate(14px, 13px) scale(1)',
            [theme.breakpoints.between(768, 1440)]: {
		      	fontSize: '14px'
		    }
        },
        "& .mui-jss-MuiInputLabel-outlined.mui-jss-MuiInputLabel-shrink": {
            transform: 'translate(14px, -6px) scale(0.75)'
        },
        "& .mui-jss-MuiOutlinedInput-input": {
            padding: '9.5px 20px'
        }
    },
    formControlRoot1: {
	    "& .mui-jss-MuiInputLabel-outlined": {
	    	transform: 'translate(14px, 20px) scale(1)'
	    },
	    "& .mui-jss-MuiInputLabel-outlined.mui-jss-MuiInputLabel-shrink": {
	    	transform: 'translate(14px, -6px) scale(0.75)'
	    },
	    "& .mui-jss-MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
	    	padding: '0'
	    },
	    "& .mui-jss-MuiInputBase-root.mui-jss-MuiOutlinedInput-root.mui-jss-MuiAutocomplete-inputRoot.mui-jss-MuiInputBase-fullWidth.mui-jss-MuiInputBase-formControl.mui-jss-MuiInputBase-adornedEnd.mui-jss-MuiOutlinedInput-adornedEnd": {
	    	paddingRight: '10px'
	    },
	    "& .mui-jss-MuiOutlinedInput-input": {
	    	padding: '17px 4px'
	    }
  	},
    inputBorder1: {
  		"minWidth": "100%",
        "&& .mui-jss-MuiOutlinedInput-root": {
            borderRadius: '0',
            borderRight: '0'
        }
    },
    inputBorder2: {
        "& .mui-jss-MuiOutlinedInput-root": {
            borderRadius: '0px 8px 8px 0px'
        }
    },
    inputBorder3: {
        borderRadius: '8px',
        width: '100%'
    },
  	inputBorder4: {
  		"minWidth": "100%",
  		"&& .mui-jss-MuiOutlinedInput-root": {
            borderRadius: '8px 0px 0px 8px'
  		}
  	},
  	selectedDay: {
  		"& .mui-jss-MuiPickersDay-root.mui-jss-Mui-selected": {
  	    	opacity: '100%'
  		}
  	},
  	highlight: {
  	    "borderRadius": "50%",
  	    "opacity": '30%',
  	    "backgroundColor": theme.palette.primary.main,
  	    "color": "white !important",
  	    "&:hover, &:focus": {
  	      backgroundColor: theme.palette.primary.dark
  	    }
  	},
    firstHighlight: {
  	    "borderRadius": "50%",
  	    "backgroundColor": theme.palette.primary.main,
  	    "color": theme.palette.common.white,
  	    "&:hover, &:focus": {
  	      backgroundColor: theme.palette.primary.dark
  	    }
  	},
  	endHighlight: {
  	    "borderRadius": "50%",
  	    "backgroundColor": theme.palette.primary.main,
  	    "color": theme.palette.common.white,
  	    "&:hover, &:focus": {
  	      backgroundColor: theme.palette.primary.dark
  	    }
  	},
  	selectedDeparture: {
  		"& .mui-jss-MuiPickersDay-root.mui-jss-Mui-selected": {
	  		"borderTopRightRadius": 0,
	  		"borderBottomRightRadius": 0,
	  		"borderTopLeftRadius": "50%",
	  		"borderBottomLeftRadius": "50%",
	  		"backgroundColor": theme.palette.primary.main,
	  		"color": theme.palette.common.white,
	  		"&:hover, &:focus": {
	  		  backgroundColor: theme.palette.primary.dark
	  		}
  		}
  	},
  	selectedReturn: {
  		"& .mui-jss-MuiPickersDay-root.mui-jss-Mui-selected": {
	  		"borderTopRightRadius": "50%",
	  		"borderBottomRightRadius": "50%",
	  		"borderTopLeftRadius": 0,
	  		"borderBottomLeftRadius": 0,
	  		"backgroundColor": theme.palette.primary.main,
	  		"color": theme.palette.common.white,
	  		"&:hover, &:focus": {
	  		  backgroundColor: theme.palette.primary.dark
	  		}
  		}
  	},
  	newDeparture: {
  		"borderTopRightRadius": 0,
  		"borderBottomRightRadius": 0,
  		"borderTopLeftRadius": "50%",
  		"borderBottomLeftRadius": "50%",
  		"backgroundColor": theme.palette.primary.main,
  		"color": theme.palette.common.white,
  		"&:hover, &:focus": {
  		  backgroundColor: theme.palette.primary.dark
  		}
  	},
  	newReturn: {
  		"borderTopRightRadius": "50%",
  		"borderBottomRightRadius": "50%",
  		"borderTopLeftRadius": 0,
  		"borderBottomLeftRadius": 0,
  		"backgroundColor": theme.palette.primary.main,
  		"color": theme.palette.common.white,
  		"&:hover, &:focus": {
  		  backgroundColor: theme.palette.primary.dark
  		}
  	},
    noBorder: {
        borderLeft: "none",
        borderRight: "none"
    },
    noBorderRight: {
        borderRight: "none"
    },
    separator: {
        borderLeft: "1px solid rgba(0, 0, 0, 0.23)",
    	display: "inline",
        position: "absolute",
        left: 0,
        top: "25%",
        bottom: "25%"
    }
}));

const DatePickerInput = ({ search, setSearch, type, elem_index, label }) => {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const matches = useMediaQuery('(min-width:600px)');
    const isTablet = useMediaQuery(theme.breakpoints.between(600, 960));
    const [selectedDate, setDate] = useState(type === "departure" ? (search[elem_index].origin_date !== null ? search[elem_index].origin_date : null) : (search[elem_index].return_date !== null ? search[elem_index].return_date : null));
    const [error, setError] = useState(false);
    const itinerary_list = useSelector((store) => {
        return store.itinerary.itinerary_list;
    });
    const start_date = useSelector((store) => {
        return store.trip.start_date;
    });
    const end_date = useSelector((store) => {
        return store.trip.end_date;
    });
    const language = useSelector(state => state.header.language);
    const [minDate, setMinDate] = useState(start_date);
    const [maxDate, setMaxDate] = useState(end_date);

    let active = null;
    let isOpen = (type === "departure" ? search[elem_index].origin_datepicker : search[elem_index].return_datepicker);
    useEffect(() => {
        handleDateChange(selectedDate);
    }, []);
    useEffect(() => {
        handleDateChange(type === "departure" ? search[elem_index].origin_date : search[elem_index].return_date);
    }, [type === "departure" ? search[elem_index].origin_date : search[elem_index].return_date]);
    useEffect(() => {
        let tmp_date = selectedDate;
        if (search[elem_index].origin_dest !== null) {
            //if (search[elem_index].origin_dest !== null && search[elem_index].origin_date === null) {
            active = itinerary_list.filter((itinerary) => {
                if (itinerary.destination !== null) {
                    return itinerary.destination.id === search[elem_index].origin_dest.id;
                }
            });
            if (active.length > 0) {
                if (type === "departure") {
                    tmp_date = moment(active[0].start_date, "YYYY-MM-DD");
                    handleDateChange(tmp_date);
                }
            } else if (type === "departure") {
                tmp_date = moment(start_date, "YYYY-MM-DD");
                handleDateChange(tmp_date);
            }
        }
        //else {
        //tmp_date = search[elem_index].origin_date;
        //console.log('tmp_date:', tmp_date);
        //handleDateChange(tmp_date);
        //}
    }, [search[elem_index].origin_dest]);
    useEffect(() => {
        let tmp_date = selectedDate;
        if (search[elem_index].return_dest !== null) {
            //if (search[elem_index].return_dest !== null && search[elem_index].return_date === null) {
            active = itinerary_list.filter((itinerary) => {
                if (itinerary.destination !== null && itinerary.destination !== undefined) {
                    return itinerary.destination.id === search[elem_index].return_dest.id;
                }
            });
            if (active.length === 0) {
                active = itinerary_list.filter((itinerary) => {
                    if (itinerary.destination !== null && itinerary.destination !== undefined) {
                        return itinerary.destination.id === search[elem_index].origin_dest.id;
                    }
                });
            }
            if (active.length > 0) {
                if (type !== "departure") {
                    if (moment(search[elem_index].origin_date).isSameOrBefore(active[0].end_date)) {
                        tmp_date = moment(active[active.length - 1].end_date, "YYYY-MM-DD");
                        handleDateChange(tmp_date);
                    }
                }
            } else if (type !== "departure" && !search[elem_index].same_dest) {
                tmp_date = moment(end_date, "YYYY-MM-DD");
                handleDateChange(tmp_date);
            }
        }
        //else {
        //handleDateChange(tmp_date);			
        //}
    }, [search[elem_index].return_dest]);
    useEffect(() => {
        if (search[elem_index].origin_dest !== null) {
            active = itinerary_list.filter((itinerary) => {
                if (itinerary.destination !== null && itinerary.destination !== undefined) {
                    return itinerary.destination.id === search[elem_index].origin_dest.id;
                }
            });
            if (active.length > 0) {
                if (type !== "departure" && search[elem_index].origin_date !== null && search[elem_index].return_date === null) {
                    if (search[elem_index].return_dest === null) {
                        let tmp_date = moment(active[0].start_date, "YYYY-MM-DD");
                        handleDateChange(tmp_date);
                    }
                }
            } else if (type !== "departure" && search[elem_index].origin_date !== null && search[elem_index].return_date === null) {
                if (search[elem_index].return_dest !== null) {
                    let tmp_date = moment(end_date, "YYYY-MM-DD");
                    handleDateChange(tmp_date);
                }
            }
        }
        //if (type !== "departure") {
        //setMinDate(search[elem_index].origin_date);
        //}
    }, [search[elem_index].origin_date]);

    useEffect(() => {
        if (search[elem_index].return_dest !== null) {
            active = itinerary_list.filter((itinerary) => {
                if (itinerary.destination !== null && itinerary.destination !== undefined) {
                    return itinerary.destination.id === search[elem_index].return_dest.id;
                }
            });
            if (active.length > 0) {
                if (type === "departure" && search[elem_index].return_date !== null) {
                    if (search[elem_index].origin_dest === null) {
                        tmp_date = moment(active[0].end_date, "YYYY-MM-DD");
                        handleDateChange(tmp_date);
                    }
                }
            }
        }
        //if (type === "departure") {
        //setMaxDate(moment(search[elem_index].return_date));
        //}
    }, [search[elem_index].return_date]);

    useEffect(() => {
        setError(search[elem_index].date_error);
    }, [search[elem_index].date_error]);

  	useEffect(() => {
        setError(type === "departure" ? search[elem_index].origin_date_error : search[elem_index].return_date_error);
  	}, [type === "departure" ? search[elem_index].origin_date_error : search[elem_index].return_date_error]);

    function handleDateChange(newValue) {
  		let tmp_search = [...search];
        tmp_search[elem_index].date_error = false;
        if (type === "departure") {
		  tmp_search[elem_index].origin_date = newValue;
		  //if (isOpen) {
		  //tmp_search[elem_index].origin_datepicker = !isOpen;
		  //tmp_search[elem_index].return_datepicker = false;
		  //}
		  if (tmp_search[elem_index].return_date !== null && moment(tmp_search[elem_index].return_date).isBefore(moment(newValue))) {
			  if (moment(newValue).isSame(moment(end_date), "day")) {
				  tmp_search[elem_index].return_date = end_date;
			  } else {
				  tmp_search[elem_index].return_date = moment(newValue).add(1, 'd');
			  }
		  }
		  if (!tmp_search[elem_index].same_dest) {
			  tmp_search[elem_index].origin_hour = GetTime("departure", itinerary_list, search, elem_index, t, language);
			  tmp_search[elem_index].origin_hour_error = false;
		  }
        } else {
		  tmp_search[elem_index].return_date = newValue;
		  //if (isOpen) {
		  //tmp_search[elem_index].return_datepicker = !isOpen;
		  //tmp_search[elem_index].origin_datepicker = false;
		  //}
		  if (tmp_search[elem_index].origin_date !== null && moment(tmp_search[elem_index].origin_date).isAfter(moment(newValue))) {
			  if (moment(newValue).isSame(moment(start_date), "day")) {
				  tmp_search[elem_index].origin_date = start_date;
			  } else {
				  tmp_search[elem_index].origin_date = moment(newValue).subtract(1, 'd');
			  }
		  }
		  if (tmp_search[elem_index].return_hour === null && tmp_search[elem_index].return_dest !== null) {
			  if (!tmp_search[elem_index].same_dest) {
				  tmp_search[elem_index].return_hour = GetTime("return", itinerary_list, search, elem_index, t, language);
				  tmp_search[elem_index].return_hour_error = false;
			  } else {
				  active = itinerary_list.filter((itinerary) => {
					  if (itinerary.destination !== null && itinerary.destination !== undefined) {
						  return itinerary.destination.id === search[elem_index].return_dest.id;
					  }
				  });
				  if (active.length > 0) {
					  tmp_search[elem_index].origin_date = moment(active[0].start_date, "YYYY-MM-DD");
				  }
				  tmp_search[elem_index].origin_hour = GetTime("departure", itinerary_list, search, elem_index, t, language);
				  tmp_search[elem_index].return_hour = GetTime("return", itinerary_list, search, elem_index, t, language);
				  tmp_search[elem_index].origin_hour_error = false;
				  tmp_search[elem_index].return_hour_error = false;
			  }
		  }
        }
  		setSearch([...tmp_search]);
        setDate(newValue);
  		setError(false);
    }
 	const renderWeekPickerDay = (date, _selectedDates, Props) => {
		let active = itinerary_list.filter((itinerary) => {
			if (itinerary.destination !== null && itinerary.destination !== undefined && (type === "departure" ? search[elem_index].origin_dest !== null : search[elem_index].return_dest !== null)) {
				let destination_name = itinerary.destination.data.name !== undefined ? itinerary.destination.data.name : itinerary.destination.data.international_name;
				let origin_name = search[elem_index].origin_dest.data !== undefined ? (search[elem_index].origin_dest.data.name || search[elem_index].origin_dest.data.international_name) : (search[elem_index].origin_dest.name || search[elem_index].origin_dest.international_name);
				let return_name = search[elem_index].return_dest !== null ? (search[elem_index].return_dest.data !== undefined && search[elem_index].return_dest.data !== null ? (search[elem_index].return_dest.data.name || search[elem_index].return_dest.data.international_name) : search[elem_index].return_dest.name || search[elem_index].return_dest.international_name) : "";
				return destination_name === (type === "departure" ? origin_name : return_name)
			}
		});
 	    let dayIsBetween = false;
 	    let isReturnDate = type !== "departure" && search[elem_index].origin_date !== null;
 	    let isDepartureDate = type === "departure" && search[elem_index].return_date !== null;
 	    const dateClone = moment(date);
 		if (active !== null) {
	 	    const selectedDateClone = moment(selectedDate ? selectedDate : new Date());
	 	    active.map((step) => {
		 	    let start = moment(step.start_date, "YYYY-MM-DD");
		 	    let end = moment(step.end_date, "YYYY-MM-DD");
		 	    if (moment(dateClone).isSameOrAfter(start) && moment(dateClone).isSameOrBefore(end)) {
		 	    	dayIsBetween = true;
		 	    }
	 	    });
 		}
 		if (dayIsBetween) {
	 	    return (
	 	    	<Tooltip title={t("cars.days_in") + (active[0].destination.data.name !== undefined ? active[0].destination.data.name : active[0].destination.data.international_name)} placement="bottom" key={date.toString()}>
	 	    		<span>
			 	    	<PickersDay 
			 	        	{...Props}
			 	        	disableMargin
			 	        	className={clsx({
			 	          		[classes.highlight]: !(isReturnDate && moment(dateClone).isSame(search[elem_index].origin_date)) && !(isDepartureDate && moment(dateClone).isSame(search[elem_index].return_date)) && dayIsBetween,
			      				[classes.newDeparture]: isReturnDate && moment(dateClone).isSame(search[elem_index].origin_date),
			      				[classes.newReturn]: isDepartureDate && moment(dateClone).isSame(search[elem_index].return_date)
			 	        	})}
			 	      	/>
	 	    		</span>
		 	    </Tooltip>
	 	    );
 		}
 		
 			return (
            <PickersDay 
			    	{...Props}
			    	disableMargin
			    	key={date.toString()}
			    	className={clsx({
			      		[classes.highlight]: !(isReturnDate && moment(dateClone).isSame(search[elem_index].origin_date)) && !(isDepartureDate && moment(dateClone).isSame(search[elem_index].return_date)) && dayIsBetween,
			      		[classes.newDeparture]: isReturnDate && moment(dateClone).isSame(search[elem_index].origin_date),
	      				[classes.newReturn]: isDepartureDate && moment(dateClone).isSame(search[elem_index].return_date)
			    	})}
			  	/>
 			);
  	};
  	const checkDate = () => {
  		if (type === "departure" && search[elem_index].origin_date === null) {
	  		let date = moment(start_date, "YYYY-MM-DD");
            handleDateChange(date);
  		} else if (type !== "departure" && search[elem_index].return_date === null) {
  			let date = moment(end_date, "YYYY-MM-DD");
            handleDateChange(date);
  		}
	  	if (!isOpen) {
	  		let tmp_search = search.slice();
	  		let copy_search = [];
	  		tmp_search.map((elem, index) => {
	  			if (index === elem_index) {
	  				let tmp_elem = Object.assign({}, elem);
	  				if (type === "departure") {
	  					tmp_elem.origin_datepicker = !isOpen;
	  					tmp_elem.return_datepicker = false;
	  				} else {
	  					tmp_elem.return_datepicker = !isOpen;
	  					tmp_elem.origin_datepicker = false;
	  				}
		  			elem = tmp_elem;
	  			}
	  			copy_search.push(elem);
	  		});
	  		setSearch(copy_search);
	  	}
  	};
  	const handleOpen = (flag) => {
  		let tmp_search = search.slice();
  		let copy_search = [];
  		tmp_search.map((elem, index) => {
  			if (index === elem_index) {
  				let tmp_elem = Object.assign({}, elem);
  				if (type === "departure") {
  					tmp_elem.origin_datepicker = flag;
  					tmp_elem.return_datepicker = false;
  				} else {
  					tmp_elem.return_datepicker = flag;
  					tmp_elem.origin_datepicker = false;
  				}
	  			elem = tmp_elem;
  			}
  			copy_search.push(elem);
  		});
  		setSearch(copy_search);
  	};
    return (
        <LocalizationProvider dateLibInstance={ moment } dateAdapter={ MomentAdapter } locale={ language }>
            {
                //<ClickAwayListener onClickAway={() => {handleOpen(false)}}>
                <DatePicker
			      	allowSameDateSelection={true}
			      	id={"datepicker-" + type}
			      	value={selectedDate}
			      	inputFormat={language === "fr" ? "DD/MM/YYYY" : "MM/DD/YYYY"}
			      	onChange={(newValue) => {
			      		handleDateChange(moment(newValue).format("YYYY-MM-DD"));
			      	}}
			      	open={isOpen}
			      	disableCloseOnSelect={true}
			      	className={`${classes.selectedDay} ${type === "departure" ? classes.selectedDeparture : classes.selectedReturn}`}
			      	renderDay={renderWeekPickerDay}
			      	onOpen={() => {
			      		handleOpen(true);
			      	}}
	      			onClose={() => {
	      				handleOpen(false);
	      			}}
	      			variant={"inline"}
	      			disableOpenPicker={true}
	      			minDate={minDate}
	      			maxDate={maxDate}
			      	renderInput={(props) => {
			      		props.InputProps.endAdornment = undefined;
			        	return (
			          		<Grid container spacing={matches ? 0 : 2}>
					            <Grid container item sm={8} md={8} xs={12} style={{ position: "relative" }}>
						            <TextField
						      			{...props}
						      			error={error}
						      			onClick={() => {
                                            checkDate();
                                        }}
						      			label={label}
						      			className={`${matches ? classes.formControlRoot : classes.formControlRoot1} ` + `${matches ? (isTablet ? (type === "departure" ? classes.inputBorder4 : classes.inputBorder1) : classes.inputBorder1) : classes.inputBorder3}`}
						      			variant={"outlined"}
						      			helperText={""}
                                        InputProps={{
                                            ...props.InputProps,
                                            classes: { notchedOutline: matches ? (isTablet ? (type === "departure" ? classes.noBorderRight : classes.noBorder) : classes.noBorder) : "" }
                                        }}
						      		/>
                                    {
                                        matches && (isTablet && type === "return") && (
                                            <div className={classes.separator}/>
                                        )
                                    }
						      	</Grid>
					            <Grid container item sm={4} md={4} xs={12} style={{ position: "relative" }}>
                                    {
                                        matches && (
                                            <div className={classes.separator}/>
                                        )
                                    }
					            	<RenderTime type={type} search={search} setSearch={setSearch} elem_index={elem_index}/>		            	
					            </Grid>		            	
				          	</Grid>
				        );
                    }
			      	}
			    />
                //</ClickAwayListener>
            }
            {
                //<DateRangePicker
                //allowSameDateSelection={true}
			 //calendars={2}
			 //value={selectedDate}
			 //onChange={(newValue) => setDate(newValue)}
			 //className={classes.selectedDay}
			 //// renderDay={renderWeekPickerDay}
			 //renderInput={(startProps, endProps) => (
		  //<Grid container spacing={matches ? 0 : 2}>
                //<Grid container item sm={4} xs={12}>
                //<TextField
                //{...startProps}
                //error={error}
                //label={t("cars.date_origin")}
                //className={`${classes.formControlRoot} ` + `${matches ? (isTablet ? classes.inputBorder4 : classes.inputBorder1) : classes.inputBorder3}`}
                //variant={"outlined"}
                //helperText={""}
                ///>
                //</Grid>
                //<Grid container item sm={2} xs={12}>
                //<RenderTime type={"departure"} search={search} setSearch={setSearch} elem_index={elem_index}/>		            	
                //</Grid>
			            	
                //<Grid container item sm={4} xs={12}>
                //<TextField
                //{...endProps}
                //error={error}
                //label={t("cars.date_return")}
                //className={`${classes.formControlRoot} ` + `${matches ? classes.inputBorder1 : classes.inputBorder3}`}
                //variant={"outlined"}
                //helperText={""}
                ///>
                //</Grid>
                //<Grid container item sm={2} xs={12}>
                //<RenderTime type={"return"} search={search} setSearch={setSearch} elem_index={elem_index}/>
                //</Grid>
			 //</Grid>
			 //)}
		  ///>
            }
        </LocalizationProvider>
    );
};
export default DatePickerInput;
