const initial_state = {
    cart_to_book: [],
    old_cart: [],
    confirmation_booking_modal: false,
    booking_modal: false,
    modal_state: null,
    booking_info: {},
    modal_detail_booking: false,
    modal_multi_room_detail_booking: false,
    room_booked_info: null,
    room_booked_info_multi: null,
    booking_info_type: null,
    error: [],
    is_booking: false,
    booking_launched: 0,
    checks_passed: null,
    same_traveler_id: [],
    nb_products_refreshed: 0,
    reload_temp_travelers: true
};

export default function reducer(state = initial_state, action) {
    let new_state = Object.assign({}, state);
    switch (action.type) {
        case "persist/REHYDRATE": {
            if (action.payload !== undefined && action.payload.booking !== undefined) {
                Object.keys(action.payload.booking).forEach(key => {
                    if (new_state[key] !== undefined && key !== "panel_type") {
                        new_state[key] = action.payload.booking[key];
                    }
                });
            }
            return new_state;
        }
        case "FLUSH_REMOVE_STATE": {
            new_state = initial_state;
            return new_state;
        }
        case "SMALL_FLUSH": {
            new_state = initial_state;
            return new_state;
        }
        case "BOOKING_SET_OLD_CART": {
            new_state.old_cart = action.payload.old_cart;
            return new_state;
        }
        case "BOOKING_SET_INFO": {
            new_state.booking_info = action.payload.booking_info;
            return new_state;
        }
        case "BOOKING_SET_CART_TO_BOOK": {
            new_state.cart_to_book = action.payload.cart_to_book;
            return new_state;
        }
        case "BOOKING_CHANGE_MODAL_STATE": {
            new_state.modal_state = action.payload.modal_state;
            return new_state;
        }
        case "BOOKING_CHANGE_MODAL_STATE_AND_BOOKING_STATE": {
            new_state.modal_state = action.payload.modal_state;
            new_state.is_booking = action.payload.is_booking;
            return new_state;
        }
        case "BOOKING_SET_IS_BOOKING": {
            new_state.is_booking = action.payload.is_booking;
            return new_state;
        }
        case "BOOKING_CHANGE_MODAL_STATE_AND_SET_ERROR": {
            new_state.modal_state = action.payload.modal_state;
            new_state.error = action.payload.error;
            return new_state;
        }
        case "BOOKING_TOGGLE_MODAL_MULTI_ROOM_DETAIL_BOOKING": {
            new_state.modal_multi_room_detail_booking = action.payload.modal_multi_room_detail_booking;
            new_state.room_booked_info_multi = action.payload.room_booked_info_multi;
            return new_state;
        }
        case "BOOKING_TOGGLE_MODAL_DETAIL_BOOKING": {
            new_state.modal_detail_booking = action.payload.modal_detail_booking;
            new_state.room_booked_info = action.payload.room_booked_info;
            new_state.booking_info_type = action.payload.booking_info_type;
            return new_state;
        }
        case "BOOKING_MODAL": {
            new_state.booking_modal = action.payload.booking_modal;
            return new_state;
        }
        case "BOOKING_ADD_CART_TO_BOOK": {
            new_state.cart_to_book = [].concat(new_state.cart_to_book, action.payload.cart_to_book);
            return new_state;
        }
        case "BOOKING_REMOVE_FROM_TO_BOOK": {
            let new_cart_to_book = new_state.cart_to_book.slice();
            new_cart_to_book.splice(action.payload.index, 1);
            new_state.cart_to_book = new_cart_to_book;
            return new_state;
        }
        case "BOOKING_ADD_BOOKING_LAUNCHED": {
            new_state.booking_launched += action.payload.number;
            return new_state;
        }
        case "BOOKING_RESET_LAUNCHED": {
            new_state.booking_launched = 0;
            return new_state;
        }
        case "BOOKING_SET_CHECKS_PASSED": {
            new_state.checks_passed = action.payload.checks_passed;
            return new_state
        }
        case "BOOKING_SET_SAME_TRAVELER_ID": {
            new_state.same_traveler_id = action.payload.same_traveler_id;
            return new_state
        }
        case "BOOKING_ADD_REFRESHED_PRODUCT": {
            new_state.nb_products_refreshed = new_state.nb_products_refreshed + 1;
            return new_state
        }
        case "BOOKING_EMPTY_REFRESHED_PRODUCT": {
            new_state.nb_products_refreshed = 0;
            return new_state
        }
        case "BOOKING_RELOAD_TEMP_TRAVELERS": {
            new_state.reload_temp_travelers = action.payload.reload_temp_travelers;
            return new_state
        }
        case "BOOKING_ADD_ERROR": {
            let temp_error = new_state.error.slice();
            temp_error.push(action.payload.error);
            new_state.error = temp_error;
            return new_state
        }
        case "BOOKING_EMPTY_ERROR": {
            new_state.error = [];
            return new_state
        }
    }
    return new_state;
}
