import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Paper from '@material-ui/core/Paper';
import Typography from "@material-ui/core/Typography";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';

import RenderForm from './RenderForm.js';
import { Alert, AlertTitle } from "@mui/material";

const useStyles = makeStyles(theme => ({
	addSearch: {
		float: 'right',
		cursor: 'pointer',
		textDecoration: 'underline'
	},
	deleteIcon: {
		float: 'right',
		cursor: 'pointer',
		marginTop: '4px'
	},
	checkboxLabel: {
		...theme.typography.overline,
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.55rem'
		}
	},
	formShadow: {
		borderRadius: 15,
		boxShadow: '0 1px 6px rgba(32,33,36,0.28)'
	},
	youngDriver: {
		marginTop: 10
	}
}));

const RenderInput = ({ last_elem, elem, elem_index, search, setSearch, edit, initState, supplementProvider }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const theme = useTheme();
	// const matches = useMediaQuery(theme.breakpoints.up('sm'));
	const matches = useMediaQuery('(min-width:600px)');
	const [youngDriver, setYoungDriver] = useState(false);
	function addSearch() {
		let tmp_search = search.slice();
		tmp_search.push(initState);
		setSearch(tmp_search);
	}
	function toggleSameDest() {
		let tmp_search = search.slice();
		let copy_search = [];
		tmp_search.map((search, search_index) => {
			if (search_index === elem_index) {
				let cpy_search = Object.assign({}, search);
				cpy_search.same_dest = !cpy_search.same_dest;
				if (cpy_search.same_dest) {
					cpy_search.return_dest = cpy_search.origin_dest;
				}
				search =  cpy_search;
			}
			copy_search.push(search);
		});
		setSearch(copy_search);
	}
	function deleteSearch() {
		let tmp_search = search.filter((tmp, tmp_index) => {
			return (tmp_index !== elem_index);
		});
		setSearch(tmp_search);
	}
	return (
		<Fragment>
			<Grid container spacing={matches ? 0 : 2}>
				{
					search.length > 1 && (
						<Fragment>
							<Grid item xs={1}/>
							<Grid item xs={10}>
								<Typography align={"center"} variant={"h6"}>
									{"Location " + (elem_index + 1)}
								</Typography>
							</Grid>
							{
								!edit && (
									<Grid item xs={1}>
										<CloseIcon className={classes.deleteIcon} onClick={() => {deleteSearch()}}/>
									</Grid>
								)
							}
						</Fragment>
					)
				}
				<Grid item sm={6} xs={12}>
					<FormControlLabel
					    control={<Checkbox checked={elem.same_dest} onChange={() => {toggleSameDest()}} color={"primary"} />}
					    label={ <span className={classes.checkboxLabel}>{t("cars.different_location")}</span> }
					/>
				</Grid>
			</Grid>
			{
				matches && (
					<Paper elevation={0} variant="outlined" className={classes.formShadow}>
						<RenderForm search={search} elem={elem} elem_index={elem_index} setSearch={setSearch} setYoungDriver={setYoungDriver}/>
					</Paper>
				)
			}
			{
				!matches && (
					<RenderForm search={search} elem={elem} elem_index={elem_index} setSearch={setSearch} setYoungDriver={setYoungDriver}/>
				)
			}
			{
				youngDriver && supplementProvider.length > 0 && (
					<Alert severity={ 'warning' } variant={ 'filled' } className={classes.youngDriver}>
						<AlertTitle>{t("cars.young-driver-title")}</AlertTitle>
						{t("cars.young-driver", {providers: supplementProvider.join(", ")})}
					</Alert>
				)
			}
			{
				last_elem && !edit && (
					<Typography variant="overline" className={ classes.addSearch }  onClick={() => {addSearch()}}>{t("cars.add_rental")}</Typography>
				)
			}
		</Fragment>
	)
}
export default RenderInput;