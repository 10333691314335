import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles, useTheme } from "@material-ui/core";

import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

import i18n from "../../../i18n.jsx";
import CountrySearch from "./CountrySearch.js";

import moment from 'moment';
import clsx from "clsx";
import Notify from "../../Common/Notify.js";

const useStyles = makeStyles((theme) => ({
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
    fontWeight400: {
        fontWeight: 400
    },
    fontSize10: {
        fontSize: 10
    },
    fontSize16: {
        fontSize: 16
    },
    upperCase: {
        textTransform: "uppercase"
    },
    letterSpacing: {
        letterSpacing: 1.25
    },
    formControlRoot1: {
        "width": "100%",
        "& .mui-jss-MuiOutlinedInput-input": {
            padding: '18.5px 14px;'
        },
        "&& .mui-jss-MuiSvgIcon-root": {
            color: "black"
        },
        "& .mui-jss-MuiFormLabel-root": {
            fontWeight: 400,
            fontSize: 15
            //zIndex: -1
        }
    },
    formControlRootRed: {
        "width": "100%",
        "& .mui-jss-MuiOutlinedInput-input": {
            padding: '18.5px 14px;'
        },
        "& .mui-jss-MuiOutlinedInput-notchedOutline": {
            borderColor: "red"
        },
        "&& .mui-jss-MuiSvgIcon-root": {
            color: "black"
        },
        "& .mui-jss-MuiFormLabel-root": {
            fontWeight: 400,
            fontSize: 15
            //zIndex: -1
        }
    },
    formControlRootMobile1: {
        "width": "100%",
        "& .mui-jss-MuiOutlinedInput-input": {
            padding: '18.5px 14px;'
        },
        "&& .mui-jss-MuiSvgIcon-root": {
            color: "black"
        },
        "& .mui-jss-MuiFormLabel-root": {
            fontWeight: 400,
            fontSize: 12
            //zIndex: -1
        },
        "& .mui-jss-MuiInputLabel-shrink": {
            fontWeight: 400,
            fontSize: 12
            //zIndex: -1
        }
    },
    formControlRootMobileRed: {
        "width": "100%",
        "& .mui-jss-MuiOutlinedInput-input": {
            padding: '18.5px 14px;'
        },
        "& .mui-jss-MuiOutlinedInput-notchedOutline": {
            borderColor: "red"
        },
        "&& .mui-jss-MuiSvgIcon-root": {
            color: "black"
        },
        "& .mui-jss-MuiFormLabel-root": {
            fontWeight: 400,
            fontSize: 12
            //zIndex: -1
        },
        "& .mui-jss-MuiInputLabel-shrink": {
            fontWeight: 400,
            fontSize: 12
            //zIndex: -1
        }
    },
    formControlRootSmall: {
        "width": "100%",
        "& .mui-jss-MuiOutlinedInput-input": {
            padding: '7px 20px;'
        },
        "&& .mui-jss-MuiSvgIcon-root": {
            color: "black"
        },
        "& .mui-jss-MuiFormLabel-root": {
            fontWeight: 400,
            fontSize: 12
            //zIndex: -1
        },
        "& .mui-jss-MuiInputLabel-outlined": {
	    	transform: 'translate(20px, 10px) scale(1)'
	    },
        "& .mui-jss-MuiInputLabel-outlined.mui-jss-MuiInputLabel-shrink": {
	    	transform: 'translate(14px, -6px) scale(0.75)',
            zIndex: 1
	    }
    },
    formControlRootSmallRed: {
        "width": "100%",
        "& .mui-jss-MuiOutlinedInput-input": {
            padding: '7px 20px;'
        },
        "& .mui-jss-MuiOutlinedInput-notchedOutline": {
            borderColor: "red"
        },
        "&& .mui-jss-MuiSvgIcon-root": {
            color: "black"
        },
        "& .mui-jss-MuiFormLabel-root": {
            fontWeight: 400,
            fontSize: 12
            //zIndex: -1
        },
        "&& .mui-jss-MuiInputLabel-outlined": {
	    	transform: 'translate(20px, 10px) scale(1)'
	    },
        "& .mui-jss-MuiInputLabel-outlined.mui-jss-MuiInputLabel-shrink": {
	    	transform: 'translate(14px, -6px) scale(0.75)',
            zIndex: 1
	    }
    },
    noWrap: {
        whiteSpace: "nowrap"
    },
    outlinedInput: {
        padding: "7px 30px 7px 20px;"
    },
    circleBorder: {
        borderRadius: 8
    },
    errorInfoRight: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: 900,
        letterSpacing: 1.25,
        color: "red",
        width: "100%",
        textAlign: "right",
        fontSize: 10
    },
    errorInfoMobile: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: 900,
        letterSpacing: 1.25,
        color: "red",
        width: "100%",
        textAlign: "center",
        fontSize: 10
    },
    errorInfo: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: 900,
        letterSpacing: 1.25,
        color: "red",
        width: "100%",
        fontSize: 10
    },
    errorPadding: {
        paddingLeft: 14
    },
    errorPaddingMobile: {
        paddingLeft: 10
    }
}));


export const TravelerInfo = (({ tempTravelers, setTempTravelers, traveler, tempContactLead, setTempContactLead, contact_lead_option, redFormControl, nationality_option, size, isFrom }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();

    const checks_passed = useSelector(store => store.booking.checks_passed);
    const same_traveler_id = useSelector(store => store.booking.same_traveler_id);
    const data_trip = useSelector((store) => store.trip.data_trip);
    const end_date = useSelector((store) => store.trip.end_date);

    const [titleError, setTitleError] = useState(false);
    const [firstNameError, setFirstNameError] = useState(false);
    const [lastNameError, setLastNameError] = useState(false);
    const [birthdateError, setBirthdateError] = useState(false);

    const downDesktop = useMediaQuery(theme.breakpoints.down('sm'));
    const titleLabel = useRef(null);
    const titleLabelWidth = titleLabel.current ? titleLabel.current.clientWidth : 33;
    const firstNameLabel = useRef(null);
    const firstNameLabelWidth = firstNameLabel.current ? firstNameLabel.current.clientWidth : 53;
    const lastNameLabel = useRef(null);
    const lastNameLabelWidth = lastNameLabel.current ? lastNameLabel.current.clientWidth : 33;
    const quotation_code = JSON.parse(localStorage.getItem("config")).quotation_code;
    const isDisabled = quotation_code === "cercledesvoyages" ? (data_trip !== null && data_trip.status_contract === "CONFIRMED" && data_trip.status_modification === "FIXED_PV" && isFrom === "tripList") : false;
    useEffect(() => {
        if (checks_passed !== null && checks_passed === true) {
            setTitleError(false);
            setFirstNameError(false);
            setLastNameError(false);
            setBirthdateError(false);
        } else if (checks_passed === false) {
            checkTitle();
            checkFirstName();
            checkLastName();
            checkBirthDate();
        }
    }, [checks_passed, same_traveler_id]);

    const checkTitle = () => {
        let temp_title_error = false;
        if (same_traveler_id.some(item => item.id === traveler.id)) {
            temp_title_error = true;
        }
        setTitleError(temp_title_error);
    };

    const checkFirstName = () => {
        let temp_first_name_error = false;

        if (traveler.first_name === "") {
            temp_first_name_error = true;
        }
        if (same_traveler_id.some(item => item.id === traveler.id)) {
            temp_first_name_error = true;
            setTitleError(true);
            setBirthdateError(true);
        }
        if (traveler.first_name !== null && traveler.first_name.match(/^[A-Za-z _]+$/) === null) {
            temp_first_name_error = true;
        }
        setFirstNameError(temp_first_name_error);
    };

    const checkLastName = () => {
        let temp_last_name_error = false;

        if (traveler.last_name === "") {
            temp_last_name_error = true;
        }
        if (same_traveler_id.some(item => item.id === traveler.id)) {
            temp_last_name_error = true;
            setTitleError(true);
            setBirthdateError(true);
        }
        if (traveler.last_name !== null && traveler.last_name.match(/^[A-Za-z _]+$/) === null) {
            temp_last_name_error = true;
        }
        setLastNameError(temp_last_name_error);
    };

    const checkBirthDate = () => {
        let temp_date_error = false;
        if (same_traveler_id.some(item => item.id === traveler.id)) {
            temp_date_error = true;
        }
        setBirthdateError(temp_date_error);
    };

    const handleTitleChange = (e) => {
        if (contact_lead_option || traveler.id === tempContactLead.id) {
            let new_contact_lead = Object.assign({}, traveler);
            new_contact_lead.title = e.target.value;
            new_contact_lead.address = tempContactLead.address;
            new_contact_lead.address_details = tempContactLead.address_details;
            new_contact_lead.city = tempContactLead.city;
            new_contact_lead.postal_code = tempContactLead.postal_code;
            setTempContactLead(new_contact_lead);
        }
        for (traveler_index = 0; traveler_index < tempTravelers.length; traveler_index++) {
            if (tempTravelers[traveler_index].id === traveler.id) {
                let newTravelers = tempTravelers.slice();
                traveler.title = e.target.value;
                newTravelers[traveler_index] = traveler;
                setTempTravelers(newTravelers);
            }
        }
    };

    const handleFirstName = (e) => {
        if (contact_lead_option || traveler.id === tempContactLead.id) {
            let new_contact_lead = Object.assign({}, traveler);
            new_contact_lead.first_name = e.target.value;
            new_contact_lead.address = tempContactLead.address;
            new_contact_lead.address_details = tempContactLead.address_details;
            new_contact_lead.city = tempContactLead.city;
            new_contact_lead.postal_code = tempContactLead.postal_code;
            new_contact_lead.default_name = false;
            setTempContactLead(new_contact_lead);
        }
        for (traveler_index = 0; traveler_index < tempTravelers.length; traveler_index++) {
            if (tempTravelers[traveler_index].id === traveler.id) {
                let newTravelers = tempTravelers.slice();
                traveler.first_name = e.target.value;
                traveler.default_name = false;
                newTravelers[traveler_index] = traveler;
                setTempTravelers(newTravelers);
            }
        }
    };

    const handleLastName = (e) => {
        if (contact_lead_option || traveler.id === tempContactLead.id) {
            let new_contact_lead = Object.assign({}, traveler);
            new_contact_lead.last_name = e.target.value;
            new_contact_lead.address = tempContactLead.address;
            new_contact_lead.address_details = tempContactLead.address_details;
            new_contact_lead.city = tempContactLead.city;
            new_contact_lead.postal_code = tempContactLead.postal_code;
            new_contact_lead.default_name = false;
            setTempContactLead(new_contact_lead);
        }
        for (traveler_index = 0; traveler_index < tempTravelers.length; traveler_index++) {
            if (tempTravelers[traveler_index].id === traveler.id) {
                let newTravelers = tempTravelers.slice();
                traveler.last_name = e.target.value;
                traveler.default_name = false;
                newTravelers[traveler_index] = traveler;
                setTempTravelers(newTravelers);
            }
        }
    };

    const handleBirthDate = (value) => {
        if (contact_lead_option || traveler.id === tempContactLead.id) {
            let new_contact_lead = Object.assign({}, traveler);
            new_contact_lead.birth_date = value !== null ? value.format("YYYY-MM-DD") : null;
            new_contact_lead.address = tempContactLead.address;
            new_contact_lead.address_details = tempContactLead.address_details;
            new_contact_lead.city = tempContactLead.city;
            new_contact_lead.postal_code = tempContactLead.postal_code;
            setTempContactLead(new_contact_lead);
        }
        for (traveler_index = 0; traveler_index < tempTravelers.length; traveler_index++) {
            if (tempTravelers[traveler_index].id === traveler.id) {
                let newTravelers = tempTravelers.slice();
                let previous_age = moment.utc(end_date).diff(moment.utc(traveler.birth_date), "y");
                let current_age = moment.utc(end_date).diff(moment.utc(value), "y");
                if (current_age !== previous_age) {
                    if ((previous_age > 18 && current_age < 18) || (previous_age < 18 && current_age > 18)) {
                        Notify(t('shared.traveler-age-warning'), {status: "warning", pos: "top-center"});
                    }
                }
                traveler.birth_date = value !== null ? value.format("YYYY-MM-DD") : null;
                newTravelers[traveler_index] = traveler;
                setTempTravelers(newTravelers);
            }
        }
    };

    if (downDesktop) {
        return (
            <Grid container alignItems="flex-start" justify="center" direction="column" style={{ padding: "10px 0px" }} spacing={2}>
                {
                    size !== "small" && (
                        <Grid item className={titleError ? classes.formControlRootMobileRed : classes.formControlRootMobile1}>
                            <Grid container alignItems="center" justify="space-between" spacing={1}>
                                <Grid item xs={4}>
                                    <FormControl variant="outlined" style={{ width: "100%" }}>
                                        <InputLabel id={`title-label-${traveler.id}${contact_lead_option ? "-lead" : ""}`} ref={titleLabel} className={classes.fontSize12}>
                                            {t("global.title")}
                                        </InputLabel>
                                        <Select
                                            disabled={isDisabled}
                                            labelId={`title-label-${traveler.id}${contact_lead_option ? "-lead" : ""}`}
                                            id={`title-choose-${traveler.id}${contact_lead_option ? "-lead" : ""}`}
                                            value={traveler.title}
                                            className={classes.circleBorder}
                                            onChange={handleTitleChange}
                                            MenuProps={{ disableScrollLock: true }}
                                            input={
                                                <OutlinedInput
                                                    name="title"
                                                    id={`title-input-${traveler.id}${contact_lead_option ? "-lead" : ""}`}
                                                    labelWidth={titleLabelWidth}
                                                    autoComplete="off"
                                                />
                                            }>
                                            <MenuItem value="MR">
                                                <Grid className={`${classes.genericText} ${classes.fontWeight400} ${classes.fontSize10} ${classes.noWrap}`}>
                                                    {t("title_value.mr")}
                                                </Grid>
                                            </MenuItem>
                                            <MenuItem value="MRS">
                                                <Grid className={`${classes.genericText} ${classes.fontWeight400} ${classes.fontSize10} ${classes.noWrap}`}>
                                                    {t("title_value.mrs")}
                                                </Grid>
                                            </MenuItem>
                                            <MenuItem value="MISS">
                                                <Grid className={`${classes.genericText} ${classes.fontWeight400} ${classes.fontSize10} ${classes.noWrap}`}>
                                                    {t("title_value.miss")}
                                                </Grid>
                                            </MenuItem>
                                            <MenuItem value="MX">
                                                <Grid className={`${classes.genericText} ${classes.fontWeight400} ${classes.fontSize10} ${classes.noWrap}`}>
                                                    {t("title_value.mx")}
                                                </Grid>
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={8}>
                                    <FormControl variant="outlined" className={firstNameError ? classes.formControlRootMobileRed : classes.formControlRootMobile1}>
                                        <InputLabel id={`first-name-label-${traveler.id}${contact_lead_option ? "-lead" : ""}`} ref={firstNameLabel} className={classes.fontSize16}>
                                            {t("menu.profile.surname")}
                                        </InputLabel>
                                        <OutlinedInput
                                            disabled={isDisabled}
                                            name={`first-name-label-${traveler.id}${contact_lead_option ? "-lead" : ""}`}
                                            id={`first-name-input-${traveler.id}${contact_lead_option ? "-lead" : ""}`}
                                            labelWidth={firstNameLabelWidth}
                                            className={classes.circleBorder}
                                            value={traveler.first_name}
                                            onChange={handleFirstName}
                                            autoComplete="off"
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            {
                                (titleError || firstNameError) && (
                                    <Grid className={classes.errorInfo}>
                                        <div className={classes.errorPaddingMobile}>
                                            { same_traveler_id.some(item => item.id === traveler.id) ? t("booking.identical_traveler") : (traveler.first_name !== "" && traveler.first_name.match(/^[A-Za-z _]+$/) === null ? t("booking.traveler_special_char_err") : t("booking.traveler_first_name_err")) }
                                        </div>
                                    </Grid>
                                )
                            }
                        </Grid>
                    )
                }
                {
                    size === "small" && (
                        <Grid item style={{ width: "100%" }}>
                            <FormControl variant="outlined" className={titleError ? classes.formControlRootSmallRed : classes.formControlRootSmall}>
                                <InputLabel id={`title-label-${traveler.id}${contact_lead_option ? "-lead" : ""}`} ref={titleLabel} className={classes.fontSize12}>
                                    {t("global.title")}
                                </InputLabel>
                                <Select
                                    disabled={isDisabled}
                                    labelId={`title-label-${traveler.id}${contact_lead_option ? "-lead" : ""}`}
                                    id={`title-choose-${traveler.id}${contact_lead_option ? "-lead" : ""}`}
                                    value={traveler.title}
                                    className={classes.circleBorder}
                                    onChange={handleTitleChange}
                                    MenuProps={{ disableScrollLock: true }}
                                    input={
                                        <OutlinedInput
                                            name="title"
                                            id={`title-input-${traveler.id}${contact_lead_option ? "-lead" : ""}`}
                                            labelWidth={titleLabelWidth}
                                            autoComplete="off"
                                        />
                                    }>
                                    <MenuItem value="MR">
                                        <Grid className={`${classes.genericText} ${classes.fontWeight400} ${classes.fontSize10} ${classes.noWrap}`}>
                                            {t("title_value.mr")}
                                        </Grid>
                                    </MenuItem>
                                    <MenuItem value="MRS">
                                        <Grid className={`${classes.genericText} ${classes.fontWeight400} ${classes.fontSize10} ${classes.noWrap}`}>
                                            {t("title_value.mrs")}
                                        </Grid>
                                    </MenuItem>
                                    <MenuItem value="MISS">
                                        <Grid className={`${classes.genericText} ${classes.fontWeight400} ${classes.fontSize10} ${classes.noWrap}`}>
                                            {t("title_value.miss")}
                                        </Grid>
                                    </MenuItem>
                                    <MenuItem value="MX">
                                        <Grid className={`${classes.genericText} ${classes.fontWeight400} ${classes.fontSize10} ${classes.noWrap}`}>
                                            {t("title_value.mx")}
                                        </Grid>
                                    </MenuItem>
                                </Select>
                            </FormControl>
                            {
                                titleError && (
                                    <Grid className={classes.errorInfo}>
                                        <div className={classes.errorPaddingMobile}>
                                            { same_traveler_id.some(item => item.id === traveler.id) ? t("booking.identical_traveler") : "" }
                                        </div>
                                    </Grid>
                                )
                            }
                        </Grid>
                    )
                }
                {
                    size === "small" && (
                        <Grid item style={{ width: "100%" }}>
                            <FormControl variant="outlined" className={`${firstNameError ? classes.formControlRootSmallRed : classes.formControlRootSmall} ${classes.circleBorder}`}>
                                <InputLabel id={`first-name-label-${traveler.id}${contact_lead_option ? "-lead" : ""}`} ref={firstNameLabel} className={classes.fontSize16}>
                                    {t("menu.profile.surname")}
                                </InputLabel>
                                <OutlinedInput
                                    disabled={isDisabled}
                                    name={`first-name-label-${traveler.id}${contact_lead_option ? "-lead" : ""}`}
                                    id={`first-name-input-${traveler.id}${contact_lead_option ? "-lead" : ""}`}
                                    labelWidth={firstNameLabelWidth}
                                    className={classes.circleBorder}
                                    value={traveler.first_name}
                                    onChange={handleFirstName}
                                    autoComplete="off"
                                />
                            </FormControl>
                            {
                                firstNameError && (
                                    <Grid className={classes.errorInfo}>
                                        <div className={classes.errorPaddingMobile}>
                                            { same_traveler_id.some(item => item.id === traveler.id) ? t("booking.identical_traveler") : (traveler.first_name !== "" && traveler.first_name.match(/^[A-Za-z _]+$/) === null ? t("booking.traveler_special_char_err") : t("booking.traveler_first_name_err")) }
                                        </div>
                                    </Grid>
                                )
                            }
                        </Grid>
                    )
                }
                <Grid item style={{ width: "100%" }}>
                    <FormControl variant="outlined" className={`${size === "small" ? (lastNameError ? classes.formControlRootSmallRed : classes.formControlRootSmall) : (lastNameError ? classes.formControlRootMobileRed : classes.formControlRootMobile1)} ${classes.circleBorder}`}>
                        <InputLabel id={`last-name-label-${traveler.id}${contact_lead_option ? "-lead" : ""}`} ref={lastNameLabel} className={classes.fontSize16}>
                            {t("global.name")}
                        </InputLabel>
                        <OutlinedInput
                            disabled={isDisabled}
                            name={`last-name-label-${traveler.id}${contact_lead_option ? "-lead" : ""}`}
                            id={`last-name-input-${traveler.id}${contact_lead_option ? "-lead" : ""}`}
                            labelWidth={lastNameLabelWidth}
                            className={classes.circleBorder}
                            value={traveler.last_name}
                            onChange={handleLastName}
                            autoComplete="off"
                        />
                        {
                            lastNameError && (
                                <Grid className={classes.errorInfo}>
                                    <div className={classes.errorPaddingMobile}>
                                        { same_traveler_id.some(item => item.id === traveler.id) ? t("booking.identical_traveler") : (traveler.last_name !== "" && traveler.last_name.match(/^[A-Za-z _]+$/) === null ? t("booking.traveler_special_char_err") : t("booking.traveler_last_name_err")) }
                                    </div>
                                </Grid>
                            )
                        }
                    </FormControl>
                </Grid>
                <Grid item style={{ width: "100%" }}>
                    <LocalizationProvider dateLibInstance={ moment } dateAdapter={ AdapterMoment } locale={ i18n.language }>
                        <Grid>
                            <DatePicker
                                disabled={isDisabled}
                                label={ t("menu.trip_list.birthdate") }
                                value={ moment.utc(new Date(traveler.birth_date), "YYYY-MM-DD") }
                                onChange={(newValue) => handleBirthDate(newValue)}
                                slotProps={{
                                    textField: {
                                        variant: 'outlined',
                                        error: birthdateError || traveler.birth_date === null,
                                        helperText: traveler.birth_date === null ?
                                            t('crm.field_required_error') : undefined
                                    }
                                }}
                            />
                        </Grid>
                        {
                            birthdateError && (
                                <Grid className={classes.errorInfo}>
                                    <div className={classes.errorPaddingMobile}>
                                        { same_traveler_id.some(item => item.id === traveler.id) ? t("booking.identical_traveler") : "" }
                                    </div>
                                </Grid>
                            )
                        }
                    </LocalizationProvider>
                </Grid>
                {
                    nationality_option && (
                        <Grid item style={{ width: "100%" }}>
                            <CountrySearch label={t("menu.trip_list.nationality")} traveler={traveler} tempTravelers={tempTravelers} setTempTravelers={setTempTravelers} tempContactLead={tempContactLead} setTempContactLead={setTempContactLead} contact_lead_option={contact_lead_option} mode={"demonym"} redFormControl={redFormControl} isFrom={"tripList"} />
                        </Grid>
                    )
                }
                {
                    nationality_option && redFormControl && traveler.nationality === "" && (
                        <Grid className={classes.errorInfoMobile} >
                            { t("booking.no_empty_field") }
                        </Grid>
                    )
                }
            </Grid>
        );
    }
    return (
        <Grid>
            <Grid container alignItems="center" justify="space-between" style={size !== "small" && !firstNameError && !lastNameError ? { padding: "16px 0px" } : { padding: "16px 0px 0px" }} spacing={1}>
                <Grid item xs={nationality_option ? 1 : 2}>
                    <FormControl variant="outlined" className={size === "small" ? (titleError ? classes.formControlRootSmallRed : classes.formControlRootSmall) : (titleError ? classes.formControlRootMobileRed : classes.formControlRoot1)}>
                        <InputLabel id={`title-label-${traveler.id}${contact_lead_option ? "-lead" : ""}`} ref={titleLabel} className={classes.fontSize16}>
                            {t("global.title")}
                        </InputLabel>
                        <Select
                            disabled={isDisabled}
                            labelId={`title-label-${traveler.id}${contact_lead_option ? "-lead" : ""}`}
                            id={`title-choose-${traveler.id}${contact_lead_option ? "-lead" : ""}`}
                            value={traveler.title}
                            className={classes.circleBorder}
                            onChange={handleTitleChange}
                            MenuProps={{ disableScrollLock: true }}
                            input={
                                <OutlinedInput
                                    name="title"
                                    id={`title-input-${traveler.id}${contact_lead_option ? "-lead" : ""}`}
                                    labelWidth={titleLabelWidth}
                                    autoComplete="off"
                                />
                            }>
                            <MenuItem value="MR">
                                <Grid className={`${classes.genericText} ${classes.fontWeight400} ${classes.fontSize10} ${classes.noWrap}`}>
                                    {t("title_value.mr")}
                                </Grid>
                            </MenuItem>
                            <MenuItem value="MRS">
                                <Grid className={`${classes.genericText} ${classes.fontWeight400} ${classes.fontSize10} ${classes.noWrap}`}>
                                    {t("title_value.mrs")}
                                </Grid>
                            </MenuItem>
                            <MenuItem value="MISS">
                                <Grid className={`${classes.genericText} ${classes.fontWeight400} ${classes.fontSize10} ${classes.noWrap}`}>
                                    {t("title_value.miss")}
                                </Grid>
                            </MenuItem>
                            <MenuItem value="MX">
                                <Grid className={`${classes.genericText} ${classes.fontWeight400} ${classes.fontSize10} ${classes.noWrap}`}>
                                    {t("title_value.mx")}
                                </Grid>
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl style={{ width: "100%" }} variant="outlined" className={size === "small" ? (firstNameError ? classes.formControlRootSmallRed : classes.formControlRootSmall) : (firstNameError ? classes.formControlRootRed : classes.formControlRoot1)}>
                        <InputLabel id={`first-name-label-${traveler.id}${contact_lead_option ? "-lead" : ""}`} ref={firstNameLabel} className={classes.fontSize16} >
                            {t("menu.profile.surname")}
                        </InputLabel>
                        <OutlinedInput
                            disabled={isDisabled}
                            name={`first-name-label-${traveler.id}${contact_lead_option ? "-lead" : ""}`}
                            id={`first-name-input-${traveler.id}${contact_lead_option ? "-lead" : ""}`}
                            labelWidth={firstNameLabelWidth}
                            className={classes.circleBorder}
                            value={traveler.first_name}
                            onChange={handleFirstName}
                            autoComplete="off"
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl style={{ width: "100%" }} variant="outlined" className={size === "small" ? (lastNameError ? classes.formControlRootSmallRed : classes.formControlRootSmall) : (lastNameError ? classes.formControlRootRed : classes.formControlRoot1)}>
                        <InputLabel id={`last-name-label-${traveler.id}${contact_lead_option ? "-lead" : ""}`} ref={lastNameLabel} className={classes.fontSize16} >
                            {t("global.name")}
                        </InputLabel>
                        <OutlinedInput
                            disabled={isDisabled}
                            name={`last-name-label-${traveler.id}${contact_lead_option ? "-lead" : ""}`}
                            id={`last-name-input-${traveler.id}${contact_lead_option ? "-lead" : ""}`}
                            labelWidth={lastNameLabelWidth}
                            className={classes.circleBorder}
                            value={traveler.last_name}
                            onChange={handleLastName}
                            autoComplete="off"
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={nationality_option ? 2 : 4}>
                    <LocalizationProvider dateLibInstance={ moment } dateAdapter={ AdapterMoment } locale={ i18n.language }>
                        <Grid>
                            <DatePicker
                                disabled={isDisabled}
                                label={ t("menu.trip_list.birthdate") }
                                value={ moment.utc(new Date(traveler.birth_date), "YYYY-MM-DD") }
                                onChange={(newValue) => handleBirthDate(newValue)}
                                slotProps={{
                                    textField: {
                                        variant: 'outlined',
                                        error: birthdateError || traveler.birth_date === null,
                                        helperText: traveler.birth_date === null ?
                                            t('crm.field_required_error') : undefined
                                    }
                                }}
                            />
                        </Grid>
                    </LocalizationProvider>
                </Grid>
                {
                    nationality_option && (
                        <Grid item xs={2}>
                            <CountrySearch label={t("menu.trip_list.nationality")} traveler={traveler} tempTravelers={tempTravelers} setTempTravelers={setTempTravelers} tempContactLead={tempContactLead} setTempContactLead={setTempContactLead} contact_lead_option={contact_lead_option} mode={"demonym"} redFormControl={redFormControl} size={size} isFrom={"tripList"}/>
                        </Grid>
                    )
                }
                {
                    nationality_option && redFormControl && traveler.nationality === "" && (
                        <Grid className={size === "small" ? classes.errorInfo : classes.errorInfoRight} >
                            { t("booking.no_empty_field") }
                        </Grid>
                    )
                }
            </Grid>
            {
                (titleError || firstNameError || lastNameError || birthdateError) && (
                    <Grid container alignItems="baseline" justify="space-between" spacing={1}>
                        <Grid item xs={size === "small" ? 2 : 1}>
                            {
                                titleError && (
                                    <Grid className={classes.errorInfo}>
                                        <div className={classes.errorPaddingMobile}>
                                            { same_traveler_id.some(item => item.id === traveler.id) ? t("booking.identical_traveler") : "" }
                                        </div>
                                    </Grid>
                                )
                            }
                        </Grid>
                        <Grid item xs={3}>
                            {
                                firstNameError && (
                                    <Grid className={classes.errorInfo}>
                                        <div className={classes.errorPadding}>
                                            { same_traveler_id.some(item => item.id === traveler.id) ? t("booking.identical_traveler") : (traveler.first_name !== "" && traveler.first_name.match(/^[A-Za-z _]+$/) === null ? t("booking.traveler_special_char_err") : t("booking.traveler_first_name_err")) }
                                        </div>
                                    </Grid>
                                )
                            }
                        </Grid>
                        <Grid item xs={3}>
                            {
                                lastNameError && (
                                    <Grid className={classes.errorInfo}>
                                        <div className={classes.errorPadding}>
                                            { same_traveler_id.some(item => item.id === traveler.id) ? t("booking.identical_traveler") : (traveler.last_name !== "" && traveler.last_name.match(/^[A-Za-z _]+$/) === null ? t("booking.traveler_special_char_err") : t("booking.traveler_last_name_err")) }
                                        </div>
                                    </Grid>
                                )
                            }
                        </Grid>
                        <Grid item xs={nationality_option ? 2 : 4}>
                            {
                                birthdateError && (
                                    <Grid className={classes.errorInfo}>
                                        <div className={classes.errorPaddingMobile}>
                                            { same_traveler_id.some(item => item.id === traveler.id) ? t("booking.identical_traveler") : "" }
                                        </div>
                                    </Grid>
                                )
                            }
                        </Grid>
                        {
                            nationality_option && <Grid item xs={2} />
                        }
                    </Grid>
                )
            }
        </Grid>
    );
});

export default React.memo(TravelerInfo);
