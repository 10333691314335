//---- Dependencies ----//
import React, { Fragment } from "react";
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

//---- Functions ----//
import GetPricesId from "../../Cart/Functions/Margin/GetPricesId.js";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

const InfoReloadFlights = ({classes}) => {
	const { t } = useTranslation();
	const user = useSelector(state => state.user.user);
	const currency = useSelector(state => state.trip.currency);
	const language = useSelector(state => state.header.tmp_language);
	const update_infos = useSelector(state => state.cart.update_infos);
	return (
		<Fragment>
            {
                (update_infos.flights).map((infos, index) => {
                    let dif_price = 0;
                    console.log('infos.new:', infos.new);
                    if (infos.new !== null && Object.keys(infos.new).length !== 0 && infos.new.error === undefined && infos.old !== null) {
                        let old_name = infos.old.segments[0].marketing_airline.commercial_name + " : " + infos.old.segments[0].origin.airport_code + " - " + infos.old.segments[0].destination.airport_code;
                        let new_name = infos.new.segments[0].marketing_airline.commercial_name + " : " + infos.new.segments[0].origin.airport_code + " - " + infos.new.segments[0].destination.airport_code;
                        dif_price = (parseFloat(infos.new.prices[GetPricesId(infos.new.prices, user.client, user, true)].selling_price) - parseFloat(infos.old.prices[GetPricesId(infos.old.prices, user.client, user, true)].selling_price));
                        return (
                            <TableRow key={index} className={classes.tableRowDialog}>
                                <TableCell> { moment(infos.new.start_date).format("DD-MM-YYYY") } </TableCell>
                                <TableCell> { "/" } </TableCell>
                                <TableCell> <strong>{ t("accommodation.flight") + infos.old.product_type }:</strong> { old_name } </TableCell>
                                <TableCell> <strong>{ t("accommodation.flight") }:</strong> { new_name } </TableCell>
                                <TableCell className={ dif_price === 0 ? null : (dif_price < 0 ? "ft-green" : "ft-red")} > { dif_price > 0 ? "+ " + Intl.NumberFormat(language, { style: "currency", currency: currency.iso_code }).format(dif_price.toFixed(2)) : Intl.NumberFormat(language, { style: "currency", currency: currency.iso_code }).format(dif_price.toFixed(2)) } </TableCell>
                            </TableRow>
                        );
                    }
                    if (infos.old !== null && (infos.new === null || (infos.new !== undefined && Object.keys(infos.new).length === 0))) {
                        let old_name = infos.old.segments[0].marketing_airline.commercial_name + " : " + infos.old.segments[0].origin.airport_code + " - " + infos.old.segments[0].destination.airport_code;
                        return (
                            <TableRow key={index} className={classes.tableRowDialog}>
                                <TableCell> { moment(infos.old.start_date).format("DD-MM-YYYY") } </TableCell>
                                <TableCell> { "/" } </TableCell>
                                <TableCell> <strong>{ t("accommodation.flight") }:</strong> { old_name } </TableCell>
                                <TableCell className={"ft-red"} > { t("itinerary.cant_change_product") } </TableCell>
                                <TableCell className={ dif_price === 0 ? null : (dif_price < 0 ? "ft-green" : "ft-red")} > / </TableCell>
                            </TableRow>
                        );
                    }
                    if (infos.old !== null && infos.new !== null && infos.new.error !== undefined) {
                        let old_name = infos.old.segments[0].marketing_airline.commercial_name + " : " + infos.old.segments[0].origin.airport_code + " - " + infos.old.segments[0].destination.airport_code;
                        return (
                            <TableRow key={index} >
                                <TableCell> { moment(infos.old.start_date).format("DD-MM-YYYY") } </TableCell>
                                <TableCell> { "/" }  </TableCell>
                                <TableCell> <strong>{ t("accommodation.flight") }:</strong> { old_name } </TableCell>
                                <TableCell className={"ft-red"}> { infos.new.error } </TableCell>
                                <TableCell className={ dif_price === 0 ? null : (dif_price < 0 ? "ft-green" : "ft-red")} > / </TableCell>
                            </TableRow>
                        );
                    }
                })
            }
        </Fragment>
	);
};

export default React.memo(InfoReloadFlights);