import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useDragDropManager } from "react-dnd";
import {
    Box,
    Button,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    Switch
} from "@mui/material";
import { useSnackbar } from "notistack";
import { groupBy, isNumber, keyBy, mapValues } from "lodash";
import { EditorState, Modifier } from "draft-js";
import { Map } from 'immutable';
import { CartConstructionContentsFormSave } from "./CartConstructionContentsFormSave";
import { CartConstructionContentsFormItemNotDragging } from "./CartConstructionContentsFormItemNotDragging";
import { CartConstructionContentsFormItemDragging } from "./CartConstructionContentsFormItemDragging";
import { CartConstructionContentsContext } from "./utils/cartConstructionContentsContext";
import { useAutoloadContents } from "./utils/autoloadContents";
import { htmlHasEmptyContent } from "../Menu/MaterialTripList/utils/htmlHasEmptyContent";
import { restoreRichEditorStateFromHtml } from "../Menu/MaterialTripList/utils/editor/restoreRichEditorStateFromHtml";
import { customColors } from "../Menu/MaterialTripList/utils/editor/customColors";
import { RichEditorStyleMap } from "../Menu/MaterialTripList/utils/editor/editor";
import {
    appendVisualEditBlocksIfEmpty,
    restoreVisualEditorBlocksFromHtmlIfEmpty
} from "../Menu/MaterialTripList/MailVisualEditor/redux/thunks";
import { clearVisualEditorBlocks } from "../Menu/MaterialTripList/MailVisualEditor/redux/actions";
import { setRecreatingTextInputs, setShowPictures, setShowProductsDescriptions } from "./redux/cartConstructionReducer";
import { copyItineraryContent } from "../Itinerary/redux/reducer";
import { Picture } from "../Menu/MaterialTripList/picture/objects/picture";
import { ItineraryContentItem } from "./objects/itineraryContentItem";
import { ItineraryByDay } from "../Itinerary/objects/itineraryByDay";
import { ItineraryByStep } from "../Itinerary/objects/itineraryByStep";
import { TextBlock } from "../Menu/MaterialTripList/MailVisualEditor/blocks/mailTemplateVisualEditorTextBlock";
import { AppState } from "../../Reducers/Reducers";

type Props = {
    stickyOffset?: number,
    locale: number,
    onChangeLocale: React.Dispatch<React.SetStateAction<number>>
}

export function CartConstructionContentsForm(props: Props): JSX.Element {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const context = useContext(CartConstructionContentsContext);
    const languages = useSelector((state: AppState) => state.user.locales);
    const currentLocale = useSelector((state: AppState) => state.header.language);
    const step = useSelector((state: AppState) => state.cart.step);
    const textsMode = useSelector((state: AppState) => state.cartConstruction.textsMode);
    const showPictures = useSelector((state: AppState) => state.cartConstruction.showPictures);
    const showProductsDescriptions = useSelector((state: AppState) => state.cartConstruction.showProductsDescriptions);
    const itineraryContent = useSelector((state: AppState) => {
        return context.version > 0 ?
            state.itinerarySlice.contentInputs[context.version] :
            undefined;
    });
    const [titleInputs, setTitleInputs] = useState<{ [id: number]: { [locale: number]: string } }>({});
    const [pictureInputs, setPictureInputs] = useState<{ [id: number]: Picture[] | null }>({});
    const steps = useMemo(() => {
        if (itineraryContent?.state === 'success') {
            const content = itineraryContent.data.mode === 'by-day' ?
                [...itineraryContent.data.content].sort((a, b) => {
                    const aDays = [...a.day];
                    const bDays = [...b.day];
                    return (aDays[0] ?? 0) < (bDays[0] ?? 0) ? -1 : 1;
                }) :
                itineraryContent.data.content;
            switch (textsMode) {
                case 'all-days': return content.map((content, index) => {
                    return {
                        mode: itineraryContent.data.mode,
                        index,
                        content
                    } as ItineraryContentItem & { index: number };
                });
                case 'current-day': return [content[step]].filter((item): item is NonNullable<typeof item> => {
                    return !!item;
                }).map((content) => {
                    return {
                        mode: itineraryContent.data.mode,
                        index: step,
                        content
                    } as ItineraryContentItem & { index: number };
                });
            }
        }
        return [];
    }, [
        step,
        textsMode,
        itineraryContent?.state === 'success' ? itineraryContent.data : null
    ]);
    const stepRefs = useRef<{importGir:() => void, contentId: number}[]>([]);
    const autoloadContents = useAutoloadContents();
    const quotationCode = JSON.parse(localStorage.getItem('config') ?? '{}').quotation_code;

    const onImportGirs = () => {
        for (const item of stepRefs.current) {
            item.importGir();
        }
        enqueueSnackbar(
            t<string>('cart-material.cart-construction-import-gir-success'),
            { variant: 'success' }
        );
    };

    const onChangeItemTitle = useCallback(
        (id: number, value: string) => {
            setTitleInputs((state) => {
                return {
                    ...state,
                    [id]: {
                        ...state[id],
                        [props.locale]: value
                    }
                };
            });
        },
        [props.locale, setTitleInputs]
    );

    const onChangeItemPicture = useCallback(
        (id: number, value: Picture[] | null) => {
            setPictureInputs((state) => {
                return {
                    ...state,
                    [id]: value
                };
            });
        },
        [setPictureInputs]
    );

    useEffect(() => {
        if (currentLocale && languages.length > 0) {
            props.onChangeLocale(
                languages.find((item) => {
                    return item.language_code === currentLocale;
                })?.id ?? 1
            );
        }
    }, [currentLocale, languages]);

    useEffect(() => {
        const showPictures = localStorage.getItem('cart-construction-contents-show-pictures');
        if (showPictures === 'false') {
            dispatch(setShowPictures(false));
        } else {
            dispatch(setShowPictures(true));
        }
    }, []);

    useEffect(() => {
        const showProductsDescriptions = localStorage.getItem('cart-construction-contents-show-products-descriptions');
        if (showProductsDescriptions === 'false') {
            dispatch(setShowProductsDescriptions(false));
        } else {
            dispatch(setShowProductsDescriptions(true));
        }
    }, []);

    useEffect(() => {
        localStorage.setItem(
            'cart-construction-contents-show-pictures',
            showPictures ? 'true' : 'false'
        );
    }, [showPictures]);

    useEffect(() => {
        localStorage.setItem(
            'cart-construction-contents-show-products-descriptions',
            showProductsDescriptions ? 'true' : 'false'
        );
    }, [showProductsDescriptions]);

    useEffect(() => {
        if (itineraryContent?.state === 'success') {
            (async () => {
                dispatch(setRecreatingTextInputs(true));
                //load texts
                for (const item of itineraryContent.data.content) {
                    let description = '';
                    const localization = item.localization.find((item) => {
                        return item.locale === props.locale;
                    });
    
                    const shortDescription = localization?.short_description ?? item.short_description ?? '';
                    const longDescription = localization?.long_description ?? item.long_description ?? '';

                    const shortDescriptionDoc = new DOMParser().parseFromString(shortDescription, 'text/html');
                    const longDescriptionDoc = new DOMParser().parseFromString(longDescription, 'text/html');
                    const areDescriptionsSame = shortDescriptionDoc.body.innerText === longDescriptionDoc.body.innerText;
    
                    if (!htmlHasEmptyContent(longDescription)) {
                        description = longDescription;
                    } else if (!htmlHasEmptyContent(shortDescription)) {
                        description = shortDescription;
                    }
    
                    if (!htmlHasEmptyContent(description)) {
                        if (itineraryContent.data.mode === 'by-day') {
                            dispatch(
                                restoreVisualEditorBlocksFromHtmlIfEmpty({
                                    instanceId: `cart-construction-by-day-long-description-${item.id}`,
                                    locale: props.locale,
                                    html: description,
                                    quotationCode
                                })
                            );
                        } else {
                            dispatch(
                                restoreVisualEditorBlocksFromHtmlIfEmpty({
                                    instanceId: `cart-construction-by-step-long-description-${item.id}`,
                                    locale: props.locale,
                                    html: description,
                                    quotationCode
                                })
                            );
                        }
                    }

                    if (!areDescriptionsSame && !htmlHasEmptyContent(longDescription) && !htmlHasEmptyContent(shortDescription)) {
                        if (itineraryContent.data.mode === 'by-day') {
                            dispatch(
                                restoreVisualEditorBlocksFromHtmlIfEmpty({
                                    instanceId: `cart-construction-by-day-short-description-${item.id}`,
                                    locale: props.locale,
                                    html: shortDescription,
                                    quotationCode
                                })
                            );
                        } else {
                            dispatch(
                                restoreVisualEditorBlocksFromHtmlIfEmpty({
                                    instanceId: `cart-construction-by-step-short-description-${item.id}`,
                                    locale: props.locale,
                                    html: shortDescription,
                                    quotationCode
                                })
                            );
                        }
                    }

                    if (areDescriptionsSame || htmlHasEmptyContent(longDescription)) {
                        if (itineraryContent.data.mode === 'by-day') {
                            dispatch(
                                clearVisualEditorBlocks({
                                    instanceId: `cart-construction-by-day-short-description-${item.id}`,
                                    locale: props.locale
                                })
                            );
                        } else {
                            dispatch(
                                clearVisualEditorBlocks({
                                    instanceId: `cart-construction-by-step-short-description-${item.id}`,
                                    locale: props.locale
                                })
                            );
                        }
                    }
                }
    
                //load product texts
                for (const item of itineraryContent.data.content) {
                    for (const interval of item.interval) {
                        for (const localization of interval.localization) {
                            if (itineraryContent.data.mode === 'by-day') {
                                dispatch(
                                    restoreVisualEditorBlocksFromHtmlIfEmpty({
                                        instanceId: `cart-construction-by-day-product-${item.id}-${interval.interval_index}`,
                                        locale: localization.locale,
                                        html: localization?.interval_description,
                                        quotationCode
                                    })
                                );
                            } else {
                                dispatch(
                                    restoreVisualEditorBlocksFromHtmlIfEmpty({
                                        instanceId: `cart-construction-by-step-product-${item.id}-${interval.interval_index}`,
                                        locale: localization.locale,
                                        html: localization?.interval_description,
                                        quotationCode
                                    })
                                );
                            }
                        }
                        const localizationIndex = interval.localization.findIndex((item) => {
                            return item.locale === props.locale;
                        });
                        if (localizationIndex < 0) {
                            if (itineraryContent.data.mode === 'by-day') {
                                dispatch(
                                    restoreVisualEditorBlocksFromHtmlIfEmpty({
                                        instanceId: `cart-construction-by-day-product-${item.id}-${interval.interval_index}`,
                                        locale: props.locale,
                                        html: interval.interval_description,
                                        quotationCode
                                    })
                                );
                            } else {
                                dispatch(
                                    restoreVisualEditorBlocksFromHtmlIfEmpty({
                                        instanceId: `cart-construction-by-step-product-${item.id}-${interval.interval_index}`,
                                        locale: props.locale,
                                        html: interval.interval_description,
                                        quotationCode
                                    })
                                );
                            }
                        }
                    }
                }
    
                //load titles and pictures
                for (const item of itineraryContent.data.content) {
                    if (item.localization.length > 0) {
                        setTitleInputs((state) => {
                            return {
                                ...state,
                                [item.id]: mapValues(
                                    groupBy(
                                        item.localization,
                                        (item) => item.locale
                                    ),
                                    (localization) => {
                                        return localization[0]?.title ??
                                                item.title ??
                                                '';
                                    }
                                )
                            };
                        });
                    } else {
                        setTitleInputs((state) => {
                            return {
                                ...state,
                                [item.id]: { [props.locale]: item.title ?? '' }
                            };
                        });
                    }
                }
                setPictureInputs(
                    mapValues(
                        keyBy(
                            itineraryContent.data.content as (ItineraryByDay | ItineraryByStep)[],
                            (item) => item.id
                        ),
                        (item) => item.pictures
                    )
                );
    
                await autoloadContents(
                    itineraryContent.data,
                    {
                        locale: props.locale,
                        onChangeItemTitle,
                        onChangeItemText(id, texts) {
                            const blocks = texts.map((text) => {
                                const styles = {
                                    ...RichEditorStyleMap,
                                    ...(quotationCode ? customColors[quotationCode] : null)
                                };
                                const block = new TextBlock();
        
                                const editorState = restoreRichEditorStateFromHtml(
                                    styles,
                                    text
                                );
                                let result = editorState;
                                const currentContent = result.getCurrentContent();
                                let selection = result.getSelection();
                                selection = selection.merge({
                                    anchorKey: currentContent.getFirstBlock().getKey(),
                                    anchorOffset: 0,  
                                  
                                    focusOffset: currentContent.getLastBlock().getText().length, 
                                    focusKey: currentContent.getLastBlock().getKey()
                                });
                                const newState = Modifier.mergeBlockData(
                                    result.getCurrentContent(),
                                    selection,
                                    Map({ alignment: 'justify' })
                                );
                                result = EditorState.push(result, newState, 'change-block-data');
        
                                block.setOptions({
                                    ...block.getOptions(),
                                    editorState: result
                                });
                                return block;
                            });
                            if (blocks.length > 0) {
                                dispatch(
                                    appendVisualEditBlocksIfEmpty({
                                        instanceId: `cart-construction-by-step-long-description-${id}`,
                                        locale: props.locale,
                                        blocks
                                    })
                                );
                            }
                        },
                        onChangeItemLongText(id, texts) {
                            const blocks = texts.map((text) => {
                                const styles = {
                                    ...RichEditorStyleMap,
                                    ...(quotationCode ? customColors[quotationCode] : null)
                                };
                                const block = new TextBlock();
        
                                const editorState = restoreRichEditorStateFromHtml(
                                    styles,
                                    text
                                );
                                let result = editorState;
                                const currentContent = result.getCurrentContent();
                                let selection = result.getSelection();
                                selection = selection.merge({
                                    anchorKey: currentContent.getFirstBlock().getKey(),
                                    anchorOffset: 0,  
                                  
                                    focusOffset: currentContent.getLastBlock().getText().length, 
                                    focusKey: currentContent.getLastBlock().getKey()
                                });
                                const newState = Modifier.mergeBlockData(
                                    result.getCurrentContent(),
                                    selection,
                                    Map({ alignment: 'justify' })
                                );
                                result = EditorState.push(result, newState, 'change-block-data');
        
                                block.setOptions({
                                    ...block.getOptions(),
                                    editorState: result
                                });
                                return block;
                            });
                            if (blocks.length > 0) {
                                dispatch(
                                    appendVisualEditBlocksIfEmpty({
                                        instanceId: `cart-construction-by-step-short-description-${id}`,
                                        locale: props.locale,
                                        blocks
                                    })
                                );
                            }
                        },
                        onChangeItemPictures(id, pictures) {
                            setPictureInputs((state) => ({
                                ...state,
                                [id]: pictures
                            }));
                        }
                    }
                );
                dispatch(setRecreatingTextInputs(false));
            })();
        }
    }, [
        itineraryContent?.state,
        itineraryContent?.state === 'success' ? itineraryContent.data : null,
        props.locale
    ]);

    useEffect(() => {
        return () => {
            dispatch(copyItineraryContent());
        };
    }, []);

    return (
        <>
            <Grid container justifyContent="space-between" sx={{ marginBottom: 2.5 }}>
                {
                    context.mode === 'text' &&
                    <Grid item xs={12} md={4}>
                        <FormControl size="small" fullWidth>
                            <InputLabel>{t<string>('shared.language')}</InputLabel>
                            <Select
                                value={props.locale}
                                label={t<string>('shared.language')}
                                onChange={(event) => isNumber(event.target.value) && props.onChangeLocale(event.target.value)}
                            >
                                {
                                    languages.map((language) => (
                                        <MenuItem key={language.id} value={language.id}>
                                            {language.full_name}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                }
                <Grid item xs={12} md={context.mode === 'text' ? 6 : undefined}>
                    <Stack direction="row" justifyContent={{ md: 'flex-end' }} alignItems="center">
                        <FormControlLabel
                            label={t<string>('cart-material.cart-construction-products-descriptions-switch')}
                            control={
                                <Switch size="small" checked={showProductsDescriptions} />
                            }
                            onChange={(_, checked) => dispatch(setShowProductsDescriptions(checked))}
                        />
                        {
                            context.mode === 'text' &&
                            <FormControlLabel
                                label={t<string>('cart-material.cart-construction-pictures-switch')}
                                control={
                                    <Switch size="small" checked={showPictures} />
                                }
                                onChange={(_, checked) => dispatch(setShowPictures(checked))}
                            />
                        }
                    </Stack>
                    <Button
                        variant="outlined"
                        onClick={onImportGirs}
                        sx={{ display: 'flex', marginLeft: 'auto' }}
                    >
                        {t<string>('cart-material.cart-construction-import-gir')}
                    </Button>
                </Grid>
            </Grid>
            <DndWrapper>
                {
                    (isDragging) => (
                        <Stack
                            direction={
                                isDragging ?
                                    'row' :
                                    'column'
                            }
                            justifyContent="center"
                            flexWrap="wrap"
                            sx={{
                                'gap': '10px',
                                '& > div': {
                                    flexBasis: '20%'
                                }
                            }}
                        >
                            {
                                steps.map((item, index, array) => {
                                    let dayOffset = 0;

                                    if (item.mode === 'by-day' && (item.content.circuit || item.content.iti_type)) {
                                        let blockHead: typeof item | null = null;

                                        for (let i = index; i >= 0; i--) {
                                            const current = array[i]! as typeof item;
                                            if (
                                                current.mode === 'by-day' &&
                                                (
                                                    current.content.circuit === item.content.circuit ||
                                                    current.content.iti_type === item.content.iti_type
                                                ) &&
                                                current.content.circuit_trip_version === item.content.circuit_trip_version
                                            ) {
                                                blockHead = current;
                                            } else {
                                                break;
                                            }
                                        }

                                        if (blockHead) {
                                            dayOffset = blockHead.content.day[0]! - 1;
                                        }
                                    }

                                    let prevStepIsCircuit = index > 0 ? steps[index - 1]?.content : {circuit: null, circuit_trip_version: null};

                                    return (
                                        <Box
                                            key={item.content.id}
                                            sx={{
                                                "maxWidth": '100%',
                                                '& .DraftEditor-editorContainer': {
                                                    zIndex: '0 !important'
                                                }
                                            }}
                                        >
                                            <Box sx={{ display: isDragging ? 'none' : undefined }}>
                                                <CartConstructionContentsFormItemNotDragging
                                                    {...item}
                                                    ref={(instance) => {
                                                        if (instance) {
                                                            stepRefs.current = stepRefs.current.filter((item) => {
                                                                return item.contentId !== instance.contentId;
                                                            });
                                                            stepRefs.current.push(instance);
                                                        }
                                                    }}
                                                    prevStepIsCircuit={prevStepIsCircuit}
                                                    dayOffset={dayOffset}
                                                    index={item.index}
                                                    locale={props.locale}
                                                    titles={titleInputs[item.content.id] ?? {}}
                                                    pictures={pictureInputs[item.content.id] ?? null}
                                                    stickyOffset={props.stickyOffset}
                                                    onChangeItemTitle={onChangeItemTitle}
                                                    onChangeItemPictures={onChangeItemPicture}
                                                />
                                            </Box>
                                            <Box sx={{ display: !isDragging ? 'none' : undefined }}>
                                                <CartConstructionContentsFormItemDragging
                                                    {...item}
                                                    index={item.index}
                                                    locale={props.locale}
                                                    titles={titleInputs[item.content.id] ?? {}}
                                                    pictures={pictureInputs[item.content.id] ?? null}
                                                    stickyOffset={props.stickyOffset}
                                                    onChangeItemTitle={onChangeItemTitle}
                                                    onChangeItemPictures={onChangeItemPicture}
                                                />
                                            </Box>
                                        </Box>
                                    );
                                })
                            }
                        </Stack>
                    )
                }
            </DndWrapper>
            <CartConstructionContentsFormSave
                titles={titleInputs}
                pictures={pictureInputs}
            />
        </>
    );
}

type DndWrapperProps = Pick<Props, 'stickyOffset'> & {
    children: (isDragging: boolean) => JSX.Element
}

function DndWrapper(props: DndWrapperProps): JSX.Element {
    const [isDragging, setIsDragging] = useState(false);
    const scrollHandler = useCallback((event: MouseEvent) => {
        const stickyOffset = 70 + (props.stickyOffset ?? 0) + 100;
        if (stickyOffset <= event.screenY && event.screenY <= stickyOffset + 50) {
            scrollBy(0, -100);
        }
    }, [props.stickyOffset]);
    const dragDropManager = useDragDropManager();

    useEffect(() => {
        const monitor = dragDropManager.getMonitor();
        return monitor.subscribeToStateChange(() => {
            if (monitor.isDragging()) {
                document.addEventListener('dragover', scrollHandler);
            } else {
                document.removeEventListener('dragover', scrollHandler);
            }
        });
    }, [dragDropManager, scrollHandler]);

    useEffect(() => {
        const monitor = dragDropManager.getMonitor();
        return monitor.subscribeToStateChange(() => {
            const type = monitor.getItemType();
            const isDragging = monitor.isDragging();
            //@see https://stackoverflow.com/questions/28408720/jquery-changing-the-dom-on-dragstart-event-fires-dragend-immediately
            setTimeout(() => {
                if (
                    type === 'cart-construction-contents-product' &&
                    isDragging
                ) {
                    setIsDragging(true);
                } else if (!isDragging) {
                    setIsDragging(false);
                }
            }, 0);
        });
    }, [dragDropManager]);

    return props.children(isDragging);
}
