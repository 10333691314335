import React, { Fragment } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTranslation } from "react-i18next";

import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';

function TabPanel(props) {
	const { children, value, index, ...other } = props;
  	return (
    	<div
    		role="tabpanel"
      		hidden={value !== index}
      		id={`simple-tabpanel-${index}`}
      		aria-labelledby={`simple-tab-${index}`}
      		{...other}
    	>
      	{value === index && (
        	<Fragment>
      		{children}
        	</Fragment>
      	)}
    	</div>
	);
}
function a11yProps(index) {
	return {
		id: `full-width-tab-${index}`,
    	'aria-controls': `full-width-tabpanel-${index}`,
  	};
}
const useStyles = makeStyles(theme => ({
	pointer: {
		cursor: 'pointer'
	},
	agencies: {
		overflowY: "auto",
		maxHeight: 452
	},
	orangeButton: {
		backgroundColor: "#E6592F",
		color: "white"
	},
	marginBottom30: {
		marginBottom: 30
	}
}));

const RenderStation = ({tab_index, handleChange, stations_arr, handleToggle, setHovered, checked, checked_return, nextTabRef, contractRef, handleContract, setTab, card}) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.up('sm'));
	const onMouseEnter = station_index => event => {
        setHovered(station_index);
	};
	const onMouseLeave = () => {
	    setHovered(null);
	};
	return (
		<Fragment>
			<Tabs value={tab_index} onChange={handleChange}>
				<Tab label={t("cars.agency_origin")} {...a11yProps(0)} />
				{
					stations_arr.return.length > 0 && (
						<Tab label={t("cars.agency_return")} {...a11yProps(1)} />
					)
				}
			</Tabs>
			<TabPanel value={tab_index} index={0} stations_arr={stations_arr} className={`${matches ? `${matches ? classes.agencies : ""}` : ""}`}>
				<Grid container>
					<Grid item>
						<List>
				        {
				        	stations_arr.pick_up.map((station, station_index) => {
        		        		if ((station.address_line !== null && station.address_line !== "") || (station.latitude !== null && station.longitude !== null)) {
        		        			const labelId = `checkbox-list-label-${station_index}`;
									let station_price = card.station_prices.find((el) => {
										return el.pickup_station === station.code;
									});
									if (station_price !== undefined) {
										return (
											<ListItem className={classes.pointer} key={station_index} role={undefined} dense button onClick={handleToggle(station_index, "departure")} onMouseEnter={ onMouseEnter(station_index) } onMouseLeave={ onMouseLeave }>
												<ListItemIcon>
													<Checkbox
														edge="start"
														checked={checked === station_index}
														tabIndex={-1}
														disableRipple
														inputProps={{ 'aria-labelledby': labelId }}
													  />
												</ListItemIcon>
												<ListItemText primary={station.name + (station.address_line !== "" && station.address_line !== null  ? " : " + station.address_line : "")}/>
											</ListItem>
										)
									}
        		        		}
				        	})
				        }
						</List>
					</Grid>
					{
						checked !== null && (
							<Fragment>
								<Grid item container justify={"center"} alignItems={"center"}>
								{
									stations_arr.return.length > 0 && (
										<Button className={`${classes.orangeButton} ${!matches ? classes.marginBottom30 : ""}`} onClick={() => {setTab(1)}}>{t("cars.choose_return_agency")}</Button>
									)
								}
								{
									stations_arr.return.length === 0 && (
										<Button onClick={handleContract} className={`${classes.orangeButton} ${!matches ? classes.marginBottom30 : ""}`}>{t("cars.choose_contract")}</Button>
									)
								}
								</Grid>
								<Grid ref={stations_arr.return.length > 0 ? nextTabRef : contractRef}/>
							</Fragment>
						)
					}
				</Grid>
	      	</TabPanel>
	      	{
	      		stations_arr.return.length > 0 && (
			      	<TabPanel value={tab_index} index={1} stations_arr={stations_arr} className={`${matches ? classes.agencies : ""}`}>
			        	<Grid container>
			        		<Grid item>
					        	<List>
						        {
						        	stations_arr.return.map((station, station_index) => {
						        		if ((station.address_line !== null && station.address_line !== "") || (station.latitude !== null && station.longitude !== null)) {
						        			const labelId = `checkbox-list-label-${station_index}`;
							        		return (
							        			<ListItem className={classes.pointer} key={station_index} role={undefined} dense button onClick={handleToggle(station_index, "return")} onMouseEnter={ onMouseEnter(station_index) } onMouseLeave={ onMouseLeave }>
		        			        				<ListItemIcon>
    			        				            	<Checkbox
    			        				                	edge="start"
    			        				                	checked={checked_return === station_index}
    			        				                	tabIndex={-1}
    			        				                	disableRipple
    			        				                	inputProps={{ 'aria-labelledby': labelId }}
    			        				              	/>
    			        				            </ListItemIcon>
							        				<ListItemText primary={station.name + (station.address_line !== "" && station.address_line !== null ? " : " + station.address_line : "")}/>
							        			</ListItem>
							        		)
						        		}
						        	})
						        }
								</List>
			        		</Grid>
			        	</Grid>
			        	{
			        		checked_return !== null && (
			        			<Fragment>
						        	<Grid item container justify={"center"} alignItems={"center"}>
										<Button className={`${classes.orangeButton} ${!matches ? classes.marginBottom30 : ""}`} onClick={handleContract}>{t("cars.choose_contract")}</Button>
						        	</Grid>
			        				<Grid item ref={contractRef}/>
			        			</Fragment>
			        		)
			        	}
			      	</TabPanel>
	      		)
	      	}
		</Fragment>
	)
}
export default React.memo(RenderStation);