import React, { useState, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles, useTheme, useMediaQuery } from "@material-ui/core";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import green from "@material-ui/core/colors/green";

import OutboundCard from "./OutboundCard";
import OutboundCardMobile from "./OutboundCardMobile";
import ImportantInfo from "./ImportantInfo";
import DetailedPriceButton from "./DetailedPriceButton";
import { CartConstructionReplaceProductContext } from "../../CartMaterial/utils/cartConstructionReplaceProductContext";

import { SelectFlight, SetOptionLoaded } from "../../../Actions/FlightSearch";

import GetOptions from "../Functions/GetOptions";

import moment from "moment";

const useStyles = makeStyles((theme) => ({
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: '#0000008A',
        letterSpacing: 1.25
    },
    fontWeight500: {
        fontWeight: 500
    },
    fontWeight900: {
        fontWeight: 900
    },
    fontSize12: {
        fontSize: 12
    },
    fontSize14: {
        fontSize: 14
    },
    fontSize16: {
        fontSize: 16
    },
    uppercase: {
        textTransform: "uppercase"
    },
    lightgrey: {
        color: '#00000040'
    },
    green: {
        color: "#66CD00"
    },
    red: {
        color: "red"
    },
    textCenter: {
        textAlign: "center"
    },
    textRight: {
        textAlign: "right"
    },
    formShadow: {
        borderRadius: 4,
        // boxShadow: '0 1px 0px rgba(32,33,36,0.28)',
        //boxShadow: '0 1px 6px rgba(32,33,36,0.28)',
        margin: "16px 4px"
    },
    genericBorder: {
        border: "2px solid",
        borderRadius: 15,
        boxSizing: "border-box",
        padding: "4px 0px"
    },
    spacer: {
        padding: "8px 16px"
    },
    smallSpacer: {
        padding: "3px 16px"
    },
    spacerMobile: {
        padding: "8px 4px"
    },
    selectButton: {
        backgroundColor: "#E6592F",
        color: "white",
        padding: "6px 12px"
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12
    },
    noPaper: {
        border: "none",
        borderRadius: 0,
        margin: 0,
        boxShadow: "none"
    }
}));

const FlightCard = ({ searched_group, flight, flight_index, providersObj, small, router }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const theme = useTheme();
    const isHandHeld = useMediaQuery(theme.breakpoints.down(960));
    const isTablet = useMediaQuery(theme.breakpoints.between(600, 960));
    const isMobile = useMediaQuery(theme.breakpoints.down(600));
    
    const quotation_code = JSON.parse(localStorage.getItem("config")).quotation_code;

    const language = useSelector(store => store.header.language);
    const end_date = useSelector(store => store.trip.end_date);
    const trip_id = useSelector(store => store.trip.trip_id);
    const tripversion_id = useSelector(store => store.trip.data_trip.id);
    const trip_currency = useSelector(store => store.trip.currency);
    const flight_groups = useSelector(store => store.flight.flight_groups);
    const flight_providers = useSelector(store => store.flight.provider_list);
    const index_group = useSelector(store => store.flight.index_group);
    const airlines = useSelector(store => store.flight_search.airlines);
    const airports = useSelector(store => store.flight_search.airports);
    const flight_list_render = useSelector(store => store.flight_search.flight_list_render);
    const option_loaded = useSelector(store => store.flight_search.option_loaded);
    const stations = useSelector(store => store.flight_search.stations);
    const trace_id = useSelector(store => store.flight_search.trace_id);
    const user = useSelector(store => store.user.user);
    const replaceProductContext = useContext(CartConstructionReplaceProductContext);

    const [selecting, setSelecting] = useState(false);

    const price_per_pax = (parseFloat(flight.total_price) / searched_group.travelers.length).toFixed(2);

    useEffect(() => {
        if (selecting) {
            dispatch(SelectFlight(flight_index));
            GetOptions(flight_list_render[flight_index], flight_providers, flight_index, flight_groups[index_group], trip_id, tripversion_id, trip_currency, trace_id, airlines, airports, stations, end_date, dispatch, setSelecting, user);
        }
    }, [selecting]);

    useEffect(() => {
        if (option_loaded && !replaceProductContext.enableReplace) {
            router.push(`/${window.url_name}/apps/selected-flight`);
        } else if (option_loaded && replaceProductContext.enableReplace) {
            replaceProductContext.onNextStep();
        }
    }, [option_loaded]);

    return (
        <Paper elevation={0} variant={isHandHeld ? "elevation" : "outlined"} className={(isHandHeld || small) ? classes.noPaper : classes.formShadow}>
            { isHandHeld && !small && <Divider style={{ height: 5 }} /> }
            <Grid>
                <Grid container alignItems="center" justify="space-between" className={small ? classes.smallSpacer : (isMobile ? classes.spacerMobile : classes.spacer)} spacing={(small || isMobile) ? 1 : 0} direction={isMobile ? "direction" : "row"}>
                    <Grid item xs={isTablet ? 4 : (isMobile ? 12 : (small ? 6 : 5))}>
                        {
                            small && (
                                <Grid container alignItems="center" justify="flex-start" spacing={isHandHeld ? 0 : 2} direction={isHandHeld ? "column" : "row"}>
                                    <Grid item className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.uppercase}`}>
                                        { 
                                            flight.outbounds[0].legs[0].origin_airport !== undefined
                                                ? airports[flight.outbounds[0].legs[0].origin_airport].iata_city.name !== undefined ? airports[flight.outbounds[0].legs[0].origin_airport].iata_city.name : airports[flight.outbounds[0].legs[0].origin_airport].iata_city.international_name
                                                : stations[flight.outbounds[0].legs[0].origin_station].iata_city.name !== undefined ? stations[flight.outbounds[0].legs[0].origin_station].iata_city.name : stations[flight.outbounds[0].legs[0].origin_station].iata_city.international_name
                                        }
                                    </Grid>
                                    <Grid item className={classes.genericText} style={{ marginTop: 4 }}>
                                        <ArrowRightAltIcon style={isHandHeld ? { transform: "rotate(90deg)" } : {}} />
                                    </Grid>
                                    <Grid item className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.uppercase}`}>
                                        { 
                                            flight.outbounds[0].legs[0].destination_airport !== undefined
                                                ? airports[flight.outbounds[0].legs[0].destination_airport].iata_city.name !== undefined ? airports[flight.outbounds[0].legs[0].destination_airport].iata_city.name : airports[flight.outbounds[0].legs[0].destination_airport].iata_city.international_name
                                                : stations[flight.outbounds[0].legs[0].destination_station].iata_city.name !== undefined ? stations[flight.outbounds[0].legs[0].destination_station].iata_city.name : stations[flight.outbounds[0].legs[0].destination_station].iata_city.international_name
                                        }
                                    </Grid>
                                    <Grid item className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.uppercase}`}>
                                        { moment(flight.outbounds[0].legs[0].departure_datetime_lt).format("dddd Do MMMM") }
                                    </Grid>
                                </Grid>
                            )
                        }
                        {
                            small === false && (
                                <Grid container alignItems="center" justify={isMobile ? "space-between" : "flex-start"} spacing={isHandHeld ? 0 : 5} direction={isTablet ? "direction" : "row"}>
                                    <Grid item>
                                        <Grid className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize16}`}>
                                            { new Intl.NumberFormat(language, { style: 'currency', currency: trip_currency.iso_code, minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(price_per_pax) }
                                        </Grid>
                                        <Grid className={`${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12} ${classes.lightgrey} ${classes.uppercase}`}>
                                            { t("flight_search.total_price") + " " + new Intl.NumberFormat(language, { style: 'currency', currency: trip_currency.iso_code, minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(flight.total_price) }
                                        </Grid>
                                        {
                                            flight.last_ticketing_date !== null && (
                                                <Grid className={`${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12} ${classes.lightgrey} ${classes.uppercase}`}>
                                                    { t("flight.latest_ticketing_date") + " " + moment(flight.last_ticketing_date).format("DD/MM/YYYY") }
                                                </Grid>
                                            )
                                        }
                                    </Grid>
                                    <DetailedPriceButton flight={flight} />
                                </Grid>
                            )
                        }
                    </Grid>
                    <Grid item xs={isTablet ? 4 : (isMobile ? 12 : (small ? 3 : 2))} className={classes.textCenter}>
                        {
                            flight.refundable !== null && flight.refundable && (
                                <Grid className={`${classes.genericText} ${classes.fontWeight900} ${small ? classes.fontSize12 : classes.fontSize14} ${classes.green} ${classes.uppercase} ${classes.genericBorder}`}>
                                    { t("flight_search.cancellable") }
                                </Grid>
                            )
                        }
                        {
                            flight.refundable === null && (
                                <Grid className={`${classes.genericText} ${classes.fontWeight900} ${small ? classes.fontSize12 : classes.fontSize14} ${classes.red} ${classes.uppercase} ${classes.genericBorder}`}>
                                    { t("flight_search.not_cancellable") }
                                </Grid>
                            )
                        }
                    </Grid>
                    <Grid item xs={isTablet ? 4 : (isMobile ? 12 : (small ? 3 : 5))} className={small && isMobile ? classes.textCenter : ""}>
                        <Grid container alignItems="center" justify={isMobile ? "space-between" : "flex-end"} spacing={isHandHeld ? 0 : 2}>
                            {
                                flight.name !== null && (
                                    <Grid item className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.uppercase}`}>
                                        { flight.name }
                                    </Grid>
                                )
                            }
                            <Grid item xs={isTablet ? 12 : false} className={isTablet ? classes.textRight : ""}>
                                {
                                    providersObj[flight.provider_id] !== undefined && (quotation_code !== 'visiteurs' || user.client_full.type !== 2) && (
                                        <>
                                        {
                                            providersObj[flight.provider_id].logo !== null ?
                                                <img src={ providersObj[flight.provider_id].logo.url } alt={ "provider logo" } style={{ maxWidth: 120, maxHeight: 65 }} /> :
                                                <span>{providersObj[flight.provider_id].name}</span>
                                        }
                                        </>
                                    )
                                }
                            </Grid>
                            {
                                small === false && (
                                    <Grid item xs={isTablet ? 12 : false} className={isTablet ? classes.textRight : ""}>
                                        <Button variant="contained" disabled={selecting || flight.error_options !== null} size="small" className={classes.selectButton} onClick={() => setSelecting(true)}> 
                                            { t("flight_search.select")}
                                            { selecting && <CircularProgress size={20} className={classes.buttonProgress} /> }
                                        </Button>
                                    </Grid>

                                )
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <Divider style={{ marginBottom: 8 }}/>
                {
                    flight.outbounds.map((outbound_flight, outbound_index) => {
                        if (isMobile) {
                            return (
                                <OutboundCardMobile key={outbound_index} flight_index={flight_index} outbound_flight={outbound_flight} outbound_index={outbound_index} travelers={searched_group.travelers} max_index={flight.outbounds.length - 1} small={small} flight_id={flight.id}/>
                            );
                        }
                        return (
                            <OutboundCard key={outbound_index} flight_index={flight_index} outbound_flight={outbound_flight} outbound_index={outbound_index} travelers={searched_group.travelers} max_index={flight.outbounds.length - 1} small={small} flight_id={flight.id}/>
                        );
                    })
                }
            </Grid>
            {
                flight !== null && (
                    <ImportantInfo flight={flight} small={small} />
                )
            }
        </Paper>
    );
};

export default React.memo(FlightCard);
