//---- Dependencies ----//
import React, {Fragment, useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useSnackbar } from "notistack";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import IconButton from '@material-ui/core/IconButton';
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CircularProgress from '@material-ui/core/CircularProgress';

import CloseIcon from '@material-ui/icons/Close';

import { DestinationChange } from "../../Actions/Header";
import { SetItineraryTypeData } from "../../Actions/ItineraryType";
import i18n from "../../i18n";
import localeText from "../Functions/localeText";

const useStyles = makeStyles(theme => ({
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
    parentList: {
        [theme.breakpoints.down('sm')]: {
            columns: 1,
        },
        [theme.breakpoints.up('sm')]: {
            columns: 5,
        },
        listStyleType: "none",
        fontWeight: "bold",
        margin: 0,
        padding: 0,
        fontSize: 17
    },
    childList: {
        listStyleType: "none",
        fontWeight: "normal",
        margin: 0,
        padding: 0,
        fontSize: 14,
        cursor: "pointer"
    },
    dialogSize: {
        "& .mui-jss-MuiDialog-paper": {
            width: 900
        },
        "& .mui-jss-MuiDialog-paperWidthSm": {
            maxWidth: 900
        }
    },
}));

const DestinationDialog = ({destinationDialog, setDestinationDialog, full_dest, router}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const locales = useSelector(state => state.user.locales);

    const current_locale = locales.find((el) => {return el.language_code === i18n.language});

    const [orderDestination, setOrderDestination] = useState(null);

    useEffect(() => {
        if (full_dest.length > 0) {
            let sort_dest = full_dest.toSorted((a, b) => {
                return a.data.type - b.data.type;
            });
            let tmp_arr = {};
            for (let i = 0; i < sort_dest.length; i++) {
                let tmp_obj = {
                    name: null,
                    data: null,
                    children: []
                };
                if (sort_dest[i].parent !== null)
                {
                    let is_parent = false;
                    sort_dest[i].data.destination = sort_dest[i].id;
                    let key = localeText(current_locale.id, sort_dest[i].parent.data.localization, sort_dest[i].parent.data.international_name, 'name');
                    Object.keys(tmp_arr).map((destination) => {
                        tmp_arr[destination].map((dest) => {
                            if (dest.name === key) {
                                is_parent = true;
                                dest.children.push({
                                    name: localeText(current_locale.id, sort_dest[i].data.localization, sort_dest[i].data.international_name, 'name'),
                                    data: sort_dest[i]
                                });
                                dest.children = dest.children.sort((a, b) => {
                                    let fa = a.name.toLowerCase();
                                    let fb = b.name.toLowerCase();

                                    if (fa < fb) {
                                        return -1;
                                    }
                                    if (fa > fb) {
                                        return 1;
                                    }
                                    return 0;
                                });
                            }
                        })
                    })
                    if (!is_parent) {
                        if (tmp_arr[key] === undefined){
                            tmp_arr[key] = [];
                            tmp_obj.name = localeText(current_locale.id, sort_dest[i].data.localization, sort_dest[i].data.international_name, 'name');
                            tmp_obj.data = sort_dest[i];
                            tmp_arr[key].push(tmp_obj);
                        } else {
                            tmp_obj.name = localeText(current_locale.id, sort_dest[i].data.localization, sort_dest[i].data.international_name, 'name');
                            tmp_obj.data = sort_dest[i];
                            tmp_arr[key].push(tmp_obj);
                        }
                    }
                }
            }
            const ordered = {};
            Object.keys(tmp_arr).sort().forEach(function(key) {
                tmp_arr[key].sort(function (a, b) {
                    return a.name.localeCompare(b.name);
                });
                ordered[key] = tmp_arr[key];
            });
            console.log('ordered:', ordered);
            setOrderDestination(ordered);
        }
    }, [full_dest]);

    const handleClose = () => {
        setDestinationDialog(!destinationDialog);
    };
    const handleChildClick = (e, data) => {
        e.preventDefault();
        handleClose();
        dispatch(DestinationChange(data));
        dispatch(SetItineraryTypeData(data.data));
        router.push(`/${window.url_name}/destination-product/${data.id}`);
    }
    console.log('orderDestination:', orderDestination);
    return (
        <Dialog open={destinationDialog} onClose={handleClose} fullScreen={fullScreen} className={classes.dialogSize}>
            <DialogTitle>
                <Grid container>
                    <Grid item style={{position: "absolute", top: 9, right: 6}}>
                        <IconButton edge={ "start" } onClick={ handleClose }><CloseIcon/></IconButton>
                    </Grid>
                    <Grid item>
                        <Typography variant="h5" className={classes.genericText}>
                        {t("home.modal.choose_destination")}
                        </Typography>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid container justify={"center"} style={{paddingBottom: 20}}>
                    <Grid item className={classes.genericText}>
                        {
                            orderDestination !== null && (
                                <ul className={classes.parentList}>
                                {
                                    Object.keys(orderDestination).map((key) => {
                                        return (
                                            <li key={key}>
                                                {key}
                                                <ul className={classes.childList}>
                                                    {
                                                        orderDestination[key].map((child, child_index) => {
                                                            return (
                                                                <li key={child_index}>
                                                                    <span onClick={(e) => handleChildClick(e, child.data)}>{child.name}</span>
                                                                    {
                                                                        child.children.length > 0 && (
                                                                            <ul style={{listStyleType: "none", paddingLeft: 10, fontStyle: "italic"}}>
                                                                            {
                                                                                child.children.map((children, children_index) => {
                                                                                    return (
                                                                                        <li key={children_index} onClick={(e) => handleChildClick(e, children.data)}>{children.name}</li>
                                                                                    )
                                                                                })
                                                                            }
                                                                            </ul>
                                                                        )
                                                                    }
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </li>
                                        )
                                    })
                                }
                                </ul>
                            )
                        }
                        {
                            orderDestination === null && (
                                <CircularProgress/>
                            )
                        }
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}
export default DestinationDialog;