//---- Dependencies ----//
import React, { Fragment, useState, useEffect, useContext } from "react";

import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import clsx from "clsx";

import useMediaQuery from '@material-ui/core/useMediaQuery';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Paper from '@material-ui/core/Paper';
import NavigateBefore from "@material-ui/icons/NavigateBefore";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import green from "@material-ui/core/colors/green";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';


//---- Functions ----//
import { CartConstructionReplaceProductContext } from "../CartMaterial/utils/cartConstructionReplaceProductContext";
import CheckBeforeRequest from "../Common/CheckBeforeRequest";

//---- Components ----//
import RenderInput from './Material/RenderInput';
import GetTransfers from './Material/GetTransfers';
import VehicleRender from './VehicleRender.jsx';
import { RenderTransfer } from './Material/RenderTransfer.tsx';
import TransferDialog from "./Material/TransferDialog";
import RenderTransfersFilter from "./Material/RenderTransfersFilter";
import {
    CartConstructionReplaceTransferInputs
} from "../CartMaterial/CartConstructionReplaceTransferInputs";

//---- Actions ----//
//import { SetCarsPanel, SetSelectedStep, ItineraryError } from "../../Actions/CarsSearch";
import { CartConstructionReplaceProductModalStep } from "../CartMaterial/objects/cartConstructionReplaceProductModalStep";
import { AppState } from "../../Reducers/Reducers";

const main_color = JSON.parse(localStorage.getItem("config")).main_color;

const useStyles = makeStyles(() => ({
    carsTitle: {
        color: 'rgba(0, 0, 0, 0.54)',
        textTransform: 'uppercase',
        fontWeight: 'bold'
    },
    orangeButton: {
        backgroundColor: `${main_color}`,
        color: "white"
    },
    returnButton: {
        display: "inline",
        marginRight: 10,
        float: "left"
        //marginTop: 6
    },
    noPaddingMargin: {
        padding: '0 !important',
        margin: 0
    },
    returnButtonContainer: {
        width: 48
    },
    resultHeight: {
        //height: '100%'
        flex: 1
    },
    formShadow: {
        borderRadius: 4,
        border: '1px solid #F3F6FC',
        background: '#F3F6FC'
    },
    marginCheckbox: {
        marginTop: 50
    },
    marginLeft8: {
        marginLeft: 8
    },
    marginBottom100: {
        marginBottom: 100
    },
    bold: {
        fontWeight: "bold"
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: "20%",
        left: "50%",
        marginLeft: -12
    },
    buttonProgress1: {
        color: green[500],
        position: 'absolute',
        top: "5%",
        left: "50%",
        marginLeft: -12
    },
    categorieTitle: {
        fontWeight: "bold",
        fontSize: 27
    },
    textCenter: {
        textAlign: "center"
    },
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal"
        // color: "#0000008A"
    }
    //matrix: {
    //maxHeight: '500px',
    //overflow: 'auto'
    //},
}));


const Transfers = ({ router }) => {
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const downDesktop = useMediaQuery('(min-width:960px)');
    const { t } = useTranslation();
    const replaceProductContext = useContext(CartConstructionReplaceProductContext);

    const trip_id = useSelector((store: AppState) => {
        return store.trip.trip_id;
    });
    const traveler_groups = useSelector((store: AppState) => {
        return store.trip.traveler_groups;
    });
    const store_travelers = useSelector((store: AppState) => {
        return store.trip.travelers;
    });
    const accommodation_cart = useSelector((store: AppState) => {
        return store.accommodation.cart;
    });
    const manual_cart = useSelector((store: AppState) => {
        return store.cart.manual_item_list;
    });
    const providers = useSelector((store: AppState) => {
        return store.trip.providers;
    });
    const all_data = useSelector((store) => store.trip.all_data);
    const manual_providers = useSelector((store) => {
        return store.trip.manual_providers;
    });
    const user = useSelector((store) => {
        return store.user.user;
    });
    const language = useSelector((store: AppState) => {
        return store.header.language;
    });
    const trip_end_date = useSelector((store: AppState) => {
        return store.trip.end_date;
    });
    const trip_start_date = useSelector((store: AppState) => store.trip.start_date);
    const [groups, setGroups] = useState([]);
    const [transfers_list, setTransferList] = useState([]);
    const [arrival_date, setArrivalDate] = useState(null);
    const [can_return, setCanReturn] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [search, setSearch] = useState({
        origin_index: null,
        dest_index: null,
        origin_name: "",
        dest_name: "",
        origin_type: "",
        dest_type: "",
        is_return: false,
        start_date: moment.utc(trip_start_date),
        end_date: moment.utc(trip_start_date),
        origin_id: null,
        country_code: null,
        origin_location_code: null,
        dest_id: null,
        dest_location_code: null,
        origin_hour: t("global.noon"),
        return_hour: t("global.noon")
    });
    const [iti_dates, setItiDates] = useState([]);
    const [vehicle_results, setVehicleResults] = useState([]);
    const [filteredVehicle, setFilteredVehicle] = useState([]);
    const [travelers, setTravelers] = useState([...store_travelers]);
    const [isForm, setIsForm] = useState(true);
    const [error1, setError1] = useState(false);
    const [error2, setError2] = useState(false);
    const [error3, setError3] = useState(false);
    const [check_accommodation, setCheckAccommodation] = useState(false);
    const [cardDescription, setCardDescription] = useState({});
    const [openDescription, setOpenDescription] = useState(false);
    const [cardCancellation, setCardCancellation] = useState({});
    const [openCancellation, setOpenCancellation] = useState(false);
    const [transferType, setTransferType] = useState([]);
    const [prestationFilter, setPrestationFilter] = useState([]);
    const [prestationType, setPrestationType] = useState("");
    const [transfersProviders, setTransfersProviders] = useState([]);
    const [providersFilters, setProvidersFilters] = useState([]);
    const [typeFilters, setTypeFilters] = useState([]);
    const [pickUp, setPickUp] = useState([]);
    const [dropOff, setDropOff] = useState([]);
    const [pickupLocation, setPickupLocation] = useState([]);
    const [dropoffLocation, setDropoffLocation] = useState([]);

    useEffect(() => {
        let tmp_groups = [];
        let new_travelers = [];
        let numero_adult = 1;
        let numero_child = 1;
        for (let i = 0; i < traveler_groups.length; i++) {
            if (traveler_groups[i].default) {
                let tmp_group = Object.assign({}, traveler_groups[i]);
                tmp_group.open = false;
                tmp_groups.push(tmp_group);
            }
        }
        travelers.map((traveler) => {
            let tmp_traveler = Object.assign({}, traveler);
            tmp_traveler.default_name = false;
            let age = moment.utc(trip_end_date).diff(moment.utc(tmp_traveler.birth_date), "y");
            if (tmp_traveler.first_name === t('global.first_name') && tmp_traveler.last_name === t('global.last_name')) {
                tmp_traveler.default_name = true;
            }
            if (age >= 18) {
                tmp_traveler.age_label = tmp_traveler.default_name ? `${t("flight_search.adult")} ${numero_adult}` : `${tmp_traveler.first_name} ${tmp_traveler.last_name}`;
                numero_adult += 1;
            } else {
                tmp_traveler.age_label = tmp_traveler.default_name ? `${t("flight_search.child")} ${numero_child} (${age})` : `${tmp_traveler.first_name} ${tmp_traveler.last_name} (${age})`;
                numero_child += 1;
            }
            new_travelers.push(tmp_traveler);
        });
        let check_accommodation = accommodation_cart.length > 0;
        if (!check_accommodation) {
            if (manual_cart.find(el => el.product_type === 0) !== undefined) {
                check_accommodation = true;
            }
        }
        setTravelers(new_travelers);
        setCheckAccommodation(check_accommodation);
        setGroups(tmp_groups);
        //GetTerminals(itinerary_list, dispatch);
    }, []);

    useEffect(() => {
        if (vehicle_results.length !== 0) {
            updateFilters(vehicle_results);
        }
    }, [vehicle_results]);
    useEffect(() => {
        getFilteredVehicles();
    }, [vehicle_results, typeFilters, prestationType, providersFilters, pickupLocation, dropoffLocation]);

    const resetFilter = () => {
        setProvidersFilters([]);
        setPickupLocation([]);
        setDropoffLocation([]);
        setTypeFilters([]);
        setPrestationType("");
    };
    const updateFilters = (vehicles) => {
        let tmp_providers = [];
        let tmp_types = [];
        let pick_up = [];
        let drop_off = [];
        let prestation = [];
        vehicle_results.map((vehicle) => {
            if (prestationType !== "" && providersFilters.length === 0 && typeFilters.length === 0 && pickupLocation.length === 0 && dropoffLocation.length === 0) {
                if ((vehicle.is_meet_connect || vehicle.is_meet_greet) && !prestation.includes(t("transfers.meet_greet"))) {
                    prestation.push(t("transfers.meet_greet"));
                }
                if (prestationType === "" && providersFilters.length === 0 && typeFilters.length === 0 && pickupLocation.length !== 0 && dropoffLocation.length === 0) {
                    if (vehicle.pickup_location !== '' && !vehicle.pickup_location.split(' ').some(location => pick_up.some(tmp_pickup => tmp_pickup.includes(location)))) {
                        pick_up.push(vehicle.pickup_location);
                    }
                }
                if (prestationType === "" && providersFilters.length === 0 && typeFilters.length === 0 && pickupLocation.length === 0 && dropoffLocation.length !== 0) {
                    if (vehicle.dropoff_location !== '' && !vehicle.dropoff_location.split(' ').some(location => drop_off.some(tmp_dropoff => tmp_dropoff.includes(location)))) {
                        drop_off.push(vehicle.dropoff_location);
                    }
                }
                if (!vehicle.is_meet_connect && !vehicle.is_meet_greet && !prestation.includes(t("providers.transfers"))) {
                    prestation.push(t("providers.transfers"));
                }
            }
            if (prestationType === "" && providersFilters.length !== 0 && typeFilters.length === 0 && pickupLocation.length === 0 && dropoffLocation.length === 0) {
                if (!tmp_providers.includes(vehicle.provider_id)) {
                    tmp_providers.push(vehicle.provider_id);
                }
            }
            if (prestationType === "" && providersFilters.length === 0 && typeFilters.length !== 0 && pickupLocation.length === 0 && dropoffLocation.length === 0) {
                if (!tmp_types.includes(vehicle.type)) {
                    tmp_types.push(vehicle.type);
                }
            }
            if (prestationType === "" && providersFilters.length === 0 && typeFilters.length === 0 && pickupLocation.length !== 0 && dropoffLocation.length === 0) {
                if (!pick_up.includes(vehicle.pickup_location)) {
                    pick_up.push(vehicle.pickup_location);
                }
            }
            if (prestationType === "" && providersFilters.length === 0 && typeFilters.length === 0 && pickupLocation.length === 0 && dropoffLocation.length !== 0) {
                if (vehicle.dropoff_location !== "" && !drop_off.includes(vehicle.dropoff_location)) {
                    drop_off.push(vehicle.dropoff_location);
                }
                if (pickupLocation.length === 0) {
                    if (!!vehicle.pickup_location && !vehicle.pickup_location.split(' ').some(location => pick_up.some(tmp_pickup => tmp_pickup.includes(location)))) {
                        pick_up.push(vehicle.pickup_location);
                    }
                }
                if (dropoffLocation.length === 0) {
                    if (!!vehicle.dropoff_location && !vehicle.dropoff_location.split(' ').some(location => drop_off.some(tmp_dropoff => tmp_dropoff.includes(location)))) {
                        drop_off.push(vehicle.dropoff_location);
                    }
                }
            }
        });
        vehicles.map((vehicle) => {
            if (prestationType === "") {
                if ((vehicle.is_meet_connect || vehicle.is_meet_greet) && !prestation.includes(t("transfers.meet_greet"))) {
                    prestation.push(t("transfers.meet_greet"));
                }
                if (!vehicle.is_meet_connect && !vehicle.is_meet_greet && !prestation.includes(t("providers.transfers"))) {
                    prestation.push(t("providers.transfers"));
                }
            }
            if (providersFilters.length === 0) {
                if (!tmp_providers.includes(vehicle.provider_id)) {
                    tmp_providers.push(vehicle.provider_id);
                }
            }
            if (typeFilters.length === 0) {
                if (!tmp_types.includes(vehicle.type)) {
                    tmp_types.push(vehicle.type);
                }
            }
            if (pickupLocation.length === 0) {
                if (!!vehicle.pickup_location && !vehicle.pickup_location.split(' ').some(location => pick_up.some(tmp_pickup => tmp_pickup.includes(location)))) {
                    pick_up.push(vehicle.pickup_location);
                }
            }
            if (dropoffLocation.length === 0) {
                if (!!vehicle.dropoff_location && !vehicle.dropoff_location.split(' ').some(location => drop_off.some(tmp_dropoff => tmp_dropoff.includes(location)))) {
                    drop_off.push(vehicle.dropoff_location);
                }
            }
        });
        if (tmp_providers.length !== 0) {
            setTransfersProviders(tmp_providers);
        }
        if (pick_up.length !== 0) {
            setPickUp(pick_up);
        }
        if (drop_off.length !== 0) {
            setDropOff(drop_off);
        }
        if (tmp_types.length !== 0) {
            setTransferType(tmp_types);
        }
        if (prestation.length !== 0) {
            setPrestationFilter(prestation);
        }
    };
    const getFilteredVehicles = () => {
        let tmp_vehicule = vehicle_results.slice();
        if (typeFilters.length !== 0) {
            tmp_vehicule = tmp_vehicule.filter((el) => {
                return typeFilters.includes(el.type);
            });
        }
        if (prestationType.includes(t("transfers.meet_greet"))) {
            tmp_vehicule = tmp_vehicule.filter((el => {
                return el.is_meet_greet || el.is_meet_connect;
            }));
        } else if (prestationType.includes(t("providers.transfers"))) {
            tmp_vehicule = tmp_vehicule.filter((el => {
                return !el.is_meet_greet && !el.is_meet_connect;
            }));
        }
        if (providersFilters.length !== 0) {
            tmp_vehicule = tmp_vehicule.filter((el) => {
                return providersFilters.includes(el.provider_id);
            });
        }
        if (pickupLocation.length !== 0) {
            tmp_vehicule = tmp_vehicule.filter((el) => {
                return el.pickup_location !== '' && el.pickup_location.split(' ').some(location => pickupLocation.some(pick_up => pick_up.split(' ').some(tmp_pickup => tmp_pickup === location)));
            });
        }
        if (dropoffLocation.length !== 0) {
            tmp_vehicule = tmp_vehicule.filter((el) => {
                return el.dropoff_location !== '' && el.dropoff_location.split(' ').some(location => dropoffLocation.some(drop_off => drop_off.split(' ').some(tmp_dropoff => tmp_dropoff === location)));
            });
        }
        updateFilters(
            tmp_vehicule.filter((item) => {
                return !replaceProductContext.enableReplace ||
                    replaceProductContext.mode === 'replace' ||
                    replaceProductContext.mode === 'add' ||
                    replaceProductContext.extraData?.current?.identifier === item.identifier;
            })
        );
        setFilteredVehicle(
            tmp_vehicule.filter((item) => {
                return !replaceProductContext.enableReplace ||
                    replaceProductContext.mode === 'replace' ||
                    replaceProductContext.mode === 'add' ||
                    replaceProductContext.extraData?.current?.identifier === item.identifier;
            })
        );
    };
    const getHours = (tmp_hour, type, new_search) => {
        let split_hour = tmp_hour.split(":");
        if (parseInt(split_hour[1]) <= 30 && parseInt(split_hour[1]) > 0) {
            split_hour[1] = "30";
        } else if (parseInt(split_hour[1]) > 30 && parseInt(split_hour[1]) <= 60) {
            split_hour[0] = (parseInt(split_hour[0]) + 1).toString();
            split_hour[1] = "00";
        }
        if (type === "origin") {
            let new_origin_hour = (parseInt(split_hour[0]) + 1 < 10 ? "0" : "") + (parseInt(split_hour[0]) + 1).toString() + "h" + split_hour[1];
            if (new_origin_hour === "12h00") {
                new_search.origin_hour = t("global.noon");
            } else if (new_origin_hour === "00h00") {
                new_search.origin_hour = t("global.midnight");
            } else {
                new_search.origin_hour = new_origin_hour;
            }
        } else {
            let new_return_hour = (parseInt(split_hour[0]) - 3 < 10 ? "0" : "") + (parseInt(split_hour[0]) - 3).toString() + "h" + split_hour[1];
            if (new_return_hour === "12h00") {
                new_search.return_hour = t("global.noon");
            } else if (new_return_hour === "00h00") {
                new_search.return_hour = t("global.midnight");
            } else {
                new_search.return_hour = new_return_hour;
            }
        }
        return new_search;
    };
    const checkInput = () => {
        return new Promise((resolve, reject) => {
            const { pass_check, headers } = CheckBeforeRequest();
            let error = false;
            if (search.origin_name === "") {
                error = true;
                setError1(true);
            }
            if (search.dest_name === "" && search.origin_index !== null) {
                error = true;
                setError2(true);
            }
            if (groups[0].travelers_list.length === 0) {
                error = true;
                setError3(true);
            }
            if (!error) {
                setIsLoading(true);
                document.body.style.overflow = '';
                document.body.style.paddingRight = '';
                GetTransfers(
                    traveler_groups,
                    groups,
                    pass_check,
                    headers,
                    providers.filter(provider => provider.branch_code === all_data.branch_code),
                    search,
                    transfers_list,
                    iti_dates,
                    trip_id,
                    setVehicleResults,
                    setArrivalDate,
                    setGroups,
                    setIsForm,
                    setIsLoading,
                    user,
                    language,
                    t
                ).then(resolve).catch(reject);
            } else {
                resolve();
            }
        });
    };

    useEffect(() => {
        if (replaceProductContext.enableReplace && replaceProductContext.extraData?.current) {
            const transfer = replaceProductContext.extraData.current;
            const startDate = window.moment.utc(transfer.start_date);
            setSearch((state) => ({
                ...state,
                is_return: false,
                start_date: startDate.toISOString() ?? null,
                origin_hour: startDate.format('HH[h]mm') ?? null,
                origin_name: transfer.station_name_pickup ?? null,
                origin_type: transfer.pickup_type?.toLowerCase() ?? null,
                origin_id: transfer.start_destination?.id ?? null,
                country_code: transfer.start_destination?.data?.country?.country_code ?? null,
                origin_location_code: transfer.location_code_pickup ?? null,
                dest_name: transfer.station_name_return ?? null,
                dest_type: transfer.return_type?.toLowerCase() ?? null,
                dest_id: transfer.end_destination?.id ?? null,
                dest_country_code: transfer.end_destination?.data?.country?.country_code ?? null,
                dest_location_code: transfer.location_code_return ?? null
            }));
        }
    }, [replaceProductContext.extraData?.current]);

    useEffect(() => {
        if (replaceProductContext.mode === 'add' && replaceProductContext.contentItem) {
            const startDate = window.moment.utc(replaceProductContext.contentItem.from);
            const endDate = window.moment.utc(replaceProductContext.contentItem.to);
            setSearch((state) => ({
                ...state,
                is_return: false,
                start_date: startDate.toISOString() ?? null,
                end_date: endDate.toISOString() ?? null,
                origin_hour: startDate.format('HH[h]mm') ?? null,
                origin_name: null,
                origin_type: null,
                origin_id: null,
                country_code: null,
                origin_location_code: null,
                return_hour: endDate.format('HH[h]mm') ?? null,
                dest_name: null,
                dest_type: null,
                dest_id: null,
                dest_country_code: null,
                dest_location_code: null
            }));
        }
    }, [
        replaceProductContext.mode,
        replaceProductContext.contentItem?.from,
        replaceProductContext.contentItem?.to
    ]);

    useEffect(() => {
        const startDate = window.moment.utc(`${search?.start_date} ${search?.origin_hour}`);
        const endDate = window.moment.utc(`${search?.end_date} ${search.return_hour}`);
        if (
            replaceProductContext.mode === 'add' &&
            search?.start_date &&
            search?.end_date &&
            search?.origin_hour &&
            search?.return_hour &&
            endDate.isBefore(startDate)
        ) {
            setSearch((state) => ({
                ...state,
                end_date: endDate.toISOString(),
                return_hour: endDate.format('HH[h]mm')
            }));
        }
    }, [
        replaceProductContext.mode,
        search?.start_date,
        search?.origin_hour,
        search?.end_date,
        search?.return_hour
    ]);

    if (replaceProductContext.enableReplace) {
        switch (replaceProductContext.step) {
            case CartConstructionReplaceProductModalStep.INPUTS: {
                replaceProductContext.onRegisterNextEvent(() => {
                    replaceProductContext.onChangeLoading(true);
                    checkInput().finally(() => {
                        replaceProductContext.onIsFinalStep();
                        replaceProductContext.onChangeLoading(false);
                    });
                    replaceProductContext.onHideNextButton();
                    replaceProductContext.onNextStep();
                });
                return (
                    <CartConstructionReplaceTransferInputs
                        value={search}
                        onChange={setSearch}
                    />
                );
            }
        }
    }
    console.log('filteredVehicle:', filteredVehicle);
    return (
        <Container maxWidth={ "lg" }>
            <Grid container spacing={ 3 }>
                {
                    !replaceProductContext.enableReplace &&
                    <>
                        <Grid item xs={ 12 } />
                        <Grid className={ `${classes.returnButtonContainer} ${classes.noPaddingMargin}` } container item xs={ 12 } alignItems={ "center" }>
                            {
                                !isForm && (
                                    <Fragment>
                                        <IconButton className={ `${classes.returnButton} ${classes.noPaddingMargin}` } onClick={() => {
                                            setIsForm(true);
                                            resetFilter();
                                        }}>
                                            <NavigateBefore fontSize={ "large" }/>
                                        </IconButton>
                                        <Typography className={ `${classes.bold} ${classes.marginLeft8}`}>{t("transfers.back_to_transfer")}</Typography>
                                    </Fragment>

                                )
                            }
                        </Grid>
                        <Grid item xs={ 12 }>
                            <Typography align={'center'} variant={"h5"} className={ classes.carsTitle} gutterBottom >{t("transfers.title")}</Typography>
                        </Grid>
                    </>
                }
                {
                    isForm && (
                        <Fragment>
                            <Grid item xs={ 12 }>
                                {
                                    matches && (
                                        <Paper elevation={0} variant="outlined" className={clsx({
                                            [classes.formShadow]: true
                                        })}
                                        >
                                            <RenderInput
                                                search={search}
                                                setSearch={setSearch}
                                                can_return={can_return}
                                                transfers_list={transfers_list}
                                                iti_dates={iti_dates}
                                                groups={groups}
                                                setGroups={setGroups}
                                                travelers={travelers}
                                                error1={error1}
                                                error2={error2}
                                                error3={error3}
                                                setError1={setError1}
                                                setError2={setError2}
                                                setError3={setError3}
                                                setTransferList={setTransferList}
                                            />
                                        </Paper>
                                    )
                                }
                                {
                                    !matches && (
                                        <RenderInput
                                            search={search}
                                            setSearch={setSearch}
                                            can_return={can_return}
                                            transfers_list={transfers_list}
                                            iti_dates={iti_dates}
                                            groups={groups}
                                            setGroups={setGroups}
                                            travelers={travelers}
                                            error1={error1}
                                            error2={error2}
                                            error3={error3}
                                            setError1={setError1}
                                            setError2={setError2}
                                            setError3={setError3}
                                            setTransferList={setTransferList}
                                        />
                                    )
                                }
                            </Grid>
                            {
                                //matches && (
                                //<Grid item xs={8}/>
                                //)
                            }
                            <Grid container item justify={"flex-end"}>
                                <Grid item sm={3} xs={6}>
                                    <div style={{ position: "relative" }}>
                                        <Button variant={ "contained" } className={ `${classes.orangeButton} ${!matches ? classes.marginBottom100 : ""}` } disabled={ isLoading } fullWidth={ true } onClick={() => {
                                            checkInput();
                                        }}>{ t("global.search") }</Button>
                                        { isLoading && <CircularProgress size={ 24 } className={ `${matches ? classes.buttonProgress : classes.buttonProgress1}` }/> }
                                    </div>
                                </Grid>

                            </Grid>
                        </Fragment>
                    )
                }
                {
                    !isForm && (
                        <Grid item xs={12}>
                            {
                                vehicle_results.length !== 0 && !replaceProductContext.enableReplace && (
                                    <RenderTransfersFilter
                                        typeFilters={typeFilters}
                                        setTypeFilters={setTypeFilters}
                                        transferType={transferType}
                                        prestationType={prestationType}
                                        setPrestationType={setPrestationType}
                                        transfersProviders={transfersProviders}
                                        providersFilters={providersFilters}
                                        setProvidersFilters={setProvidersFilters}
                                        pickUp={pickUp}
                                        dropOff={dropOff}
                                        pickupLocation={pickupLocation}
                                        setPickupLocation={setPickupLocation}
                                        dropoffLocation={dropoffLocation}
                                        setDropoffLocation={setDropoffLocation}
                                        resetFilter={resetFilter}
                                        prestationFilter={prestationFilter}
                                    />
                                )
                            }
                            {
                                filteredVehicle.length !== 0 && (
                                    <div className={`${!downDesktop ? classes.marginBottom100 : ""}`}>
                                        {
                                            filteredVehicle.map((vehicle, vehicle_index) => {
                                                return (
                                                    <RenderTransfer
                                                        card={vehicle}
                                                        key={vehicle_index}
                                                        search={search}
                                                        groups={groups}
                                                        router={router}
                                                    />
                                                    // <VehicleRender
                                                    //     card={vehicle}
                                                    //     card_index={vehicle_index}
                                                    //     search={search}
                                                    //     transfers_list={transfers_list}
                                                    //     groups={groups}
                                                    //     router={router}
                                                    //     display_vehicles={filteredVehicle}
                                                    //     key={vehicle_index}
                                                    //     setCardDescription={setCardDescription}
                                                    //     setOpenDescription={setOpenDescription}
                                                    //     setCardCancellation={setCardCancellation}
                                                    //     setOpenCancellation={setOpenCancellation}
                                                    // />
                                                );
                                            })
                                        }
                                    </div>
                                )
                            }
                            {
                                filteredVehicle.length === 0 && (
                                    <div className={`${classes.textCenter} ${classes.genericText}`} style={{ fontSize: 20, marginTop: 20 }} >{t("transfers.no_results_filtered_available")} </div>
                                )
                            }
                            {
                                vehicle_results.length === 0 && (
                                    <div className={`${classes.textCenter} ${classes.genericText}`} style={{ fontSize: 20 }} >{t("transfers.no_transfers_available") + search.origin_name + " " + t("global.and") + " " + search.dest_name} </div>
                                )
                            }
                        </Grid>
                    )
                }

            </Grid>
            <TransferDialog openDialog={openDescription} setOpenDialog={setOpenDescription} cardDescription={cardDescription} cardCancellation={null}/>
            {/* <TransferDialog openDialog={openCancellation} setOpenDialog={setOpenCancellation} cardDescription={null} cardCancellation={cardCancellation}/> */}

            <Dialog fullScreen={ fullScreen } PaperProps={{ style: { borderRadius: fullScreen ? 0 : 16 } }} fullWidth maxWidth={ "md" } open={!check_accommodation}>
                <DialogContent dividers>
                    {t("transfers.no_accommodation_in_cart")}
                </DialogContent>
                <DialogActions>
                    <Button variant={"contained"} className={classes.orangeButton} onClick={() => {
                        setCheckAccommodation(true);
                    }}>Ok</Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};
export default React.memo(Transfers);
