import { useMemo } from "react";
import { useSelector } from "react-redux";
import { findProductItineraryError } from "../../CartMaterial/utils/findProductItineraryError";
import { useCartProducts } from "./cartProducts";
import { AppState } from "../../../Reducers/Reducers";

export function useCartNeedRecalc(): boolean {
    const itinerary = useSelector((state: AppState) => state.itinerary.itinerary_list);
    const trip = useSelector((state: AppState) => state.trip.data_trip);
    const cart = useCartProducts();
    return useMemo(() => {
        return cart.accommodations.filter((item) => {
            return item.accommodation.is_displayed;
        }).findIndex((item) => {
            if (item.type === 'normal') {
                const { iti_error } = findProductItineraryError(
                    'accommodation',
                    {
                        itinerary,
                        product: item.accommodation,
                        stackInfos: trip?.stack_info ?? null
                    }
                );
                return iti_error;
            }
            const { iti_error } = findProductItineraryError(
                'manual',
                {
                    itinerary,
                    product: item.accommodation,
                    stackInfos: trip?.stack_info ?? null
                }
            );
            return iti_error;
        }) >= 0 ||
        cart.cars.filter((item) => {
            return item.car.is_displayed;
        }).findIndex((item) => {
            if (item.type === 'normal') {
                const { iti_error } = findProductItineraryError(
                    'car',
                    {
                        itinerary,
                        product: item.car,
                        stackInfos: trip?.stack_info ?? null
                    }
                );
                return iti_error.start || iti_error.end;
            }
        }) >= 0 ||
        cart.pois.filter((item) => {
            return item.poi.is_displayed;
        }).findIndex((item) => {
            if (item.type === 'normal') {
                const { iti_error } = findProductItineraryError(
                    'poi',
                    {
                        itinerary,
                        product: item.poi,
                        stackInfos: trip?.stack_info ?? null
                    }
                );
                return iti_error;
            }
            const { iti_error } = findProductItineraryError(
                'manual',
                {
                    itinerary,
                    product: item.poi,
                    stackInfos: trip?.stack_info ?? null
                }
            );
            return iti_error;
        }) >= 0 ||
        cart.transfers.filter((item) => {
            return item.transfer.is_displayed;
        }).findIndex((item) => {
            if (item.type === 'normal') {
                const { iti_error } = findProductItineraryError(
                    'transfer',
                    {
                        itinerary,
                        product: item.transfer,
                        stackInfos: trip?.stack_info ?? null
                    }
                );
                return iti_error;
            }
            const { iti_error } = findProductItineraryError(
                'manual',
                {
                    itinerary,
                    product: item.transfer,
                    stackInfos: trip?.stack_info ?? null
                }
            );
            return iti_error;
        }) >= 0;
    }, [
        cart,
        itinerary,
        trip
    ]);
}
