import React, { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import GetTrip from "../../Base/Functions/GetTrip";
import i18n from "../../../i18n.jsx";
import Grid from "@material-ui/core/Grid";
import clsx from "clsx";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import Header from "../../Header/MaterialHeader.jsx";
import MaterialNavigation from "../../Common/MaterialNavigation.js";
import Help from "../../Base/Help";

const useStyles = makeStyles(theme => ({
    "@global": {
        html: {
            scrollBehavior: "smooth"
        },
        '*::-webkit-scrollbar': {
            width: 6,
            backgroundColor: '#F5F5F5'
        },
        '*::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.3)',
            borderRadius: 5,
            backgroundColor: '#F5F5F5'
        },
        '*::-webkit-scrollbar-thumb': {
            borderRadius: 5,
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,.3)',
            backgroundColor: '#CCC'
        }
    },
    mainContainer: {
        overflow: 'hidden'
    },
    standardWidth: {
        position: "relative",
        height: "calc(100vh - 130px)",
        width: 1002,
        overflowY: "auto",
        overflowX: "hidden",
        [theme.breakpoints.down('xl')]: {
            width: 850,
        },
        [theme.breakpoints.up('xl')]: {
            width: 1002,
        },
    },
    fullWidth: {
        width: "100%"
    }
}));

const BaseMenu = (props) => {
    const is_connected = useSelector(store => store.user.is_connected);
    const user = useSelector(store => store.user.user);
    const classes = useStyles();
    const language = useSelector(store => store.header.language);
    const data_trip = useSelector(store => store.trip.data_trip);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const theme = useTheme();
    const responsive = useMediaQuery(theme.breakpoints.down(1280));

    useEffect(() => {
        if (language === null) {
            dispatch({
                type: "HEADER_TOGGLE_AND_SET_LANGUAGE",
                payload: {
                    show_language: false,
                    language: i18n.language
                }
            });
        }
        if (!window.location.pathname.includes('trip-info')) {
            GetTrip(user, dispatch, t);
        }
        setTimeout(function() {
            if (is_connected === false)
                props.router.push(`/home/${window.url_name}`);
        }, 1000);
    }, []);
    let path = "";
    for (let i = 0; i < props.route.childRoutes.length; i++) {
        if (props.route.childRoutes[i].path === props.routes[2].path) {
            path = props.route.childRoutes[i].path;
        }
    }
    return (
        <Grid container>
            <Grid item xs={ 12 } style={{marginBottom: 70}}>
                <Header router={props.router}/>
            </Grid>
            <Grid item className={ classes.fullWidth }>
                { !path.includes("crm") && <MaterialNavigation router={ props.router } current_page={ path } to_render={ data_trip !== null }/> }
                { React.cloneElement(props.children, { key: props.location.pathname, component: this }) }
            </Grid>
            { JSON.parse(localStorage.getItem("config")).quotation_code === 'visiteurs' && <Help/> }
        </Grid>
    )
}
export default React.memo(BaseMenu);