//---- Dependencies ----//
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import i18n from "../../i18n.jsx";
import GetCookie from "../Common/Functions/GetCookie";

//---- CSS ----//
import "../../Style/Base.css";

//---- Actions ----//
import { ToggleAndSetLanguage } from "../../Actions/Header";

//---- Functions ----//
import GetTrip from "./Functions/GetTrip";
import GetItinerary from "./Functions/GetItinerary";

//---- Components ----//
import Map from "../Map/Map";
import { ItineraryMap } from "../Map/ItineraryMap.tsx";
import Header from "../Header/MaterialHeader.jsx";
//import Header from "../Header/Header.jsx";
import Calendar from "../Calendar/Calendar.jsx";
import PlaneLoader from "../PlaneLoader/PlaneLoader.jsx";
import MaterialNavigation from "../Common/MaterialNavigation";
import MaterialTimeline from "../Common/MaterialTimeline";
import CheckBeforeRequest from "../Common/CheckBeforeRequest";
import clsx from "clsx";
import UpdateTripDatesFromFlight from "../Flight/FlightSelected/UpdateTripDatesFromFlight";
import Help from './Help';
import axios from "axios";
import { SetItineraryTypeDetails } from "../../Actions/ItineraryType.js";

const useStyles = makeStyles(theme => ({
    "@global": {
        "html": {
            scrollBehavior: "smooth"
        },
        '*::-webkit-scrollbar': {
            width: 6,
            backgroundColor: '#F5F5F5'
        },
        '*::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.3)',
            "borderRadius": 5,
            "backgroundColor": '#F5F5F5'
        },
        '*::-webkit-scrollbar-thumb': {
            "borderRadius": 5,
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,.3)',
            "backgroundColor": '#CCC'
        }
    },
    "mainContainer": {
        overflow: 'hidden'
    },
    "standardWidth": {
        position: "relative",
        height: "calc(100vh - 130px)",
        width: 1002,
        overflowY: "auto",
        overflowX: "hidden",
        [theme.breakpoints.down('xl')]: {
            width: 850
        },
        [theme.breakpoints.up('xl')]: {
            width: 1002
        }
    },
    "itiWidth": {
        position: "relative",
        height: "calc(100vh - 130px)",
        width: 900,
        maxWidth: 900,
        overflowY: "auto",
        overflowX: "hidden",
        [theme.breakpoints.down('xl')]: {
            width: 750
        },
        [theme.breakpoints.up('xl')]: {
            width: 900
        }
    },
    "itiRetractTransition": {
        transition: theme.transitions.create(
            'max-width',
            {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.short
            }
        )
    },
    "itiRetracted": {
        maxWidth: 500
    },
    "fullWidth": {
        width: "100%"
    },
    "relative": {
        position: "relative"
    },
    "hide": {
        display: 'none'
    }
}));

const Base = (props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const panel_type = useSelector(state => state.base.panel_type);
    const trip_id = useSelector(state => state.trip.trip_id);
    const language = useSelector(state => state.header.tmp_language);
    const itinerary_retrieve = useSelector(state => state.itinerary.itinerary_retrieve);
    const all_data = useSelector(state => state.trip.all_data);
    const itiShowDestinationsList = useSelector((state) => state.itinerarySlice.showDestinationsList);
    const user = useSelector(state => state.user.user);
    const { router } = props;
    const theme = useTheme();
    const responsive = useMediaQuery(theme.breakpoints.down(1280));
    useEffect(() => {
        if (language !== null && language !== i18n.language) {
            if (language !== i18n.language) {
                i18n.changeLanguage(language);
            }
        }
        let lang = i18n.languages.filter((lng) => {
            return lng.length === 2;
        });
        dispatch(ToggleAndSetLanguage(false, lang[0]));
    }, [language]);
    useEffect(() => {
        if (trip_id === null || trip_id !== parseInt(GetCookie('trip_id'))) {
            GetTrip(user, dispatch, t);
        } else if (itinerary_retrieve) {
            GetItinerary(dispatch, t);
        }
    }, [trip_id, itinerary_retrieve]);
    useEffect(() => {
        const { headers } = CheckBeforeRequest();
        if (all_data !== null && (all_data.package_type || all_data.circuit_type || all_data.itinerary_type)) {
            let url = `${API_HREF}client/${window.id_owner}/${all_data.circuit_type ? 'circuits' : 'packages'}/${all_data.circuit_type ? all_data.circuit : all_data.package}/?reduced=true`;
            axios({
                method: "GET",
                headers: headers,
                url: url
            }).then((response) => {
                console.log('response.data reduced:', response.data);
                dispatch(SetItineraryTypeDetails(response.data));
            }).catch((error) => {
                console.log('error:', error);
            });
        }
    }, [all_data]);
    let hide = false;
    let type = 0;
    let path = "";
    let timeline = false;
    for (let i = 0; i < props.route.childRoutes.length; i++) {
        if (props.route.childRoutes[i].path === props.routes[2].path) {
            hide = !props.route.childRoutes[i].show_map_calendar;
            timeline = props.route.childRoutes[i].timeline;
            type = props.route.childRoutes[i].type;
            path = props.route.childRoutes[i].path;
        }
    }
    if (trip_id !== null && trip_id === parseInt(GetCookie('trip_id'))) {
        return (
            <Grid container>
                <Grid item xs={ 12 } style={{ marginBottom: 70 }}>
                    <Header router={router}/>
                </Grid>
                <Grid item xs={ 12 }>
                    <MaterialNavigation router={ router } current_page={ path } to_render={ true }/>
                </Grid>
                <Grid
                    className={
                        clsx(
                            {
                                [classes.fullWidth]: hide || responsive,
                                [classes.itiWidth]: !hide && !responsive && type === 0,
                                [classes.standardWidth]: !hide && !responsive && type !== 0,
                                [classes.itiRetracted]: path === 'itinerary' && !itiShowDestinationsList
                            },
                            classes.itiRetractTransition
                        )
                    }
                    item
                >
                    { timeline && <MaterialTimeline/> }
                    { React.cloneElement(props.children, { key: props.location.pathname }) }
                </Grid>
                {
                    (
                        !itiShowDestinationsList ||
                        !(hide || responsive)
                    ) && (
                        <Grid className={ classes.relative } item xs>
                            {
                                path !== 'itinerary' && panel_type === 0 &&
                                <Map router={ router } type={ type }/>
                            }
                            {
                                path === 'itinerary' &&
                                <ItineraryMap />
                            }
                            {
                                ![0, 3].includes(panel_type) &&
                                <Calendar />
                            }
                        </Grid>
                    )
                }
                <UpdateTripDatesFromFlight/>
                <Help/>
                { JSON.parse(localStorage.getItem("config")).quotation_code === 'visiteurs' && <Help/> }
            </Grid>
        );
    } 
    return (
        <div className={"ft-light-green absolute-center ft-font-25"}>
            <PlaneLoader/>
            <p>{ t("trip.load") }</p>
        </div>
    );
};

export default Base;
