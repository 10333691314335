import { ManualProductPoiCart } from "../../../Itinerary/objects/manualProductPoiCart";
import { PoiCart } from "../../../Itinerary/objects/poiCart";

export function getPoiName(
    item: {
        type: "normal",
        poi: PoiCart,
    } | {
        type: "manual",
        poi: ManualProductPoiCart,
    },
    options: {locale: number | null}
): string {
    if (item.type === 'normal') {
        const poiLocalization = item.poi.localization.find((item) => {
            return item.locale === options.locale;
        });
        if (poiLocalization) {
            return poiLocalization.name;
        }
        const localization = item.poi.custom_product?.localization?.find((item) => {
            return item.locale === options.locale;
        });
        if (localization) {
            return (localization?.title.length ?? 0) > 0 ?
                localization?.title :
                item.poi.custom_product?.title ??
                '';
        }
        return item.poi.custom_product?.title ?? '';
    }

    return item.poi.localization?.find((item) => {
        return item.locale === options.locale;
    })?.name ??
    item.poi.name ??
    '';
}
