import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    MenuProps
} from "@mui/material";
import { Check } from "@mui/icons-material";
import { isFunction } from "lodash";
import { CartProductStatus } from "./CartConstructionProductsTableItemMenu";
import { AppState } from "../../Reducers/Reducers";

type Props = {
    open: boolean,
    anchorEl?: MenuProps['anchorEl'],
} & ({
    statuses: CartProductStatus[],
    onChangeStatuses: (statuses: CartProductStatus[]) => void,
    multiple: true
} | {
    status: CartProductStatus,
    onChangeStatus: (statuses: CartProductStatus) => void,
    multiple?: false
})

export function CartConstructionProductStatusMenu(props: Props): JSX.Element {
    const { t } = useTranslation();
    const user = useSelector((state: AppState) => state.user.user);
    const data_trip = useSelector((state: AppState) => state.trip.data_trip);
    const [container, setContainer] = useState<HTMLUListElement | null>(null);
    const [containerWidth, setContainerWidth] = useState(0);
    const [anchorElPosition, setAnchorElPosition] = useState({ top: 0, left: 0 });
    const position = useMemo(() => {
        const top = anchorElPosition.top;
        let left = 0;
        if (anchorElPosition.left - containerWidth - 10 < 0) {
            left = 10 + containerWidth;
        } else {
            left = anchorElPosition.left - 10;
        }
        return { top, left };
    }, [containerWidth, anchorElPosition]);
    const quotation_code = JSON.parse(localStorage.getItem('config') ?? '{}').quotation_code;
    const isAll = props.multiple && props.statuses.length === 5;
    let checked: CartProductStatus[] = [];

    if (props.multiple) {
        checked = isAll ?
            [] :
            props.statuses;
    } else {
        checked = [props.status];
    }

    const onToggleStatus = (status: CartProductStatus | 'ALL') => {
        if (props.multiple && status === 'ALL') {
            props.onChangeStatuses([
                'DEFAULT',
                'HIDE',
                'HIDE_COUNT',
                'OPTION',
                'VARIANT'
            ]);
        } else if (props.multiple && status !== 'ALL' && isAll) {
            props.onChangeStatuses([status]);
        } else if (props.multiple && status !== 'ALL' && !props.statuses.includes(status)) {
            props.onChangeStatuses(props.statuses.concat([status]));
        } else if (props.multiple && status !== 'ALL' && props.statuses.includes(status)) {
            props.onChangeStatuses(props.statuses.filter((item) => item !== status));
        } else if (!props.multiple && status !== 'ALL' && props.status !== status) {
            props.onChangeStatus(status);
        }
    };

    useEffect(() => {
        if (container) {
            const observer = new ResizeObserver((entries) => {
                setContainerWidth(entries[0]?.contentRect.width ?? 0);
            });
            observer.observe(container);
            return () => observer.disconnect();
        }
    }, [container]);

    //needed to detect if filter button has changed position
    useEffect(() => {
        if (props.anchorEl && !isFunction(props.anchorEl)) {
            const checkPosition = () => {
                const currentTop = (props.anchorEl as HTMLUListElement).getBoundingClientRect().top;
                const currentLeft = (props.anchorEl as HTMLUListElement).getBoundingClientRect().left;
    
                setAnchorElPosition((position) => {
                    if (currentTop !== position.top || currentLeft !== position.left) {
                        return {
                            top: currentTop,
                            left: currentLeft
                        };
                    }
                    return position;
                });

                requestAnimationFrame(checkPosition);
            };
            checkPosition();
        }
    }, [props.anchorEl]);

    return (
        <Menu
            open={props.open}
            MenuListProps={{
                ref: (element) => {
                    setContainer(element);
                }
            }}
            PaperProps={{
                sx: {
                    position: 'static',
                    maxWidth: 'none',
                    maxHeight: 'none'
                }
            }}
            sx={{
                ...position,
                right: 'unset',
                bottom: 'unset',
                transform: 'translateX(-100%) !important'
            }}
            anchorEl={props.anchorEl}
            hideBackdrop
        >
            {
                props.multiple &&
                <MenuItem onClick={() => onToggleStatus('ALL')}>
                    {
                        isAll &&
                        <ListItemIcon>
                            <Check color="success" />
                        </ListItemIcon>
                    }
                    <ListItemText inset={!isAll}>
                        {t<string>('cart-material.cart-construction-all-products')}
                    </ListItemText>
                </MenuItem>
            }
            <MenuItem onClick={() => onToggleStatus('DEFAULT')}>
                {
                    checked.includes('DEFAULT') &&
                    <ListItemIcon>
                        <Check color="success" />
                    </ListItemIcon>
                }
                <ListItemText inset={!checked.includes('DEFAULT')}>
                    { t<string>('cart-material.in-quotation') }
                </ListItemText>
            </MenuItem>
            <MenuItem onClick={() => onToggleStatus('OPTION')}>
                {
                    checked.includes('OPTION') &&
                    <ListItemIcon>
                        <Check color="success" />
                    </ListItemIcon>
                }
                <ListItemText inset={!checked.includes('OPTION')}>
                    { t<string>('cart-material.in-option') }
                </ListItemText>
            </MenuItem>
            {
                ['cercledesvoyages', 'continentsinsolites', 'visiteurs', 'tropicalementvotre'].includes(quotation_code) &&
                user?.client_full.type !== 2 &&
                <MenuItem onClick={() => onToggleStatus('HIDE')}>
                    {
                        checked.includes('HIDE') &&
                        <ListItemIcon>
                            <Check color="success" />
                        </ListItemIcon>
                    }
                    <ListItemText inset={!checked.includes('HIDE')}>
                        { t<string>('cart-material.in-hide') }
                    </ListItemText>
                </MenuItem>
            }
            {
                ['cercledesvoyages', 'continentsinsolites', 'tropicalementvotre'].includes(quotation_code) &&
                    <MenuItem onClick={() => onToggleStatus('HIDE_COUNT')}>
                        {
                            checked.includes('HIDE_COUNT') &&
                            <ListItemIcon>
                                <Check color="success" />
                            </ListItemIcon>
                        }
                        <ListItemText inset={!checked.includes('HIDE_COUNT')}>
                            { t<string>('cart-material.automatic-product-is-hidden-for-travelers') }
                        </ListItemText>
                    </MenuItem>
            }
            {
                (data_trip?.trip.package_type || data_trip?.trip.circuit_type) &&
                <MenuItem onClick={() => onToggleStatus('VARIANT')}>
                    {
                        checked.includes('VARIANT') &&
                        <ListItemIcon>
                            <Check color="success" />
                        </ListItemIcon>
                    }
                    <ListItemText inset={!checked.includes('VARIANT')}>
                        { t<string>('cart-material.in-variant') }
                    </ListItemText>
                </MenuItem>
            }
        </Menu>
    );
}
