import React, { useEffect, useState, Fragment } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { makeStyles } from "@material-ui/core";

import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import Tooltip from "@material-ui/core/Tooltip";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { Typography } from "@mui/material";

const useStyles = makeStyles((theme) => ({
    timePickerWidth: {
        width: 53
    },
    menuHeight: {
        maxHeight: 216
    },
    borderRed: {
        border: "2px solid red"
    },
    red: {
        color: "red"
    }
}))


const CustomTimePicker = ({ value, setValue, valueChoices, step, minValue, maxValue }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const custom_time_error = useSelector(state => state.poi.custom_time_error);

    const [openMenu, setOpenMenu] = useState(null);
    const [menuChoices, setMenuChoices] = useState([]);

    const handleMenu = (event) => {
        setOpenMenu(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setOpenMenu(null);
    };

    useEffect(() => {
        if (minValue !== null){
            setValue(minValue);
        }
    }, [minValue])
    useEffect(() => {
        if (step !== null){
            let hours = [...Array(24).keys()];  
            let minutes = [...Array(60).keys()];
            let temp_choices = [];                                              
    
            hours.map((hour) => {   
                minutes.map((minute) => {
                    if (Number.isInteger(minute/step)){
                        let temp_hour = hour;
                        let temp_minute = minute;
                        if (temp_hour < 10){
                            temp_hour = "0" + temp_hour;
                        }
                        if (temp_minute < 10){
                            temp_minute = "0" + temp_minute;
                        }
                        let temp_time = temp_hour + ":" + temp_minute;
                        if (minValue !== null && maxValue !== null){
                            if (temp_time > minValue && temp_time < maxValue){
                                temp_choices.push(temp_hour + ":" + temp_minute);
                            }
                        }else{
                            temp_choices.push(temp_hour + ":" + temp_minute);
                        }
                    }
                })
            })
            setMenuChoices(temp_choices);
        }

        if (valueChoices !== null && valueChoices.length !== 0){
            setValue(valueChoices[0]);
            setMenuChoices(valueChoices);
        }
    }, [minValue, maxValue, valueChoices])

    // useEffect(() => {
    //     if (valueChoices !== null && valueChoices.length !== 0){
    //         setValue(valueChoices[0]);
    //         setMenuChoices(valueChoices);
    //     }
    // }, [valueChoices])

    const isBetweenMinMax = (time) => {
        if (minValue !== null && maxValue !== null && time.length === 5 && (time < minValue || time > maxValue)){
            enqueueSnackbar(t("poi.time_outer_limit") + ": " + time, { variant: "error", autoHideDuration: 4000, disableWindowBlurListener: true });
            setValue(minValue);
        }
    }

    const changeCustomTime = (new_time) => {
        if (/^[0-9,:]*$/.test(new_time) && new_time.length <= 5){
            if (value.length === 1 && new_time.length === 2) {
                // add : automatically
                new_time = new_time + ":";
                setValue(new_time);
            }
            else if (value.length === 2 && new_time.length === 3){
                new_time = value + ":" + new_time[2];
                setValue(new_time);
            }
            else{
                setValue(new_time);
            }
            isBetweenMinMax(new_time);
        }
    }
    return(
        <Grid container alignItems="center" justify="flex-start" spacing={1}>
            {
                menuChoices.length !== 0 && (
                    <Fragment>
                        <Grid item className={`${classes.timePickerWidth} ${custom_time_error && value === "" ? classes.borderRed : ""}`}>
                            <Tooltip title={t("poi.timepicker_tooltip")}>
                                <Input
                                    id={"custom_time"}
                                    value={value}
                                    onChange={(event) => changeCustomTime(event.target.value)}
                                    onClick={handleMenu}
                                />
                            </Tooltip>
                            <Menu
                                id="hours-menu"
                                anchorEl={openMenu}
                                keepMounted
                                open={Boolean(openMenu)}
                                onClose={handleCloseMenu}
                                disableScrollLock={true}
                                PaperProps={{className: classes.menuHeight}}
                                >
                                {
                                    menuChoices.map((time_choice) => {
                                        return (
                                            <MenuItem key={time_choice} value={time_choice} onClick={() => {
                                                handleCloseMenu();
                                                setValue(time_choice);
                                            }}>
                                                {time_choice}
                                            </MenuItem>
                                        )
                                    })
                                }
                            </Menu>
                        </Grid>
                        <Grid item>
                            <Tooltip title={t("poi.timepicker_tooltip")}>
                                <IconButton size="small" onClick={handleMenu}>
                                    <AccessTimeIcon className={custom_time_error && value === "" ? classes.red : ""} />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Fragment>
                )
            }
            {
                menuChoices.length === 0 && (
                    <Typography style={{paddingLeft: 5}}>{value}</Typography>
                )
            }
        </Grid>
    )
}

export default React.memo(CustomTimePicker);