import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles, useTheme, useMediaQuery } from "@material-ui/core";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Menu from "@material-ui/core/Menu";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import IconButton from "@material-ui/core/IconButton";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DomainIcon from '@material-ui/icons/Domain';
import CloseIcon from '@material-ui/icons/Close';

import Transition from "../../../Common/Transition";

import { ToggleAirport, ToggleStopoverAirport, ToggleStation, ShowAllAirports, ShowAllStopoverAirports, ShowAllStations } from "../../../../Actions/FlightSearch";

const main_color = JSON.parse(localStorage.getItem("config")).main_color;

const useStyles = makeStyles((theme) => ({
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: '#0000008A',
        letterSpacing: 1.25
    },
    fontWeight500: {
        fontWeight: 500
    },
    fontWeight900: {
        fontWeight: 900
    },
    fontSize12: {
        fontSize: 12
    },
    fontSize14: {
        fontSize: 14
    },
    textCenter: {
        textAlign: "center"
    },
    textRight: {
        textAlign: "right"
    },
    verticalSpacer: {
        padding: "8px 0px"
    },
    horizontalSpacer: {
        padding: "0px 16px"
    },
    filterButton: {
        border: "1.03872px solid #DDDDDD",
        boxSizing: "border-box",
        borderRadius: 31.1617,
        padding: "8px 12px"
    },
    closeMenu: {
        marginRight: 5
    }
}));

const AirportStationsButton = (({ searched_group }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const theme = useTheme();
    const isHandHeld = useMediaQuery(theme.breakpoints.down(960));

    const flight_list = useSelector(store => store.flight_search.flight_list);
    const airports = useSelector(store => store.flight_search.airports);
    const show_all_airports = useSelector(store => store.flight_search.show_all_airports);
    const show_all_stop_over_airport = useSelector(store => store.flight_search.show_all_stop_over_airport);
    const show_all_stations = useSelector(store => store.flight_search.show_all_stations);
    const stopover_airport = useSelector(store => store.flight_search.stopover_airport);
    const stations = useSelector(store => store.flight_search.stations);

    const [openAirportsStations, setOpenAirportsStations] = useState(null);
    const [originDestination, setOriginDestination] = useState([]);
    const [stopoversJourney, setStopoversJourney] = useState([]);
    const [displayStopovers, setDisplayStopovers] = useState(false);

    useEffect(() => {
        let temp_origin_dest = [];
        let temp_stopovers = [...searched_group.journey];
        flight_list.map((flight) => {
            flight.outbounds.map((outbound, outbound_index) => {
                let stopovers_by_journey = [];
                //  Get origin airports/stations and destination airports/stations
                if (outbound.legs[0].origin_airport !== undefined && temp_origin_dest.find((tmp) => {
                    return tmp.id === outbound.legs[0].origin_airport;
                }) === undefined) {
                    temp_origin_dest.push(airports[outbound.legs[0].origin_airport]);
                }
                if (outbound.legs[outbound.legs.length - 1].destination_airport !== undefined && temp_origin_dest.find((tmp) => {
                    return tmp.id === outbound.legs[outbound.legs.length - 1].destination_airport;
                }) === undefined) {
                    temp_origin_dest.push(airports[outbound.legs[outbound.legs.length - 1].destination_airport]);
                }
                if (outbound.legs[0].origin_station !== undefined && temp_origin_dest.find((tmp) => {
                    return tmp.id === outbound.legs[0].origin_station;
                }) === undefined) {
                    temp_origin_dest.push(stations[outbound.legs[0].origin_station]);
                }
                if (outbound.legs[outbound.legs.length - 1].destination_station !== undefined && temp_origin_dest.find((tmp) => {
                    return tmp.id === outbound.legs[outbound.legs.length - 1].destination_airport;
                }) === undefined) {
                    temp_origin_dest.push(stations[outbound.legs[outbound.legs.length - 1].destination_station]);
                }
                // Get stopover airports/stations
                if (outbound.legs.length > 1) {
                    for (let i = 0; i < outbound.legs.length - 1; i++) {
                        if (i === 0) {
                            if (outbound.legs[0].destination_airport !== undefined && stopovers_by_journey.find((tmp) => {
                                return tmp.id === outbound.legs[0].destination_airport;
                            }) === undefined) {
                                stopovers_by_journey.push(airports[outbound.legs[0].destination_airport]);
                            }
                            if (outbound.legs[0].origin_station !== undefined && stopovers_by_journey.find((tmp) => {
                                return tmp.id === outbound.legs[0].origin_station;
                            }) === undefined) {
                                stopovers_by_journey.push(stations[outbound.legs[0].origin_station]);
                            }
                        } else if (i === outbound.legs.length - 1) {
                            if (outbound.legs[outbound.legs.length - 1].origin_station !== undefined && stopovers_by_journey.find((tmp) => {
                                return tmp.id === outbound.legs[outbound.legs.length - 1].origin_station;
                            }) === undefined) {
                                stopovers_by_journey.push(stations[outbound.legs[outbound.legs.length - 1].origin_station]);
                            }
                            if (outbound.legs[outbound.legs.length - 1].destination_station !== undefined && stopovers_by_journey.find((tmp) => {
                                return tmp.id === outbound.legs[outbound.legs.length - 1].destination_station;
                            }) === undefined) {
                                stopovers_by_journey.push(stations[outbound.legs[outbound.legs.length - 1].destination_station]);
                            }
                        } else {
                            if (outbound.legs[i].origin_airport !== undefined && stopovers_by_journey.find((tmp) => {
                                return tmp.id === outbound.legs[i].origin_airport;
                            }) === undefined) {
                                stopovers_by_journey.push(airports[outbound.legs[i].origin_airport]);
                            }
                            if (outbound.legs[i].destination_airport !== undefined && stopovers_by_journey.find((tmp) => {
                                return tmp.id === outbound.legs[i].destination_airport;
                            }) === undefined) {
                                stopovers_by_journey.push(airports[outbound.legs[i].destination_airport]);
                            }
                            if (outbound.legs[i].origin_station !== undefined && stopovers_by_journey.find((tmp) => {
                                return tmp.id === outbound.legs[i].origin_station;
                            }) === undefined) {
                                stopovers_by_journey.push(stations[outbound.legs[i].origin_station]);
                            }
                            if (outbound.legs[i].destination_station !== undefined && stopovers_by_journey.find((tmp) => {
                                return tmp.id === outbound.legs[i].destination_station;
                            }) === undefined) {
                                stopovers_by_journey.push(stations[outbound.legs[i].destination_station]);
                            }
                        }
                    }
                }
                stopovers_by_journey = stopovers_by_journey.sort((a, b) => {
                    if (a.international_name < b.international_name) {
                        return -1;
                    }
                    if (a.international_name > b.international_name) {
                        return 1;
                    }
                    return 0;
                });
                let temp_journey = temp_stopovers[outbound_index];
                if (temp_journey.stopovers !== undefined) {
                    temp_journey.stopovers = [...temp_journey.stopovers, ...stopovers_by_journey];
                } else {
                    temp_journey.stopovers = stopovers_by_journey;
                }
                temp_journey.stopovers = temp_journey.stopovers.filter((v, i, a)=> a.findIndex(v2 => (v2.id === v.id)) === i);
            });
        });
        temp_origin_dest = temp_origin_dest.sort((a, b) => {
            if (a.international_name < b.international_name) {
                return -1;
            }
            if (a.international_name > b.international_name) {
                return 1;
            }
            return 0;
        });
        setOriginDestination(temp_origin_dest);
        setStopoversJourney(temp_stopovers);
    }, []);

    useEffect(() => {
        let temp_display_stopover = false;
        stopover_airport.map((journey) => {
            Object.keys(journey).forEach(key => {
                if (journey[key].displayed !== null) {
                    temp_display_stopover = true;
                }
            });
        });
        setDisplayStopovers(temp_display_stopover);
    }, [stopover_airport]);
    return (
        <Grid item>
            <Button size="small" className={classes.filterButton} onClick={(event) => setOpenAirportsStations(event.currentTarget)}>
                <DomainIcon className={classes.genericText} />
                <div className={`${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}`} style={{ paddingLeft: 8, textTransform: "capitalize" }}>
                    { t("filters.airports_stations") }
                </div>
            </Button>
            {
                isHandHeld && (
                    <Dialog open={Boolean(openAirportsStations)} fullScreen TransitionComponent={Transition}>
                        <DialogTitle>
                            <Grid container alignItems="center" justify="center">
                                <Grid item xs={4} />
                                <Grid item xs={4} className={`${classes.verticalSpacer} ${classes.genericText} ${classes.fontWeight900} ${classes.fontSize14} ${classes.textCenter}`}>
                                    { t("filters.airports_stations") }
                                </Grid>
                                <Grid item xs={4} className={classes.textRight}>
                                    <IconButton size="small" className={classes.closeMenu} onClick={() => setOpenAirportsStations(false)}>
                                        <CloseIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </DialogTitle>
                        <DialogContent style={{ padding: "0px 18px 0px 8px" }}>
                            <div className={`${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12} ${classes.textCenter}`}>
                                { t("filters.airports")}
                            </div>
                            <Divider />
                            <FormGroup className={classes.horizontalSpacer}>
                                <FormControlLabel 
                                    control={<Checkbox sx={{
                                        '&.Mui-checked': {
                                            color: `${main_color}`
                                        }
                                    }} checked={show_all_airports} onChange={() => dispatch(ShowAllAirports())} name="airports_null" size="small" />}
                                    label={t("flight_search.filter_null")}
                                    classes={{ label: `${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}` }}
                                />
                                {
                                    originDestination.map((place) => {
                                        if (airports[place.id] !== undefined && airports[place.id].displayed !== null) {
                                            return (
                                                <FormControlLabel 
                                                    key={place.code}
                                                    control={<Checkbox sx={{
                                                        '&.Mui-checked': {
                                                            color: `${main_color}`
                                                        }
                                                    }} checked={airports[place.id].displayed} onChange={() => dispatch(ToggleAirport(place.id))} name={place.international_name} size="small" />}
                                                    label={ place.name !== undefined ? place.name : place.international_name }
                                                    classes={{ label: `${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}` }}
                                                />
                                            );
                                        }
                                    })
                                }
                            </FormGroup>
                            <Divider />
                            <div className={`${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12} ${classes.textCenter}`}>
                                { t("filters.stations")}
                            </div>
                            <Divider />
                            <FormGroup className={classes.horizontalSpacer}>
                                <FormControlLabel 
                                    control={<Checkbox sx={{
                                        '&.Mui-checked': {
                                            color: `${main_color}`
                                        }
                                    }} checked={show_all_stations} onChange={() => dispatch(ShowAllStations())} name="stations_null" size="small" />}
                                    label={t("flight_search.filter_null")}
                                    classes={{ label: `${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}` }}
                                />
                                {
                                    originDestination.map((place) => {
                                        if (stations[place.id] !== undefined && stations[place.id].displayed !== null) {
                                            return (
                                                <FormControlLabel 
                                                    key={place.code}
                                                    control={<Checkbox sx={{
                                                        '&.Mui-checked': {
                                                            color: `${main_color}`
                                                        }
                                                    }} checked={stations[place.id].displayed} onChange={() => dispatch(ToggleStation(place.id))} name={place.international_name} size="small" />}
                                                    label={ place.name !== undefined ? place.name : place.international_name }
                                                    classes={{ label: `${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}` }}
                                                />
                                            );
                                        }
                                    })
                                }
                            </FormGroup>
                            {
                                displayStopovers && (
                                    <Grid>
                                        <Divider />
                                        <div className={`${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12} ${classes.textCenter}`}>
                                            { t("filters.stopover_airports")}
                                        </div>
                                        <Divider />
                                        <FormControlLabel 
                                            control={<Checkbox sx={{
                                                '&.Mui-checked': {
                                                    color: `${main_color}`
                                                }
                                            }} checked={show_all_stop_over_airport} onChange={() => dispatch(ShowAllStopoverAirports())} name="stopovers_null" size="small" />}
                                            label={t("flight_search.filter_null")}
                                            classes={{ label: `${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}` }}
                                            className={classes.horizontalSpacer}
                                        />
                                        {
                                            stopoversJourney.map((journey, journey_index) => {
                                                return (
                                                    <Grid key={journey_index}>
                                                        <Divider />
                                                        <div className={`${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12} ${classes.textCenter}`} style={{ paddingTop: 8 }}>
                                                            { journey.origin.city + " - " + journey.destination.city}
                                                        </div>
                                                        <FormGroup className={classes.horizontalSpacer}>
                                                            {
                                                                journey.stopovers.map((place) => {
                                                                    if (stopover_airport[journey_index] !== undefined && stopover_airport[journey_index][place.id] !== undefined && stopover_airport[journey_index][place.id].displayed !== null) {
                                                                        return (
                                                                            <FormControlLabel 
                                                                                key={place.code}
                                                                                control={<Checkbox sx={{
                                                                                    '&.Mui-checked': {
                                                                                        color: `${main_color}`
                                                                                    }
                                                                                }} checked={stopover_airport[journey_index][place.id].displayed} onChange={() => dispatch(ToggleStopoverAirport(place.id.toString(), journey_index))} name={place.international_name} size="small" />}
                                                                                label={ place.name !== undefined ? place.name : place.international_name }
                                                                                classes={{ label: `${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}` }}
                                                                            />
                                                                        );
                                                                    }
                                                                })
                                                            }
                                                        </FormGroup>
                                                    </Grid>
                                                );
                                            })
                                        }
                                    </Grid>
                                )
                            }
                        </DialogContent>
                    </Dialog>
                )
            }
            {
                !isHandHeld && (
                    <Menu 
                        id="airports-stations-menu"
                        anchorEl={openAirportsStations}
                        keepMounted
                        open={Boolean(openAirportsStations)}
                        onClose={() => setOpenAirportsStations(false)}
                        disableScrollLock={true}
                        getContentAnchorEl={null}
                        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                    >
                        <Grid container alignItems="center" justify="center" style={{ width: 500 }}>
                            <Grid item xs={4} />
                            <Grid item xs={4} className={`${classes.verticalSpacer} ${classes.genericText} ${classes.fontWeight900} ${classes.fontSize14} ${classes.textCenter}`}>
                                { t("filters.airports_stations") }
                            </Grid>
                            <Grid item xs={4} className={classes.textRight}>
                                <IconButton size="small" className={classes.closeMenu} onClick={() => setOpenAirportsStations(false)}>
                                    <CloseIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Divider />
                        <div className={`${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12} ${classes.textCenter}`}>
                            { t("filters.airports")}
                        </div>
                        <Divider />
                        <FormGroup className={classes.horizontalSpacer}>
                            <FormControlLabel 
                                control={<Checkbox sx={{
                                    '&.Mui-checked': {
                                        color: `${main_color}`
                                    }
                                }} checked={show_all_airports} onChange={() => dispatch(ShowAllAirports())} name="airports_null" size="small" />}
                                label={t("flight_search.filter_null")}
                                classes={{ label: `${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}` }}
                            />
                            {
                                originDestination.map((place) => {
                                    if (airports[place.id] !== undefined && airports[place.id].displayed !== null) {
                                        return (
                                            <FormControlLabel 
                                                key={place.code}
                                                control={<Checkbox sx={{
                                                    '&.Mui-checked': {
                                                        color: `${main_color}`
                                                    }
                                                }} checked={airports[place.id].displayed} onChange={() => dispatch(ToggleAirport(place.id))} name={place.international_name} size="small" />}
                                                label={ place.name !== undefined ? place.name : place.international_name }
                                                classes={{ label: `${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}` }}
                                            />
                                        );
                                    }
                                })
                            }
                        </FormGroup>
                        <Divider />
                        <div className={`${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12} ${classes.textCenter}`}>
                            { t("filters.stations")}
                        </div>
                        <Divider />
                        <FormGroup className={classes.horizontalSpacer}>
                            <FormControlLabel 
                                control={<Checkbox sx={{
                                    '&.Mui-checked': {
                                        color: `${main_color}`
                                    }
                                }} checked={show_all_stations} onChange={() => dispatch(ShowAllStations())} name="stations_null" size="small" />}
                                label={t("flight_search.filter_null")}
                                classes={{ label: `${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}` }}
                            />
                            {
                                originDestination.map((place) => {
                                    if (stations[place.id] !== undefined && stations[place.id].displayed !== null) {
                                        return (
                                            <FormControlLabel 
                                                key={place.code}
                                                control={<Checkbox sx={{
                                                    '&.Mui-checked': {
                                                        color: `${main_color}`
                                                    }
                                                }} checked={stations[place.id].displayed} onChange={() => dispatch(ToggleStation(place.id))} name={place.international_name} size="small" />}
                                                label={ place.name !== undefined ? place.name : place.international_name }
                                                classes={{ label: `${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}` }}
                                            />
                                        );
                                    }
                                })
                            }
                        </FormGroup>
                        {
                            displayStopovers && (
                                <Grid>
                                    <Divider />
                                    <div className={`${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12} ${classes.textCenter}`}>
                                        { t("filters.stopover_airports")}
                                    </div>
                                    <Divider />
                                    <FormControlLabel 
                                        control={<Checkbox sx={{
                                            '&.Mui-checked': {
                                                color: `${main_color}`
                                            }
                                        }} checked={show_all_stop_over_airport} onChange={() => dispatch(ShowAllStopoverAirports())} name="stopovers_null" size="small" />}
                                        label={t("flight_search.filter_null")}
                                        classes={{ label: `${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}` }}
                                        className={classes.horizontalSpacer}
                                    />
                                    {
                                        stopoversJourney.map((journey, journey_index) => {
                                            return (
                                                <Grid key={journey_index}>
                                                    <Divider />
                                                    <div className={`${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12} ${classes.textCenter}`} style={{ paddingTop: 8 }}>
                                                        { journey.origin.city + " - " + journey.destination.city}
                                                    </div>
                                                    <FormGroup className={classes.horizontalSpacer}>
                                                        {
                                                            journey.stopovers.map((place) => {
                                                                if (stopover_airport[journey_index] !== undefined && stopover_airport[journey_index][place.id] !== undefined && stopover_airport[journey_index][place.id].displayed !== null) {
                                                                    return (
                                                                        <FormControlLabel 
                                                                            key={place.code}
                                                                            control={<Checkbox sx={{
                                                                                '&.Mui-checked': {
                                                                                    color: `${main_color}`
                                                                                }
                                                                            }} checked={stopover_airport[journey_index][place.id].displayed} onChange={() => dispatch(ToggleStopoverAirport(place.id.toString(), journey_index))} name={place.international_name} size="small" />}
                                                                            label={ place.name !== undefined ? place.name : place.international_name }
                                                                            classes={{ label: `${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}` }}
                                                                        />
                                                                    );
                                                                }
                                                            })
                                                        }
                                                    </FormGroup>
                                                </Grid>
                                            );
                                        })
                                    }
                                </Grid>
                            )
                        }
                    </Menu>
                )
            }
        </Grid>
    );
});

export default React.memo(AirportStationsButton);
