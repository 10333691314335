//---- Dependencies ----//
import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';

//----icons----//
import FlightIcon from '@material-ui/icons/Flight';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import AirportShuttleIcon from '@material-ui/icons/AirportShuttle';
import LocalTaxiIcon from '@material-ui/icons/LocalTaxi';
import DirectionsTransitIcon from '@material-ui/icons/DirectionsTransit';
import TramIcon from '@material-ui/icons/Tram';
import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import { getClassSet } from 'react-bootstrap/lib/utils/bootstrapUtils';

const useStyles = makeStyles(theme => ({
    container: {
        maxWidth: 1128,
        paddingTop: 15
    },
    genericText: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        color: '#0000008A'
    },
    absoluteCenter: {
        margin: 0,
        position: 'absolute',
        top: '24%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    orangeButton: {
        backgroundColor: '#E6592F',
        color: 'white'
    },
    fullWidth: {
        width: '100%'
    },
    textJustify: {
        textAlign: 'justify'
    },
}));

const RenderItinerary = ({itinerary_list, newIti, handleItinerary, setSearch, setOpenItinerary, setNewIti, inputRef}) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const [renderItinerary, setRenderItinerary] = useState([]);

    useEffect(() => {
        console.log('itinerary_list:', itinerary_list);
        let tmp_iti = [];
        let check = [];
        itinerary_list.map((itinerary, index) => {
            if (index !== itinerary_list.length - 1 && itinerary.destination !== null && itinerary_list[index + 1].destination !== null) {
                if (itinerary.r2r_json.vehicle !== undefined && itinerary.r2r_json.vehicle !== null && itinerary.r2r_json.vehicle.kind !== undefined && itinerary.r2r_json.vehicle.kind !== null && itinerary.r2r_json.vehicle.kind === "car") {
                    check.push(index - 1);
                }
                tmp_iti.push({
                    current: {
                        start_date: itinerary.end_date,
                        destination: itinerary.destination,
                        step: index
                    },
                    next: {
                        start_date: itinerary_list[index + 1].start_date,
                        destination: itinerary_list[index + 1].destination,
                        step: index + 1
                    },
                    r2r: itinerary.r2r_json
                });
            }
        });
        if (newIti.length > 0) {
            check = newIti.slice();
        }
        console.log('tmp_iti:', tmp_iti);
        console.log('check:', check);
        setNewIti(check);
        setRenderItinerary(tmp_iti);
    }, []);
    const handleIti = () => {
        // let first_index = 0;
        // let last_index = 0;
        let new_array = [];
        let r = [];
        let t = [];
        for (let i = 0; i < newIti.length; ++i)
        {
            if (i == 0)
            {
                t.push(newIti[i]); // add the first element and continue
                continue;
            }
            if (newIti[i - 1] != (newIti[i] - 1))
            {
                // if the current is not sequential
                // add the current temporary array to arrays result
                r.push(t);
                // clear the temporary array and start over
                t = [];
            }
            t.push(newIti[i]);
        }
        r.push(t);
        r.map((el) => {
            let origin_hour = moment.utc(renderItinerary[el[0]].current.start_date).format('HH[h]mm');
            let return_hour = moment.utc(renderItinerary[el[el.length - 1]].next.start_date).format('HH[h]mm');
            let split_origin_hour = origin_hour.split("h");
            let split_return_hour = return_hour.split("h");
            if (parseInt(split_origin_hour[1]) <= 30) {
                split_origin_hour[1] = "30";
            }
            else if (parseInt(split_origin_hour[1]) <= 60) {
                split_origin_hour[0] = (parseInt(split_origin_hour[0]) + 1).toString();
                split_origin_hour[1] = "00";
            }
            if (parseInt(split_return_hour[1]) <= 30) {
                split_return_hour[1] = "30";
            }
            else if (parseInt(split_return_hour[1]) <= 60) {
                split_return_hour[0] = (parseInt(split_return_hour[0]) + 1).toString();
                split_return_hour[1] = "00";
            }
            let new_start_hour = (parseInt(split_origin_hour[0]) < 10 ? "0" : "") + (parseInt(split_origin_hour[0])).toString() + "h" + split_origin_hour[1];
            let new_return_hour = (parseInt(split_return_hour[0]) < 10 ? "0" : "") + (parseInt(split_return_hour[0])).toString() + "h" + split_return_hour[1];
            new_array.push({
                same_dest: false,
				age: 25,
				nb_vehicle: 1,
				origin_dest: renderItinerary[el[0]].current.destination,
				origin_dest_error: false,
				return_dest: renderItinerary[el[el.length - 1]].next.destination,
				return_dest_error: false,
				origin_date: moment.utc(renderItinerary[el[0]].current.start_date),
				origin_date_error: false,
				return_date: moment.utc(renderItinerary[el[el.length - 1]].next.start_date),
				date_error: false,
				return_date_error: false,
				origin_hour: new_start_hour,
				origin_hour_error: false,
				return_hour: new_return_hour,
				return_hour_error: false,
				contract: "",
				country_code: renderItinerary[el[0]].current.destination.data.country.country_code,
				origin_datepicker: false,
				return_datepicker: false
            });
        });
        setSearch(new_array);
        setTimeout(() => {
            inputRef.current.scrollIntoView({behavior: "smooth", block: "start"});
        }, 200);
        // setOpenItinerary(false);
    }
    console.log('renderItinerary:', renderItinerary);
    console.log('newIti:', newIti);

    return (
        <Grid item container direction={"column"} spacing={2}>
            {
                renderItinerary.map((itinerary, itinerary_index) => {
                    let vehicle = itinerary.r2r !== undefined && itinerary.r2r !== null ? (itinerary.r2r.vehicle !== undefined && itinerary.r2r.vehicle !== null && itinerary.r2r.vehicle.kind !== undefined && itinerary.r2r.vehicle.kind !== null ? itinerary.r2r.vehicle.kind : null) : null
                    return (
                        <Grid item key={itinerary_index} container direction={"row"} spacing={2} alignItems={"center"} justify={"space-around"}>
                            <Grid item>
                            {
                                vehicle !== null && (
                                    <Fragment>
                                    {
                                        vehicle === "plane" && (
                                            <FlightIcon/>
                                        )
                                    }
                                    {
                                        vehicle === "car" && (
                                            <DriveEtaIcon/>
                                        )
                                    }
                                    {
                                        vehicle === "shuttle" && (
                                            <AirportShuttleIcon/>
                                        )
                                    }
                                    {
                                        vehicle === "taxi" && (
                                            <LocalTaxiIcon/>
                                        )
                                    }
                                    {
                                        (vehicle === "subway" || vehicle === "train") && (
                                            <DirectionsTransitIcon/>
                                        )
                                    }
                                    {
                                        vehicle === "tram" && (
                                            <TramIcon/>
                                        )
                                    }
                                    {
                                        vehicle === "foot" && (
                                            <DirectionsWalkIcon/>
                                            )
                                    }
                                    </Fragment>
                                )
                            }
                            {
                                vehicle === null && (
                                    <DirectionsWalkIcon/>
                                )
                            }
                            </Grid>
                            <Grid item container direction={"column"} xs alignItems={"center"} justify={"center"}>
                                <Grid item style={{fontWeight: "bold"}}>{t("itinerary.step") + itinerary.current.step}</Grid>
                                <Grid item>
                                    {itinerary.current.destination.data.name}
                                </Grid>
                                <Grid item>
                                    {moment(itinerary.current.start_date).format("L") + " - " + moment.utc(itinerary.current.start_date).format("HH[h]mm")}
                                </Grid>
                            </Grid>
                            <Grid item><ArrowRightAltIcon/></Grid>
                            <Grid item container direction={"column"} xs alignItems={"center"} justify={"center"}>
                                <Grid item style={{fontWeight: "bold"}}>{t("itinerary.step") + itinerary.next.step}</Grid>
                                <Grid item>
                                    {itinerary.next.destination.data.name}
                                </Grid>
                                <Grid item>
                                    {moment(itinerary.next.start_date).format("L") + " - " + moment.utc(itinerary.next.start_date).format("HH[h]mm")}
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Checkbox
                                    checked={newIti.includes(itinerary_index)}
                                    onChange={() => {handleItinerary(itinerary_index)}}
                                    color={"primary"}
                                />
                            </Grid>
                        </Grid>
                    )
                })
            }
            <Grid item>
                <Button className={classes.orangeButton} variant={ "contained" } style={{float: "right"}} onClick={handleIti}>{t("flight.validate_change")}</Button>
            </Grid>
        </Grid>
    )
}
export default React.memo(RenderItinerary);