//Dependencies
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
//Core
import { Button, Stack } from '@mui/material';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
//Common
import GetCookie from '../Common/Functions/GetCookie';
import CheckBeforeRequest from '../Common/CheckBeforeRequest';

const useStyles = makeStyles({
    validateButton: {
        padding: '15px 10px',
        backgroundColor: '#E6592F',
        color: 'white'
    },
    cancelButton: {
        padding: '15px 10px',
        backgroundColor: 'white',
        border: '1px solid #E6592F',
        color: '#E6592F'
    }
});

const CartAssistanceManualCancellationRules = ({ assistance, onManualCancellationRules }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [noRefund, setNoRefund] = useState(assistance.no_refund);
    const [cancellationRules, setCancellationRules] = useState(assistance.cancel_condition_text !== null ? assistance.cancel_condition_text : '');
    const onNoRefundChange = event => {
        if (event.target.value) {
            setCancellationRules(t('cart-material.not-cancellable'));
        } else {
            const { headers } = CheckBeforeRequest();
            axios({
                method: 'GET',
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/custom-products/${assistance.id}/get_cancellation_text_from_charges/`
            }).then(response => {
                setCancellationRules(response.data.cancellation_text);
            }).catch(error => {
            });
        }
        setNoRefund(event.target.value);
    };
    const onCancellationRulesChange = event => {
        setCancellationRules(event.target.value);
    };
    const onValidateManualCancellationRules = () => {
        const { headers } = CheckBeforeRequest();
        axios({
            method: 'PATCH',
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/custom-products/${assistance.id}/`,
            data: {
                no_refund: noRefund,
                cancel_condition_text: cancellationRules === '' ? null : cancellationRules
            }
        }).then(response => {
            dispatch({ type: 'CART_ASSISTANCE_EDIT_BY_ID', payload: response.data });
            onManualCancellationRules();
        }).catch(error => {
            console.log(error);
            onManualCancellationRules();
        });
    };
    const onCancelManualCancellationRules = () => {
        onManualCancellationRules();
    };
    useEffect(() => {
        if (assistance.cancel_condition_text === null) {
            const { headers } = CheckBeforeRequest();
            axios({
                method: 'GET',
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/custom-products/${assistance.id}/get_cancellation_text_from_charges/`
            }).then(response => {
                setCancellationRules(response.data.cancellation_text);
            }).catch(error => {
            });
        }
    }, []);
    return (
        <Grid container spacing={ 2 }>
            <Grid item xs={ 4 }>
                <FormControl fullWidth variant={ 'outlined' }>
                    <Select id={ 'no-refund' } value={ noRefund } onChange={ onNoRefundChange }>
                        <MenuItem value={ false }>{ t('cart-material.cancellable-with-conditions') }</MenuItem>
                        <MenuItem value={ true }>{ t('cart-material.not-cancellable') }</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={ 12 }>
                <FormControl fullWidth variant={ 'outlined' }>
                    <InputLabel htmlFor={ 'cancellation-rules' }>{ t('cart-material.manual-cancellation-rules') }</InputLabel>
                    <OutlinedInput
                        id={ 'cancellation-rules' }
                        type={ 'text' }
                        multiline
                        rows={ 4 }
                        onChange={ onCancellationRulesChange }
                        label={ t('cart-material.manual-cancellation-rules') }
                        value={ cancellationRules === t('cart-material.not-cancellable') ? '' : cancellationRules }
                    />
                </FormControl>
            </Grid>
            <Grid item xs={ 12 }>
                <Stack direction="row" justifyContent="flex-end" spacing={1}>
                    <Button onClick={ onCancelManualCancellationRules }>{ t('global.cancel') }</Button>
                    <Button onClick={ onValidateManualCancellationRules }>{ t('global.validate') }</Button>
                </Stack>
            </Grid>
        </Grid>
    );
};

export default CartAssistanceManualCancellationRules;
