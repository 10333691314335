//---- Dependencies ----//
import React, { Fragment } from "react";
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

//---- Functions ----//
import GetPricesId from "../../Cart/Functions/Margin/GetPricesId.js";
import moment from "moment";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

const InfoReloadCars = ({classes}) => {
	const { t } = useTranslation();
	const user = useSelector(state => state.user.user);
	const currency = useSelector(state => state.trip.currency);
	const language = useSelector(state => state.header.tmp_language);
	const update_infos = useSelector(state => state.cart.update_infos);
	update_infos.cars.sort((a, b) => {
		if (a.old !== null && b.old !== null) {
			if (moment(a.old.start_date).isBefore(moment(b.old.start_date))) {
				return -1;
			}
			return 1;
		}
		return 1;
	});
	return (
		<Fragment>
		    {
		        (update_infos.cars).map((infos, index) => {
				    let dif_price = 0;
		            if (infos.new !== null && infos.new.error === undefined && infos.old !== null) {
		                dif_price = (parseFloat(infos.new.prices[GetPricesId(infos.new.prices, user.client, user, true)].selling_price) - parseFloat(infos.old.prices[GetPricesId(infos.old.prices, user.client, user, true)].selling_price));
		                return (
		                    <TableRow key={index} className={classes.tableRowDialog}>
		                        <TableCell> { moment(infos.new.start_date).format("DD-MM-YYYY") } </TableCell>
		                        <TableCell> { infos.new.address_pickup } </TableCell>
		                        <TableCell> <strong>{ t("global.car") } { moment(infos.old.end_date).diff(moment(infos.old.start_date), 'd') } { t("global.days") } :</strong> { infos.old.name }</TableCell>
		                        <TableCell> <strong>{ t("global.car") } { moment(infos.new.end_date).diff(moment(infos.new.start_date), 'd') } { t("global.days") } :</strong> { infos.new.name }</TableCell>
		                        <TableCell className={ dif_price === 0 ? null : (dif_price < 0 ? "ft-green" : "ft-red")} > { dif_price > 0 ? "+ " + Intl.NumberFormat(language, { style: "currency", currency: currency.iso_code }).format(dif_price.toFixed(2))  : Intl.NumberFormat(language, { style: "currency", currency: currency.iso_code }).format(dif_price.toFixed(2)) } </TableCell>
		                    </TableRow>
		                );
		            }
		            if (infos.old !== null && infos.new === null) {
		                return (
		                    <TableRow key={index} className={classes.tableRowDialog}>
		                        <TableCell> { moment(infos.old.start_date).format("DD-MM-YYYY") } </TableCell>
		                        <TableCell> { infos.old.address_pickup } </TableCell>
		                        <TableCell> <strong>{ t("global.car") }:</strong> { infos.old.name } </TableCell>
		                        <TableCell className={"ft-red"} > { t("itinerary.cant_change_product") } </TableCell>
		                        <TableCell className={ dif_price === 0 ? null : (dif_price < 0 ? "ft-green" : "ft-red")} > / </TableCell>
		                    </TableRow>
		                );
		            }
		            if (infos.old !== null && infos.new !== null && infos.new.error !== undefined) {
		                return (
		                    <TableRow key={index} className={classes.tableRowDialog}>
		                        <TableCell> { moment(infos.old.start_date).format("DD-MM-YYYY") } </TableCell>
		                        <TableCell> { infos.old.address_pickup } </TableCell>
		                        <TableCell> <strong>{ t("global.car") }:</strong> { infos.old.name } </TableCell>
		                        <TableCell className={"ft-red"}> { infos.new.error } </TableCell>
		                        <TableCell className={ dif_price === 0 ? null : (dif_price < 0 ? "ft-green" : "ft-red")} > / </TableCell>
		                    </TableRow>
		                );
		            }
		        })
		    }
		</Fragment>
	);
};

export default React.memo(InfoReloadCars);