var Handlebars = require("../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"flight-tickets-page-content "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(data && lookupProperty(data,"last")),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":48},"end":{"line":16,"column":103}}})) != null ? stack1 : "")
    + "\">\n            <div class=\"flight-tickets-page-content-header\">\n                <h2>\n                    DETAILS DES VOLS\n                </h2>\n                <div>\n                    Référence de réservation : <span>"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"ref") : depth0), depth0))
    + "</span>\n                </div>\n            </div>\n            <ul class=\"flight-tickets-page-list\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"items") : depth0),{"name":"each","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":16},"end":{"line":59,"column":25}}})) != null ? stack1 : "")
    + "            </ul>\n        </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return " flight-tickets-page-last-content ";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li class=\"flight-tickets-page-item\">\n                    <div class=\"flight-tickets-page-item-title\">\n                        "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"from") : depth0), depth0))
    + " &gt; "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"to") : depth0), depth0))
    + "\n                    </div>\n                    <div class=\"flight-tickets-page-item-content\">\n                        <div class=\"flight-tickets-page-item-date\">\n                            <span>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"fromDate") : depth0), depth0))
    + "</span>\n                            <img src=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"logo") : depth0), depth0))
    + "\">\n                            <span>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"toDate") : depth0), depth0))
    + "</span>\n                        </div>\n                        <div class=\"flight-tickets-page-item-separator\">\n                            <img src=\"../../../../../../Img/noun-plane-145381 2.png?roadbook\">\n                            <div></div>\n                        </div>\n                        <div class=\"flight-tickets-page-item-details\">\n                            <span>\n                                "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"departureAirport") : depth0), depth0))
    + "\n                            </span>\n                            <span>\n                                "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"flight") : depth0), depth0))
    + "\n                            </span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"hourly") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":48,"column":28},"end":{"line":52,"column":35}}})) != null ? stack1 : "")
    + "                            <span>\n                                "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"arrivalAirport") : depth0), depth0))
    + "\n                            </span>\n                        </div>\n                    </div>\n                </li>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <span>\n                                "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"hourly") : depth0), depth0))
    + "\n                            </span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"chapter flight-tickets-container\">\n    <div class=\"flight-tickets-page\">\n        <h1>\n            Vos <span style=\"font-weight: bold;\">billets d'avion</span>\n        </h1>\n        <p>\n            Ceci est votre billet éléctronique. Vous n'avez pas besoin d'imprimer\n            d'autres documents. Veuillez vous enregistrer en ligne sur le site de la compagnie\n            aérienne 24 heures avant votre départ et vous rendre directement au comptoir.\n        </p>\n        <p>\n            Nous vous recommandons de vous présenter 3 heures à l'avance à l'aéroport, au comptoir\n            de la compagnie aérienne.\n        </p>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"contents") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":8},"end":{"line":62,"column":17}}})) != null ? stack1 : "")
    + "        <p class=\"flight-tickets-page-footer\">\n            Valable uniquement dans le cadre d’un forfait touristique.\n            <br />\n            Aucune demande de remboursement de billet ne pourra être formulée directement auprès des Compagnies\n            Aériennes.\n        </p>\n    </div>\n</div>";
},"useData":true});