import React, { useState } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid"
import Calendar from "react-calendar";

import AvailabilityCard from "./AvailabilityCard";

import moment from "moment";

const useStyles = makeStyles(theme => ({
    calendar: {
        "& .react-calendar__navigation__arrow":{
            backgroundColor: "#FFFFFF",
            border: "none"
        },
        "& .react-calendar__month-view__weekdays__weekday":{
            padding: 8
        },
        "& .react-calendar__month-view__weekdays__weekday abbr":{
            textDecoration: "none"
        },
        "& .react-calendar__navigation__label": {
            backgroundColor: "#FFFFFF",
            border: "none",
            color: "#000000",
            padding: "8px 0px"
        }
    },
    notInRangeDate: {
        backgroundColor: "#FFFFFF",
            border: "none",
            padding: 4
    },
    notAvailableDate: {
        backgroundColor: "#ff7373",
        border: "none",
        padding: 4
    },
    availableDate: {
        backgroundColor: "#b4eeb4",
        border: "none",
        padding: 4
    }
}))

const AvailabilityCalendar = ({ arrivalDate, departureDate, availabilities, assignedGroups, displayCalendarDate, setDisplayCalendarDate, selectActivity, selectedVariantPrice, manualVariant }) => {
    const classes = useStyles();
    const language = useSelector(state => state.header.tmp_language);

    const [calendarDate, setCalendarDate] = useState(new Date(arrivalDate));

    const tileClassName = ({ date, view }) => {
        // Add class to tiles in month view only
        if (view === 'month') {
            if (moment(date).diff(moment(arrivalDate), "d") >= 0 && moment(date).diff(moment(departureDate), "d") <= 0){
                let date_is_available = false;
                availabilities[assignedGroups.length].scheduled_prices.map((day_prices) => {
                    if (day_prices.date.length === 10) {
                        
                        if (moment(date).format("YYYY-MM-DD") === day_prices.date){
                            date_is_available = true;
                        }
                    } else {
                        let tmp_dates = day_prices.date.split(" - ");
                        if (moment(date).format("YYYY-MM-DD") === tmp_dates[0]){
                            date_is_available = true;
                        }
                    }
                })
                if (date_is_available){
                    return classes.availableDate;
                }else{
                    return classes.notAvailableDate;
                }
            }else{
                return classes.notInRangeDate;
            }
        }
    }
    
    const changeCalendarDate  = (new_date) => {
        setCalendarDate(new_date);
        setDisplayCalendarDate(true);
    }
    return (
        <Grid>
            <Calendar
                onChange={(new_date) => {changeCalendarDate(new_date)}}
                value={calendarDate}
                minDetail="month"
                maxDetail="month"
                view="month"
                locale={language}
                className={`${classes.calendar} ${classes.spacer}`}
                tileClassName={tileClassName}
                />
            {
                // availability for date chosen on calendar
                displayCalendarDate && (selectedVariantPrice === undefined || selectedVariantPrice === null) && (manualVariant === undefined || manualVariant === null) && availabilities[assignedGroups.length].scheduled_prices.map((day_prices) => {
                    if (day_prices.date === moment(calendarDate).format("YYYY-MM-DD")){
                        return(
                            <AvailabilityCard key={`${day_prices.date}-${day_prices.hour.length >= 1 ? day_prices.hour[0] : ""}`} day_prices={day_prices} selectActivity={selectActivity} />
                        )
                    }
                })
            }
            {
                displayCalendarDate && selectedVariantPrice !== undefined && selectedVariantPrice !== null && selectedVariantPrice.length !== 0 && selectedVariantPrice.map((day_prices) => {
                    let identifier = JSON.parse(day_prices.identifier);
                    if (day_prices.date.length === 10 && day_prices.date === moment(calendarDate).format("YYYY-MM-DD")) {
                        return(
                            <AvailabilityCard key={`${day_prices.date}-${day_prices.hour.length >= 1 ? day_prices.hour[0] : ""}`} day_prices={day_prices} selectActivity={selectActivity} />
                        )
                    } else {
                        let tmp_dates = day_prices.date.split(" - ");
                        if (moment(calendarDate).format("YYYY-MM-DD") === tmp_dates[0]){
                            return(
                                <AvailabilityCard key={`${day_prices.date}-${day_prices.hour.length >= 1 ? day_prices.hour[0] : ""}`} day_prices={day_prices} selectActivity={selectActivity} />
                            )
                        }
                    }
                })
            }
        </Grid>
    )
}

export default React.memo(AvailabilityCalendar);