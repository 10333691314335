import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import GetCookie from "../../Common/Functions/GetCookie";
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import { ManualProduct } from "../../Itinerary/objects/manualProduct";
import { AppState } from "../../../Reducers/Reducers";

type Callback = () => Promise<boolean>;

export function useMarkBookingForManualProducts(): Callback {
    const dispatch = useDispatch();
    const manualProducts = useSelector((state: AppState) => state.cart.manual_item_list);
    const bookableManualProducts = useSelector((state: AppState) => state.cartConstruction.bookableManualProducts);

    return async () => {
        let promises = bookableManualProducts.map(async (product) => {
            const response = await makeRequest({
                id: product.id,
                bookable: true
            });

            if (response) {
                dispatch({ type: 'CART_EDIT_MANUAL_ITEM', payload: response });
            }
        });
        const bookableManualProductsIds = bookableManualProducts.map((product) => {
            return product.id;
        });
        promises = promises.concat(
            manualProducts?.filter((product) => {
                return !bookableManualProductsIds.includes(product.id);
            }).map(async (product) => {
                const response = await makeRequest({
                    id: product.id,
                    bookable: false
                });
    
                if (response) {
                    dispatch({ type: 'CART_EDIT_MANUAL_ITEM', payload: response });
                }
            }) ?? []
        );

        const responses = await Promise.allSettled(promises);
        return responses.every((response) => {
            return response.status === 'fulfilled';
        });
    };
}

type RequestOptions = {
    id: number,
    bookable: boolean
}

async function makeRequest(options: RequestOptions): Promise<ManualProduct | null> {
    const { pass_check, headers } = CheckBeforeRequest();

    if (pass_check) {
        const response = await axios({
            method: 'PATCH',
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/manual-products/${options.id}/`,
            data: {
                booking_process_state: options.bookable ? 'BOOK' : 'NOTHING'
            }
        });
        return response.data;
    }

    return null;
}
