import { cloneDeep, isNumber, mapKeys, mapValues } from "lodash";
import { MailTemplateState } from "../objects/mailTemplateState";
import {
    ADD_VISUAL_EDITOR_BLOCK,
    ADD_VISUAL_EDITOR_BLOCKS_WITHOUT_ORDER,
    APPEND_VISUAL_EDITOR_BLOCK,
    CLEAR_VISUAL_EDITOR_BLOCKS,
    CREATE_HTML_CODE_EDITOR_LOCALE_INITIAL_CONTENT,
    CREATE_VISUAL_EDITOR_LOCALE_INITIAL_CONTENT,
    DELETE_VISUAL_EDITOR_BLOCK,
    DELETE_VISUAL_EDITOR_BLOCK_FROM_COLUMNS_BLOCK,
    DUPLICATE_VISUAL_EDITOR_BLOCK,
    INSERT_VISUAL_EDITOR_BLOCK,
    MailTemplateActionTypes,
    REDO_VISUAL_EDITOR,
    RESET_CREATE_FORM,
    RESET_EDITORS,
    RESTORE_VISUAL_EDITOR_BLOCKS_FROM_HTML,
    SELECT_VISUAL_EDITOR_BLOCK,
    SET_AUTOCOMPLETE,
    SET_CREATE_ACTION,
    SET_CREATE_EXTRA_PARAMS,
    SET_CREATE_NAME,
    SET_CREATE_SUBJECT,
    SET_EDITOR_CHOICE,
    SET_GOOGLE_FONTS,
    SET_HTML_CODE_EDITOR_CONTENT,
    SET_LOADING,
    SET_MAIL_TEMPLATES,
    SET_QUERY,
    SET_RECIPIENTS,
    SET_VISUAL_EDITOR_BLOCK_OPTIONS,
    SET_VISUAL_EDITOR_BODY_STYLES,
    SET_VISUAL_EDITOR_DRAGGED_BLOCK_ID,
    SET_VISUAL_EDITOR_MODE,
    TOGGLE_CANCEL_MODAL,
    TOGGLE_CREATE,
    UNDO_VISUAL_EDITOR,
    UNSELECT_VISUAL_EDITOR_BLOCK
} from "./actionTypes";
import { RegisteredBlocks } from "../utils/registered-blocks";
import { rgbToHex } from "../utils/rgbToHex";
import { htmlWalker, MailTemplateBodyHtmlWalkingStrategy } from "../utils/html-walker";
import { createDomObjectsFromHtml } from "../utils/dom";
import { Block } from "../objects/block";
import { GoogleFont } from "../objects/googleFont";
import DefaultHtml from "../assets/mail-template-default.html?raw";
import undoable, { includeAction } from "redux-undo";
import { combineReducers } from "redux";
import { MailTemplateVisualEditorState } from "../objects/mailTemplateVisualEditorState";
import { ColumnsBlock } from "../blocks/mailTemplateVisualEditorColumnsBlock";
import { Mode } from "../objects/mode";
import { restorer } from "../blocks/restorer";
import { extractVersionFromHtml } from "../utils/extractVersionFromHtml";
import { restoreRichEditorStateFromHtml } from "../../utils/editor/restoreRichEditorStateFromHtml";
import { isVisualEditorCompatible } from "../utils/isVisualEditorCompatible";
import { RichEditorStyleMap } from "../../utils/editor/editor";
import { customColors } from "../../utils/editor/customColors";
import { TextBlock } from "../blocks/mailTemplateVisualEditorTextBlock";

const defaultFont: GoogleFont = {
    name: 'Roboto',
    url: 'https://fonts.googleapis.com/css2?family=Roboto&display=swap&global=true'
};

const initialState: MailTemplateState = {
    autocomplete: { state: 'loading' },
    cancel_modal: false,
    create: false,
    create_action: null,
    create_extra_params: {
        activated: true,
        replyable: true
    },
    create_name: [],
    create_subject: [],
    editorChoice: null,
    googleFonts: [],
    queries: [],
    htmlCodeEditorContents: [],
    mailTemplates: null,
    loading: false,
    instances: {},
    recipients: { state: 'loading' }
};

const initialVisualEditorState: MailTemplateVisualEditorState['instances'][string] = {
    visualEditorMode: Mode.DESKTOP,
    visualEditorBlocks: {},
    visualEditorBodyStyles: {
        fontFamily: defaultFont,
        backgroundColor: "#fff",
        width: 600,
        padding: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0
        },
        innerBorder: {
            color: '#000',
            width: 0
        },
        innerBackgroundColor: '#fff',
        innerPadding: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0
        }
    }
};

const MailTemplateReducer = (state: MailTemplateState = initialState, action: MailTemplateActionTypes): MailTemplateState => {
    switch (action.type) {
        case CREATE_HTML_CODE_EDITOR_LOCALE_INITIAL_CONTENT: {
            const firstContent = state.htmlCodeEditorContents[0];
            const contents = state.htmlCodeEditorContents.find(({ locale }) => locale === action.payload.locale);

            if (!contents && firstContent) {
                return {
                    ...state,
                    htmlCodeEditorContents: state.htmlCodeEditorContents.concat([
                        {
                            locale: action.payload.locale,
                            content: firstContent.content
                        }
                    ])
                };
            } else if (!contents && !firstContent) {
                return {
                    ...state,
                    htmlCodeEditorContents: state.htmlCodeEditorContents.concat([
                        { locale: action.payload.locale, content: DefaultHtml }
                    ])
                };
            }

            return state;
        }
        case RESET_CREATE_FORM: {
            return {
                ...state,
                create_action: 'USER_EMAIL_VERIFICATION',
                create_name: [],
                create_subject: [],
                create_extra_params: {
                    activated: true,
                    replyable: true
                }
            };
        }
        case RESET_EDITORS: {
            return {
                ...state,
                editorChoice: null,
                htmlCodeEditorContents: []
            };
        }
        case SET_AUTOCOMPLETE: {
            return {
                ...state,
                autocomplete: action.payload
            };
        }
        case SET_CREATE_ACTION: {
            return { ...state, create_action: action.payload };
        }
        case SET_CREATE_EXTRA_PARAMS: {
            return { ...state, create_extra_params: action.payload };
        }
        case SET_CREATE_NAME: {
            const current = state.create_name.find((item) => item.locale === action.payload.locale);

            if (!current) {
                return {
                    ...state,
                    create_name: state.create_name.concat([action.payload])
                };
            }

            return {
                ...state,
                create_name: state.create_name.map((item) => {
                    if (item.locale === action.payload.locale) {
                        return {
                            ...item,
                            value: action.payload.value
                        };
                    }
                    return item;
                })
            };
        }
        case SET_CREATE_SUBJECT: {
            const current = state.create_subject.find((item) => item.locale === action.payload.locale);

            if (!current) {
                return {
                    ...state,
                    create_subject: state.create_subject.concat([action.payload])
                };
            }

            return {
                ...state,
                create_subject: state.create_subject.map((item) => {
                    if (item.locale === action.payload.locale) {
                        return {
                            ...item,
                            value: action.payload.value
                        };
                    }
                    return item;
                })
            };
        }
        case SET_EDITOR_CHOICE: {
            return { ...state, editorChoice: action.payload };
        }
        case SET_GOOGLE_FONTS: {
            return { ...state, googleFonts: action.payload };
        }
        case SET_HTML_CODE_EDITOR_CONTENT: {
            const index = state.htmlCodeEditorContents.findIndex((item) => {
                return item.locale === action.payload.locale;
            });

            if (index < 0) {
                return {
                    ...state,
                    htmlCodeEditorContents: state.htmlCodeEditorContents.concat([action.payload])
                };
            }

            return {
                ...state,
                htmlCodeEditorContents: [
                    ...state.htmlCodeEditorContents.slice(0, index),
                    action.payload,
                    ...state.htmlCodeEditorContents.slice(index + 1)
                ]
            };
        }
        case SET_LOADING: {
            return {
                ...state,
                loading: action.payload
            };
        }
        case SET_MAIL_TEMPLATES: {
            return {
                ...state,
                mailTemplates: action.payload
            };
        }
        case SET_QUERY: {
            const currentQuery = state.queries.find((query) => query.key === action.payload.key);
            if (currentQuery) {
                return {
                    ...state,
                    queries: state.queries.map((query) => query.key === action.payload.key ? action.payload : query)
                };
            }
            return {
                ...state,
                queries: [...state.queries, action.payload]
            };
        }
        case SET_RECIPIENTS: {
            return {
                ...state,
                recipients: action.payload
            };
        }
        case TOGGLE_CANCEL_MODAL: {
            return { ...state, cancel_modal: !state.cancel_modal };
        }
        case TOGGLE_CREATE: {
            return { ...state, create: !state.create };
        }
        case SET_VISUAL_EDITOR_DRAGGED_BLOCK_ID: {
            return {
                ...state,
                instances: {
                    ...state.instances,
                    [action.payload.instanceId]: {
                        ...(
                            state.instances[action.payload.instanceId] ??
                            {
                                visualEditorDraggedBlockId: null,
                                visualEditorSelectedBlockId: null
                            }
                        ),
                        visualEditorDraggedBlockId: action.payload.blockId
                    }
                }
            };
        }
        case UNSELECT_VISUAL_EDITOR_BLOCK: {
            return {
                ...state,
                instances: {
                    ...state.instances,
                    [action.payload.instanceId]: {
                        ...(
                            state.instances[action.payload.instanceId] ??
                            {
                                visualEditorDraggedBlockId: null,
                                visualEditorSelectedBlockId: null
                            }
                        ),
                        visualEditorSelectedBlockId: null
                    }
                }
            };
        }
        case SELECT_VISUAL_EDITOR_BLOCK: {
            return {
                ...state,
                instances: {
                    ...state.instances,
                    [action.payload.instanceId]: {
                        ...(
                            state.instances[action.payload.instanceId] ??
                            {
                                visualEditorDraggedBlockId: null,
                                visualEditorSelectedBlockId: action.payload.blockId
                            }
                        ),
                        visualEditorSelectedBlockId: action.payload.blockId
                    }
                }
            };
        }
        case DELETE_VISUAL_EDITOR_BLOCK: {
            return {
                ...state,
                instances: mapValues(
                    state.instances,
                    () => ({
                        visualEditorDraggedBlockId: null,
                        visualEditorSelectedBlockId: null
                    })
                )
            };
        }
        case DELETE_VISUAL_EDITOR_BLOCK_FROM_COLUMNS_BLOCK: {
            return {
                ...state,
                instances: mapValues(
                    state.instances,
                    () => ({
                        visualEditorDraggedBlockId: null,
                        visualEditorSelectedBlockId: null
                    })
                )
            };
        }
        default: return state;
    }
};

const VisualEditorReducer = (
    state: MailTemplateVisualEditorState = { instances: {} },
    action: MailTemplateActionTypes
): MailTemplateVisualEditorState => {
    switch (action.type) {
        case ADD_VISUAL_EDITOR_BLOCK: {
            const instance = state.instances[action.payload.instanceId] ?? cloneDeep({
                ...initialVisualEditorState
            });
            const blockOptions = RegisteredBlocks.find((item) => item.type === action.payload.type);
            const content = instance.visualEditorBlocks[action.payload.locale];
            const order = content?.order ?? [];
            const blocks = content?.blocks ?? {};
            const prevBlockIndex = order.findIndex((blockId) => blockId === action.payload.prevBlockId);
            if (blockOptions) {
                const block = blockOptions.factory();
                return {
                    ...state,
                    instances: {
                        ...state.instances,
                        [action.payload.instanceId]: {
                            ...instance,
                            visualEditorBlocks: {
                                ...instance.visualEditorBlocks,
                                [action.payload.locale]: {
                                    ...content,
                                    order: prevBlockIndex >= 0 ?
                                        [...order.slice(0, prevBlockIndex + 1), block.getId(), ...order.slice(prevBlockIndex + 1)] :
                                        order.concat([block.getId()]),
                                    blocks: {
                                        ...blocks,
                                        [block.getId()]: block
                                    }
                                }
                            }
                        }
                    }
                };
            }
            return state;
        }
        case ADD_VISUAL_EDITOR_BLOCKS_WITHOUT_ORDER: {
            const instance = state.instances[action.payload.instanceId] ?? cloneDeep({
                ...initialVisualEditorState
            });
            const content = instance.visualEditorBlocks[action.payload.locale];

            if (content) {
                return {
                    ...state,
                    instances: {
                        ...state.instances,
                        [action.payload.instanceId]: {
                            ...instance,
                            visualEditorBlocks: {
                                ...instance.visualEditorBlocks,
                                [action.payload.locale]: {
                                    ...content,
                                    blocks: {
                                        ...content.blocks,
                                        ...mapKeys(
                                            action.payload.blocks,
                                            (block) => block.getId()
                                        )
                                    }
                                }
                            }
                        }
                    }
                };
            }

            return state;
        }
        case APPEND_VISUAL_EDITOR_BLOCK: {
            const instance = state.instances[action.payload.instanceId] ?? cloneDeep({
                ...initialVisualEditorState
            });
            const content = instance.visualEditorBlocks[action.payload.locale];

            return {
                ...state,
                instances: {
                    ...state.instances,
                    [action.payload.instanceId]: {
                        ...instance,
                        visualEditorBlocks: {
                            ...instance.visualEditorBlocks,
                            [action.payload.locale]: {
                                order: (content?.order ?? []).concat([action.payload.block.getId()]),
                                blocks: {
                                    ...(content?.blocks ?? {}),
                                    [action.payload.block.getId()]: action.payload.block
                                }
                            }
                        }
                    }
                }
            };
        }
        case CREATE_VISUAL_EDITOR_LOCALE_INITIAL_CONTENT: {
            const instance = state.instances[action.payload.instanceId] ?? cloneDeep(initialVisualEditorState);
            const firstBlocks = Object.values(instance?.visualEditorBlocks ?? {})[0];
            const blocks = instance?.visualEditorBlocks[action.payload.locale];

            if (!blocks && firstBlocks) {
                return {
                    ...state,
                    instances: {
                        ...state.instances,
                        [action.payload.instanceId]: {
                            ...instance,
                            visualEditorBlocks: {
                                ...instance.visualEditorBlocks,
                                [action.payload.locale]: { ...firstBlocks }
                            }
                        }
                    }
                };
            } else if (!blocks && !firstBlocks) {
                return {
                    ...state,
                    instances: {
                        ...state.instances,
                        [action.payload.instanceId]: {
                            ...instance,
                            visualEditorBlocks: {
                                ...instance.visualEditorBlocks,
                                [action.payload.locale]: { order: [], blocks: {} }
                            }
                        }
                    }
                };
            }

            return state;
        }
        case CLEAR_VISUAL_EDITOR_BLOCKS: {
            const instance = state.instances[action.payload.instanceId] ?? cloneDeep(initialVisualEditorState);

            return {
                ...state,
                instances: {
                    ...state.instances,
                    [action.payload.instanceId]: {
                        ...instance,
                        visualEditorBlocks: {}
                    }
                }
            };
        }
        case DELETE_VISUAL_EDITOR_BLOCK: {
            const instance = state.instances[action.payload.instanceId] ?? cloneDeep(initialVisualEditorState);
            const content = instance.visualEditorBlocks[action.payload.locale];

            if (content) {
                //eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { [action.payload.id]: block, ...rest } = content.blocks;
                return {
                    ...state,
                    instances: {
                        ...state.instances,
                        [action.payload.instanceId]: {
                            ...instance,
                            visualEditorBlocks: {
                                ...instance.visualEditorBlocks,
                                [action.payload.locale]: {
                                    order: content.order.filter((item) => item !== action.payload.id),
                                    blocks: rest
                                }
                            }
                        }
                    }
                };
            }

            return state;
        }
        case DELETE_VISUAL_EDITOR_BLOCK_FROM_COLUMNS_BLOCK: {
            const instance = state.instances[action.payload.instanceId] ?? cloneDeep(initialVisualEditorState);
            const content = instance.visualEditorBlocks[action.payload.locale];
            const columnsBlock = content?.blocks[action.payload.columnsBlockId];

            if (content && columnsBlock) {
                const newBlock = columnsBlock.clone(columnsBlock.getOptions()) as ColumnsBlock;
                newBlock.removeChild(action.payload.id);
                return {
                    ...state,
                    instances: {
                        ...state.instances,
                        [action.payload.instanceId]: {
                            ...instance,
                            visualEditorBlocks: {
                                ...instance.visualEditorBlocks,
                                [action.payload.locale]: {
                                    ...content,
                                    blocks: {
                                        ...content.blocks,
                                        [action.payload.columnsBlockId]: newBlock
                                    }
                                }
                            }
                        }
                    }
                };
            }

            return state;
        }
        case DUPLICATE_VISUAL_EDITOR_BLOCK: {
            const instance = state.instances[action.payload.instanceId] ?? cloneDeep(initialVisualEditorState);
            const content = instance.visualEditorBlocks[action.payload.locale];
            const selectedBlock = content?.blocks[action.payload.id];
            const selectedBlockIndex = content?.order.findIndex((item) => item === action.payload.id);

            if (content && selectedBlock && isNumber(selectedBlockIndex)) {
                const block = selectedBlock.clone();
                return {
                    ...state,
                    instances: {
                        ...state.instances,
                        [action.payload.instanceId]: {
                            ...instance,
                            visualEditorBlocks: {
                                ...instance.visualEditorBlocks,
                                [action.payload.locale]: {
                                    order: [
                                        ...content.order.slice(0, selectedBlockIndex + 1),
                                        block.getId(),
                                        ...content.order.slice(selectedBlockIndex + 1)
                                    ],
                                    blocks: {
                                        ...content.blocks,
                                        [block.getId()]: block
                                    }
                                }
                            }
                        }
                    }
                };
            }

            return state;
        }
        case INSERT_VISUAL_EDITOR_BLOCK: {
            const instance = state.instances[action.payload.instanceId] ?? cloneDeep(initialVisualEditorState);
            const content = instance.visualEditorBlocks[action.payload.locale] ?? { order: [], blocks: {} };

            return {
                ...state,
                instances: {
                    ...state.instances,
                    [action.payload.instanceId]: {
                        ...instance,
                        visualEditorBlocks: {
                            ...instance.visualEditorBlocks,
                            [action.payload.locale]: {
                                order: [
                                    ...content.order.slice(0, action.payload.index),
                                    action.payload.block.getId(),
                                    ...content.order.slice(action.payload.index)
                                ],
                                blocks: {
                                    ...content.blocks,
                                    [action.payload.block.getId()]: action.payload.block
                                }
                            }
                        }
                    }
                }
            };
        }
        case RESET_EDITORS: {
            return {
                ...state,
                instances: mapValues(
                    state.instances,
                    () => ({
                        visualEditorMode: Mode.DESKTOP,
                        visualEditorBlocks: [],
                        visualEditorBodyStyles: {
                            fontFamily: defaultFont,
                            backgroundColor: "#fff",
                            width: 600,
                            padding: {
                                top: 0,
                                bottom: 0,
                                left: 0,
                                right: 0
                            },
                            innerBorder: {
                                color: '#000',
                                width: 0
                            },
                            innerBackgroundColor: '#fff',
                            innerPadding: {
                                top: 0,
                                bottom: 0,
                                left: 0,
                                right: 0
                            }
                        }
                    })
                )
            };
        }
        case RESTORE_VISUAL_EDITOR_BLOCKS_FROM_HTML: {
            const instance = state.instances[action.payload.instanceId] ?? cloneDeep(initialVisualEditorState);
            let children: Block<any>[] = [];
            const content = {
                locale: action.payload.locale,
                blocks: htmlWalker(
                    action.payload.html,
                    (node) => {
                        const element = node as HTMLElement;

                        if (element.dataset.blockRoot) {
                            return restorer.restoreFromVersion(
                                extractVersionFromHtml(action.payload.html),
                                {
                                    type: element.dataset.blockRoot,
                                    html: element.outerHTML,
                                    quotationCode: action.payload.quotationCode,
                                    registerChildren: (blocks) => {
                                        children = children.concat(blocks);
                                    }
                                }
                            );
                        }

                        return null;
                    },
                    new MailTemplateBodyHtmlWalkingStrategy()
                ).filter((item) => item) as Block<any>[]
            };

            const { document } = createDomObjectsFromHtml(action.payload.html);
            let fontFamily = defaultFont;
            document.querySelectorAll('link').forEach((node) => {
                const url = new URL(node.href);
                if (url.searchParams.get('global') === 'true') {
                    fontFamily = {
                        name: url.searchParams.get('family') ?? 'Unknown font',
                        url: node.href
                    };
                }
            });
            const bodyOptionsElement = document.body.querySelector('div');
            const bodyWrapper: HTMLDivElement | null | undefined = bodyOptionsElement?.querySelector('.body-wrapper');
            const bodyWrapperTD = bodyOptionsElement?.querySelector('td');

            if (!isVisualEditorCompatible(action.payload.html)) {
                const styles = {
                    ...RichEditorStyleMap,
                    ...(action.payload.quotationCode ? customColors[action.payload.quotationCode] : null)
                };
                const block = new TextBlock();

                block.setOptions({
                    ...block.getOptions(),
                    editorState: restoreRichEditorStateFromHtml(
                        styles,
                        action.payload.html
                    )
                });

                content.blocks.push(block);
            }

            return {
                ...state,
                instances: {
                    ...state.instances,
                    [action.payload.instanceId]: {
                        ...instance,
                        visualEditorBlocks: {
                            ...instance.visualEditorBlocks,
                            [action.payload.locale]: {
                                order: content.blocks.map((item) => item.getId()),
                                blocks: {
                                    ...mapKeys(
                                        content.blocks,
                                        (block) => block.getId()
                                    ),
                                    ...mapKeys(
                                        children,
                                        (block) => block.getId()
                                    )
                                }
                            }
                        },
                        visualEditorBodyStyles: {
                            fontFamily,
                            backgroundColor: rgbToHex(
                                bodyOptionsElement?.style.backgroundColor,
                                'rgb(255, 255, 255)'
                            ),
                            width: parseInt(bodyOptionsElement?.dataset.width ?? '200'),
                            padding: {
                                top: parseInt(bodyOptionsElement?.style.paddingTop ?? '0'),
                                bottom: parseInt(bodyOptionsElement?.style.paddingBottom ?? '0'),
                                left: parseInt(bodyOptionsElement?.style.paddingLeft ?? '0'),
                                right: parseInt(bodyOptionsElement?.style.paddingRight ?? '0')
                            },
                            innerBorder: {
                                color: rgbToHex(
                                    bodyWrapperTD?.style.borderColor,
                                    '#000'
                                ),
                                width: parseInt(bodyWrapperTD?.style.borderWidth ?? '0')
                            },
                            innerBackgroundColor: rgbToHex(
                                bodyWrapper?.style.backgroundColor,
                                '#fff'
                            ),
                            innerPadding: {
                                top: parseInt(bodyWrapper?.style.paddingTop ?? '0'),
                                bottom: parseInt(bodyWrapper?.style.paddingBottom ?? '0'),
                                left: parseInt(bodyWrapper?.style.paddingLeft ?? '0'),
                                right: parseInt(bodyWrapper?.style.paddingRight ?? '0')
                            }
                        }
                    }
                }
            };
        }
        case SET_VISUAL_EDITOR_BLOCK_OPTIONS: {
            const instance = state.instances[action.payload.instanceId] ?? cloneDeep(initialVisualEditorState);
            const content = instance.visualEditorBlocks[action.payload.locale];
            const block = content?.blocks[action.payload.id];

            if (content && block) {
                const newBlock = block.clone(action.payload.options);
                return {
                    ...state,
                    instances: {
                        ...state.instances,
                        [action.payload.instanceId]: {
                            ...instance,
                            visualEditorBlocks: {
                                ...instance.visualEditorBlocks,
                                [action.payload.locale]: {
                                    ...content,
                                    blocks: {
                                        ...content.blocks,
                                        [action.payload.id]: newBlock
                                    }
                                }
                            }
                        }
                    }
                };
            }

            return state;
        }
        case SET_VISUAL_EDITOR_BODY_STYLES: {
            const instance = state.instances[action.payload.instanceId] ?? cloneDeep(initialVisualEditorState);
            return {
                ...state,
                instances: {
                    ...state.instances,
                    [action.payload.instanceId]: {
                        ...instance,
                        visualEditorBodyStyles: action.payload.styles
                    }
                }
            };
        }
        case SET_VISUAL_EDITOR_MODE: {
            const instance = state.instances[action.payload.instanceId] ?? cloneDeep(initialVisualEditorState);
            return {
                ...state,
                instances: {
                    ...state.instances,
                    [action.payload.instanceId]: {
                        ...instance,
                        visualEditorMode: action.payload.mode
                    }
                }
            };
        }
        default: return state;
    }
};

export default combineReducers({
    others: MailTemplateReducer,
    visualEditor: undoable(
        VisualEditorReducer,
        {
            undoType: UNDO_VISUAL_EDITOR,
            redoType: REDO_VISUAL_EDITOR,
            filter: includeAction([
                ADD_VISUAL_EDITOR_BLOCK,
                DELETE_VISUAL_EDITOR_BLOCK,
                DUPLICATE_VISUAL_EDITOR_BLOCK,
                SET_VISUAL_EDITOR_BLOCK_OPTIONS
            ])
        }
    )
});
