//---- Dependencies ----//
import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';

import Picture from "../../Accommodation/FunctionalComponent/Picture";
import MosaicPicture from "../../Accommodation/FunctionalComponent/MosaicPicture";

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Grid, Stack } from '@mui/material';
const useStyles = makeStyles(theme => ({
    container: {
        maxWidth: 1128,
        paddingTop: 15
    },
    genericText: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        color: '#0000008A'
    },
    absoluteCenter: {
        margin: 0,
        position: 'absolute',
        top: '24%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    orangeButton: {
        backgroundColor: '#E6592F',
        color: 'white'
    },
    fullWidth: {
        width: '100%'
    },
    textJustify: {
        textAlign: 'justify'
    },
    carouselAccommodationFullScren: {
        position: 'relative',
        float: 'left',
        width: '100%'
    },
    carouselAccommodation: {
        position: 'relative',
        float: 'left',
        width: '50%',
        marginRight: '1em',
        marginBottom: 0
    },
    carouselAccommodationAlt: {
        width: '120% !important',
        overflow: 'hidden',
        display: 'flex',
        position: 'unset'
    },
    carouselAccommodationInteractions: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0 5px'
    },
    carouselAccommodationInteractionsSvg: {
        cursor: 'pointer',
        fontSize: '22pt',
        backgroundColor: '#0000004f',
        color: 'white'
    },
    firstImg: {
        marginRight: '2%',
        width: '60% !important',
        cursor: 'pointer'
    },
    secondImg: {
        width: '49% !important'
    },
    containImage: {
        width: '100%',
        objectFit: 'cover',
        cursor: 'pointer'
    },
    onePicture: {
        margin: "15px 0",
        width: '100% !important'
    },
    multiplePictures: {
        margin: "1.5em 0",
        position: "relative",
        overflow: "hidden"
        // paddingLeft: '2em'
    }
}));

type ByDayPicturesProps = {
    pictures: any;
    is_step: boolean
}

const ByDayPictures = (props: ByDayPicturesProps) => {
    const { pictures, is_step } = props;
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const [selectedImageIndex, setSelectedImageIndex] = useState<number>(0);
	const [currentPicture, setCurrentPicture] = useState<number>(0);
	const [modalPictures, setModalPictures] = useState<[]>([]);
	const [openMozaic, setOpenMozaic] = useState<boolean>(false);
	const [openPicture, setOpenPicture] = useState<boolean>(false);

    const move = (step:number) => {
        if (pictures !== undefined && pictures !== null) {
            if (pictures.length <= 0) return;
            if (step == -1) setSelectedImageIndex(selectedImageIndex <= 0 ? pictures.length - 1 : selectedImageIndex - 1);
            else if (step == 1) setSelectedImageIndex(selectedImageIndex >= pictures.length - 1 ? 0 : selectedImageIndex + 1);
        }
    };
    const openModal = () => {
		if (!fullScreen) {
			togglePictureModal(pictures)
		}
		else {
			toggleMosaicModal(pictures);
		}
	}
	const togglePictureModal = (pictures:any) => {
		setOpenPicture(!openPicture);
        setModalPictures(pictures);
		setCurrentPicture(selectedImageIndex);
	};
	const toggleMosaicModal = (pictures:any) => {
        setModalPictures(pictures);
		setOpenMozaic(!openMozaic);
    };
    return (
        <Stack sx={{ width: '100%' }}>
            {
                fullScreen && pictures.length !== 0 &&
                <Grid xs={12} item style={{ width: '100%' }}>
                {pictures?.at(0) && (
                    <Grid className={`${classes.carouselAccommodationFullScren}`} onClick={openModal} style={{cursor: 'pointer'}}>
                        <div className={classes.carouselAccommodationInteractions}>
                            <ArrowBackIosNewIcon className={classes.carouselAccommodationInteractionsSvg} onClick={(e) => {e.stopPropagation();move(-1)}} />
                            <ArrowForwardIosIcon className={classes.carouselAccommodationInteractionsSvg} onClick={(e) => {e.stopPropagation();move(1)}} />
                        </div>
                        <img
                            className={classes.containImage}
                            src={(selectedImageIndex ? pictures?.at(selectedImageIndex)?.url : pictures?.at(0)?.url) ?? undefined}
                            style={{
                                height: pictures?.at(0)?.url ? "20em" : 0
                            }}
                        />
                    </Grid>
                )}
                </Grid>
            }
            {
                !fullScreen && pictures.length !== 0 &&
                <Grid xs={12} item sx={{ width: (is_step ? '100%' : '102%') }} className={clsx({ [classes.onePicture]: pictures.length === 1, [classes.multiplePictures]: pictures.length > 1 })}>
                    {pictures?.at(0) && (
                        <Grid className={clsx({ [classes.carouselAccommodation]: pictures.length > 1, [classes.carouselAccommodationAlt]: pictures.length > 1, [classes.carouselAccommodationFullScren]: pictures.length })} onClick={openModal} style={{ cursor: 'pointer' }}>
                            {pictures && pictures.length > 1 && (
                                <div className={classes.carouselAccommodationInteractions}>
                                    <ArrowBackIosNewIcon className={classes.carouselAccommodationInteractionsSvg} onClick={(e) => {e.stopPropagation();move(-1)}} />
                                    <ArrowForwardIosIcon className={classes.carouselAccommodationInteractionsSvg} onClick={(e) => {e.stopPropagation();move(1)}}/>
                                </div>
                            )}
                            <img
                                className={clsx({[classes.containImage]: pictures.length === 1, [classes.firstImg]: pictures.length > 1})}
                                src={(selectedImageIndex ? pictures?.at(selectedImageIndex)?.url : pictures?.at(0)?.url) ?? undefined}
                                style={{ height: pictures?.at(0)?.url ? "20em" : 0 }}
                            />
                            {pictures.length > 1 && pictures?.at(selectedImageIndex === pictures.length - 1 ? 0 : selectedImageIndex + 1)?.url != pictures?.at(selectedImageIndex)?.url &&
                                <img
                                    className={classes.secondImg}
                                    src={pictures?.at(selectedImageIndex === pictures.length - 1 ? 0 : selectedImageIndex + 1)?.url ?? undefined}
                                    style={{ height: pictures?.at(selectedImageIndex === pictures.length - 1 ? 0 : selectedImageIndex + 1)?.url ? "20em" : 0 }}
                                />}
                        </Grid>
                    )}
                </Grid>
            }
            <MosaicPicture open={ openMozaic } onClose={ toggleMosaicModal } pictures={ modalPictures } openModal={ togglePictureModal }/>
            <Picture open={ openPicture } onClose={ togglePictureModal } pictures={ modalPictures } current_picture={ currentPicture } setCurrentPicture={ setCurrentPicture }/>
        </Stack>
    );
};
export default ByDayPictures;
