import axios from "axios";

import CheckBeforeRequest from "../../Common/CheckBeforeRequest";

import GetCookie from "../../Common/Functions/GetCookie";

import { SetCartError } from "../../../Actions/FlightSearch";

import FlightCartRequest from "./FlightCartRequest";

export default (flight_groups, flight_providers, searched_group, group_index, max_group_index, trip_currency, flight, trace_id, end_date, filter_time, user, enqueueSnackbar, dispatch, t, router, context) => {
    let { pass_check, headers } = CheckBeforeRequest();
    let travelers_ids = [];

    searched_group.travelers.map((traveler) => {
        travelers_ids.push(traveler.id);
    });

    if (pass_check) {
        axios({
            method: "POST",
            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/travelers-group/?token=${GetCookie("trip_token")}`,
            headers: headers,
            data: {
                travelers: travelers_ids
		    }
        }).then(function (response) {
            FlightCartRequest(flight_groups, flight_providers, response.data.id, group_index, max_group_index, trip_currency, flight, trace_id, end_date, filter_time, "check", user, enqueueSnackbar, dispatch, t, router, context);
        }).catch(function (error) {
            console.log('group_passenger_error:', error);
            dispatch(SetCartError(error.responseText ? error.responseText : error.statusText));
            enqueueSnackbar(t("add_to_cart.failed"), { variant: 'error', disableWindowBlurListener: true });
        });
    }
};
