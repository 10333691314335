import React from "react";
import { useTranslation } from "react-i18next";
import {
    Document,
    Page,
    Text,
    View,
    Font,
    StyleSheet,
    Image,
    Link
} from "@react-pdf/renderer";
import RobotoRegular from "../../../Fonts/Roboto-Regular.ttf";
import RobotoMedium from "../../../Fonts/Roboto-Medium.ttf";
import RobotoBold from "../../../Fonts/Roboto-Bold.ttf";
import ApstLogo from "../../../Img/TripListInvoice/apst-logo.png";
import IataLogo from "../../../Img/TripListInvoice/iata-logo.png";
import SyndicatToLogo from "../../../Img/TripListInvoice/syndicat-to-logo.png";
import i18next from "i18next";

export function TripListInvoicePdfDocument(props) {
    const { t } = useTranslation();
    console.log('props.owner.logo.url : ', props.owner.logo.url);
    //{ uri: valid-url, method: 'GET', headers: {}, body: '' }
    return (
        <Document>
            <Page size="A4" style={styles.body}>
                <View style={styles.entitiesSection}>
                    <View style={styles.ownerLogoBlock}>
                        <Image
                            style={styles.ownerLogo}
                            //src={ props.owner.logo.url }
                            src={{ uri: props.owner.logo.url, method: "GET", headers: { "Cache-Control": "no-cache" }, body: "" }}
                            //crossorigin
                            //src={ 'https://facilitatrip-staging-picture.s3.amazonaws.com/lfMHYdZ88iSwwaJZ' }
                            //src={ `${props.owner.logo.url}${props.owner.logo.url.includes('storagefacilitatrip') ? '' : '.png'}` }
                        />
                    </View>
                    <View style={styles.ownerBlock}>
                        <View style={styles.ownerSubBlock}>
                            <Text style={styles.ownerTitle}>
                                {props.owner.name}
                            </Text>
                            <Text style={styles.ownerInfo}>
                                {props.owner.street}
                            </Text>
                            <Text style={styles.ownerInfo}>
                                {props.owner.city} {props.owner.postal_code}
                            </Text>
                            <Text style={styles.ownerInfo}>
                                {props.owner.phone_number}
                            </Text>
                        </View>
                    </View>
                    <View style={styles.clientBlock}>
                        <Text style={styles.clientTitle}>
                            {props.detailsInputs.companyName.data}
                        </Text>
                        <Text style={styles.clientInfo}>
                            <Text style={styles.clientLastName}>{props.client.last_name}</Text> {props.client.first_name}
                        </Text>
                        <Text style={styles.clientInfo}>
                            {props.client.address}
                        </Text>
                        <Text style={styles.clientInfo}>
                            {props.client.city} { props.client.postal_code }
                        </Text>
                        {
                            props.client.country !== null &&
                            <Text style={styles.clientInfo}>
                                {props.client.country.international_name}
                            </Text>
                        }
                    </View>
                </View>
                <Text style={styles.invoiceNumber}>
                    {
                        props.invoice && props.invoice.is_generated &&
                          (
                              props.invoiceType === 'INVOICE' ?
                                  t('menu.invoice.invoice-number') + ' ' + props.invoice.invoice_number :
                                  t('menu.invoice.credit-note-number') + ' ' + props.invoice.credit_note_number
                          )
                    }
                    {
                        (!props.invoice || props.invoice.is_generated === false) &&
                          (
                              props.invoiceType === 'INVOICE' ?
                                  t('menu.invoice.invoice-number-waiting') :
                                  t('menu.invoice.credit-note-number-waiting')
                          )
                    }
                </Text>
                <View style={styles.tripDetails}>
                    <View style={styles.tripInfo}>
                        <Text style={styles.tripInfoText}>
                            <Text style={styles.tripInfoTitle}>
                                {t('menu.invoice.departure-date')}
                            </Text> : {window.moment.utc(props.startDate).format('DD/MM/YYYY')}
                        </Text>
                        <Text style={styles.tripInfoText}>
                            <Text style={styles.tripInfoTitle}>
                                {t('menu.invoice.invoice-date')}
                            </Text> : {
                                props.invoice && props.invoice.is_generated === true ?
                                    window.moment.utc(props.invoice.invoice_date).format('DD/MM/YYYY') :
                                    window.moment.utc(new Date().toString()).format('DD/MM/YYYY')
                            }
                        </Text>
                    </View>
                    <View style={styles.tripInfo}>
                        <Text style={styles.tripInfoText}>
                            <Text style={styles.tripInfoTitle}>
                                {t('menu.invoice.arrival-date')}
                            </Text> : {window.moment.utc(props.endDate).format('DD/MM/YYYY')}
                        </Text>
                        {
                            props.destination &&
                            <Text style={styles.tripInfoText}>
                                <Text style={styles.tripInfoTitle}>
                                    {t('menu.invoice.destination')}
                                </Text> : {props.destination.data.international_name}
                            </Text>
                        }
                    </View>
                    <View style={styles.tripInfo}>
                        {
                            props.tripName &&
                            <Text style={styles.tripInfoText}>
                                <Text style={styles.tripInfoTitle}>
                                    {t('menu.invoice.trip-name')}
                                </Text> : {props.tripName}
                            </Text>
                        }
                        <Text style={styles.tripInfoText}>
                            <Text style={styles.tripInfoTitle}>
                                {t('menu.invoice.travelers-names')}
                            </Text> : {
                                props.travelers.map((traveler) => {
                                    return traveler.first_name + ' ' + traveler.last_name;
                                }).join(', ')
                            }
                        </Text>
                    </View>
                    <View style={styles.tripInfo}>
                        <Text style={styles.tripInfoText}>
                            <Text style={styles.tripInfoTitle}>
                                { t('menu.invoice.folder-number') }
                            </Text> : { props.tripReference } v{ props.versionNumber }
                        </Text>
                    </View>
                </View>
                <View style={styles.table}>
                    <View style={Object.assign(
                        Object.assign({}, styles.tableRow),
                        styles.tableHeadRow
                    )}>
                        <Text
                            style={
                                Object.assign(
                                    Object.assign({}, styles.tableHeadCell),
                                    styles.tableSubjectCell
                                )
                            }
                        >
                            {t('menu.invoice.subject')}
                        </Text>
                        <Text style={styles.tableHeadCell}>
                            {t('menu.invoice.total-without-taxes-break')}
                        </Text>
                        <Text style={styles.tableHeadCell}>
                            {t('menu.invoice.flight-tax')}
                        </Text>
                        <Text style={styles.tableHeadCell}>
                            {t('menu.invoice.total')}
                        </Text>
                    </View>
                    {
                        props.productInputs.map((input, input_index) => (
                            <View key={input.id} style={ input_index < props.productInputs.length - 1 ? Object.assign(
                                Object.assign({}, styles.tableRow),
                                styles.tableRowBorder
                            ) : styles.tableRow}>
                                <Text
                                    style={
                                        Object.assign(
                                            Object.assign({}, styles.tableBodyCell),
                                            styles.tableSubjectCell
                                        )
                                    }
                                >
                                    {input.description}
                                </Text>
                                <Text style={styles.tableBodyCell}>
                                    {
                                        new Intl.NumberFormat(
                                            i18next.language,
                                            {
                                                style: 'currency',
                                                currency: props.currency ? props.currency.iso_code : 'EUR'
                                            }
                                        ).format(input.totalWithoutTax).replace(/(?! )\s/g, ' ')
                                    }
                                </Text>
                                <Text style={styles.tableBodyCell}>
                                    {
                                        new Intl.NumberFormat(
                                            i18next.language,
                                            {
                                                style: 'currency',
                                                currency: props.currency ? props.currency.iso_code : 'EUR'
                                            }
                                        ).format(input.flightTax).replace(/(?! )\s/g, ' ')
                                    }
                                </Text>
                                <Text style={styles.tableBodyCell}>
                                    {
                                        new Intl.NumberFormat(
                                            i18next.language,
                                            {
                                                style: 'currency',
                                                currency: props.currency ? props.currency.iso_code : 'EUR'
                                            }
                                        ).format(parseFloat(input.totalWithoutTax) + parseFloat(input.flightTax)).replace(/(?! )\s/g, ' ')
                                    }
                                </Text>
                            </View>

                        ))
                    }
                </View>
                <View style={styles.totalSection}>
                    <View />
                    <View>
                        <View style={styles.totalDetails}>
                            <View style={styles.totalWithoutTaxes}>
                                <Text style={styles.totalTitle}>
                                    {t('menu.invoice.total-without-taxes')}
                                </Text>
                                <Text style={styles.totalAmount}>
                                    {
                                        new Intl.NumberFormat(
                                            i18next.language,
                                            {
                                                style: 'currency',
                                                currency: props.currency ? props.currency.iso_code : 'EUR'
                                            }
                                        ).format(props.totalWithoutTaxes).replace(/(?! )\s/g, ' ')
                                    }
                                </Text>
                            </View>
                            <View style={styles.flightTaxes}>
                                <Text style={styles.totalTitle}>
                                    {t('menu.invoice.flight-taxes')}
                                </Text>
                                <Text style={styles.totalAmount}>
                                    {
                                        new Intl.NumberFormat(
                                            i18next.language,
                                            {
                                                style: 'currency',
                                                currency: props.currency ? props.currency.iso_code : 'EUR'
                                            }
                                        ).format(props.totalFlightTaxes).replace(/(?! )\s/g, ' ')
                                    }
                                </Text>
                            </View>
                        </View>
                        <View style={styles.totalSum}>
                            <Text style={styles.totalSumTitle}>
                                {t('menu.invoice.total')}
                            </Text>
                            <Text style={styles.totalAmount}>
                                {
                                    new Intl.NumberFormat(
                                        i18next.language,
                                        {
                                            style: 'currency',
                                            currency: props.currency ? props.currency.iso_code : 'EUR'
                                        }
                                    ).format(props.totalAmount).replace(/(?! )\s/g, ' ')
                                }
                            </Text>
                        </View>
                    </View>
                </View>
                <View style={styles.footer} fixed>
                    <View style={styles.footerInformations}>
                        <Text style={styles.footerInformationsTitle}>
                          Information Importante
                        </Text>
                        <Text>
                          Les hausses de taxes, de carburant et/ou de tarif décidées
                          unilatéralement par la compagnie sont répercutables jusqu'au
                          règlement du solde (sauf indication spécifique fournie dans la
                          confirmation qui vous a été adressée). Solde éventuel à payer sur
                          ce{" "}
                            <Link style={styles.footerLink} src={ `${window.origin}/quotation/trip?trip=${props.trip_info.trip.id}&token=${props.trip_info.trip.token}` }>
                                <Text>lien</Text>
                            </Link>
                          . Conditions Générales de Ventes disponibles à l'adresse suivante{" "}
                            <Link
                                style={styles.footerLink}
                                src="https://www.cercledesvoyages.com/wp-content/uploads/CONDITIONS-DE-VENTE-SEJOURS.pdf"
                            >
                                <Text>
                                    www.cercledesvoyages.com/wp-content/uploads/CONDITIONS-DE-VENTE-SEJOURS.pdf
                                </Text>
                            </Link>{" "}
                          Pour les informations sur les formalités d'entrée dans le pays
                          (passeport, visa) et pour les formalités sanitaires : consulter la
                          rubrique conseils-aux- voyageurs du site{" "}
                            <Link
                                style={styles.footerLink}
                                src="https://www.diplomatie.gouv.fr"
                            >
                                <Text>www.diplomatie.gouv.fr</Text>
                            </Link>{" "}
                          (voir plus spécifiquement les rubriques "risque pays" et "santé")
                          et le site{" "}
                            <Link
                                style={styles.footerLink}
                                src="https://www.action-visas.com"
                            >
                                <Text>www.action-visas.com</Text>
                            </Link>
                          . Il est important de vérifier ces informations et de les
                          consulter régulièrement jusqu'au départ.
                        </Text>
                    </View>
                    <View style={styles.footerCredsInformations}>
                        <Text style={styles.footerCredsInformationsLinkItem}>
                          https://www.cercledesvoyages.com/
                        </Text>
                        <Text>
                          Le Cercle des Vacances - SAS au capital de 1 500 012€ - Atout
                          France : IM 075100367 - Agrément IATA : 202 47 312 - APS RCS
                        </Text>
                        <Text>
                          Paris 500 157 532 Siret 500 157 532 00010 - APE 7911 Z - N° TVA
                          FR75500157532 - Régime particulier – Agences de voyages
                        </Text>
                        <View style={styles.footerLogos}>
                            <Image style={styles.footerLogoItem} src={IataLogo} />
                            <Image style={styles.footerLogoItem} src={SyndicatToLogo} />
                            <Image style={styles.footerApstLogo} src={ApstLogo} />
                        </View>
                        <Text
                            style={styles.footerCredsInformationsPageNumber}
                            render={({ pageNumber, totalPages }) =>
                                `Page: ${pageNumber} / ${totalPages}`
                            }
                        />
                    </View>
                </View>
            </Page>
        </Document>
    );
}

Font.register({
    family: "Roboto",
    fonts: [
        {
            src: RobotoRegular,
            fontWeight: 400
        },
        {
            src: RobotoMedium,
            fontWeight: 500
        },
        {
            src: RobotoBold,
            fontWeight: "bold"
        }
    ]
});

const styles = StyleSheet.create({
    body: {
        padding: "15pt",
        fontFamily: "Roboto",
        paddingBottom: "10cm"
    },
    entitiesSection: {
        width: "100%",
        marinBottom: "2mm"
    },
    ownerBlock: {
        width: "100%",
        flexDirection: "row",
        alignItems: "center",
        gap: "20pt"
    },
    ownerSubBlock: {
        paddingBottom: "4mm",
        borderBottom: "0.3mm solid rgb(0, 0, 0)"
    },
    ownerTitle: {
        fontSize: "15pt",
        marginBottom: "2mm",
        textTransform: "uppercase"
    },
    ownerLogoBlock: {
        width: "100%",
        height: "auto",
        marginLeft: "2mm",
        marginBottom: "4mm",
        borderBottom: "0.3mm solid rgb(0, 0, 0)"
    },
    ownerLogo: {
        width: "8%",
        height: "auto",
        paddingBottom: "4mm",
        marginRight: "5mm"
    },
    ownerInfo: {
        color: "rgba(0, 0, 0, 0.75)",
        fontSize: "12pt"
    },
    clientBlock: {
        width: "60%",
        textAlign: "left"
    },
    clientTitle: {
        fontSize: "10pt",
        marginBottom: "2mm"
    },
    clientInfo: {
        color: "rgba(0, 0, 0, 0.75)",
        fontSize: "12pt",
        textAlign: "right"
    },
    clientLastName: {
        textTransform: "uppercase"
    },
    invoiceNumber: {
        fontSize: "22pt",
        marginTop: "2mm",
        marginBottom: "2mm"
    },
    tripDetails: {
        padding: "6mm",
        marginBottom: "2mm",
        backgroundColor: "rgba(247, 200, 186, 0.43)",
        borderRadius: "5mm"
    },
    tripInfo: {
        display: "flex",
        flexDirection: "row",
        padding: "1mm"
    },
    tripInfoTitle: {
        fontWeight: "bold"
    },
    tripInfoText: {
        flex: 1,
        fontSize: "10pt",
        paddingRight: "3mm"
    },
    table: {
        marginTop: "3mm"
    },
    tableRow: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    },
    tableRowBorder: {
        borderBottom: "0.3mm solid rgb(100, 100, 100)"
    },
    tableHeadRow: {
        borderBottom: "0.3mm solid rgb(0, 0, 0)"
    },
    tableHeadCell: {
        flex: 1,
        fontSize: "10pt",
        fontWeight: 500,
        padding: "2mm",
        textAlign: "left"
    },
    tableSubjectCell: {
        flex: 6,
        textAlign: 'left'
    },
    tableBodyCell: {
        flex: 1,
        fontSize: "10pt",
        padding: "2mm",
        textAlign: "left",
        alignItems: "center",
        whiteSpace: "nowrap"
    },
    totalSection: {
        flexDirection: "row",
        justifyContent: "space-between",
        marginTop: "3mm"
    },
    totalDetails: {
        border: "0.2mm solid rgb(0, 0, 0)",
        borderLeft: 0,
        borderRight: 0
    },
    totalWithoutTaxes: {
        borderBottom: "0.2mm solid rgb(100, 100, 100)",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: "3mm 1.5mm"
    },
    totalTitle: {
        fontSize: "10pt",
        marginRight: "40mm"
    },
    totalAmount: {
        fontSize: "10pt",
        fontWeight: "bold"
    },
    flightTaxes: {
        flexDirection: "row",
        justifyContent: "space-between",
        padding: "3mm 1.5mm"
    },
    totalSum: {
        flexDirection: "row",
        justifyContent: "space-between",
        padding: "3mm 1.5mm"
    },
    totalSumTitle: {
        fontSize: "10pt",
        marginRight: "40mm",
        fontWeight: "bold"
    },
    footer: {
        fontSize: "10pt",
        position: "absolute",
        bottom: 30,
        left: "15pt",
        right: "15pt"
    },
    footerInformations: {
        borderBottom: "0.2mm solid rgb(0, 0, 0)",
        paddingBottom: "10mm"
    },
    footerInformationsTitle: {
        fontSize: "10pt",
        marginBottom: "2mm",
        fontWeight: "bold"
    },
    footerInformationsContent: {
        fontSize: "8pt"
    },
    footerCredsInformations: {
        marginTop: "5mm",
        textAlign: "center"
    },
    footerCredsInformationsLinkItem: {
        marginBottom: "4mm"
    },
    footerCredsInformationsPageNumber: {
        marginTop: "2mm"
    },
    footerLink: {
        color: "rgb(78, 158, 212)"
    },
    footerLogos: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "3mm"
    },
    footerLogoItem: {
        width: "6.5mm",
        height: "auto"
    },
    footerApstLogo: {
        width: "4mm",
        height: "2.37mm"
    }
});
