//---- Dependencies ----//
import React, { Fragment } from "react";
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

//---- Functions ----//
import GetPricesId from "../../Cart/Functions/Margin/GetPricesId.js";
import GetProductType from "../../Menu/TripList/Functions/GetProductType.js";
import Notify from "../../Common/Notify";
import moment from "moment";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

const InfoRealoadCustomProducts = ({classes}) => {
	const { t } = useTranslation();
	const user = useSelector(state => state.user.user);
	const currency = useSelector(state => state.trip.currency);
	const language = useSelector(state => state.header.tmp_language);
	const update_infos = useSelector(state => state.cart.update_infos);
	update_infos.custom_products.sort((a, b) => {
		if (a.old !== null && b.old !== null) {
			if (moment(a.old.start_date).isBefore(moment(b.old.start_date))) {
				return -1;
			}
			return 1;
		}
		return 1;
	});
	return (
		<Fragment>
            {
                (update_infos.custom_products).map((infos, index) => {
                    let poi_at_same_time = [];
                    if (infos.new !== null) {
						update_infos.custom_products.map((poi_control) => {
							if (poi_control.new !== null && Object.keys(infos.new).length !== 0 && infos.new.id !== poi_control.new.id) {
								if (moment(infos.new.start_date).isBetween(moment(poi_control.new.start_date), moment(poi_control.new.end_date)) || moment(infos.new.end_date).isBetween(moment(poi_control.new.start_date), moment(poi_control.new.end_date))) {
									poi_at_same_time.push(poi_control);
								}
							}
						});
					}
                    if (poi_at_same_time.length > 0) {
                        Notify(t("poi.poi_at_same_time"), { status: "danger", pos: "top-center"});
                    }
                    let dif_price = 0;
                    if (infos.new !== null && Object.keys(infos.new).length !== 0 && infos.new.error === undefined && infos.old !== null && !infos.new.poi_type) {
                        dif_price = (parseFloat(infos.new.prices[GetPricesId(infos.new.prices, user.client, user, true)].selling_price) - parseFloat(infos.old.prices[GetPricesId(infos.old.prices, user.client, user, true)].selling_price));
                        return (
                            <TableRow key={index} className={ classes.tableRowDialog }>
                                <TableCell> { moment(infos.new.start_date).format("DD-MM-YYYY") } </TableCell>
                                {
                                    infos.old.product_type !== 4 && (
                                        <TableCell> { infos.new.custom_product.destination !== undefined && infos.new.custom_product.destination !== null && infos.new.custom_product.destination.data !== null ? infos.new.custom_product.destination.data.name !== undefined ? infos.new.custom_product.destination.data.name : infos.new.custom_product.destination.data.international_name : "/" } </TableCell>
                                        )
                                    }
                                {
                                    infos.old.product_type === 4 && (
                                        <TableCell> { infos.new.origin_name !== "" && infos.new.origin_name !== null && infos.new.destination_name !== "" && infos.new.destination_name !== null ? infos.new.origin_name + " - " + infos.new.destination_name : "/" } </TableCell>
                                    )
                                }
                                <TableCell>
                                    <strong>{ t("providers." + GetProductType(infos.old.product_type)) } : </strong>
                                    { infos.old.custom_product.title }
                                    {
                                        infos.old.product_type === 4 && (
                                            <Fragment>
                                                {" | "}
                                                <span className={"ft-red"}>{moment.utc(infos.old.start_date).format("HH[h]mm")}</span>
                                            </Fragment>
                                        )
                                    }
                                </TableCell>
                                <TableCell>
                                    <strong>{ t("providers." + GetProductType(infos.old.product_type)) } : </strong>
                                    { infos.new.custom_product.title }
                                    {
                                        infos.new.product_type === 4 && (
                                            <Fragment>
                                                {" | "}
                                                <span className={"ft-green"}>{moment.utc(infos.new.start_date).format("HH[h]mm")}</span>
                                            </Fragment>
                                        )
                                    }
                                </TableCell>
                                <TableCell className={ dif_price === 0 ? null : (dif_price < 0 ? "ft-green" : "ft-red")} > { dif_price > 0 ? "+ " + Intl.NumberFormat(language, { style: "currency", currency: currency.iso_code }).format(dif_price.toFixed(2)) : Intl.NumberFormat(language, { style: "currency", currency: currency.iso_code }).format(dif_price.toFixed(2)) } </TableCell>
                            </TableRow>
                        );
                    }
                    if (infos.old !== null && infos.new !== null && Object.keys(infos.new).length !== 0 && infos.new.error !== undefined && !infos.new.poi_type) {
                        return (
                            <TableRow key={index} className={classes.tableRowDialog}>
                                <TableCell> { moment(infos.old.start_date).format("DD-MM-YYYY") } </TableCell>
                                <TableCell> { infos.old.custom_product.destination !== undefined && infos.old.custom_product.destination !== null && infos.old.custom_product.destination.data !== null ? infos.old.custom_product.destination.data.name !== undefined ? infos.old.custom_product.destination.data.name : infos.old.custom_product.destination.data.international_name : "/" } </TableCell>
                                <TableCell> <strong>{ t("providers." + GetProductType(infos.old.product_type)) }:</strong> { infos.old.custom_product.title } </TableCell>
                                <TableCell className={"ft-red"}> { infos.new.error } </TableCell>
                                <TableCell className={ dif_price === 0 ? null : (dif_price < 0 ? "ft-green" : "ft-red")} > / </TableCell>
                            </TableRow>
                        );
                    }
					return null;
                })
            }
        </Fragment>
	);
};

export default React.memo(InfoRealoadCustomProducts);