import { flattenDeep, groupBy, mapValues } from "lodash";
import { CreatePageOptions } from "../objects/createPageOptions";
import { Template } from "../utils/handlebars/roadbook.handlebars";
import { createFlightsContent } from "./createFlightsContent";

export function createFlightTicketsContent(options: CreatePageOptions): Parameters<Template>[0]['contents'][0] | null {
    const flightsContent = createFlightsContent(options);
    const contents = Object.values(
        mapValues(
            groupBy(
                options.cart.flights.map((item) => {
                    return {
                        ref: item.flight.booking_status?.item_reference ?? '',
                        items: (
                            item.type === 'normal' ?
                                flattenDeep(
                                    item.flight.outbounds.map((item) => {
                                        return item.legs.map((item) => {
                                            return {
                                                startDate: item.departure_time,
                                                from: options.t(
                                                    'roadbook.city-with-code',
                                                    {
                                                        city: item.origin?.iata_city.international_name ?? item.origin_station?.iata_city?.international_name,
                                                        code: item.origin?.iata_city.city_code ?? item.origin_station?.iata_city?.city_code
                                                    }
                                                ),
                                                to: options.t(
                                                    'roadbook.city-with-code',
                                                    {
                                                        city: item.destination?.iata_city.international_name ?? item.destination_station?.iata_city?.international_name,
                                                        code: item.destination?.iata_city.city_code ?? item.destination_station?.iata_city?.city_code
                                                    }
                                                ),
                                                fromDate: window.moment.utc(item.departure_time).format('ddd DD MMMM'),
                                                logo: `https://storagefacilitatrip.blob.core.windows.net/iata-company/${item.operating_airline?.airline_code}.png`,
                                                toDate: window.moment.utc(item.arrival_time).format('ddd DD MMMM'),
                                                departureAirport: options.t(
                                                    'roadbook.flight-tickets-departure-airport',
                                                    {
                                                        hourly: window.moment.utc(item.departure_time).format('HH[h]mm'),
                                                        airport: item.origin?.international_name ?? item.origin_station?.international_name ?? '',
                                                        terminal: item.origin_terminal
                                                    }
                                                ),
                                                flight: options.t(
                                                    'roadbook.flight-tickets-flight',
                                                    {
                                                        flight: item.flight_number,
                                                        airline: item.operating_airline?.commercial_name
                                                    }
                                                ),
                                                arrivalAirport: options.t(
                                                    'roadbook.flight-tickets-arrival-airport',
                                                    {
                                                        hourly: window.moment.utc(item.arrival_time).format('HH[h]mm'),
                                                        airport: item.destination?.international_name ?? item.destination_station?.international_name ?? ''
                                                    }
                                                )
                                            };
                                        });
                                    })
                                ) :
                                item.flight.flight_segment.map((item) => {
                                    return {
                                        startDate: item.start_date,
                                        from: options.t(
                                            'roadbook.city-with-code',
                                            {
                                                city: item.origin_airport?.iata_city.international_name ??
                                                      item.origin_station?.iata_city.international_name ??
                                                      '',
                                                code: item.origin_airport?.iata_city.city_code ??
                                                      item.origin_station?.iata_city.city_code ??
                                                      ''
                                            }
                                        ),
                                        to: options.t(
                                            'roadbook.city-with-code',
                                            {
                                                city: item.destination_airport?.iata_city.international_name ??
                                                      item.destination_station?.iata_city.international_name ??
                                                      '',
                                                code: item.destination_airport?.iata_city.city_code ??
                                                      item.destination_station?.iata_city.city_code ??
                                                      ''
                                            }
                                        ),
                                        fromDate: window.moment.utc(item.start_date).format('ddd DD MMMM'),
                                        logo: `https://storagefacilitatrip.blob.core.windows.net/iata-company/${item.airline.airline_code}.png`,
                                        toDate: window.moment.utc(item.end_date).format('ddd DD MMMM'),
                                        departureAirport: options.t(
                                            'roadbook.flight-tickets-departure-airport',
                                            {
                                                hourly: window.moment.utc(item.start_date).format('HH[h]mm'),
                                                airport: item.origin_airport?.international_name ??
                                                         item.origin_station?.international_name ??
                                                         '',
                                                terminal: item.origin_terminal
                                            }
                                        ),
                                        flight: options.t(
                                            'roadbook.flight-tickets-flight',
                                            {
                                                flight: item.flight_number,
                                                airline: item.airline.commercial_name
                                            }
                                        ),
                                        arrivalAirport: options.t(
                                            'roadbook.flight-tickets-arrival-airport',
                                            {
                                                hourly: window.moment.utc(item.end_date).format('HH[h]mm'),
                                                airport: item.destination_airport?.international_name ??
                                                         item.destination_station?.international_name
                                            }
                                        )
                                    };
                                })
                        )
                    };
                }),
                (item) => item.ref
            ),
            (value) => {
                return value.reduce((prev, current) => {
                    return {
                        ...prev,
                        items: prev.items.concat(current.items).sort((a, b) => {
                            return window.moment.utc(a.startDate).isBefore(b.startDate) ? -1 : 1;
                        })
                    };
                });
            }
        )
    );
    return flightsContent ?
        {
            type: 'flight-tickets',
            anchor: 'flight-tickets',
            showInSummary: false,
            summaryTitle: options.title,
            title: options.title,
            contents
        } :
        null;
}
