import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { pdf } from '@react-pdf/renderer';
import axios from "axios";
import { useSnackbar } from "notistack";
import { TripListInvoicePdfDocument } from "../TripListInvoicePdfDocument";
import CheckBeforeRequest from "../../../Common/CheckBeforeRequest";
import { makeInvoiceCreateRequest } from "./makeInvoiceCreateRequest";
import { makeInvoiceEditRequest } from "./makeInvoiceEditRequest";

export function useGenerateInvoice(options) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const trip_info = useSelector(store => store.menu.trip_info);
    const to_show_version = useSelector(store => store.menu.to_show_version);
    //const version = to_show_version !== null ? to_show_version : trip_info.current_version;
    const versions = useSelector((store) => store.trip.all_data && store.trip.all_data.data);
    const version_index = versions !== null ? versions.map(version => version.status_contract).indexOf('CONFIRMED') : -1;
    const version = version_index !== -1 ? versions[version_index].id : to_show_version !== null ? to_show_version : trip_info?.current_version;

    const handleError = (error) => {
        console.error(error);
        enqueueSnackbar(
            error.message,
            { variant: 'error' }
        );
        options.setLoading(false);
    };

    return ({
        invoice,
        detailsInputs,
        productInputs,
        totalWithoutTaxes,
        totalFlightTaxes,
        totalAmount
    }) => {
        const type = invoice ? invoice.invoice_type : options.invoiceType;

        const linkPdf = (existingInvoice) => {
            return new Promise((resolve) => {
                generatePDF({
                    owner: options.owner,
                    client: options.client,
                    invoice: existingInvoice,
                    invoiceType: existingInvoice.invoice_type,
                    tripReference: options.tripReference,
                    versionNumber: invoice && versions !== null ? versions.map(version => version.id).indexOf(invoice.trip_version) + 1 : options.versionNumber,
                    trip_info: options.data_trip,
                    startDate: options.startDate,
                    endDate: options.endDate,
                    destination: options.destination,
                    tripName: options.tripName,
                    travelers: options.travelers,
                    detailsInputs: detailsInputs,
                    productInputs: productInputs,
                    currency: options.currency,
                    totalWithoutTaxes,
                    totalFlightTaxes,
                    totalAmount
                }).then((blob) => {
                    makeInvoicePdfUploadRequest({
                        invoice: existingInvoice,
                        trip_info,
                        version,
                        file: blob
                    }).then((uploadResponse) => {
                        makeInvoiceEditRequest({
                            trip_info,
                            version,
                            id: existingInvoice.id,
                            data: {
                                document: uploadResponse.data.id
                            }
                        }).then((finalResponse) => {
                            options.onClose();
                            options.setLoading(false);
                            resolve(finalResponse);
                        }).catch(handleError);
                    }).catch(handleError);
                }).catch(handleError);
            });
        };

        if (invoice) {
            options.setLoading(true);
            makeInvoiceEditRequest({
                trip_info,
                version,
                id: invoice.id,
                data: {
                    company_name: detailsInputs ? detailsInputs.companyName.data !== '' ? detailsInputs.companyName.data : null : undefined,
                    amount: totalAmount ? parseFloat(totalAmount).toFixed(2).toString() : undefined,
                    invoice_product: productInputs ?
                        productInputs.map((input) => ({
                            name: input.description,
                            total_without_tax: input.totalWithoutTax,
                            flight_tax: input.flightTax,
                            total_amount: (
                                parseFloat(input.totalWithoutTax) +
                            parseFloat(input.flightTax)
                            ).toFixed(2).toString()
                        })) :
                        undefined,
                    invoice_date: window.moment.utc(new Date()).format('YYYY-MM-DD'),
                    is_generated: true
                }
            }).then((response) => {
                linkPdf(response.data).then((finalResponse) => {
                    dispatch({ type: 'TRIP_UPDATE_INVOICE', payload: finalResponse.data });
                    enqueueSnackbar(
                        type === 'INVOICE' ?
                            t('menu.invoice.invoice-generated') :
                            t('menu.invoice.credit-note-generated'),
                        { variant: 'success' }
                    );
                });
            }).catch(handleError);
        } else {
            options.setLoading(true);
            makeInvoiceCreateRequest({
                trip_info,
                version,
                data: {
                    name: 'Invoice',
                    document: null,
                    is_generated: true,
                    invoice_type: options.invoiceType,
                    company_name: detailsInputs.companyName.data !== '' ? detailsInputs.companyName.data : null,
                    amount: parseFloat(totalAmount).toFixed(2).toString(),
                    invoice_date: window.moment.utc(new Date()).format('YYYY-MM-DD'),
                    invoice_product: productInputs.map((input) => ({
                        name: input.description,
                        total_without_tax: input.totalWithoutTax,
                        flight_tax: input.flightTax,
                        total_amount: (
                            parseFloat(input.totalWithoutTax) +
                            parseFloat(input.flightTax)
                        ).toFixed(2).toString()
                    }))
                }
            }).then((response) => {
                linkPdf(response.data).then(() => {
                    dispatch({ type: 'TRIP_SET_INVOICES', payload: null });
                    enqueueSnackbar(
                        type === 'INVOICE' ?
                            t('menu.invoice.invoice-generated') :
                            t('menu.invoice.credit-note-generated'),
                        { variant: 'success' }
                    );
                });
            }).catch(handleError);
        }
    };
}

function generatePDF(options) {
    return pdf(
        <TripListInvoicePdfDocument
            owner={options.owner}
            client={options.client}
            invoice={options.invoice}
            invoiceType={options.invoiceType}
            tripReference={options.tripReference}
            versionNumber={options.versionNumber}
            trip_info={options.trip_info}
            startDate={options.startDate}
            endDate={options.endDate}
            destination={options.destination}
            tripName={options.tripName}
            travelers={options.travelers}
            detailsInputs={options.detailsInputs}
            productInputs={options.productInputs}
            totalWithoutTaxes={options.totalWithoutTaxes}
            totalFlightTaxes={options.totalFlightTaxes}
            totalAmount={options.totalAmount}
            currency={options.currency}
        />
    ).toBlob();
}

function makeInvoicePdfUploadRequest(options) {
    let { pass_check, headers } = CheckBeforeRequest();

    if (pass_check) {
        const bucketName = "facilitatrip-documents";
        // const formData = new FormData();
        const domain = window.location.host.replace("www.", "").split('.');
        // formData.append("file_object", options.file);
        // formData.append("bucket_name", bucketName);
        // formData.append("prefix", `${domain[0]}-${domain[2] === 'com' ? 'prod' : 'pre-prod'}`);
        // formData.append("type", options.invoice.invoice_type);
        return axios.postForm(
            //eslint-disable-next-line no-undef
            `${API_HREF}client/${window.id_owner}/trip/${options.trip_info.id}/versions/${options.version}/file/upload_to_aws/`,
            {
                file_object: options.file,
                bucket_name: bucketName,
                prefix: `${domain[0]}-${domain[2] === 'com' ? 'prod' : 'pre-prod'}`,
                type: options.invoice.invoice_type,
            },
            { headers }
        );
    }

    return new Promise((resolve) => resolve(null));
}
