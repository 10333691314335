import { Block } from "../objects/block";
import { GoogleFont } from "../objects/googleFont";
import { MailTemplate } from "../objects/mailTemplate";
import { MailTemplateListQueryState } from "../objects/mailTemplateListQueryState";
import { MailTemplateListServerResponse } from "../objects/mailTemplateListServerResponse";
import { MailTemplateState } from "../objects/mailTemplateState";
import { MailTemplateVisualEditorBodyStyles } from "../objects/mailTemplateVisualEditorState";
import { Mode } from "../objects/mode";
import {
    ADD_PREFERENCES,
    ADD_VISUAL_EDITOR_BLOCK,
    ADD_VISUAL_EDITOR_BLOCKS_WITHOUT_ORDER,
    APPEND_VISUAL_EDITOR_BLOCK,
    CLEAR_VISUAL_EDITOR_BLOCKS,
    CREATE_HTML_CODE_EDITOR_LOCALE_INITIAL_CONTENT,
    CREATE_VISUAL_EDITOR_LOCALE_INITIAL_CONTENT,
    DELETE_VISUAL_EDITOR_BLOCK,
    DELETE_VISUAL_EDITOR_BLOCK_FROM_COLUMNS_BLOCK,
    DUPLICATE_VISUAL_EDITOR_BLOCK,
    INSERT_VISUAL_EDITOR_BLOCK,
    MailTemplateActionTypes,
    MOVE_DOWN_COLUMN,
    MOVE_UP_COLUMN,
    REDO_VISUAL_EDITOR,
    REPLACE_ITEM_IN_LIST,
    RESET_CREATE_FORM,
    RESET_EDITORS,
    RESET_FILTERS,
    RESTORE_VISUAL_EDITOR_BLOCKS_FROM_HTML,
    SELECT_VISUAL_EDITOR_BLOCK,
    SET_AUTOCOMPLETE,
    SET_CREATE_ACTION,
    SET_CREATE_EXTRA_PARAMS,
    SET_CREATE_NAME,
    SET_CREATE_SUBJECT,
    SET_EDITOR_CHOICE,
    SET_EXPORT_TYPE,
    SET_GOOGLE_FONTS,
    SET_HTML_CODE_EDITOR_CONTENT,
    SET_LIMIT,
    SET_LOADING,
    SET_MAIL_TEMPLATES,
    SET_ORDER_BY,
    SET_PAGE,
    SET_PREFERENCES,
    SET_QUERY,
    SET_RECIPIENTS,
    SET_VISUAL_EDITOR_BLOCK_OPTIONS,
    SET_VISUAL_EDITOR_BODY_STYLES,
    SET_VISUAL_EDITOR_DRAGGED_BLOCK_ID,
    SET_VISUAL_EDITOR_MODE,
    TOGGLE_CANCEL_MODAL,
    TOGGLE_COLUMN,
    TOGGLE_CREATE,
    TOGGLE_EXPORT,
    TOGGLE_EXPORT_FULL,
    TOGGLE_FILTERS,
    TOGGLE_REFRESH,
    UNDO_VISUAL_EDITOR,
    UNSELECT_VISUAL_EDITOR_BLOCK,
    UPDATE_FILTER_ACTION,
    UPDATE_FILTER_CREATED_DATE_GTE,
    UPDATE_FILTER_CREATED_DATE_LTE,
    UPDATE_FILTER_CREATOR,
    UPDATE_FILTER_LAST_AUTHOR,
    UPDATE_FILTER_MODIFIED_DATE_GTE,
    UPDATE_FILTER_MODIFIED_DATE_LTE,
    UPDATE_FILTER_NAME,
    UPDATE_FILTER_PERIOD,
    UPDATE_FILTER_PERIOD_TYPE,
    UPDATE_MAIL_TEMPLATE_LIST_QUERY
} from "./actionTypes";


export const addPreferences = (preferences: unknown[]): MailTemplateActionTypes => ({
    type: ADD_PREFERENCES,
    payload: preferences
});

export const addVisualEditorBlock = (
    instanceId: string,
    locale: number,
    blockType: string | symbol,
    prevBlockId: number | null
): MailTemplateActionTypes => ({
    type: ADD_VISUAL_EDITOR_BLOCK,
    payload: {
        instanceId,
        locale,
        prevBlockId,
        type: blockType
    }
});

export const addVisualEditorBlocksWithoutOrder = (
    instanceId: string,
    locale: number,
    blocks: Block<any>[]
): MailTemplateActionTypes => ({
    type: ADD_VISUAL_EDITOR_BLOCKS_WITHOUT_ORDER,
    payload: {
        instanceId,
        locale,
        blocks
    }
});

export const appendVisualEditorBlock = (
    instanceId: string,
    locale: number,
    block: Block<any>
): MailTemplateActionTypes => ({
    type: APPEND_VISUAL_EDITOR_BLOCK,
    payload: {
        instanceId,
        locale,
        block
    }
});

export const createHtmlCodeEditorLocaleInitialContent = (locale: number): MailTemplateActionTypes => ({
    type: CREATE_HTML_CODE_EDITOR_LOCALE_INITIAL_CONTENT,
    payload: { locale }
});

export const createVisualEditorLocalInitialContent = (
    instanceId: string,
    locale: number
): MailTemplateActionTypes => ({
    type: CREATE_VISUAL_EDITOR_LOCALE_INITIAL_CONTENT,
    payload: { instanceId, locale }
});

export const deleteVisualEditorBlock = (
    instanceId: string,
    locale: number,
    id: number
): MailTemplateActionTypes => ({
    type: DELETE_VISUAL_EDITOR_BLOCK,
    payload: { instanceId, locale, id }
});

export const deleteVisualEditorBlockFromColumnsBlock = (
    instanceId: string,
    locale: number,
    columnsBlockId: number,
    id: number
): MailTemplateActionTypes => ({
    type: DELETE_VISUAL_EDITOR_BLOCK_FROM_COLUMNS_BLOCK,
    payload: { instanceId, locale, id, columnsBlockId }
});

export const duplicateVisualEditorBlock = (
    instanceId: string,
    locale: number,
    id: number
): MailTemplateActionTypes => ({
    type: DUPLICATE_VISUAL_EDITOR_BLOCK,
    payload: { instanceId, locale, id }
});

export const insertVisualEditorBlock = (
    payload: {
        instanceId: string,
        locale: number,
        index: number,
        block: Block<any>,
    }
): MailTemplateActionTypes => ({
    type: INSERT_VISUAL_EDITOR_BLOCK,
    payload
});

export const moveDownColumn = (index: number): MailTemplateActionTypes => ({
    type: MOVE_DOWN_COLUMN,
    payload: index
});

export const moveUpColumn = (index: number): MailTemplateActionTypes => ({
    type: MOVE_UP_COLUMN,
    payload: index
});

export const redoVisualEditor = (): MailTemplateActionTypes => ({
    type: REDO_VISUAL_EDITOR
});

export const resetCreateForm = (): MailTemplateActionTypes => ({
    type: RESET_CREATE_FORM
});

export const resetEditors = (): MailTemplateActionTypes => ({
    type: RESET_EDITORS
});

export const replaceItemInList = (template: MailTemplate): MailTemplateActionTypes => ({
    type: REPLACE_ITEM_IN_LIST,
    payload: template
});

export const resetFilters = (): MailTemplateActionTypes => ({
    type: RESET_FILTERS
});

export const restoreVisualEditorBlocksFromHtml = (payload: {
    instanceId: string,
    locale: number,
    html: string,
    quotationCode: string | null
}): MailTemplateActionTypes => ({
    type: RESTORE_VISUAL_EDITOR_BLOCKS_FROM_HTML,
    payload
});

export const clearVisualEditorBlocks = (payload: {
    instanceId: string,
    locale: number
}): MailTemplateActionTypes => ({
    type: CLEAR_VISUAL_EDITOR_BLOCKS,
    payload
});

export const selectVisualEditorBlock = (
    instanceId: string,
    blockId: number
): MailTemplateActionTypes => ({
    type: SELECT_VISUAL_EDITOR_BLOCK,
    payload: { instanceId, blockId }
});

export const setAutocomplete = (autocomplete: MailTemplateState['autocomplete']): MailTemplateActionTypes => ({
    type: SET_AUTOCOMPLETE,
    payload: autocomplete
});

export const setCreateAction = (action: string): MailTemplateActionTypes => ({
    type: SET_CREATE_ACTION,
    payload: action
});

export const setCreateExtraParams = (
    params: MailTemplateState["create_extra_params"]
): MailTemplateActionTypes => ({
    type: SET_CREATE_EXTRA_PARAMS,
    payload: params
});

export const setCreateName = (locale: number, name: string): MailTemplateActionTypes => ({
    type: SET_CREATE_NAME,
    payload: { locale, value: name }
});

export const setCreateSubject = (locale: number, subject: string): MailTemplateActionTypes => ({
    type: SET_CREATE_SUBJECT,
    payload: { locale, value: subject }
});

export const setEditorChoice = (choice: number): MailTemplateActionTypes => ({
    type: SET_EDITOR_CHOICE,
    payload: choice
});

export const setExportType = (type: 'CSV' | 'PDF'): MailTemplateActionTypes => ({
    type: SET_EXPORT_TYPE,
    payload: type
});

export const setGoogleFonts = (fonts: GoogleFont[]): MailTemplateActionTypes => ({
    type: SET_GOOGLE_FONTS,
    payload: fonts
});

export const setHtmlCodeEditorContent = (locale: number, content: string): MailTemplateActionTypes => ({
    type: SET_HTML_CODE_EDITOR_CONTENT,
    payload: { locale, content }
});

export const setLimit = (limit: number): MailTemplateActionTypes => ({
    type: SET_LIMIT,
    payload: limit
});

export const setLoading = (loading: boolean): MailTemplateActionTypes => ({
    type: SET_LOADING,
    payload: loading
});

export const setMailTemplates = (templates: MailTemplate[] | null): MailTemplateActionTypes => ({
    type: SET_MAIL_TEMPLATES,
    payload: templates
});

export const setOrderBy = (order_by: string): MailTemplateActionTypes => ({
    type: SET_ORDER_BY,
    payload: order_by
});

export const setPage = (page: number): MailTemplateActionTypes => ({
    type: SET_PAGE,
    payload: page
});

export const setPreferences = (preferences: unknown[]): MailTemplateActionTypes => ({
    type: SET_PREFERENCES,
    payload: preferences
});

export const setQuery = (key: string, query: unknown): MailTemplateActionTypes => ({
    type: SET_QUERY,
    payload: { key, query }
});

export const setRecipients = (recipients: MailTemplateState['recipients']) => ({
    type: SET_RECIPIENTS,
    payload: recipients
});

export const setVisualEditorBlockOptions = <Options>(
    instanceId: string,
    locale: number,
    id: number,
    options: Options
): MailTemplateActionTypes => ({
        type: SET_VISUAL_EDITOR_BLOCK_OPTIONS,
        payload: {
            instanceId,
            locale,
            id,
            options
        }
    });

export const setVisualEditorBodyStyles = (
    instanceId: string,
    styles: MailTemplateVisualEditorBodyStyles
): MailTemplateActionTypes => ({
    type: SET_VISUAL_EDITOR_BODY_STYLES,
    payload: { instanceId, styles }
});

export const setVisualEditorDraggedBlockId = (
    instanceId: string,
    blockId: number | null
): MailTemplateActionTypes => ({
    type: SET_VISUAL_EDITOR_DRAGGED_BLOCK_ID,
    payload: { instanceId, blockId }
});

export const setVisualEditorMode = (
    instanceId: string,
    mode: Mode
): MailTemplateActionTypes => ({
    type: SET_VISUAL_EDITOR_MODE,
    payload: { instanceId, mode }
});

export const toggleCancelModal = (): MailTemplateActionTypes => ({
    type: TOGGLE_CANCEL_MODAL
});

export const toggleColumn = (index: number): MailTemplateActionTypes => ({
    type: TOGGLE_COLUMN,
    payload: index
});

export const toggleCreate = (): MailTemplateActionTypes => ({
    type: TOGGLE_CREATE
});

export const toggleExport = (active: boolean): MailTemplateActionTypes => ({
    type: TOGGLE_EXPORT,
    payload: active
});

export const toggleExportFull = (): MailTemplateActionTypes => ({
    type: TOGGLE_EXPORT_FULL
});

export const toggleFilters = (): MailTemplateActionTypes => ({
    type: TOGGLE_FILTERS
});

export const toggleRefresh = (): MailTemplateActionTypes => ({
    type: TOGGLE_REFRESH
});

export const undoVisualEditor = (): MailTemplateActionTypes => ({
    type: UNDO_VISUAL_EDITOR
});

export const unselectVisualEditorBlock = (
    instanceId: string
): MailTemplateActionTypes => ({
    type: UNSELECT_VISUAL_EDITOR_BLOCK,
    payload: { instanceId }
});

export const updateFilterAction = (action: string | ''): MailTemplateActionTypes => ({
    type: UPDATE_FILTER_ACTION,
    payload: action
});

export const updateFilterCreatedDateGte = (date: string | null): MailTemplateActionTypes => ({
    type: UPDATE_FILTER_CREATED_DATE_GTE,
    payload: date
});

export const updateFilterCreatedDateLte = (date: string | null): MailTemplateActionTypes => ({
    type: UPDATE_FILTER_CREATED_DATE_LTE,
    payload: date
});

export const updateFilterCreator = (creator: string): MailTemplateActionTypes => ({
    type: UPDATE_FILTER_CREATOR,
    payload: creator
});

export const updateFilterLastAuthor = (last_author: string): MailTemplateActionTypes => ({
    type: UPDATE_FILTER_LAST_AUTHOR,
    payload: last_author
});

export const updateFilterModifiedDateGte = (date: string | null): MailTemplateActionTypes => ({
    type: UPDATE_FILTER_MODIFIED_DATE_GTE,
    payload: date
});

export const updateFilterModifiedDateLte = (date: string | null): MailTemplateActionTypes => ({
    type: UPDATE_FILTER_MODIFIED_DATE_LTE,
    payload: date
});

export const updateFilterName = (name: string): MailTemplateActionTypes => ({
    type: UPDATE_FILTER_NAME,
    payload: name
});

export const updateFilterPeriod = (period: 'CREATION' | 'MODIFICATION'): MailTemplateActionTypes => ({
    type: UPDATE_FILTER_PERIOD,
    payload: period
});

export const updateFilterPeriodType = (type: number): MailTemplateActionTypes => ({
    type: UPDATE_FILTER_PERIOD_TYPE,
    payload: type
});

export const updateMailTemplateListQuery = (
    state: MailTemplateListQueryState,
    data: MailTemplateListServerResponse = {
        count: 0,
        next: null,
        previous: null,
        results: []
    }
): MailTemplateActionTypes => ({
    type: UPDATE_MAIL_TEMPLATE_LIST_QUERY,
    payload: {
        state,
        data
    }
});
