import { CartProductStatus } from "../CartConstructionProductsTableItemMenu";

type Product = {
    is_optional: boolean,
    is_hidden_for_traveler: boolean,
    is_hidden_for_traveler_count_price: boolean,
    variant: unknown
}

export function getProductStatus(
    product: Product,
    hasPackage: boolean
): CartProductStatus {
    let status: CartProductStatus = 'DEFAULT';

    if (product.is_optional) {
        status = 'OPTION';
    } else if (product.is_hidden_for_traveler) {
        status = 'HIDE';
    } else if (product.is_hidden_for_traveler_count_price) {
        status = 'HIDE_COUNT';
    } else if (product.variant && hasPackage) {
        status = 'VARIANT';
    }

    return status;
}
