import React, { Fragment, useState, useEffect } from "react";
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import ListSubheader from '@material-ui/core/ListSubheader';
import Paper from '@material-ui/core/Paper';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from "@material-ui/icons/ChevronRight";
import IconButton from "@material-ui/core/IconButton";
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '19%'
    },
    rootTablet: {
        width: '33%'
    },
  	absoluteCenter: {
  		margin: '0',
  		position: 'absolute',
  		top: '50%',
  		left: '50%',
  		transform: 'translate(-50%, -50%)',
        width: '100%',
        textAlign: 'center'
  	},
  	carsCategoryName: {
  	    width: '140px',
  	    fontSize: '12px',
  	    textTransform: 'uppercase',
  	    fontWeight: 'bold',
  	    whiteSpace: "nowrap",
  	    lineHeight: 1.5
  	},
  	carsPriceContainer: {
  	    //background: '#F3F7F9',
  	    "position": 'relative',
  	    "height": '100%',
  	    "cursor": 'pointer',
  	    '&:hover': {
  	    	border: '1px solid black'
  	    }
  	},
  	carsPriceContainerEmpty: {
  	    position: 'relative',
  	    height: '100%'
  	},
  	categoryHeader: {
  		"height": '155px !important',
  		"cursor": 'pointer',
  		'& img': {
  			maxWidth: '100%',
  			height: '91.72px'
  		},
  		'& .mui-jss-MuiListSubheader-sticky': {
  			top: '70px'
  		}
  	},
  	arrowLeft: {
  		position: 'absolute',
  		left: '-4%',
  		marginTop: '25px',
  		border: '1px solid',
  		padding: '4px'
  	},
  	arrowRight: {
  		position: 'absolute',
  		right: '0',
  		marginTop: '25px',
  		border: '1px solid',
  		padding: '4px'
  	},
    oneWayInfo: {
        fontSize: 16,
        marginRight: 4,
        verticalAlign: 'text-top'
    }
}));

const RenderMainMatrix = ({ cars_categories, cars_results, user, language, currency, all_cars, setProviderID, setCategoryID }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.between(768, 1024));
    const [offset, setOffset] = useState(5);
    const [index, setIndex] = useState(0);
    const [step, setStep] = useState(0);
    const currency_list = useSelector(store => store.base.currency_list);

    const quotation_code = JSON.parse(localStorage.getItem("config")).quotation_code;

    let arr = [];
    useEffect(() => {
        setOffset(isTablet ? 3 : 5);
    }, [isTablet]);
    const addOffset = () => {
        setIndex(index + offset);
        setStep(step + 1);
    };
    const subtractOffset = () => {
        setIndex(index - offset);
        setStep(step - 1);
    };
    let categories_to_render = cars_categories.filter((el) => {
        return el.to_render;
    });
    let maxStep = Math.ceil(categories_to_render.length / offset);
    arr.push(
        <div key={'arrowButton'}>
            {
                index > 0 && (
                    <IconButton className={ `${classes.arrowLeft}` } onClick={() => subtractOffset()}>
				    <ChevronLeft/>
                    </IconButton>
                )
            }
            {
                (step + 1) < maxStep && (
                    <IconButton className={ `${classes.arrowRight}` } onClick={() => addOffset()}>
				    <ChevronRight/>
                    </IconButton>
                )
            }
        </div>
    );
    for (let i = index; i < (index + offset < categories_to_render.length ? index + offset : categories_to_render.length); i++) {
        let category = categories_to_render[i];
        let category_currency = null;
        category_currency = currency_list.find((el) => {
            return el.id === category.min_price.currency;
        });
        if (!(user.client_full.type === 2 && quotation_code === "amerigo" && category.code === "ECAR")) {
            arr.push(
		      	<GridList cellHeight={50} key={i} cols={1} className={isTablet ? classes.rootTablet : classes.root}>
		  			<GridListTile className={classes.categoryHeader} onClick={() => {
		  				setCategoryID(category.name);
		  				setProviderID(null);
		  			}}>
			  			<ListSubheader component={"div"}>
			  	    	  	<Paper elevation={0} className="cars-category-container text-center">
				  	    	  	<div className={ "absolute-center" }>
				  	    	  		<div>
				  	    	  			<img src={ "/Img/Cars/" + (category.img === "fullsize-fcar" ? 'standard elite' : category.img === 'minivan-mvar' ? 'minivan' : category.img.replace(/fullsize-fcar|standard elite|suv-ifar|suv-sfar|minivan-mvar|suv-ffar|standard_elite/g, 'suv')) + ".png"} />
				  	    	  		</div>
				  	    	  		<div className={ classes.carsCategoryName }>
				  	    	  			{ t("cars.categories." + ((category.name).toLowerCase()).replace(" ", "_")) }
                                        <br/>
                                        {`(${category.code})`}
				  	    	  			{
				  	    	  				// ['marcovasco', 'cercledesvoyages'].includes(quotation_code) && (
				  	    	  				// 	<Fragment>
				  	    	  				// 		<br/>
					  	    	  			// 		{" (" + category.code + ")"}
				  	    	  				// 	</Fragment>
				  	    	  				// )
				  	    	  			}
				  	    	  		</div>
				  	    	  	</div>
			  	    	  	</Paper>
			  	    	</ListSubheader>
		  	        </GridListTile>
		  	        <GridListTile onClick={() => {
		  				setCategoryID(category.name);
		  				setProviderID(null);
		  			}}>
		  	        	<Paper elevation={0} className={ category.min_price !== null ? classes.carsPriceContainer : classes.carsPriceContainerEmpty } >
		  	        		<div className={ "pointer" } >
		  	        			<div className={ "absolute-center cars-price-details bold" }>
		  	        				{
		  	        					all_cars ? (
		  	        						category.min_price !== null ?
			  	        						new Intl.NumberFormat(language, { style: 'currency', currency: category_currency.iso_code }).format(parseFloat(category.min_price.min)) : "---"
		  	        					) :
		  	        					<Skeleton height={50} width={140} animation="wave"/>
		  	        				}
		  	        			</div>
		  	        		</div>
		  	        	</Paper>
		  	        </GridListTile>
		  	        {
		  	        	cars_results.map((result, result_index) => {
		  	        		if (result.to_render) {
			  	        		let find = null;
			  	        		if (result.vehicles[category.name] !== undefined) {
			  	        			find = result.vehicles[category.name].find((el) => {
			  	        				return el.to_render && el.station_prices !== null;
			  	        			});
			  	        		}
                                let selling_currency = null;
                                if (find !== null && find !== undefined) {
                                    selling_currency = currency_list.find((el) => {
                                        return el.id === find.station_prices[0].prices[0].selling_currency;
                                    });
                                }
                                if (find !== undefined && find !== null && find.to_render) {
                                    return (
                                        <GridListTile cols={1} key={result_index} onClick={() => {
                                            if (result.vehicles[category.name] !== undefined && result.vehicles[category.name][0].station_prices !== null && result.vehicles[category.name][0].to_render) {
                                                setCategoryID(category.name);
                                                setProviderID(result_index);
                                            }
                                        }}>
                                            {
                                                result.vehicles[category.name] !== undefined && (
                                                    <Paper elevation={0} className={ result.vehicles[category.name][0].station_prices !== null ? classes.carsPriceContainer : classes.carsPriceContainerEmpty } >
                                                        <div className={ clsx(classes.absoluteCenter, "cars-price-details" + (result.vehicles[category.name][0].station_prices !== null ? ((parseFloat(result.vehicles[category.name][0].station_prices[0].prices[0].selling_price) === category.min_price.min && result.vehicles[category.name][0].to_render) ? " bold ft-pink" : "") : ""))}>
														  {
															  find !== undefined && find.station_prices[0].onsite_fares !== undefined && find.station_prices[0].onsite_fares.map((onsite_fare, onsite_fare_index) => {
																  if (onsite_fare.description === "One way fares" && onsite_fare.price !== 0) {
																	  const currency = currency_list.find((el) => {
																		  return el.id === onsite_fare.currency;
																	  });
																	  return <Tooltip key={ `cars-matrix-one-way-${onsite_fare_index}` } title={ `${t('cars.one_way')} : ${new Intl.NumberFormat(language, { style: 'currency', currency: currency.iso_code }).format(onsite_fare.price)}` } arrow><InfoOutlined className={ classes.oneWayInfo }/></Tooltip>;
																  }
															  })
														  }
                                                            {
                                                                find !== undefined && selling_currency !== undefined && (
																  <span>{ new Intl.NumberFormat(language, { style: 'currency', currency: selling_currency.iso_code }).format(parseFloat(find.station_prices[0].prices[0].selling_price)) }</span>
														  )
                                                            }
                                                            {
                                                                find === undefined && selling_currency === undefined && (
                                                                    "---"
                                                                )
                                                            }
                                                        </div>
                                                    </Paper>
                                                )
                                            }
                                            {
                                                result.vehicles[category.name] === undefined && (
                                                    <Paper elevation={0} className={classes.carsPriceContainerEmpty}>
                                                        <div className={ "absolute-center cars-price-details" }>
                                                            { "---" }
                                                        </div>
                                                    </Paper>
                                                )
                                            }
                                        </GridListTile>
                                    );
                                }
                                return (
                                    <GridListTile cols={1} key={result_index}>
                                        <Paper elevation={0} className={classes.carsPriceContainerEmpty}>
                                            <div className={ "absolute-center cars-price-details" }>
                                                { "---" }
                                            </div>
                                        </Paper>
                                    </GridListTile>
                                );
		  	        		}
		  	        	})
		  	        }
	    	       	{
	    	       		!all_cars && (
	       					<GridListTile cols={1}>
	       			    	  	<Paper elevation={0}>
	       			    	  		<Skeleton height={50} width={140} animation="wave"/>
	       			    	  	</Paper>
	       			        </GridListTile>
	    	       		)
	    	       	}
	    	       	{
	    	       		!all_cars && (
	       					<GridListTile cols={1}>
	       			    	  	<Paper elevation={0}>
	       			    	  		<Skeleton height={50} width={140} animation="wave"/>
	       			    	  	</Paper>
	       			        </GridListTile>
	    	       		)
	    	       	}
	    	       	{
	    	       		!all_cars && (
	       					<GridListTile cols={1}>
	       			    	  	<Paper elevation={0}>
	       			    	  		<Skeleton height={50} width={140} animation="wave"/>
	       			    	  	</Paper>
	       			        </GridListTile>
	    	       		)
	    	       	}
		  	    </GridList>
            );
        }
    }
    return (
        arr
    );
};
export default React.memo(RenderMainMatrix);
