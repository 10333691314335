import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { PanTool } from "@mui/icons-material";
import {
    Button,
    Divider,
    FormControl,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    Stack,
    Tooltip,
    Typography
} from "@mui/material";
import { debounce, isNumber } from "lodash";
import { LoadingBackDrop } from "../Common/LoadingBackdrop";
import { useShowError } from "../Utils/showError";
import { useCartProductUpdate } from "./network/cartProductUpdate";
import { useGetFlightSellingPrice } from "./utils/getFlightSellingPrice";
import GetCookie from "../Common/Functions/GetCookie";
import { Currency } from "../../Reducers/objects/currency";
import { CarCart } from "../Itinerary/objects/carCart";
import { PoiCart } from "../Itinerary/objects/poiCart";
import { TransferCart } from "../Itinerary/objects/transferCart";
import { AssistanceCart } from "../Itinerary/objects/assistanceCart";
import { AccommodationCart } from "../Itinerary/objects/accommodationCart";
import { ManualProduct } from "../../Reducers/objects/manualProduct";
import { ManualProductFlightCart } from "../Itinerary/objects/manualProductFlightCart";
import { ManualProductAccommodationCart } from "../Itinerary/objects/manualProductAccommodationCart";
import { Flight } from "../Itinerary/network/flight";
import { AppState } from "../../Reducers/Reducers";
import { useGetPrice } from './utils/getPrice';

type Props = {
    type: 'car',
    item: CarCart
} | {
    type: 'assistance',
    item: AssistanceCart
} | {
    type: 'accommodation',
    item: AccommodationCart,
} | {
    type: 'manual-accommodation',
    item: ManualProductAccommodationCart,
} | {
    type: 'poi',
    item: PoiCart
} | {
    type: 'transfer',
    item: TransferCart
} | {
    type: 'manual-flight',
    item: ManualProductFlightCart
} | {
    type: 'flight',
    item: Flight
} | {
    type: 'manual',
    item: ManualProduct
}

export function CartConstructionProductsTableItemMargin(props: Props): JSX.Element {
    const { t } = useTranslation();
    const getPrice = useGetPrice();
    const user = useSelector((state: AppState) => state.user.user);
    const language = useSelector((state: AppState) => state.header.tmp_language);
    const currency = useSelector((state: AppState) => state.trip.currency);
    const providers = useSelector((state: AppState) => state.trip.providers);
    const manual_providers = useSelector((state: AppState) => state.trip.manual_providers);
    const currency_list = useSelector((state: AppState) => state.base.currency_list);
    const [editMargin, setEditMargin] = useState(false);
    const [marginValue, setMarginValue] = useState<string | null>(null);
    const [marginPercent, setMarginPercent] = useState<string | null>(null);
    const [marginType, setMarginType] = useState<'FIX' | 'PER'>('PER');
    const [luggagePrices, setLuggagePrices] = useState({
        purchase: 0,
        purchaseConverted: 0,
        margin: 0,
        selling: 0
    });
    const [seatsPrices, setSeatsPrices] = useState({
        purchase: 0,
        purchaseConverted: 0,
        margin: 0,
        selling: 0
    });
    const [priceInputs, setPriceInputs] = useState<{
        purchasePrice: number,
        sellingPrice: number,
        commissionType: 'PER' | 'FIX',
        commission: number | null,
        markupType: 'PER' | 'FIX',
        markup: number | null,
        taxes: number
    }>({
        purchasePrice: 0,
        sellingPrice: 0,
        commissionType: 'PER',
        commission: 0,
        markupType: 'PER',
        markup: 0,
        taxes: 0
    });
    const [margin, setMargin] = useState<
        {
            id: number,
            markup: boolean,
            purchase: number,
            purchase_converted: number,
            purchase_currency: Currency,
            selling: number,
            selling_currency: Currency,
            custom_rate: number | null,
            custom_rate_type: string,
            value: number,
            percent: number,
            factor_used: string,
            ids: {
                room_id: number,
                price_id: number
            }[]
        } | null
    >(null);
    const [saveMargin, setSaveMargin] = useState<typeof margin>(null);
    const [loading, setLoading] = useState(false);
    //eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, getFlightOptionCost] = useGetFlightSellingPrice();
    const catalogPrice = useMemo(() => {
        if (props.type !== 'accommodation') {
            return false;
        }

        let catalog_price = false;

        for (const room of (props.item.rooms ?? [])) {
            if (room.catalog_price || room.cataloged_price) {
                catalog_price = true;
            }
        }

        return catalog_price;
    }, [props.item]);
    const provider = useMemo(() => {
        let tmp_provider = null;
        for (let i = 0; i < providers.length; i++) {
            if (providers[i]?.provider.id === props.item.provider) {
                tmp_provider = providers[i];
            }
        }
        for (let i = 0; i < manual_providers.length; i++) {
            if (manual_providers[i]?.provider.id === props.item.provider) {
                tmp_provider = manual_providers[i];
            }
        }
        return tmp_provider;
    }, [props.item, providers]);
    const taxes = useMemo(() => {
        if (
            props.type === 'flight' ||
            props.type === 'manual-flight'
        ) {
            return parseFloat(props.item.taxes ?? '0');
        }
        return 0;
    }, [props.type, props.item]);
    const extra = useMemo(() => {
        let option_cost = 0;
        if (props.type === 'flight') {
            const result = getFlightOptionCost(props.item);
            option_cost = result[0] + result[1];
        }
        return option_cost + taxes;
    }, [props.type, props.item, taxes]);
    const getMargin = (tmp_margin: any, tmp_surcom: any, prices: any) => {
        if (user?.client_full?.type === 1 || user?.client_full?.type === 3 || quotation_code === 'verdie') {
            for (let i = 0; i < prices.length; i++) {
                if (prices[i].is_surcom) {
                    tmp_surcom.purchase += parseFloat(prices[i].purchase_price) * parseFloat(prices[i].factor_used);
                    tmp_surcom.selling += parseFloat(prices[i].selling_price);
                    tmp_surcom.currency = currency_list.find(currency => currency.id === prices[i].selling_currency);
                    tmp_surcom.percent += parseFloat(prices[i].percentage_between);
                    break;
                }
            }
            if (
                props.type.includes('manual') &&
                (props.item as { auto_product?: { is_surcom_only: boolean } }).auto_product?.is_surcom_only
            ) {
                for (let i = 0; i < prices.length; i++) {
                    if (prices[i].is_surcom) {
                        tmp_margin.factor_used = prices[i].factor_used;
                        tmp_margin.purchase += parseFloat(prices[i].purchase_price);
                        tmp_margin.purchase_converted += parseFloat(prices[i].purchase_price) * parseFloat(prices[i].factor_used);
                        tmp_margin.purchase_currency = currency_list.find(currency => currency.id === prices[i].purchase_currency);
                        tmp_margin.selling += parseFloat(prices[i].selling_price);
                        tmp_margin.selling_currency = currency_list.find(currency => currency.id === prices[i].selling_currency);
                        tmp_margin.percent += parseFloat(prices[i].percentage_between);
                        tmp_margin.custom_rate = prices[i].custom_rate;
                        tmp_margin.custom_rate_type = prices[i].custom_rate_type;
                        tmp_margin.id = prices[i].id;
                        return;
                    }
                }
            } else {
                for (let i = 0; i < prices.length; i++) {
                    if (prices[i].master_price) {
                        tmp_margin.factor_used = prices[i].factor_used;
                        tmp_margin.purchase += parseFloat(prices[i].purchase_price);
                        tmp_margin.purchase_converted += parseFloat(prices[i].purchase_price) * parseFloat(prices[i].factor_used);
                        tmp_margin.purchase_currency = currency_list.find(currency => currency.id === prices[i].purchase_currency);
                        tmp_margin.selling += parseFloat(prices[i].selling_price);
                        tmp_margin.selling_currency = currency_list.find(currency => currency.id === prices[i].selling_currency);
                        tmp_margin.percent += parseFloat(prices[i].percentage_between);
                        tmp_margin.custom_rate = prices[i].custom_rate;
                        tmp_margin.custom_rate_type = prices[i].custom_rate_type;
                        tmp_margin.id = prices[i].id;
                        return;
                    }
                }
            }
            if (!tmp_margin.id) {
                for (let i = 0; i < prices.length; i++) {
                    if (prices[i].owner === user?.client) {
                        tmp_margin.factor_used = prices[i].factor_used;
                        tmp_margin.purchase += parseFloat(prices[i].purchase_price);
                        tmp_margin.purchase_converted += parseFloat(prices[i].purchase_price) * parseFloat(prices[i].factor_used);
                        tmp_margin.purchase_currency = currency_list.find(currency => currency.id === prices[i].purchase_currency);
                        tmp_margin.selling += parseFloat(prices[i].selling_price);
                        tmp_margin.selling_currency = currency_list.find(currency => currency.id === prices[i].selling_currency);
                        tmp_margin.percent += parseFloat(prices[i].percentage_between);
                        tmp_margin.custom_rate = prices[i].custom_rate;
                        tmp_margin.custom_rate_type = prices[i].custom_rate_type;
                        tmp_margin.id = prices[i].id;
                        return;
                    }
                }
            }
        } else {
            for (let i = 0; i < prices.length; i++) {
                if (prices[i].is_tva) {
                    tmp_margin.factor_used = prices[i].factor_used;
                    tmp_margin.purchase += parseFloat(prices[i].purchase_price);
                    tmp_margin.purchase_converted += parseFloat(prices[i].purchase_price) * parseFloat(prices[i].factor_used);
                    tmp_margin.purchase_currency = currency_list.find(currency => currency.id === prices[i].purchase_currency);
                    tmp_margin.selling += parseFloat(prices[i].selling_price);
                    tmp_margin.selling_currency = currency_list.find(currency => currency.id === prices[i].selling_currency);
                    tmp_margin.percent += parseFloat(prices[i].percentage_between);
                    tmp_margin.custom_rate = prices[i].custom_rate;
                    tmp_margin.custom_rate_type = prices[i].custom_rate_type;
                    tmp_margin.id = prices[i].id;
                    return;
                }
            }
            for (let i = 0; i < prices.length; i++) {
                if (prices[i].owner === user?.client) {
                    tmp_margin.factor_used = prices[i].factor_used;
                    tmp_margin.purchase += parseFloat(prices[i].purchase_price);
                    tmp_margin.purchase_converted += parseFloat(prices[i].purchase_price) * parseFloat(prices[i].factor_used);
                    tmp_margin.purchase_currency = currency_list.find(currency => currency.id === prices[i].purchase_currency);
                    tmp_margin.selling += parseFloat(prices[i].selling_price);
                    tmp_margin.selling_currency = currency_list.find(currency => currency.id === prices[i].selling_currency);
                    tmp_margin.percent += parseFloat(prices[i].percentage_between);
                    tmp_margin.custom_rate = prices[i].custom_rate;
                    tmp_margin.custom_rate_type = prices[i].custom_rate_type;
                    tmp_margin.id = prices[i].id;
                    return;
                }
            }
        }
    };
    const quotation_code = JSON.parse(localStorage.getItem('config') ?? '{}').quotation_code;
    const productTva = useMemo(() => {
        if (props.type === 'accommodation') {
            let tva = 0;

            for (const room of (props.item.rooms)) {
                let tva_price = room.prices.find((price) => price.is_tva);
                if (tva_price) {
                    tva = parseFloat(tva_price.selling_price) - parseFloat(tva_price.purchase_price);
                }
            }

            return tva;
        }
        let tva = 0;

        let tva_price = props.item.prices.find((price) => price.is_tva);
        if (tva_price) {
            tva = parseFloat(tva_price.selling_price) - parseFloat(tva_price.purchase_price);
        }

        return tva;
    }, [props.item]);

    const surcom = useMemo(() => {
        let tmp_surcom: {
            static: number,
            purchase: number,
            selling: number,
            value: number,
            percent: number,
            currency: Currency | null
        } = {
            static: 0,
            purchase: 0,
            selling: 0,
            value: 0,
            percent: 0,
            currency: null
        };
        let tmp_margin = {
            static_percent: 0,
            static_value: 0,
            markup: false,
            purchase: 0,
            purchase_converted: 0,
            selling: 0,
            value: 0,
            percent: 0,
            currency: null,
            custom_rate: 0,
            id: [],
            ids: []
        };

        if (props.type === 'accommodation') {
            for (const room of (props.item.rooms)) {
                getMargin(tmp_margin, tmp_surcom, room.prices);
            }
        } else {
            getMargin(tmp_margin, tmp_surcom, props.item.prices);
        }

        tmp_margin.value = parseFloat((tmp_margin.selling - tmp_margin.purchase_converted).toFixed(2));
        tmp_margin.static_value = tmp_margin.value;
        tmp_margin.markup = Math.abs(
            tmp_margin.percent - (100 - (tmp_margin.purchase_converted / tmp_margin.selling) * 100)
        ) > Math.abs(
            tmp_margin.percent - ((tmp_margin.value / tmp_margin.purchase_converted) * 100)
        );
        if (props.type === 'accommodation') {
            tmp_surcom.percent = tmp_surcom.percent / props.item.rooms.length;
        }
        tmp_surcom.value = tmp_surcom.selling - tmp_surcom.purchase;
        tmp_margin.static_percent = tmp_margin.percent;

        return tmp_surcom;
    }, [props.item]);
    const recalculateMargin = useCallback(
        debounce(
            (sellingPrice: number, purchasePrice: number) => {
                const diff = sellingPrice - purchasePrice;
                if (diff <= 0) {
                    setMarginValue('0');
                    onChangePriceInput(
                        'sellingPrice',
                        purchasePrice
                    );
                } else {
                    setMarginValue(Math.abs(diff).toFixed(2));
                }
            },
            500
        ),
        []
    );
    const showError = useShowError();
    const update = useCartProductUpdate({
        onTrigger() {
            setLoading(true);
        },
        onSuccess() {
            setEditMargin(false);
        },
        onError(error) {
            console.error(error);
            showError(error);
        },
        onFinally() {
            setLoading(false);
        }
    });

    const onKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (event.keyCode === 38 || event.keyCode === 40) {
            event.preventDefault();
        }
    };

    const onMarginToInValueChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setMarginValue(event.target.value);
    };

    const onMarginToInPercentageChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setMarginPercent(event.target.value);
    };

    const onCancelMargin = () => {
        setMarginValue(null);
        setMarginPercent(null);
        if (saveMargin) {
            setMargin({ ...saveMargin });
        }
        setEditMargin(!editMargin);
    };

    const onValidateMargin = () => {
        const versionString = GetCookie("trip_id_version");
        const version = versionString ? parseInt(versionString) : -1;
        if (props.type === 'accommodation') {
            for (const room of props.item.rooms) {
                const tmp_margin = { ...margin };
                const tmp_surcom = { ...surcom };
                getMargin(tmp_margin, tmp_surcom, room.prices);
                const customValue = marginType === 'FIX' ? margin?.value : margin?.percent;
                const purchasePrice = priceInputs.purchasePrice;
                update(
                    version,
                    {
                        id: room.id,
                        type: props.type,
                        isCustom: props.item.is_custom
                    },
                    {
                        prices: [
                            {
                                id: tmp_margin?.id,
                                custom_rate: true,
                                custom_rate_type: marginType,
                                custom_value: ((customValue ?? 0) / (marginType === 'FIX' ? props.item.rooms.length : 1)).toFixed(2),
                                purchase_price: (purchasePrice / props.item.rooms.length).toFixed(2),
                                purchase_currency: margin?.purchase_currency?.id
                            }
                        ],
                        com_is_percentage: priceInputs.commissionType === 'PER',
                        com_price: isNumber(priceInputs.commission) ?
                            priceInputs.commission.toFixed(2) :
                            null,
                        markup_is_percentage: priceInputs.markupType === 'PER',
                        markup_price: isNumber(priceInputs.markup) ?
                            priceInputs.markup.toFixed() :
                            null
                    }
                );
            }
        } else {
            const customValue = marginType === 'FIX' ? margin?.value : margin?.percent;
            const purchasePrice = priceInputs.purchasePrice;
            update(
                version,
                {
                    id: props.item.id,
                    type: props.type,
                    isCustom: props.item.is_custom
                },
                {
                    prices: [
                        {
                            id: margin?.id,
                            custom_rate: true,
                            custom_rate_type: marginType,
                            custom_value: customValue?.toFixed(2),
                            purchase_price: purchasePrice.toFixed(2),
                            purchase_currency: margin?.purchase_currency?.id
                        }
                    ],
                    taxes: props.type === 'manual-flight' ?
                        priceInputs.taxes.toFixed(2) :
                        undefined,
                    com_is_percentage: priceInputs.commissionType === 'PER',
                    com_price: isNumber(priceInputs.commission) ?
                        priceInputs.commission.toFixed(2) :
                        null,
                    markup_is_percentage: priceInputs.markupType === 'PER',
                    markup_price: isNumber(priceInputs.markup) ?
                        priceInputs.markup.toFixed() :
                        null
                }
            );
        }
    };

    const onEditMargin = () => {
        setMarginValue(margin?.value.toString() ?? null);
        setMarginType((margin?.custom_rate_type as 'PER' | 'FIX' | undefined) ?? 'PER');
        setMarginPercent(margin?.percent.toString() ?? null);
        setEditMargin(!editMargin);
    };

    function onChangePriceInput<K extends keyof typeof priceInputs>(
        key: K,
        value: typeof priceInputs[K]
    ): void {
        setPriceInputs((state) => ({
            ...state,
            [key]: value
        }));
    }

    useEffect(() => {
        if (
            margin &&
            marginValue &&
            marginValue !== '' &&
            marginValue !== margin.value.toString()
        ) {
            let tmp_margin = { ...margin };
            tmp_margin.value = parseFloat(marginValue);
            const factorUsed = parseFloat(margin?.factor_used ?? '1');
            const purchasePrice = priceInputs.purchasePrice * factorUsed;
            if (tmp_margin.markup) {
                tmp_margin.percent = parseFloat(((tmp_margin.value / purchasePrice) * 100).toFixed(2));
            } else {
                tmp_margin.percent = parseFloat(((tmp_margin.value / priceInputs.sellingPrice) * 100).toFixed(2));
            }
            setMarginPercent(tmp_margin.percent.toString());
            setMargin(tmp_margin);
        }
    }, [marginValue, priceInputs.sellingPrice, priceInputs.purchasePrice]);

    useEffect(() => {
        if (
            margin &&
            marginPercent &&
            marginPercent !== '' &&
            marginPercent !== margin.percent.toString()
        ) {
            let tmp_margin = { ...margin };
            tmp_margin.percent = parseFloat(marginPercent);
            if (tmp_margin.markup) {
                tmp_margin.value = parseFloat((tmp_margin.purchase_converted * (tmp_margin.percent / 100)).toFixed(2));
            } else {
                tmp_margin.value = parseFloat((tmp_margin.selling * (tmp_margin.percent / 100)).toFixed(2));
            }
            setMarginValue(tmp_margin.value.toString());
            setMargin(tmp_margin);
        }
    }, [marginPercent]);

    useEffect(() => {
        let tmp_margin = {
            markup: false,
            purchase: 0,
            purchase_converted: 0,
            selling: 0,
            static_value: 0,
            value: 0,
            percent: 0,
            static_percent: 0,
            currency: null,
            id: 0,
            ids: [],
            purchase_currency: currency,
            selling_currency: currency,
            custom_rate: null,
            custom_rate_type: 'PER',
            factor_used: ''
        };
        let tmp_surcom: {
            static: number,
            purchase: number,
            selling: number,
            value: number,
            percent: number,
            currency: Currency | null
        } = {
            static: 0,
            purchase: 0,
            selling: 0,
            value: 0,
            percent: 0,
            currency: null
        };
        let tmp_luggage: {
            purchase: number,
            margin: number,
            selling: number
        } = {
            purchase: 0,
            margin: 0,
            selling: 0
        };

        if (props.type === 'accommodation') {
            for (const room of (props.item.rooms)) {
                getMargin(tmp_margin, tmp_surcom, room.prices);
            }

            tmp_margin.value = parseFloat((tmp_margin.selling - tmp_margin.purchase_converted).toFixed(2));
            tmp_margin.static_value = tmp_margin.value;
            tmp_margin.markup = Math.abs(
                (tmp_margin.percent / props.item.rooms.length) -
                (100 - (tmp_margin.purchase_converted / tmp_margin.selling) * 100)
            ) > Math.abs(
                (tmp_margin.percent / props.item.rooms.length) -
                ((tmp_margin.value / tmp_margin.purchase_converted) * 100)
            );
            tmp_margin.percent = tmp_margin.percent / props.item.rooms.length;
            tmp_margin.static_percent = tmp_margin.percent;
        } else {
            getMargin(tmp_margin, tmp_surcom, props.item.prices);

            tmp_margin.value = parseFloat((tmp_margin.selling - tmp_margin.purchase_converted).toFixed(2));
            tmp_margin.static_value = tmp_margin.value;
            tmp_margin.markup = Math.abs(
                tmp_margin.percent - (100 - (tmp_margin.purchase_converted / tmp_margin.selling) * 100)
            ) > Math.abs(
                tmp_margin.percent - ((tmp_margin.value / tmp_margin.purchase_converted) * 100)
            );
            tmp_margin.static_percent = tmp_margin.percent;
        }
        if (props.type === "flight") {
            const provider = providers.concat(manual_providers).find((item) => {
                return item.provider.id === props.item.provider;
            })?.provider;
            if (provider?.code !== 'amadeus_rest') {
                let seatsCost = 0;
                let option_on_flight: any[] = [];
                props.item.outbounds.map(outbound => {
                    let option_on_group: any[] = [];
                    outbound.legs.map(leg => {
                        for (const seat of leg.seats ?? []) {
                            let seatPrice = 0;
                            if ((seat.prices?.length ?? 0) > 0) {
                                seatPrice = getPrice(seat.prices!).cost;
                            } else {
                                seatPrice = parseFloat(seat.price ?? '0');
                            }
                            seatsCost += seatPrice;
                        }
                        if (leg.paid_options && leg.paid_options.length > 0) {
                            leg.paid_options.map(option => {
                                let optionPrice: {
                                    cost: number;
                                    margin: number;
                                    marginType: "FIX" | "PER" | null;
                                    currency: Currency | undefined
                                };
                                if ((option.prices?.length ?? 0) > 0) {
                                    optionPrice = getPrice(option.prices!);
                                }
                                if (option.option_on_flight) {
                                    if (!option_on_flight.includes(`${option.key_optional_service}-${option.traveler}`)) {
                                        if ((option.prices?.length ?? 0) > 0 && optionPrice!) {
                                            tmp_luggage.purchase += parseFloat(option.price);
                                            tmp_luggage.margin += optionPrice.cost - parseFloat(option.price);
                                            tmp_luggage.selling += optionPrice.cost;
                                        } else {
                                            tmp_luggage.purchase += parseFloat(option.price);
                                            tmp_luggage.selling += parseFloat(option.price);
                                        }
                                        option_on_flight.push(`${option.key_optional_service}-${option.traveler}`);
                                    }
                                } else if (option.option_on_group) {
                                    if (!option_on_group.includes(`${option.key_optional_service}-${option.traveler}`)) {
                                        if ((option.prices?.length ?? 0) > 0 && optionPrice!) {
                                            tmp_luggage.purchase += parseFloat(option.price);
                                            tmp_luggage.margin += optionPrice.cost - parseFloat(option.price);
                                            tmp_luggage.selling += optionPrice.cost;
                                        } else {
                                            tmp_luggage.purchase += parseFloat(option.price);
                                            tmp_luggage.selling += parseFloat(option.price);
                                        }
                                        option_on_group.push(`${option.key_optional_service}-${option.traveler}`);
                                    }
                                }
                            });
                        }
                    });
                });
                setLuggagePrices({
                    purchase: tmp_luggage.purchase,
                    purchaseConverted: tmp_luggage.purchase,
                    selling: tmp_luggage.selling,
                    margin: tmp_luggage.margin
                });
                setSeatsPrices({
                    purchase: seatsCost,
                    purchaseConverted: seatsCost,
                    margin: 0,
                    selling: seatsCost
                });
            } else {
                const seatsPrice = props.item.paid_option_price.filter((item) => {
                    return item.service_type === 'SEATS';
                }).reduce((prev, current) => {
                    const purchase = getPrice(current.prices, 'master-price');
                    const purchasePrice = purchase.purchaseCost;
                    const purchaseConvertedPrice = purchase.purchaseCost * purchase.factorUsed
                    const sellingPrice = getPrice(current.prices).cost;
                    return {
                        purchase: prev.purchase + purchasePrice,
                        purchaseConverted: prev.purchaseConverted + purchaseConvertedPrice,
                        selling: prev.selling + sellingPrice,
                        margin: prev.margin + (sellingPrice - purchasePrice),
                    }
                }, {
                    purchase: 0,
                    purchaseConverted: 0,
                    selling: 0,
                    margin: 0,
                });
                const otherOptionsPrice = props.item.paid_option_price.filter((item) => {
                    return item.service_type !== 'SEATS';
                }).reduce((prev, current) => {
                    const purchase = getPrice(current.prices, 'master-price');
                    const purchasePrice = purchase.purchaseCost;
                    const purchaseConvertedPrice = purchase.purchaseCost * purchase.factorUsed
                    const sellingPrice = getPrice(current.prices).cost;
                    return {
                        purchase: prev.purchase + purchasePrice,
                        purchaseConverted: prev.purchaseConverted + purchaseConvertedPrice,
                        selling: prev.selling + sellingPrice,
                        margin: prev.margin + (sellingPrice - purchasePrice),
                    }
                }, {
                    purchase: 0,
                    purchaseConverted: 0,
                    selling: 0,
                    margin: 0,
                });
                setLuggagePrices(otherOptionsPrice);
                setSeatsPrices(seatsPrice);
            }
        }

        setSaveMargin(tmp_margin);
        setMargin(tmp_margin);
    }, [props.item]);

    useEffect(() => {
        onChangePriceInput(
            'commissionType',
            props.item.com_is_percentage ?
                'PER' :
                'FIX'
        );
        onChangePriceInput(
            'commission',
            parseFloat(props.item.com_price ?? '0')
        );
        onChangePriceInput(
            'markupType',
            props.item.markup_is_percentage ?
                'PER' :
                'FIX'
        );
        onChangePriceInput(
            'markup',
            parseFloat(props.item.markup_price ?? '0')
        );
    }, [props.item, editMargin]);

    useEffect(() => {
        if (margin) {
            onChangePriceInput(
                'purchasePrice',
                !isNaN(margin.purchase) ?
                    parseFloat(margin.purchase.toFixed(2)) :
                    0
            );
            onChangePriceInput(
                'sellingPrice',
                !isNaN(margin.selling) ?
                    parseFloat(margin.selling.toFixed(2)) :
                    0
            );
            onChangePriceInput(
                'taxes',
                taxes
            );
        }
    }, [
        margin?.selling,
        margin?.purchase_converted,
        editMargin,
        taxes
    ]);

    useEffect(() => {
        const factorUsed = parseFloat(margin?.factor_used ?? '1');
        const purchasePrice = priceInputs.purchasePrice * factorUsed;
        recalculateMargin(
            priceInputs.sellingPrice,
            purchasePrice
        );
    }, [priceInputs.sellingPrice, priceInputs.purchasePrice]);

    return (
        <>
            <Grid
                container
                justifyContent="space-between"
                alignItems="center"
            >
                {
                    (
                        (
                            props.type === 'manual-flight' &&
                            !editMargin
                        ) ||
                        props.type === 'flight'
                    ) &&
                    <Grid item>
                        <Stack>
                            <Typography variant="caption">
                                {t('cart-material.cart-construction-flight-taxes-price')}
                                <strong>
                                    {
                                        ((margin?.purchase || 0) + luggagePrices.purchase + seatsPrices.purchase).toLocaleString(
                                            language,
                                            { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                                        )
                                    }{' '}
                                    {margin?.purchase_currency.iso_code}
                                    {' '}(
                                    {
                                        (() => {
                                            const value = ((margin?.purchase_converted || 0) + luggagePrices.purchaseConverted + seatsPrices.purchaseConverted).toLocaleString(
                                                language,
                                                { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                                            );
                                            return `${value} ${margin?.selling_currency.iso_code}`;
                                        })()
                                    }
                                    )
                                </strong>
                            </Typography>
                            <Typography variant="caption">
                                {t('flight_search.taxe_price')} :{' '}
                                <strong>
                                    {
                                        parseFloat(props.item.taxes ?? '0').toLocaleString(
                                            language,
                                            { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                                        )
                                    }{' '}
                                    {margin?.purchase_currency.iso_code}
                                </strong>
                            </Typography>
                            <Typography variant="caption">
                                {t('cart-material.cart-construction-flight-total-price')}
                                <strong>
                                    {
                                        ((margin?.purchase ?? 0) + parseFloat(props.item.taxes ?? '0') + luggagePrices.purchase + seatsPrices.purchase).toLocaleString(
                                            language,
                                            { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                                        )
                                    }{' '}
                                    {margin?.purchase_currency.iso_code}
                                    {' '}(
                                    {
                                        (() => {
                                            const value = ((margin?.purchase_converted ?? 0) + parseFloat(props.item.taxes ?? '0') + luggagePrices.purchaseConverted + seatsPrices.purchaseConverted).toLocaleString(
                                                language,
                                                { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                                            );
                                            return `${value} ${margin?.selling_currency.iso_code}`;
                                        })()
                                    }
                                    )
                                </strong>
                            </Typography>
                        </Stack>
                    </Grid>
                }
                {
                    (
                        editMargin ||
                        props.type !== 'manual-flight'
                    ) &&
                    props.type !== 'flight' &&
                    <Grid item>
                        {
                            editMargin &&
                                (
                                    props.type.includes('manual') ||
                                    (
                                        catalogPrice ||
                                        (
                                            ['cercledesvoyages', 'continentsinsolites', 'tropicalementvotre'].includes(quotation_code) &&
                                            (provider === null || provider?.provider.custom)
                                        ) ||
                                        (!props.item.is_available && (provider === null || provider?.provider.custom))
                                    ) ||
                                    (
                                        props.type === 'poi' &&
                                        props.item.is_custom
                                    )
                                ) ?
                                <FormControl
                                    size="small"
                                    variant={'outlined'}
                                    disabled={
                                        props.type.includes('manual') &&
                                        (props.item as { auto_product?: { is_surcom_only: boolean } }).auto_product?.is_surcom_only
                                    }
                                    fullWidth
                                >
                                    <InputLabel>
                                        {
                                            t(
                                                quotation_code === 'continentsinsolites' ?
                                                    'cart-material.price-in-currency-with-commission' :
                                                    'cart-material.price-in-currency'
                                            )
                                        }
                                    </InputLabel>
                                    <OutlinedInput
                                        type={'number'}
                                        onChange={(event) => onChangePriceInput('purchasePrice', parseFloat(event.target.value))}
                                        onKeyDown={onKeyDown}
                                        label={
                                            t(
                                                quotation_code === 'continentsinsolites' ?
                                                    'cart-material.price-in-currency-with-commission' :
                                                    'cart-material.price-in-currency'
                                            )
                                        }
                                        value={priceInputs.purchasePrice}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                {margin?.purchase_currency.symbol}
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl> :
                                <Stack>
                                    <Typography variant="caption">
                                        {
                                            t(
                                                quotation_code === 'continentsinsolites' ?
                                                    'cart-material.price-in-currency-with-commission' :
                                                    'cart-material.price-in-currency'
                                            )
                                        } :{' '}
                                        <strong>
                                            {
                                                margin?.purchase.toLocaleString(
                                                    language,
                                                    { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                                                )
                                            }{' '}
                                            {margin?.purchase_currency.iso_code}
                                            {' '}(
                                            {
                                                (() => {
                                                    const value = margin?.purchase_converted.toLocaleString(
                                                        language,
                                                        { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                                                    );
                                                    return `${value} ${margin?.selling_currency.iso_code}`;
                                                })()
                                            }
                                            )
                                        </strong>
                                    </Typography>
                                    {
                                        quotation_code === 'continentsinsolites' &&
                                        <Typography variant="caption">
                                            {t('cart-material.price-in-currency-without-commission')} :{' '}
                                            <strong>
                                                {
                                                    ((margin?.purchase ?? 0) - ((margin?.purchase ?? 0) * ((priceInputs.commission ?? 0) / 100))).toLocaleString(
                                                        language,
                                                        { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                                                    )
                                                }{' '}
                                                {margin?.purchase_currency.iso_code}
                                                {' '}(
                                                {
                                                    (() => {
                                                        const value = ((margin?.purchase_converted ?? 0) - ((margin?.purchase_converted ?? 0) * ((priceInputs.commission ?? 0) / 100))).toLocaleString(
                                                            language,
                                                            { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                                                        );
                                                        return `${value} ${margin?.selling_currency.iso_code}`;
                                                    })()
                                                }
                                                )
                                            </strong>
                                        </Typography>
                                    }
                                </Stack>
                        }
                    </Grid>
                }
                {
                    editMargin &&
                    props.type === 'manual-flight' &&
                    <>
                        <Divider orientation="vertical" flexItem />
                        <Grid item>
                            <FormControl
                                size="small"
                                variant={'outlined'}
                                fullWidth
                            >
                                <InputLabel>
                                    {t('flight_search.taxe_price')}
                                </InputLabel>
                                <OutlinedInput
                                    type={'number'}
                                    onChange={(event) => onChangePriceInput('taxes', parseFloat(event.target.value))}
                                    onKeyDown={onKeyDown}
                                    label={t('flight_search.taxe_price')}
                                    value={priceInputs.taxes}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            {margin?.purchase_currency.symbol}
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </Grid>
                    </>
                }
                {
                    quotation_code === 'continentsinsolites' &&
                    <>
                        <Divider orientation="vertical" flexItem />
                        <Grid item>
                            {
                                editMargin ?
                                    <FormControl
                                        size="small"
                                        variant={'outlined'}
                                        sx={{ width: 100 }}
                                    >
                                        <InputLabel>
                                            {t('shared.commission')}
                                        </InputLabel>
                                        <OutlinedInput
                                            type={'number'}
                                            onChange={(event) => onChangePriceInput('commission', parseFloat(event.target.value))}
                                            onKeyDown={onKeyDown}
                                            label={t('shared.commission')}
                                            value={priceInputs.commission}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    %
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl> :
                                    <Typography variant="caption">
                                        {t('shared.commission')} :{' '}
                                        <strong>
                                            {priceInputs.commission} %
                                        </strong>
                                    </Typography>
                            }
                        </Grid>
                    </>
                }
                <Divider orientation="vertical" flexItem />
                <Grid item>
                    {
                        editMargin ? (
                            <FormControl
                                size="small"
                                variant={'outlined'}
                                fullWidth
                            >
                                <InputLabel htmlFor={'margin-to-in-value'}>
                                    {
                                        //eslint-disable-next-line no-nested-ternary
                                        quotation_code !== "cercledesvoyages" &&
                                            quotation_code !== "continentsinsolites" ?
                                            (
                                                user?.client_full?.type !== 2 ?
                                                    t('cart-material.margin-to-in-value') :
                                                    t('cart-material.margin-agency-in-value')
                                            ) :
                                            t('cart-material.manual-product-margin-value')
                                    }
                                </InputLabel>
                                <OutlinedInput
                                    id={'margin-to-in-value'}
                                    type={'number'}
                                    onChange={
                                        marginType === 'FIX' ?
                                            onMarginToInValueChange :
                                            onMarginToInPercentageChange
                                    }
                                    onKeyDown={onKeyDown}
                                    label={
                                        //eslint-disable-next-line no-nested-ternary
                                        quotation_code !== "cercledesvoyages" &&
                                            quotation_code !== "continentsinsolites" ?
                                            (
                                                user?.client_full?.type !== 2 ?
                                                    t('cart-material.margin-to-in-value') :
                                                    t('cart-material.margin-agency-in-value')
                                            ) :
                                            t('cart-material.manual-product-margin-value')
                                    }
                                    value={
                                        marginType === 'FIX' ?
                                            marginValue :
                                            marginPercent
                                    }
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <FormControl fullWidth>
                                                <Select
                                                    size="small"
                                                    value={marginType}
                                                    onChange={(event) => setMarginType(event.target.value as typeof marginType)}
                                                >
                                                    <MenuItem value="FIX">
                                                        {margin?.selling_currency.symbol}
                                                    </MenuItem>
                                                    <MenuItem value="PER">%</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </InputAdornment>
                                    }
                                    sx={{ paddingRight: 0 }}
                                />
                            </FormControl>
                        ) : (
                            <>
                                <Typography variant="caption">
                                    {
                                        margin?.custom_rate && (
                                            <Tooltip title={t("cart-material.manual-margin")}>
                                                <PanTool style={{ verticalAlign: "sub", marginRight: 5, cursor: "transfernter" }} />
                                            </Tooltip>
                                        )
                                    }
                                    {
                                        //eslint-disable-next-line no-nested-ternary
                                        quotation_code !== "cercledesvoyages" &&
                                            quotation_code !== "continentsinsolites" ?
                                            (
                                                user?.client_full?.type !== 2 ?
                                                    t('cart-material.margin-to-in-value') :
                                                    t('cart-material.margin-agency-in-value')
                                            ) :
                                            t('cart-material.manual-product-margin-value') + " " + currency.symbol
                                    } :{' '}
                                    <strong>
                                        {
                                            margin?.value.toLocaleString(
                                                language,
                                                { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                                            )
                                        }{' '}
                                        {margin?.selling_currency.iso_code}
                                        {' '}(
                                        {
                                            (() => {
                                                const value = margin?.percent.toLocaleString(
                                                    language,
                                                    { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                                                );
                                                return `${value} %`;
                                            })()
                                        }
                                        )
                                    </strong>
                                </Typography>
                                {
                                    user?.client_full?.type !== 2 &&
                                    props.type.includes('flight') &&
                                    <>
                                        <br />
                                        <Typography variant="caption">
                                            {t('cart-material.margin-luggage-in-value')} : <strong>
                                                {luggagePrices.margin.toLocaleString(
                                                    language,
                                                    { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                                                )} {margin?.selling_currency.iso_code}</strong>
                                        </Typography>
                                        <br />
                                        <Typography variant="caption">
                                            {t('cart-material.margin-seat-in-value')} : <strong>
                                                {seatsPrices.margin.toLocaleString(
                                                    language,
                                                    { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                                                )} {margin?.selling_currency.iso_code}</strong>
                                        </Typography>
                                    </>
                                }
                            </>
                        )
                    }
                </Grid>
                {
                    productTva !== 0 && (
                        <>
                            <Divider orientation="vertical" flexItem />
                            <Grid item>
                                <Typography variant="caption">
                                    {t('cart-material.manual-product-margin-vat-value')} :{' '}
                                    <strong>
                                        {
                                            productTva.toLocaleString(
                                                language,
                                                { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                                            )
                                        }{' '}{margin?.selling_currency.iso_code}
                                    </strong>
                                </Typography>
                            </Grid>
                        </>
                    )
                }
                {
                    user?.client_full?.type !== 2 &&
                    surcom.currency &&
                    quotation_code !== "cercledesvoyages" && quotation_code !== "continentsinsolites" && (
                        <>
                            <Divider orientation="vertical" flexItem />
                            <Grid item>
                                <Typography variant="caption">
                                    {
                                        quotation_code === 'verdie' ?
                                            t('cart-material.assistance-to-in-value') :
                                            t('cart-material.surcom-to-in-value')
                                    } :{' '}
                                    <strong>
                                        {
                                            surcom.value.toLocaleString(
                                                language,
                                                { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                                            )
                                        }{' '}
                                        {surcom.currency.iso_code}
                                        {' '}(
                                        {
                                            surcom.percent.toLocaleString(
                                                language,
                                                { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                                            )
                                        }
                                        {' '}%)
                                    </strong>
                                </Typography>
                            </Grid>
                        </>
                    )
                }
                {
                    quotation_code === 'continentsinsolites' &&
                    <>
                        <Divider orientation="vertical" flexItem />
                        <Grid item>
                            {
                                editMargin ?
                                    <FormControl
                                        size="small"
                                        variant={'outlined'}
                                        sx={{ width: 100 }}
                                    >
                                        <InputLabel>
                                            {t('shared.markup')}
                                        </InputLabel>
                                        <OutlinedInput
                                            type={'number'}
                                            onChange={(event) => onChangePriceInput('markup', parseFloat(event.target.value))}
                                            onKeyDown={onKeyDown}
                                            label={t('shared.markup')}
                                            value={priceInputs.markup}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    %
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl> :
                                    <Typography variant="caption">
                                        {t('shared.markup')} :{' '}
                                        <strong>
                                            {priceInputs.markup} %
                                        </strong>
                                    </Typography>
                            }
                        </Grid>
                    </>
                }
                <Divider orientation="vertical" flexItem />
                <Grid item>
                    {
                        editMargin &&
                            (
                                props.type.includes('manual') ||
                                (
                                    catalogPrice ||
                                    (quotation_code === 'cercledesvoyages' && (provider === null || provider?.provider.custom)) ||
                                    (!props.item.is_available && (provider === null || provider?.provider.custom))
                                ) ||
                                (
                                    props.type === 'poi' &&
                                    props.item.is_custom
                                )
                            ) ?
                            <FormControl
                                size="small"
                                variant={'outlined'}
                                fullWidth
                            >
                                <InputLabel>
                                    {
                                        t(
                                            quotation_code === 'continentsinsolites' ?
                                                'shared.selling-price-with-markup' :
                                                'shared.selling-price'
                                        )
                                    }
                                </InputLabel>
                                <OutlinedInput
                                    type={'number'}
                                    onChange={(event) => onChangePriceInput('sellingPrice', parseFloat(event.target.value) - extra)}
                                    onKeyDown={onKeyDown}
                                    label={
                                        t(
                                            quotation_code === 'continentsinsolites' ?
                                                'shared.selling-price-with-markup' :
                                                'shared.selling-price'
                                        )
                                    }
                                    value={priceInputs.sellingPrice + extra}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            {margin?.selling_currency.symbol}
                                        </InputAdornment>
                                    }
                                />
                            </FormControl> :
                            <Stack>
                                <Typography variant="caption">
                                    {
                                        t(
                                            quotation_code === 'continentsinsolites' ?
                                                'shared.selling-price-with-markup' :
                                                'shared.selling-price'
                                        )
                                    } :{' '}
                                    <strong>
                                        {
                                            ((margin?.selling ?? 0) + extra).toLocaleString(
                                                language,
                                                { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                                            )
                                        }{' '}
                                        {margin?.selling_currency.iso_code}
                                    </strong>
                                </Typography>
                                {
                                    quotation_code === 'continentsinsolites' &&
                                    <Typography variant="caption">
                                        {t('shared.selling-price-without-markup')} :{' '}
                                        <strong>
                                            {
                                                ((margin?.selling ?? 0) - ((margin?.selling ?? 0) * ((priceInputs.markup ?? 0) / 100))).toLocaleString(
                                                    language,
                                                    { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                                                )
                                            }{' '}
                                            {margin?.selling_currency.iso_code}
                                        </strong>
                                    </Typography>
                                }
                            </Stack>
                    }
                </Grid>
                {
                    editMargin ? (
                        <>
                            <Grid item>
                                <Button
                                    size="small"
                                    onClick={onCancelMargin}
                                    sx={{ marginRight: 1 }}
                                >
                                    {t('global.cancel')}
                                </Button>
                                <Button
                                    size="small"
                                    variant={'outlined'}
                                    onClick={onValidateMargin}
                                >
                                    {t('global.validate')}
                                </Button>
                            </Grid>
                        </>
                    ) : (
                        <Grid item>
                            <Button
                                size="small"
                                variant={'outlined'}
                                onClick={onEditMargin}
                            >
                                {t('global.edit')}
                            </Button>
                        </Grid>
                    )
                }
            </Grid>
            <LoadingBackDrop open={loading} />
        </>
    );
}
