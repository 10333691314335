import { TFunction } from "i18next";
import { getPoiName } from "./getPoiName";
import { useCartProducts } from "../../../Itinerary/network/cartProducts";

export function transformPoiToCalendarProducts(
    options: {
        cart: ReturnType<typeof useCartProducts>,
        dateString: string,
        locale: number | null,
        t: TFunction
    }
) {
    const date = window.moment.utc(options.dateString, "YYYY-MM-DD").startOf('day');
    const pois = options.cart.pois.filter((item) => {
        return (
            window.moment.utc(item.poi.start_date).isSame(date, 'day')
        ) &&
        !item.poi.poi_type &&
        !item.poi.is_optional;
    });

    return pois.map((item) => {
        const localization = item.type === 'normal' ?
            item.poi.custom_product?.localization?.find((item) => {
                return item.locale === options.locale;
            }) :
            undefined;
        return {
            dateOrder: window.moment.utc(item.poi.start_date).isSame(date, 'day') ?
                item.poi.start_date :
                item.poi.end_date,
            description: (item.poi.hide_hours || item.poi.custom_product?.hide_hours) ? getPoiName(item, options) : options.t(
                'roadbook.activity-description',
                {
                    hourly: window.moment.utc(item.poi.start_date).format('HH:mm'),
                    text: item.type === 'normal' ?
                        localization?.title ?? item.poi.custom_product?.title ?? '' :
                        item.poi.name
                }
            )
        };
    });
}
