/*eslint-disable no-nested-ternary */
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { Close } from "@mui/icons-material";
import {
    Dialog,
    DialogContent,
    IconButton
} from "@mui/material";
import CircuitsDetails from "../ItineraryType/CircuitsMaterial/CircuitsDetails";
import ItiTypeDetails from "../ItineraryType/ItiTypes/ItiTypeDetails";
import { getTrip } from "./utils/getTrip";
import { getCircuit } from "./utils/getCircuit";
import { BlockReducedPackage } from "./objects/blockReducedPackage";
import { Circuit } from "./objects/circuit";
import { TripBlock } from "./objects/tripBlock";
import { Trip } from "../Menu/MaterialTripList/objects/trip";
import "../../Style/GlobalAgencies.css";
import "../../Style/ItineraryType.css";
import "../../Style/Home.css";

type Props = {
    open: boolean,
    onClose: () => void
} & ({
    type: 'circuit' | 'package',
    circuit: BlockReducedPackage,
} | {
    type: 'typical-trip',
    version: number,
    trip: TripBlock
})

export function ItineraryBlockModal(props: Props): JSX.Element {
    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            maxWidth="xl"
            fullWidth
        >
            <DialogContent sx={{ padding: 0, position: 'relative' }}>
                <IconButton
                    sx={{ position: 'absolute', top: 10, right: 16, color: '#fff', zIndex: 1 }}
                    onClick={props.onClose}
                >
                    <Close />
                </IconButton>
                {
                    props.type !== 'typical-trip' &&
                    <CircuitContent
                        circuit={props.circuit}
                        onClose={props.onClose}
                    />
                }
                {
                    props.type === 'typical-trip' &&
                    <TypicalTripContent version={props.version} trip={props.trip} />
                }
            </DialogContent>
        </Dialog>
    );
}

type CircuitContentProps = {
    circuit: BlockReducedPackage,
    onClose: () => void
}

const CircuitContent = withRouter<CircuitContentProps>(
    function CircuitContent(props): JSX.Element | null {
        const [circuit, setCircuit] = useState<Circuit | null>(null);

        useEffect(() => {
            (async () => {
                setCircuit(await getCircuit(props.circuit.id));
            })();
        }, [props.circuit.id]);

        if (!circuit) {
            return null;
        }

        return (
            <CircuitsDetails
                location={props.location}
                params={{
                    ...props.params,
                    itinerary_type_id: props.circuit.id
                }}
                router={props.router}
                routes={props.routes}
                itinerary_type_details_data={circuit}
                onClose={props.onClose}
                forItinerary
            />
        );
    }
);

type TypicalTripContentProps = {
    version: number,
    trip: TripBlock
}

const TypicalTripContent = withRouter<TypicalTripContentProps>(
    function (props): JSX.Element | null {
        const [trip, setTrip] = useState<Trip | null>(null);

        useEffect(() => {
            (async () => {
                setTrip(await getTrip(props.trip.id));
            })();
        }, [props.trip.id]);

        if (!trip) {
            return null;
        }

        return (
            <ItiTypeDetails
                location={props.location}
                params={{
                    ...props.params,
                    itinerary_type_id: props.trip.id
                }}
                router={props.router}
                routes={props.routes}
                itinerary_type_details_data={trip}
                itinerary_data={props.trip.data[0]?.steps ?? []}
                contentOnly
            />
        );
    }
);
