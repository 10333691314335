import axios from "axios";
import memoizee from "memoizee";
import PinImage from "../../../Img/Pin/destination-pin.png";

type Options = {
    x: number,
    y: number,
    width: number,
    height: number,
    radius: number
}

let pin: ImageBitmap | null = null;

async function create(
    url: string,
    name: string,
    options: Options
): Promise<Blob | null> {
    const canvas = window.OffscreenCanvas ?
        new OffscreenCanvas(600, 800) :
        document.createElement('canvas');

    if (canvas instanceof HTMLCanvasElement) {
        canvas.width = 600;
        canvas.height = 800;
    }

    const context = canvas.getContext('2d') as OffscreenCanvasRenderingContext2D |
    CanvasRenderingContext2D |
    null;

    if (!pin) {
        pin = await createImage(PinImage);
    }

    const image = await createImage(url);

    if (image && pin) {
        context?.clearRect(0, 0, options.width + 8, options.height + 16);
        context?.drawImage(pin, 0, 0, options.width + 8, options.height + 16);

        context?.beginPath();
        context?.roundRect(options.x, options.y, options.width, options.height - 2, [options.radius]);
        context?.closePath();
        context?.clip();

        context?.drawImage(image, options.x, options.y, options.width, options.height - 2);
    }

    if (context) {
        context.font = 'bold 13px Roboto';
        context.fillStyle = '#fff';
    }

    context?.fillText(
        name.split(',')[0] ?? 'Unknown',
        options.x + 5, options.height - 5,
        (options.width + 8) * 2 / 3
    );

    const blob = canvas instanceof HTMLCanvasElement ?
        await new Promise<Blob | null>((resolve) => {
            canvas.toBlob((blob) => {
                resolve(blob);
            });
        }) :
        await canvas.convertToBlob();
    
    return blob;
}

async function createImage(url: string): Promise<ReturnType<typeof createImageBitmap> | null> {
    try {
        const response = await axios.get(url, { responseType: 'blob' });
        return await createImageBitmap(response.data);
    } catch (error) {
        console.error(error);
    }

    try {
        const response = await axios.get(
            '/Img/destination_default.jpg',
            { responseType: 'blob' }
        );
        return await createImageBitmap(response.data);
    } catch (error) {
        console.error(error);
    }

    return null;
}

export const createDestinationMarkerImage = memoizee(
    create,
    {
        promise: true,
        normalizer(args) {
            return `${args[0]}-${args[1]}`;
        }
    }
);
