import React, { Fragment, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles, useTheme } from "@material-ui/core";
import useMediaQuery from '@material-ui/core/useMediaQuery';

import green from "@material-ui/core/colors/green";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "@material-ui/lab/Alert";
import Pagination from "@material-ui/lab/Pagination";
import PaginationItem from "@material-ui/lab/PaginationItem";
import Settings from "@material-ui/icons/Settings";
import Search from "@material-ui/icons/Search";
import Favorite from "@material-ui/icons/Favorite";
import Add from "@material-ui/icons/Add";
import Apps from '@material-ui/icons/Apps';
import List from '@material-ui/icons/List';
import Widgets from '@material-ui/icons/Widgets';

import PoiListActivityOne from "./PoiListComponents/PoiListActivityOne";
import PoiListActivityTwo from "./PoiListComponents/PoiListActivityTwo";
import PoiListActivityThree from "./PoiListComponents/PoiListActivityThree";
import SkeletonOne from "./PoiListComponents/SkeletonOne";
import SkeletonTwo from "./PoiListComponents/SkeletonTwo";
import SkeletonThree from "./PoiListComponents/SkeletonThree";
import PoiListParameters from "./PoiListComponents/PoiListParameters";
import PoiListSorts from "./PoiListComponents/PoiListSorts";
import PoiListFilters from "./PoiListComponents/PoiListFilters";

import CheckBeforeRequest from "../Common/CheckBeforeRequest";
import GetCookie from "../Common/Functions/GetCookie";

import {
    SetSearch,
    TogglePoiRecommended,
    ToggleFilters,
    TogglePoiSorts,
    ToggleParameters,
    ChangeGripType,
    ChangeActivePage,
    SetPoiFullList,
    AddPrices,
    SetProviderFailed,
    SetProviderRunning,
    SetPricesLoaded,
    SetRequestSent
} from "../../Actions/Poi";
import { SetSelectedPage } from "../../Actions/Menu";

import axios from "axios";
import moment from "moment";
import clsx from 'clsx';
import i18n from "../../i18n";

const useStyles = makeStyles((theme) => ({
    containerFirst: {
        padding: '16px 16px 0 16px'
    },
    containerSecond: {
        padding: '0 16px 16px 16px'
    },
    containerSecondMarginBottom: {
        marginBottom: 63
    },
    settingsButton: {
        fontStyle: "italic",
        textTransform: "inherit"
    },
    filterButton: {
        textTransform: 'capitalize',
        width: '100%',
        borderRadius: 40,
        padding: '8px 16px'
    },
    filterButtonActive: {
        border: '2px solid black'
    },
    active: {
        color: green[500]
    },
    mobileWidth: {
        width: '25%',
        minWidth: 100
    },
    providerRunning: {
        width: '16px !important',
        height: '16px !important',
        float: 'left',
        marginTop: 6,
        marginRight: 8
    },
    alignCenter: {
        textAlign: "center"
    },
    burger: {
        padding: 0
    },
    recommendedWarning: {
        marginBottom: 8,
        width: '100%'
    },
    filterWarning: {
        width: '100%'
    },
    paginationItemActive: {
        outline: "5px auto -webkit-focus-ring-color"
    },
    pagination: {
        "& button:focus": {
            outline: "none !important"
        }
    }
}));

const PoiList = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));
    const smallmobile = useMediaQuery(theme.breakpoints.down('xs'));

    const traveler_groups = useSelector(state => state.trip.traveler_groups);
    const active = useSelector(state => state.base.active);
    const active_filters = useSelector(state => state.poi.active_filters);
    const active_page = useSelector(state => state.poi.active_page);
    const bounds = useSelector(state => state.poi.bounds);
    const count = useSelector(state => state.poi.count);
    const current_groups = useSelector(state => state.poi.current_groups);
    const destination = useSelector(state => state.poi.destination);
    const end_date = useSelector(state => state.poi.end_date);
    const full_list = useSelector(state => state.poi.full_list);
    const grid_type = useSelector(state => state.poi.grid_type);
    const itinerary_list = useSelector(state => state.itinerary.itinerary_list);
    const language = useSelector(state => state.header.tmp_language);
    const page_list = useSelector(state => state.poi.page_list);
    const price_list = useSelector(state => state.poi.price_list);
    const providers_running_count = useSelector(state => state.poi.providers_running_count);
    const prices_loaded = useSelector(state => state.poi.prices_loaded);
    const recommended = useSelector(state => state.poi.recommended);
    const request_sent = useSelector(state => state.poi.request_sent);
    const search = useSelector(state => state.poi.search);
    const start_date = useSelector(state => state.poi.start_date);
    const travelers = useSelector(state => state.trip.travelers);
    const trip_id = useSelector(state => state.trip.trip_id);
    const card_unmount = useSelector(state => state.poi.card_unmount);
    const poi_filter = useSelector(state => state.poi.poi_filter);
    const eco_filter = useSelector(state => state.poi.eco_filter);
    const nugget_filter = useSelector(state => state.poi.nugget_filter);
    const providers_list = useSelector(state => state.trip.providers);
    const all_data = useSelector(state => state.trip.all_data);
    const manual_providers = useSelector(state => state.trip.manual_providers);
    const locales = useSelector(state => state.user.locales);

    const current_locale = locales.find((el) => {
        return el.language_code === i18n.language;
    });

    const [providers, setProviders] = useState({});

    const poiListRef = useRef(null);

    let search_timeout = null;

    useEffect(() => {
        dispatch(SetSelectedPage("poi"));
        //on page refresh
        if ((active === null || isNaN(active)) && itinerary_list.length > 1) {
            let active_bounds = JSON.parse(itinerary_list[1].destination.data.bounds.replace(/'/g, '"'));

            let { pass_check, headers } = CheckBeforeRequest();
            if (pass_check) {
                let new_current_groups = [];

                let travelers_id = [];
                travelers.map((traveler) => {
                    travelers_id.push(traveler.id);
                });

                //create group if group with all travelers not found
                let request = {
                    default: false,
                    travelers: travelers_id
                };

                axios({
                    method: "POST",
                    headers: headers,
                    url: `${API_HREF}client/${window.id_owner}/trip/${trip_id}/versions/${GetCookie("trip_id_version")}/travelers-group/?token=${GetCookie("trip_token")}`,
                    data: request
                }).then(function (response) {
                    new_current_groups.push(response.data);
                });
                dispatch({
                    type: "POI_SET_BASIC_DATA",
                    payload: {
                        active: itinerary_list[1].id,
                        destination: itinerary_list[1],
                        current_groups: new_current_groups,
                        start_date: moment.utc(itinerary_list[1].start_date).format('YYYY-MM-DD'),
                        end_date: moment.utc(itinerary_list[1].end_date).format('YYYY-MM-DD'),
                        bounds: {
                            north: active_bounds.north,
                            east: active_bounds.east,
                            south: active_bounds.south,
                            west: active_bounds.west
                        }
                    }
                });
            }
        }
    }, []);
    useEffect(() => {
        if (price_list.length !== 0) {
            let new_page_list = [];
            //filter by title or localization
            price_list.map((poi) => {
                if (poi.localization.length !== 0) {
                    let displayed_text = poi.title;
                    for (let i = 0; i < poi.localization.length; i++) {
                        if (poi.localization[i].locale === current_locale.id) {
                            if (poi.localization[i].title) {
                                displayed_text = poi.localization[i].title;
                            }
                        }
                    }
                    if (displayed_text.match(new RegExp(search, 'i'))) {
                        new_page_list.push(poi);
                    }
                } else if (poi.title.match(new RegExp(search, 'i'))) {
                    new_page_list.push(poi);
                }
            });
            dispatch({ type: 'POI_SET_LIST', payload: new_page_list });
        }
    }, [search, price_list]);
    useEffect(() => {
        if (bounds !== null) {
            if (!card_unmount) {
                getActivities(500);
                dispatch(SetPricesLoaded(false));
            }
        }
    }, [bounds]);

    useEffect(() => {
        if (full_list.length !== 0) {
            let temp_providers = {};
            let no_provider = [];
            full_list.map((activity) => {
                let connected_providers = [];
                if (activity.providers?.length === 1) {
                    connected_providers = providers_list.filter(provider => provider.id === activity.providers[0].id);
                }
                if (connected_providers.length === 0 || connected_providers.find(provider => provider.branch_code === all_data.branch_code)) {
                    if (activity.provider === null && (activity.providers === undefined || activity.providers === null || activity.providers.length === 0)) {
                        no_provider.push(
                            {
                                prices: [],
                                product_id: activity.id,
                                catalog_price: false,
                                poi_type: false,
                                number_product_model: false,
                                max_pax: null,
                                is_price_estimated: false
                            }
                        );
                        return;
                    }
                    if (activity.is_custom && activity.providers !== undefined && activity.providers.length > 0) {
                        activity.providers.map((provider) => {
                            let find_provider = manual_providers.find((manual_provider) => manual_provider.id === provider.id);
                            if (find_provider !== undefined && find_provider !== null) {
                                if (temp_providers.hasOwnProperty(find_provider.provider.name)) {
                                    let temp_provider = Object.assign({}, temp_providers[find_provider.provider.name]);
                                    if (!temp_provider.identifiers.includes(activity.id)) {
                                        temp_provider.identifiers.push(activity.id);
                                    }
                                } else {
                                    let temp_provider = {
                                        id: provider.provider_id,
                                        identifiers: [activity.id],
                                        is_custom: activity.is_custom
                                    };
                                    temp_providers[find_provider.provider.name] = temp_provider;
                                }
                            }
                        });
                    } else if (!activity.is_custom && temp_providers.hasOwnProperty(activity.provider.name)) {
                        let temp_provider = Object.assign({}, temp_providers[activity.provider.name]);
                        temp_provider.identifiers.push(activity.product_code);
                    } else if (activity.is_custom && activity.provider !== undefined && activity.provider !== null && temp_providers.hasOwnProperty(activity.provider.provider.name)) {
                        let temp_provider = Object.assign({}, temp_providers[activity.provider.provider.name]);
                        temp_provider.identifiers.push(activity.id);
                    } else {
                        let temp_provider = {
                            id: activity.is_custom ? (activity.provider !== null ? activity.provider.provider.id : activity.providers[0]) : activity.provider.id,
                            identifiers: [activity.is_custom ? activity.id : activity.product_code],
                            is_custom: activity.is_custom
                        };
                        if (activity.is_custom) {
                            if (activity.provider !== null) {
                                temp_providers[activity.provider.provider.name] = temp_provider;
                            }
                        } else {
                            temp_providers[activity.provider.name] = temp_provider;
                        }
                    }
                }
            });
            dispatch(AddPrices(no_provider, null));
            setProviders(temp_providers);
        }
    }, [full_list]);

    useEffect(() => {
        if (Object.keys(providers).length !== 0 && current_groups !== null && prices_loaded === false) {
            let { pass_check, headers } = CheckBeforeRequest();
            if (pass_check) {
                Object.keys(providers).map((provider_name) => {
                    dispatch(SetProviderRunning());
                    let provider = providers[provider_name];
                    let custom_provider = null;
                    let groups_ids = [];
                    current_groups.map((group) => {
                        groups_ids.push(group.id);
                    });
                    if (provider.is_custom) {
                        custom_provider = manual_providers.find((manual_provider) => manual_provider.provider.id === provider.id);
                    }
                    let custom_activities = provider.identifiers.filter(id => typeof id === 'number');
                    let connected_activities = provider.identifiers.filter(id => typeof id === 'string');
                    if (custom_activities.length > 0) {
                        axios({
                            method: "POST",
                            url: `${API_HREF}client/${window.id_owner}/custom-products/minimal_price/?custom_provider=true&arrival_date=${start_date}&departure_date=${end_date}&start_destination=${destination.destination.id}&provider_id=${provider.id}&comps=${groups_ids}&trip=${trip_id}&language=${language}`,
                            headers: headers,
                            data: JSON.stringify({
                                product_identifiers: custom_activities
                            })
                        }).then(function (response) {
                            dispatch(AddPrices(response.data, custom_provider));
                        }).catch(function (error) {
                            console.log(error);
                            dispatch(SetProviderFailed());
                        });
                    }
                    if (connected_activities.length > 0) {
                        axios({
                            method: "POST",
                            url: `${API_HREF}client/${window.id_owner}/custom-products/minimal_price/?custom_provider=false&arrival_date=${start_date}&departure_date=${end_date}&start_destination=${destination.destination.id}&provider_id=${provider.id}&comps=${groups_ids}&trip=${trip_id}&language=${language}`,
                            headers: headers,
                            data: JSON.stringify({
                                product_identifiers: connected_activities
                            })
                        }).then(function (response) {
                            dispatch(AddPrices(response.data, custom_provider));
                        }).catch(function (error) {
                            console.log(error);
                            dispatch(SetProviderFailed());
                        });
                    }
                });
                dispatch(SetPricesLoaded(true));
            }
        }
    }, [providers, current_groups, active, start_date, end_date]);
    //
    //useEffect(() => {
    //if (card_unmount) {
    //dispatch(SetPricesLoaded(true));
    //} else {
    //itinerary_list.map((itinerary_step) => {
    //if (itinerary_step.step_type === "STEP" && itinerary_step.id === active) {
    //let active_bounds = JSON.parse(itinerary_step.destination.data.bounds.replace(/'/g, '"'));
    //let new_current_groups = [];
    //if (current_groups !== null && current_groups.length !== 0) {
    //new_current_groups = current_groups;
    //} else {
    //let travelers_id = [];
    //travelers.map((traveler) => {
    //travelers_id.push(traveler.id);
    //});
    //
    ////create group if group with all travelers not found
    //let { pass_check, headers } = CheckBeforeRequest();
    //if (pass_check) {
    //let request = {
    //default: false,
    //travelers: travelers_id
    //};
    //axios({
    //method: "POST",
    //headers: headers,
    //url: `${API_HREF}client/${window.id_owner}/trip/${trip_id}/versions/${GetCookie("trip_id_version")}/travelers-group/?token=${GetCookie("trip_token")}`,
    //data: request
    //}).then(function (response) {
    //new_current_groups.push(response.data);
    //});
    //}
    //}
    //dispatch({
    //type: "POI_SET_BASIC_DATA",
    //payload: {
    //active: itinerary_step.id,
    //destination: itinerary_step,
    //current_groups: new_current_groups,
    //start_date: moment.utc(itinerary_step.start_date).format('YYYY-MM-DD'),
    //end_date: moment.utc(itinerary_step.end_date).format('YYYY-MM-DD'),
    //bounds: {
    //north: active_bounds.north,
    //east: active_bounds.east,
    //south: active_bounds.south,
    //west: active_bounds.west
    //}
    //}
    //});
    //}
    //});
    //}
    //}, [itinerary_list, active]);
    useEffect(() => {
        if (
            destination === null ||
            current_groups === null ||
            start_date === null ||
            end_date === null ||
            (bounds === null && fullScreen)
        ) {
            let active_itinerary_step = destination;
            let new_current_groups = current_groups;
            if (active_itinerary_step === null) {
                for (let i = 0; i < itinerary_list.length; i++) {
                    if (itinerary_list[i].step_type === "STEP") {
                        if (active_itinerary_step === null) {
                            active_itinerary_step = itinerary_list[i];
                        }
                        if (itinerary_list[i].id === active) {
                            active_itinerary_step = itinerary_list[i];
                            break;
                        }
                    }
                }
            }
            if (new_current_groups === null) {
                new_current_groups = [];
                traveler_groups.map((traveler_group) => {
                    if (traveler_group.default) {
                        new_current_groups.push({ ...traveler_group });
                    }
                });
            }
            const active_bounds = JSON.parse(active_itinerary_step.destination.data.bounds.replace(/'/g, '"'));
            dispatch({
                type: "POI_SET_BASIC_DATA",
                payload: {
                    active: active_itinerary_step.id,
                    destination: active_itinerary_step,
                    current_groups: new_current_groups,
                    start_date: (start_date !== null ? start_date : moment.utc(active_itinerary_step.start_date).format("YYYY-MM-DD")),
                    end_date: (end_date !== null ? end_date : moment.utc(active_itinerary_step.end_date).format("YYYY-MM-DD")),
                    bounds: fullScreen ? {
                        north: active_bounds.north,
                        east: active_bounds.east,
                        south: active_bounds.south,
                        west: active_bounds.west
                    } : null
                }
            });
        }
    });
    const setSearch = elem => {
        clearTimeout(search_timeout);
        elem.persist();
        search_timeout = setTimeout(() => {
            dispatch(SetSearch(elem.target.value));
        }, 250);
    };

    const toggleParameters = () => {
        dispatch(ToggleParameters());
    };

    const toggleRecommended = () => {
        dispatch(TogglePoiRecommended());
    };

    const toggleFilters = () => {
        dispatch(ToggleFilters());
    };

    const toggleSorts = () => {
        dispatch(TogglePoiSorts());
    };

    const togglePoiFilter = () => {
        dispatch({ type: "POI_TOGGLE_POI_FILTER", payload: !poi_filter });
    };
    const toggleEcoFilter = () => {
        dispatch({ type: "POI_TOGGLE_ECO_FILTER", payload: !eco_filter });
    };
    const toggleNugget = () => {
        dispatch({ type: "POI_TOGGLE_NUGGET_FILTER", payload: !nugget_filter });
    };
    const onGridTypeChange = () => {
        let new_grid_type = grid_type + 1;
        new_grid_type = new_grid_type > 4 ? 1 : new_grid_type;
        dispatch(ChangeGripType(new_grid_type));
    };

    const setActivePage = (elem, page) => {
        poiListRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
        dispatch(ChangeActivePage(page));
    };

    const getActivities = limit => {
        let { pass_check, headers } = CheckBeforeRequest();
        if (pass_check) {
            axios({
                method: "GET",
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/custom-products/search/`,
                params: {
                    limit: limit,
                    format: "json",
                    latitude_max: bounds.north.toFixed(6),
                    longitude_max: bounds.east.toFixed(6),
                    latitude_min: bounds.south.toFixed(6),
                    longitude_min: bounds.west.toFixed(6),
                    search: search !== null ? search : undefined,
                    trip: trip_id
                }
            }).then((response) => {
                if (response.data.results.length > 0) {
                    dispatch(SetPoiFullList(response.data.results));
                }
                dispatch(SetRequestSent(true));
            }).catch((error) => {
                console.log(error);
            });
        }
    };

    useEffect(() => {
        const element = document.getElementById('activity-search');
        if (element && element.value !== search) {
            element.value = search;
        }
    }, [search]);

    return (
        <Fragment>
            <Grid className={ classes.containerFirst } container spacing={ 1 }>
                <Button className={ `${classes.settingsButton}` } endIcon={ <Settings/> } onClick={ toggleParameters }>
                    <Typography variant={ "body2" }>{ t("global.from") } { moment.utc(start_date).format("DD MMMM") } { t("global.to") } { moment.utc(end_date).format("DD MMMM") } </Typography>
                </Button>
                <Grid item xs={ 12 }>
                    <Typography variant={ "h5" }>{ t("poi.different_destination_poi") } : { (destination !== undefined && destination !== null && destination.destination !== undefined && destination.destination !== null && destination.destination.data !== undefined && destination.destination.data !== null ? destination.destination.data.name !== undefined ? destination.destination.data.name : destination.destination.data.international_name : "") }</Typography>
                </Grid>
            </Grid>
            <Grid ref={poiListRef} className={ clsx(classes.containerSecond, { [classes.containerSecondMarginBottom]: mobile }) } container justify={ "space-between" } spacing={ 1 }>
                <Grid item container alignItems={ "center" } spacing={ 2 } xs>
                    <Grid item xs={ smallmobile ? 12 : 'auto' }>
                        <TextField defaultValue={ search } label={ t("global.search") } id={ "activity-search" } fullWidth={ smallmobile } size={ 'small' } InputProps={{ startAdornment: <InputAdornment position={ "start" }><Search/></InputAdornment> }} variant={ "outlined" } placeholder={ t("poi.poi_name") } onChange={ setSearch }/>
                    </Grid>
                    <Grid item>
                        <Button className={ `${classes.filterButton}${recommended ? ` ${classes.active}` : ""}` } variant={ "outlined" } size={ 'small' } startIcon={ <Favorite/> } onClick={ toggleRecommended }>{ t("poi.recommended") }</Button>
                    </Grid>
                    <Grid item className={ clsx({ [classes.mobileWidth]: mobile })}>
                        <Button className={ clsx(classes.filterButton, { [classes.filterButtonActive]: active_filters > 0 }) } variant={ "outlined" } size={ 'small' } startIcon={ <Add/> } onClick={ toggleFilters }>{ t("poi.filters") } { active_filters > 0 ? ` - ${active_filters}` : '' }</Button>
                    </Grid>
                    <Grid item className={ clsx({ [classes.mobileWidth]: mobile })}>
                        <Button className={ classes.filterButton } variant={ "outlined" } size={ 'small' } onClick={ toggleSorts }>{ t("poi.sort") }</Button>
                    </Grid>                    
                    {/* <Grid item className={ clsx({ [classes.mobileWidth]: mobile })}>
                        <Button className={ clsx(classes.filterButton, { [classes.filterButtonActive]: poi_filter }) } variant={ "outlined" } size={ 'small' } onClick={ togglePoiFilter }>{ 'Poi' }</Button>
                    </Grid>
                    <Grid item className={ clsx({ [classes.mobileWidth]: mobile })}>
                        <Button className={ clsx(classes.filterButton, { [classes.filterButtonActive]: eco_filter }) } variant={ "outlined" } size={ 'small' } onClick={ toggleEcoFilter }>{ t('poi.eco_filter') }</Button>
                    </Grid>
                    <Grid item className={ clsx({ [classes.mobileWidth]: mobile })}>
                        <Button className={ clsx(classes.filterButton, { [classes.filterButtonActive]: nugget_filter }) } variant={ "outlined" } size={ 'small' } onClick={ toggleNugget }>{ t('shared.pepite') }</Button>
                    </Grid> */}
                    {
                        mobile &&
                        <Grid className={ classes.alignRight } item xs>
                            { (prices_loaded === false || providers_running_count > 0) && <CircularProgress className={ classes.providerRunning }/> }
                            <Typography variant={ "caption" }>{ count } {t("poi.results")}</Typography>
                        </Grid>
                    }
                </Grid>
                {
                    !mobile &&
                    <Grid item className={ classes.alignCenter }>
                        <IconButton className={ classes.burger } onClick={onGridTypeChange}>
                            { grid_type === 1 ? <List/> : grid_type === 2 ? <Widgets/> : grid_type === 3 ? <Apps/> : <List/> }
                        </IconButton>
                        <br/>
                        { (prices_loaded === false || providers_running_count > 0) && <CircularProgress className={ classes.providerRunning }/> }
                        <Typography variant={ "caption" }>{ count } {t("poi.results")}</Typography>
                    </Grid>
                }
                <Grid item xs={ 12 }>
                    <Divider/>
                </Grid>
                {
                    recommended &&
                    <Grid item xs={ 12 }>
                        <Alert className={ classes.recommendedWarning } severity={ "warning" } variant={ "filled" }>{ t("poi.recommended_warning") }</Alert>
                        <Divider/>
                    </Grid>
                }
                {
                    start_date !== null && moment.utc(start_date).isBefore(moment()) &&
                    <Grid item xs={ 12 }>
                        <Alert className={ classes.recommendedWarning } severity={ "warning" } variant={ "filled" }>{ t("poi.past_date_warning") }</Alert>
                        <Divider/>
                    </Grid>
                }
                <Grid item container xs={ 12 }>
                    { page_list.length === 0 && providers_running_count > 0 ? grid_type === 1 ? <SkeletonOne/> : grid_type === 2 ? <SkeletonThree/> : <SkeletonTwo/> :
                        <Fragment>
                            {
                                page_list.length > 0 ? (
                                    <Fragment>
                                        {
                                            page_list.map((item, index_item) => {
                                                return (
                                                    <Fragment key={ index_item }>
                                                        { (grid_type === 1 || grid_type === 2) && index_item > 0 && <Divider/> }
                                                        {
                                                            grid_type === 1 && (
                                                                <PoiListActivityOne index={ index_item } activity={ item } router={ props.router } />
                                                            )
                                                        }
                                                        {
                                                            grid_type === 2 && (
                                                                <PoiListActivityThree index={ index_item } activity={ item } router={ props.router } />
                                                            )
                                                        }
                                                        {
                                                            (grid_type === 3 || grid_type === 4) && (
                                                                <PoiListActivityTwo index={ index_item } activity={ item } router={ props.router } />
                                                            )
                                                        }
                                                    </Fragment>
                                                );
                                            })
                                        }
                                    </Fragment>
                                ) : request_sent && <Alert className={ classes.filterWarning } severity={ "warning" } variant={ "filled" }>{ full_list.length !== 0 ? t("poi.no_filter_message") : t("poi.no_activity_found") }</Alert>
                            }
                        </Fragment>
                    }
                </Grid>
                <Grid item xs={ 12 }>
                    <Divider className={ classes.marginBottom }/>
                </Grid>
                <Grid item xs={ 12 } container justify={ "center" }>
                    <Grid item>
                        <Pagination className={classes.pagination} count={ Math.ceil(count / 30) } variant={ "outlined" } hideNextButton={ (page_list === null || page_list.length === 0 || active_page === Math.ceil(count / 30)) } hidePrevButton={ active_page === 1 } disabled={ page_list === null || page_list.length === 0 } onChange={ setActivePage } renderItem={(item) => <PaginationItem className={ `${item.selected ? classes.paginationItemActive : ""}` } {...item}/>}/>
                    </Grid>
                </Grid>
            </Grid>
            <PoiListFilters />
            <PoiListSorts />
            { current_groups !== null && current_groups.length !== 0 && start_date !== null && end_date !== null && <PoiListParameters/> }
        </Fragment>
    );
};

export default React.memo(PoiList);
