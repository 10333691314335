import { useProviderQuotationProducts } from "../../ProviderQuotation/utils/providerQuotationProducts";
import {
    makeAccommodationEditRequest,
    makeCarEditRequest,
    makeCustomProductEditRequest,
    makeFlightEditRequest,
    makeManualProductEditRequest,
    makeTransferEditRequest
} from "../../CartMaterial/utils/editProductRequests";

type Product = {
    id: number,
    isCustom: boolean,
    type: ReturnType<typeof useProviderQuotationProducts>[number]['type'],
    providerComment: string | null
}

type Callback = (
    product: Product,
    options: {
        tripId: number,
        version: number,
        providerId: number
    }
) => Promise<void>

type Options = Partial<{
    onTrigger: () => void,
    onSuccess: (product: Product) => void,
    onError: (error: Error) => void,
    onFinally: () => void
}>

export function useProviderBookingChangeComment(options: Options): Callback {
    return async (item, requestOptions) => {
        try {
            if (options.onTrigger) {
                options.onTrigger();
            }
            let product: Product | null = null;
            if (
                (
                    item.type === 'transfers' ||
                    item.type === 'pois'
                ) &&
                item.isCustom
            ) {
                product = await makeCustomProductEditRequest({
                    ...requestOptions,
                    ...item,
                    data: {
                        provider_booking_comment: (item.providerComment?.trim().length ?? 0) === 0 ?
                            null :
                            item.providerComment
                    }
                }) as Product | null;
            } else {
                switch (item.type) {
                    case 'flights': {
                        product = await makeFlightEditRequest({
                            ...requestOptions,
                            ...item,
                            data: {
                                provider_booking_comment: (item.providerComment?.trim().length ?? 0) === 0 ?
                                    null :
                                    item.providerComment
                            }
                        }) as Product | null;
                        break;
                    }
                    case 'cars': {
                        product = await makeCarEditRequest({
                            ...requestOptions,
                            ...item,
                            data: {
                                provider_booking_comment: (item.providerComment?.trim().length ?? 0) === 0 ?
                                    null :
                                    item.providerComment
                            }
                        }) as Product | null;
                        break;
                    }
                    case 'accommodations': {
                        product = await makeAccommodationEditRequest({
                            ...requestOptions,
                            ...item,
                            data: {
                                provider_booking_comment: (item.providerComment?.trim().length ?? 0) === 0 ?
                                    null :
                                    item.providerComment
                            }
                        }) as Product | null;
                        break;
                    }
                    case 'transfers': {
                        product = await makeTransferEditRequest({
                            ...requestOptions,
                            ...item,
                            data: {
                                provider_booking_comment: (item.providerComment?.trim().length ?? 0) === 0 ?
                                    null :
                                    item.providerComment
                            }
                        }) as Product | null;
                        break;
                    }
                    case 'pois': {
                        product = await makeCustomProductEditRequest({
                            ...requestOptions,
                            ...item,
                            data: {
                                provider_booking_comment: (item.providerComment?.trim().length ?? 0) === 0 ?
                                    null :
                                    item.providerComment
                            }
                        }) as Product | null;
                        break;
                    }
                    default: {
                        product = await makeManualProductEditRequest({
                            ...requestOptions,
                            ...item,
                            data: {
                                provider_booking_comment: (item.providerComment?.trim().length ?? 0) === 0 ?
                                    null :
                                    item.providerComment
                            }
                        }) as Product | null;
                        break;
                    }
                }
            }
            if (options.onSuccess && product) {
                options.onSuccess(product);
            }
        } catch (error: any) {
            if (options.onError) {
                options.onError(error);
            }
        } finally {
            if (options.onFinally) {
                options.onFinally();
            }
        }
    };
}
