//---- Dependencies ----//
import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Link, History } from "react-router";
import clsx from "clsx";

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';


import SearchIcon from '@material-ui/icons/Search';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import TuneOutlinedIcon from '@material-ui/icons/TuneOutlined';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined';
import AddCircleOutlinedIcon from '@material-ui/icons/AddCircleOutlined';

import HeaderDatePicker from "./HeaderDatePicker.js";
import HeaderPassenger from "./HeaderPassenger.js";
import ReloadCartModalNew from "../Itinerary/ReloadCartModalNew.js";
import AutomaticProductUpdate from "./AutomaticProductUpdate.js";
import TripBudget from "./TripBudget.js";
import CartHeader from "./CartHeader.js";
import AddNewVersion from "./AddNewVersion.js";
import RenderFileNb from "./RenderFileNb.js";
import AvatarHeader from "./AvatarHeader.js";
import MobileHeader from "./MobileHeader.js";
import HeaderTripName from "./HeaderTripName.js";
import SetCookie from "../Common/Functions/SetCookie.js";
import GetCookie from "../Common/Functions/GetCookie.js";
import CheckBeforeRequest from "../Common/CheckBeforeRequest";
import Axios from "axios";
import { ToShowVersion } from "../../Actions/Menu.js";

const useStyles = makeStyles(theme => ({
    root: {
        height: "100%"
    },
    headerContainer: {
        top: "0px !important",
        height: "70px !important",
        backgroundColor: "white",
        textAlign: "center",
        //boxShadow: "0 1px 4px rgba(0,0,0,0.3)",
        position: "fixed !important",
        left: "0px !important",
        width: "100% !important",
        zIndex: "1000 !important",
        borderBottom: "1px solid rgba(0, 0, 0, 0.08)"
    },
    homeHeader: {
        backgroundColor: "transparent",
        boxShadow: "none",
        borderBottom: 'none'
    },
    stickyHeader: {
        position: "fixed",
        top: 0,
        width: "100%",
        height: 70,
        background: "white",
        zIndex: 100,
        transition: "all .2s ease-out",
        borderBottom: "1px solid rgba(0, 0, 0, 0.08)"
    },
    logo: {
        //margin: '4px 12px'
        marginTop: 4
    },
    leftSideContainer: {
        display: 'inline-flex',
        borderRadius: 22
        //boxShadow: '0px 8px 16px rgb(0 0 0 / 15%), 0px 3px 8px rgb(0 0 0 / 10%) !important'/*'0px 16px 32px rgb(0 0 0 / 15%), 0px 3px 8px rgb(0 0 0 / 10%) !important'*/
    },
    inlineFlex: {
        display: 'inline-flex'
    },
    homeLink: {
        display: "inline-block",
        padding: 15,
        float: "left"
    },
    separator: {
        borderLeft: "1px solid rgba(0, 0, 0, 0.23)",
    	display: "inline",
        position: "absolute",
        left: 0,
        top: "25%",
        bottom: "25%"
    },
    budgetContainer: {
        width: '150px !important',
        maxWidth: '150px !important'
    },
    budgetContainerResponsive: {
        width: '150px !important',
        maxWidth: '91px !important'
    },
    budgetContainer1: {
        width: '150px !important',
        maxWidth: '250px !important'
    },
    budgetContainer1Responsive: {
        width: '150px !important',
        maxWidth: '150px !important'
    },
    soloButton: {
        borderRadius: 20
    },
    orange: {
        color: "#E6592F !important"
    },
    mobileContainer: {
        width: "100% !important",
        height: "48px !important",
        display: "inline-flex !important",
        boxShadow: "rgba(0, 0, 0, 0.2) 0px 6px 20px !important",
        color: "rgb(34, 34, 34) !important",
        background: "padding-box rgb(255, 255, 255) !important",
        borderColor: "rgba(0, 0, 0, 0.04) !important",
        borderRadius: "24px !important",
        borderStyle: "solid !important",
        borderWidth: "1px !important"
    },
    leftSide: {
        WebkitBoxPack: "center !important",
        WebkitBoxAlign: "center !important",
        flexBasis: "48px !important",
        flexShrink: "0 !important",
        minWidth: "0px !important",
        display: "flex !important",
        alignItems: "center !important",
        justifyContent: "center !important"
    },
    leftButton: {
        WebkitBoxAlign: "center !important",
        appearance: "none !important",
        display: "inline-flex !important",
        borderRadius: "50% !important",
        border: "1px solid rgba(0, 0, 0, 0.03) !important",
        outline: "0px !important",
        margin: "0px !important",
        padding: "0px !important",
        color: "rgb(34, 34, 34) !important",
        backgroundColor: "rgb(255, 255, 255) !important",
        cursor: "pointer !important",
        touchAction: "manipulation !important",
        alignItems: "center !important",
        justifyContent: "center !important",
        transition: "box-shadow 0.2s ease 0s, -ms-transform 0.25s ease 0s, -webkit-transform 0.25s ease 0s, transform 0.25s ease 0s !important",
        width: "44px !important",
        height: "44px !important",
        backgroundClip: "padding-box !important",
        boxShadow: "rgb(0 0 0 / 15%) 0px 5px 12px !important"
    },
    middleSide: {
        WebkitBoxFlex: "1 !important",
  		flexGrow: "1 !important"
    },
    middleButton: {
        cursor: "pointer !important",
        position: "relative !important",
        touchAction: "manipulation !important",
        fontFamily: "inherit !important",
        fontSize: "inherit !important",
        lineHeight: "inherit !important",
        fontWeight: "inherit !important",
        borderRadius: "0px !important",
        outline: "none !important",
        transition: "box-shadow 0.2s ease 0s, -ms-transform 0.1s ease 0s, -webkit-transform 0.1s ease 0s, transform 0.1s ease 0s !important",
        background: "transparent !important",
        border: "none !important",
        color: "inherit !important",
        display: "block !important",
        margin: "0px !important",
        padding: "0px !important",
        textAlign: "inherit !important",
        textDecoration: "none !important",
        height: "100% !important",
        width: "100% !important"
    },
    middleContainer: {
        WebkitBoxAlign: "center !important",
        alignItems: "center !important",
        display: "flex !important",
        paddingRight: "12px !important",
        borderRight: "1px solid rgba(0, 0, 0, 0.15) !important"
    },
    leftIcon: {
        WebkitBoxFlex: "1 !important",
        fontSize: "22px !important",
        lineHeight: "18px !important",
        fontWeight: "600 !important",
        flexGrow: "1 !important",
        paddingRight: "4px !important"
    },
    rightIcon: {
        fontSize: "14px !important",
        //lineHeight: "18px !important",
        maxHeight: "18px !important",
        overflow: "hidden !important",
        //display: "-webkit-box !important",
        color: "rgb(113, 113, 113) !important",
        fontWeight: "600 !important",
        textAlign: "center !important",
        flexGrow: "1 !important",
        textTransform: "capitalize"
    },
    rightSide: {
        flexBasis: "48px !important",
        flexShrink: "0 !important",
        minWidth: "48px !important"
    },
    rightButton: {
        WebkitBoxAlign: "center !important",
        WebkitBoxPack: "center !important",
        cursor: "pointer !important",
        position: "relative !important",
        touchAction: "manipulation !important",
        fontFamily: "inherit !important",
        fontSize: "inherit !important",
        lineHeight: "inherit !important",
        fontWeight: "inherit !important",
        borderRadius: "0px 24px 24px 0px !important",
        outline: "none !important",
        transition: "box-shadow 0.2s ease 0s, -ms-transform 0.1s ease 0s, -webkit-transform 0.1s ease 0s, transform 0.1s ease 0s !important",
        background: "transparent !important",
        border: "none !important",
        color: "inherit !important",
        display: "inline-flex !important",
        margin: "0px !important",
        padding: "0px 8px 0px 0px !important",
        textAlign: "inherit !important",
        textDecoration: "none !important",
        height: "100% !important",
        width: "100% !important",
        justifyContent: "center !important",
        alignItems: "center !important"
    },
    staging: {
        backgroundColor: '#ffcc80'
    },
    tripName: {
        maxWidth: '15% !important'
    }
}));
const MaterialHeader = ({ router }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const isTablet = useMediaQuery(theme.breakpoints.between(600, 1050));
    const smallScreen = useMediaQuery(theme.breakpoints.between(960, 1258));
    const downDesktop = useMediaQuery(theme.breakpoints.down(1650));
    const matches = useMediaQuery(theme.breakpoints.down(1050));
    const config = JSON.parse(localStorage.getItem("config"));

    const [trip_displayed_version, setVersion] = useState(`${GetCookie('trip_id_version') !== null ? GetCookie('trip_id_version') : trip_info.current_version}`);
    const [toRender, setToRender] = useState(false);
    const [isOpen, setIsOpen] = useState(null);
    const [mobileOpen, setMobileOpen] = useState(null);
    const [logo] = useState(config !== null && config.logo !== undefined && config.logo !== null && config.logo.url !== undefined && config.logo.url !== null ? config.logo : null);
    const data_trip = useSelector((store) => {
        return store.trip.data_trip;
    });
    const all_data = useSelector((store) => {
        return store.trip.all_data;
    });
    const start_date = useSelector((store) => {
        return store.trip.start_date;
    });
    const end_date = useSelector((store) => {
        return store.trip.end_date;
    });
    let currentLocation = router.getCurrentLocation().pathname;

    useEffect(() => {
        getScroll();
        return () => {
            setToRender(false);
        };
    }, []);
    //useEffect(() => {
    //getScroll();
    //}, [currentLocation]);
    const getScroll = () => {
        if (currentLocation.includes("/home") || currentLocation.includes("/destination-product") || currentLocation.includes("/packages-details") || currentLocation.includes("/circuits-details") || currentLocation.includes("/itinerary-type-details")) {
            let homePicture = document.getElementById("home-picture");
            window.onscroll = function () {
                if (window.pageYOffset > homePicture.offsetHeight) {
                    setToRender(true);
                } else {
                    setToRender(false);
                }
            };
        }
    };
    const checkUrl = () => {
        if (currentLocation.includes("/home") || currentLocation.includes("/destination-product") || currentLocation.includes("/packages-details") || currentLocation.includes("/circuits-details") || currentLocation.includes("/itinerary-type-details")) {
            return true;
        }
        return false;
    };
    const handleVersion = (event) => {
        let new_version_id = event.target.value;
        setVersion(new_version_id);
    	SetCookie("trip_id_version", new_version_id, 365, `/`, (window.location.host === "localhost:3000" ? null : window.location.host));

        let { pass_check, headers } = CheckBeforeRequest();

        if (pass_check) {
            Axios({
                method: "GET",
                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${new_version_id}/`,
                headers: headers
            }).then(function (response) {
                dispatch({
                    type: "TRIP_SET_DATA_TRIP",
                    payload: {
                        data_trip: response.data
                    }
                });
            }).catch(function (error) {
                console.log(error.responseJSON);
            });
        }
    };
    return (
        <div id={"header"} className={clsx(classes.headerContainer, { [classes.homeHeader]: checkUrl(), [classes.stickyHeader]: toRender, [classes.staging]: document.location.hostname.split('.')[2] === 'fr' && document.location.hostname !== 'test-aws.facilitatrip.fr' })}>
            <Grid container className={classes.root} alignItems={"center"} direction={"row"} justify={ data_trip !== null ? "space-between" : "flex-start" } style={{ paddingRight: 16, paddingLeft: 16 }}>
                {
                    data_trip !== null && all_data !== null && !checkUrl() && (
                        <Fragment>
                            {
                                !matches && (
                                    <Fragment>
                                        <Grid item container justify={ "flex-start" } xs spacing={2}>
                                            <Grid className={ classes.logo } item>
                                                <Link to={ `/home/${window.url_name}/` }>
                                                    <img src={ logo !== null ? (logo.thumbnail_little !== null ? logo.thumbnail_little : logo.url) : "/Img/inner_logo_header.png" } height={ 30 } alt={ "" }/>
                                                </Link>
                                            </Grid>
                                            <Grid item className={ `${classes.genericText} ${classes.leftSideContainer}` }>
                                                {
                                                    //<Grid item className={ classes.inlineFlex }>
                                                    //<DestinationSearch setIsOpen={setIsOpen} isOpen={isOpen}/>
                                                    //</Grid>
                                                }
                                                <Grid item className={ classes.inlineFlex } style={{ position: "relative" }}>
                                                    <HeaderDatePicker setIsOpen={setIsOpen} isOpen={isOpen}/>
                                                    {/*<div className={classes.separator}/> */}
                                                </Grid>
                                                <Grid item className={ classes.inlineFlex } style={{ position: "relative" }}>
                                                    <HeaderPassenger setIsOpen={setIsOpen} isOpen={isOpen}/>
                                                    <div className={classes.separator}/>
                                                </Grid>
                                            </Grid>
                                            <Grid className={ clsx({ [classes.budgetContainer1]: !smallScreen, [classes.budgetContainer1Responsive]: smallScreen })} item container xs>
                                                <TripBudget setIsOpen={setIsOpen} isOpen={isOpen} router={router}/>
                                            </Grid>
                                        </Grid>
                                        <Grid className={classes.tripName} item container xs>
                                            <HeaderTripName setIsOpen={setIsOpen} isOpen={isOpen} router={router}/>
                                        </Grid>
                                        <Grid item container justify={"flex-end"} spacing={ !smallScreen ? 4 : 3 } xs>
                                            <Grid item>
                                                <AddNewVersion setIsOpen={setIsOpen} isOpen={isOpen} data_trip={data_trip}/>
                                            </Grid>
                                            <Grid item>
                                                <CartHeader setIsOpen={setIsOpen} router={router}/>
                                            </Grid>
                                            {
                                                data_trip !== undefined && data_trip.trip !== undefined && data_trip.trip.itinerary_type !== true && data_trip.trip.package_type !== true && data_trip.trip.circuit_type !== true && (
                                                    <Fragment>
                                                        <Grid className={ clsx({ [classes.budgetContainer]: !smallScreen, [classes.budgetContainerResponsive]: smallScreen }) } item container xs >
                                                            <RenderFileNb setIsOpen={setIsOpen} router={router} data_trip={data_trip} all_data={all_data}/>
                                                        </Grid>
                                                        {
                                                            //<Grid item>
                                                            //<Notification/>
                                                            //</Grid>
                                                        }
                                                    </Fragment>
                                                )
                                            }
                                            <Grid item>
                                                <AvatarHeader setIsOpen={setIsOpen} router={router} isOpen={isOpen}/>
                                            </Grid>
                                        </Grid>
                                    </Fragment>
                                )
                            }
                            {
                                matches && (
                                    <Fragment>
                                        <Grid item container direction={"row"} justify={"space-evenly"} alignItems={"center"}>
                                            {
                                                isTablet && (
                                                    <Grid item container xs={3} className={ classes.budgetContainer1 }>
                                                        <TripBudget setIsOpen={setMobileOpen} isOpen={mobileOpen} router={router}/>
                                                    </Grid>
                                                )
                                            }
                                            {
                                                mobileOpen !== "middle" && (
                                                    <Grid item xs={isTablet ? 6 : 10} className={classes.mobileContainer}>
                                                        <div className={classes.leftSide}>
                                                            <IconButton className={classes.leftButton} onClick={() => {
                                                                router.push(`/home/${window.url_name}/`);
                                                            }}>
                                                                <ChevronLeftIcon/>
                                                            </IconButton>
                                                        </div>
                                                        <div className={classes.middleSide}>
                                                            <Button className={classes.middleButton} onClick={() => {
                                                                setMobileOpen("middle");
                                                            }}>
                                                                <Grid container direction={'column'} className={classes.middleContainer}>
                                                                    <Grid item className={classes.rightIcon}>
                                                                        <div style={{ width: "80%", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", margin: "auto" }}>{all_data.name}</div>
                                                                    </Grid>
                                                                    <Grid item className={classes.rightIcon}>
                                                                        <div>
                                                                            {moment.utc(start_date).format("DD MMM") + " - " + moment.utc(end_date).format("DD MMM")}
                                                                        </div>
                                                                    </Grid>
                                                                </Grid>
                                                            </Button>
                                                        </div>
                                                        <div className={classes.rightSide}>
                                                            <IconButton className={classes.rightButton} onClick={() => {
                                                                setMobileOpen("middle");
                                                            }}>
                                                                <TuneOutlinedIcon/>
                                                            </IconButton>
                                                        </div>
                                                    </Grid>
                                                )
                                            }
                                            {
                                                mobileOpen === "middle" && (
                                                    <MobileHeader setMobileOpen={setMobileOpen} router={router}/>
                                                )
                                            }
                                            {
                                                isTablet && data_trip.trip.itinerary_type !== true && data_trip.trip.package_type !== true && (
                                                    <Grid item container xs={3} className={ classes.budgetContainer } style={{ marginBottom: 3 }}>
                                                        <RenderFileNb setIsOpen={setMobileOpen} router={router} data_trip={data_trip} all_data={all_data}/>
                                                    </Grid>
                                                )
                                            }
                                        </Grid>
                                    </Fragment>
                                )
                            }
                        </Fragment>
                    )
                }
                {
                    (data_trip === null || checkUrl()) && (
                        <Fragment>
                            <Grid item container sm={12} alignItems={"center"} justify="space-between">
                                <Grid item>
                                    <Link to={ `/home/${window.url_name}/` } style={{ marginLeft: 15 }}>
                                        <img src={ logo !== null ? (logo.thumbnail_little !== null ? logo.thumbnail_little : logo.url) : "/Img/inner_logo_header.png" } height={ 30 } alt={ "" }/>
                                    </Link>
                                </Grid>
                                <Grid item style={{ marginRight: 30 }}>
                                    <AvatarHeader setIsOpen={setIsOpen} router={router} isOpen={isOpen}/>
                                </Grid>
                            </Grid>
                        </Fragment>
                    )
                }
            </Grid>
            <ReloadCartModalNew/>
            <AutomaticProductUpdate/>
        </div>
    );
};
export default MaterialHeader;
