export default function (accommodation_cart, providers, manual_providers, destinations) {
    let accommodations = [];
    let new_list = [];
    let destination_id = [];
    destinations.map((destination) => {
        if (destination.type === 3) {
            new_list.push({
                origin_name: destination.city,
                // origin_name: localeText(current_locale.id, destination.localization, destination.international_name, 'name'),
                origin_dest: { id: destination.destination_id },
                country_code: destination.country,
                type: destination.type,
                location_code_pickup: destination.code

            });
            destination_id.push(destination.code);
        } else {
            new_list.push({
                origin_name: `${destination.name}, ${destination.city}`,
                // origin_name: localeText(current_locale.id, destination.localization, destination.international_name, 'name'),
                origin_dest: { id: destination.destination_id },
                country_code: destination.country,
                type: destination.type,
                location_code_pickup: destination.code
            });
        }
    });
    if (accommodation_cart.length > 0) {
	    accommodation_cart.map((accommodation) => {
	        if (!accommodation.is_optional && destination_id.includes(accommodation.start_destination.id) && accommodation.booking_status === null || (accommodation.booking_status !== null && accommodation.booking_status.status_booking !== "CANCELLED")) {
	            let result = null;
	            result = providers.find((el) => {
	                return el.provider.id === accommodation.provider;
	            });
	            if (result === undefined) {
	                result = manual_providers.find((el) => {
	                    return el.provider.id === accommodation.provider;
	                });
	            }
	            // if (result.available_content_types !== undefined && result.available_content_types.includes(4) || result.enabled_content_types !== undefined && result.enabled_content_types.includes(4)) {
	                let hotel_info = accommodation.hotel[0];
	                let start_destination = accommodation.start_destination.id;
	                let origin_name = hotel_info.name + (hotel_info.city_name !== null ? ", " + hotel_info.city_name : "");
	                let hotel_id = hotel_info.id;
                if (accommodations.find((el) => el.location_code_pickup === hotel_id) === undefined) {
                    accommodations.push({
                        origin_name: origin_name,
                        location_code_pickup: hotel_id,
                        origin_dest: { id: start_destination },
                        country_code: accommodation.start_destination.data.country !== null ? accommodation.start_destination.data.country.country_code : "",
                        type: 1
                    });
                }
	            // }
	        }
	    });
    }
    accommodations.map((accommodation) => {
        let tmp_accommodation = Object.assign({}, accommodation);
        tmp_accommodation.destination = destinations;
        new_list.push({ ...tmp_accommodation });
    });
    return new_list;
}
