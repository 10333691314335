import axios from "axios";
import { EditTraveler } from "../../../Actions/Trip";
import GetCookie from "../../Common/Functions/GetCookie";

const PatchTravelers = (tempTravelers, tempContactLead, withApplicant, headers, from, dispatch, enqueueSnackbar, t) => {
    let temp_info_incomplete = [];
    let companions = [];
    let travel_companions = tempContactLead?.travel_companions !== undefined ? tempContactLead.travel_companions : [];
    tempTravelers.map((traveler) => {
        if (traveler.id !== tempContactLead.id && travel_companions.find((companion) => companion.id === traveler.id) === undefined) {
            companions.push(traveler.id);
        }
    });
    tempTravelers.map((traveler, traveler_index) => {
        temp_info_incomplete[traveler_index] = false;
        if (traveler.identity_doc_number !== "" && traveler.identity_doc_number !== null) {
            if (traveler.identity_doc_country_emission === null || traveler.identity_doc_country_emission === "" || traveler.identity_doc_expiration === "" || traveler.identity_doc_expiration === null || traveler.nationality === null || traveler.nationality === "") {
                enqueueSnackbar(t("booking.passport_issues"), { variant: "error", disableWindowBlurListener: true, autoHideDuration: 8000 });
                temp_info_incomplete[traveler_index] = true;
            }
        }
        if (temp_info_incomplete[traveler_index] === false) {
            let request = {
                address: traveler.address,
                address_details: traveler.address_details,
                birth_date: window.moment(traveler.birth_date).format('YYYY-MM-DD'),
                city: traveler.city,
                country: traveler.country !== null ? traveler.country.id : null,
                email: traveler.email !== "" ? traveler.email : null,
                first_name: traveler.first_name !== "" ? traveler.first_name : t("global.first_name"),
                gender: traveler.gender,
                identity_doc: traveler.identity_doc,
                identity_doc_country_emission: traveler.identity_doc_country_emission !== null ? traveler.identity_doc_country_emission.id : null,
                identity_doc_expiration: traveler.identity_doc_expiration !== "" ? traveler.identity_doc_expiration : null,
                identity_doc_number: traveler.identity_doc_number !== "" ? traveler.identity_doc_number : null,
                last_name: traveler.last_name !== "" ? traveler.last_name : t("global.last_name"),
                loyalty_cards: traveler.loyalty_cards,
                middle_name: traveler.middle_name,
                nationality: traveler.nationality !== null ? traveler.nationality.id : null,
                phone_country_code: traveler.phone_country_code,
                phone_number: traveler.phone_number,
                postal_code: traveler.postal_code,
                title: traveler.title,
                traveler_relationship: traveler.traveler_relationship,
                trip_reason: traveler.trip_reason
            };
            axios({
                method: "PATCH",
                url: `${API_HREF}client/${window.id_owner}/travelers/${traveler.id}/`,
                headers: headers,
                data: JSON.stringify(request)
            }).then(function (response) {
                // enqueueSnackbar(t("menu.trip_list.traveler") + ` ${traveler.age_label} ` + t("menu.trip_list.success_change_travelers"), { variant: "success", disableWindowBlurListener: true, autoHideDuration: 3500 });
                if (traveler.id === tempContactLead.id && traveler.from_id !== undefined) {
                    axios({
                        method: "GET",
                        headers: headers,
                        url: `${API_HREF}client/${window.id_owner}/user/${traveler.from_id}/`
                    }).then(function (res) {
                        let travelers = [];
                        if (res.data.travlers?.length) {
                            travelers = res.data.travlers;
                        }
                        if (!travelers.includes(response.data.id)) {
                            travelers.push(response.data.id);
                        }
                        axios({
                            method: "PATCH",
                            headers: headers,
                            url: `${API_HREF}client/${window.id_owner}/user/${traveler.from_id}/`,
                            data: {
                                travelers,
                                travel_companions: companions
                            }
                        }).then(function (respo) {
                            if (respo.data) {
                                console.log("lead traveler patched");
                            }
                        }).catch(function (error) {
                            console.log('error:', error);
                        });
                    }).catch(function (error) {
                        console.log('error:', error);
                    });
                }
                dispatch(EditTraveler(response.data, traveler.real_index));
                // if(traveler.temp_lead === true){
                //     setContactLead(response.data);
                // }
            }).catch(function (error) {
                console.log(error);
                // enqueueSnackbar(t("menu.trip_list.traveler") + ` ${traveler.age_label} ` + t("menu.trip_list.failed_change_travelers"), { variant: "error", disableWindowBlurListener: true, autoHideDuration: 3500 });
            });
        }
    });
    if (withApplicant === false) {
        let request = {
            address: tempContactLead.address,
            address_details: tempContactLead.address_details,
            birth_date: tempContactLead.birth_date,
            city: tempContactLead.city,
            country: tempContactLead.country !== null ? tempContactLead.country.id : null,
            email: tempContactLead.email !== "" ? tempContactLead.email : null,
            first_name: tempContactLead.first_name !== "" ? tempContactLead.first_name : t("global.first_name"),
            gender: tempContactLead.gender,
            identity_doc: tempContactLead.identity_doc,
            identity_doc_country_emission: tempContactLead.identity_doc_country_emission !== null ? tempContactLead.identity_doc_country_emission.id : null,
            identity_doc_expiration: tempContactLead.identity_doc_expiration !== "" ? tempContactLead.identity_doc_expiration : null,
            identity_doc_number: tempContactLead.identity_doc_number !== "" ? tempContactLead.identity_doc_number : null,
            last_name: tempContactLead.last_name !== "" ? tempContactLead.last_name : t("global.last_name"),
            loyalty_cards: tempContactLead.loyalty_cards,
            middle_name: tempContactLead.middle_name,
            nationality: tempContactLead.nationality !== null ? tempContactLead.nationality.id : null,
            phone_country_code: tempContactLead.phone_country_code,
            phone_number: tempContactLead.phone_number,
            postal_code: tempContactLead.postal_code,
            title: tempContactLead.title,
            traveler_relationship: tempContactLead.traveler_relationship,
            trip_reason: tempContactLead.trip_reason
        };
        if (tempContactLead.id !== 0) {
            axios({
                method: "PATCH",
                url: `${API_HREF}client/${window.id_owner}/travelers/${tempContactLead.id}/`,
                headers: headers,
                data: JSON.stringify(request)
            }).then(function (response) {
                // enqueueSnackbar(t("menu.trip_list.traveler") + ` ${tempContactLead.age_label} ` + t("menu.trip_list.success_change_travelers"), { variant: "success", disableWindowBlurListener: true, autoHideDuration: 3500 });
                // setTempContactLead(response.data);
                // setContactLead(response.data);
            }).catch(function (error) {
                console.log(error.response);
                if (error.response.detail !== undefined) {
                    enqueueSnackbar(error.response.detail, { variant: "error", disableWindowBlurListener: true, autoHideDuration: 3500 });  
                } else {
                    enqueueSnackbar(t("menu.trip_list.traveler") + ` ${tempContactLead.age_label} ` + t("menu.trip_list.failed_change_travelers"), { variant: "error", disableWindowBlurListener: true, autoHideDuration: 3500 });
                }
            });
        } else {
            axios({
                method: "POST",
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/travelers/`,
                data: JSON.stringify(request)
            }).then(function (response) {
                let request_travelers = {
                    contact_traveler: response.data.id
                };
                tempContactLead.id = response.data.id;
                if (from === 'duplicate') {
                    axios({
                        method: "PATCH",
                        headers: headers,
                        url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/?token=${GetCookie("trip_token")}`,
                        data: JSON.stringify(request_travelers),
                    }).then((patchContactTraveler) => {
                        console.log('patchContactTraveler:', patchContactTraveler);
                    }).catch((errorPatchTravelerContact) => {
                        console.log('errorPatchTravelerContact:', errorPatchTravelerContact);
                    });
                    // enqueueSnackbar(t("menu.trip_list.traveler") + ` ${tempContactLead.age_label} ` + t("menu.trip_list.success_change_travelers"), { variant: "success", disableWindowBlurListener: true, autoHideDuration: 3500 });
                    // setTempContactLead(response.data);
                    // setContactLead(response.data);
                }
            }).catch(function (error) {
                console.log(error.response);
                enqueueSnackbar(t("menu.trip_list.traveler") + ` ${tempContactLead.age_label} ` + t("menu.trip_list.failed_change_travelers"), { variant: "error", disableWindowBlurListener: true, autoHideDuration: 3500 });
            });
        }
    }
};
export default PatchTravelers;
