import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTranslation } from 'react-i18next';
import CheckBeforeRequest from '../Common/CheckBeforeRequest';
import React, { Fragment, useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import grey from '@material-ui/core/colors/grey';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { AppState } from '../../Reducers/Reducers';

const useStyles = makeStyles(theme => ({
    formControlRoot: {
        "& .MuiInputLabel-outlined": {
            transform: 'translate(14px, 13px) scale(1)'
        },
        "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
            transform: 'translate(14px, -6px) scale(0.75)'
        },
        "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
            padding: '0'
        },
        "& .MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd": {
            paddingRight: '10px'
        }
    },
    formControlRoot1: {
        "& .MuiInputLabel-outlined": {
            transform: 'translate(14px, 20px) scale(1)'
        },
        "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
            transform: 'translate(14px, -6px) scale(0.75)'
        },
        "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
            padding: '0'
        },
        "& .MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd": {
            paddingRight: '10px'
        },
        "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input": {
            padding: '17px 4px'
        }
    },
    inputBorder1: {
        "&& .MuiOutlinedInput-notchedOutline": {
            borderRight: 'none'
        },
        "&& .MuiSelect-select": {
            padding: "6px"
        },
        "&& .MuiOutlinedInput-root": {
            borderRadius: '8px 0px 0px 8px',
            borderRight: '0'
        }
    },
    inputBorder2: {
        "&& .MuiOutlinedInput-notchedOutline": {
            borderLeft: 'none'
        },
        "&& .MuiSelect-select": {
            padding: "6px"
        },
        "& .MuiOutlinedInput-root": {
            borderRadius: '0px 8px 8px 0px',
            borderLeft: '0'
        }
    },
    inputBorder3: {
        borderRadius: '8px'
    },
    inputBorder4: {
        "&& .MuiOutlinedInput-root": {
            borderRadius: '0px 8px 8px 0px'
        }
    },
    inputBorder5: {
        "&& .MuiOutlinedInput-root": {
            borderRadius: '8px'
        }
    },
    greyColor: {
        color: grey[500],
        marginLeft: '4px'
    },
    noBorderLeft: {
        borderLeft: "none"
    },
    noBorderRight: {
        borderRight: "none"
    },
    noBorder: {
        borderRight: "none",
        borderLeft: "none"
    }
}));

const StationInput = ({
    label,
    search,
    setSearch,
    type,
    elem_index,
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery('(min-width:600px)');
    const isTablet = useMediaQuery(theme.breakpoints.between(600, 960));
    const { t } = useTranslation();
    const { headers } = CheckBeforeRequest();
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState(type === "departure" ? search[elem_index].origin_station : search[elem_index].return_station);
    const [error, setError] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const providers = useSelector((state: AppState) => state.trip.providers);
    const handleChange = (event) => {
        let tmp_search = search.slice();
        let copy_search = [];
        tmp_search.map((elem, index) => {
            if (index === elem_index) {
                let tmp_elem = Object.assign({}, elem);
                if (type === "departure") {
                    tmp_elem.origin_station = options.find((option) => (option.oag_code + option.vendor_code) === event.target.value);
                    if (tmp_elem.same_dest) {
                        tmp_elem.return_station = options.find((option) => (option.oag_code + option.vendor_code) === event.target.value);
                    }
                } else {
                    tmp_elem.return_station = options.find((option) => (option.oag_code + option.vendor_code) === event.target.value);
                }
                elem = tmp_elem;
            }
            copy_search.push(elem);
        });
        setSearch(copy_search);
        setValue(event.target.value);
        setLoading(false);
    };
    useEffect(() => {
        const providerId = providers.find((provider) => provider.provider.code === 'hertz_gsa')?.provider.id;
        if (providerId && search[elem_index]) {
            console.log("type : ", type)
            axios({
                method: "GET",
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/cars/get_locations/`,
                params: {
                    provider_id: providerId,
                    destination_id_pickup: search[elem_index].origin_dest ? search[elem_index].origin_dest.id : undefined,
                    destination_id_return: search[elem_index].return_dest ? search[elem_index].return_dest.id : undefined,
                },
            }).then(function (response) {
                console.log("stations response : ", response.data);
                setOptions(type === "departure" ? response.data[0].pickup_locations : response.data[0].return_locations);
                setLoading(false);
            }).catch(function (error) {
                if (!axios.isCancel(error)) {
                    console.log('Request canceled', error.message);
                }
                //TODO: show snackbar error
            });
        }
    }, [search]);
    return (
        <FormControl
            fullWidth
            className={matches ? (type === "departure" ? (isTablet ? (!search[elem_index].same_dest ? classes.inputBorder1 : classes.inputBorder5) : classes.inputBorder1) : (isTablet ? classes.inputBorder4 : classes.inputBorder2)) : classes.inputBorder3}
        >
            <InputLabel shrink>
                { label }
            </InputLabel>
            <Select
                label={ label }
                notched
                value={ value !== null ? value : "" }
                onChange={ handleChange }
                className={matches ? (type === "departure" ? (isTablet ? (!search[elem_index].same_dest ? classes.inputBorder1 : classes.inputBorder5) : classes.inputBorder1) : (isTablet ? classes.inputBorder4 : classes.inputBorder2)) : classes.inputBorder3}
                // className={matches ? classes.formControlRoot : classes.formControlRoot1}
            >
                {
                    options.map((option) => (
                        <MenuItem value={ option.oag_code + option.vendor_code }>
                            { option.name }
                        </MenuItem>
                    ))
                }
            </Select>
        </FormControl>
    );
};

export default StationInput;