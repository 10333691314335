//---- Dependencies ----//
import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from "@material-ui/core/useMediaQuery";

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

import Tooltip from '@material-ui/core/Tooltip';

import ItineraryIcon from './Icons/ItineraryIcon';
import Lock from '@material-ui/icons/LockOutlined';
import LockOpen from "@material-ui/icons/LockOpenOutlined";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import {
    CloudCircle
} from "@mui/icons-material";

import { AddHistorydest, SetItineraryTypeDetailsDataAndDayAndNight } from '../../Actions/ItineraryType';
import clsx from 'clsx';
import localeText from "../Functions/localeText";
import i18n from '../../i18n';
import VisiteursMoreInfo from '../VisiteursHome/VisiteursMoreInfo';
import GetPictureBySize from '../Accommodation/Functions/GetPictureBySize';

const main_color = JSON.parse(localStorage.getItem("config")).main_color;

const useStyles = makeStyles(() => ({
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
    cardContainer: {
        "width": "100%",
        "height": "auto",
        "boxShadow": "1px 1px 3px rgb(0 0 0 / 10%)",
        "overflow": "hidden",
        "transition": "transform .2s",
        "&:hover": {
            "&& .mui-jss-MuiCardActionArea-root": {
                transform: "scale(1.2)"
            },
            "&& .mui-jss-MuiCardActions-root": {
                backgroundColor: `${main_color}`,
                color: "white !important",
                transition: "all .2s ease",
                transformOrigin: "top"
            },
            "&& .lower-price": {
                color: "white !important"
            }
        }
    },
    cardContainerV: {
        "width": "100%",
        "height": "auto",
        "boxShadow": "1px 1px 3px rgb(0 0 0 / 10%)",
        "overflow": "hidden",
        "transition": "transform .2s",
        "&:hover": {
            "&& .mui-jss-MuiCardActionArea-root": {
                transform: "scale(1.2)"
            },
            "&& .mui-jss-MuiCardActions-root": {
                backgroundColor: "#BF202D",
                color: "white !important",
                transition: "all .2s ease",
                transformOrigin: "top"
            },
            "&& .lower-price": {
                color: "white !important"
            }
        }
    },
    cardImg: {
        height: "auto",
        zIndex: 1,
        position: "relative",
        transition: "all .6s ease-out",
        display: "block",
        width: "100%",
        objectFit: "cover"
    },
    cardTitle: {
        width: "100%",
        background: "white",
        height: "auto",
        display: "flex",
        zIndex: 2,
        padding: "5px",
        position: "relative",
        cursor: "pointer"
    },
    pink: {
        color: "#E0368D"
    }
}));

const MaterialCircuitCard = ({ circuit_info, router, desti_id }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const config = JSON.parse(localStorage.getItem("config"));
    const quotation_code = config.quotation_code;
    
    const user = useSelector(state => state.user.user);
    const locales = useSelector(state => state.user.locales);

    const [moreInfoModal, setMoreInfoModal] = useState(false);
    const current_locale = locales.find((el) => {
        return el.language_code === i18n.language;
    });

    const replaceAll = (str, find, replace) => {
        return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
    };
    const escapeRegExp = (str) => {
	    return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
    };
    const handleMoreInfo = () => {
        setMoreInfoModal(!moreInfoModal);
    };
    let cover_picture = null;
    if (circuit_info !== null && circuit_info.pictures !== null && circuit_info.pictures.length > 0) {
        cover_picture = circuit_info.pictures[0];
    }
    let total_days = circuit_info.duration_day;
    let tmp_total_nights = circuit_info.duration_night;
    let currency_symbol = "-";
    if (circuit_info !== null) {
        if (circuit_info.trip.currency !== undefined && circuit_info.trip.currency !== null) {
            currency_symbol = circuit_info.trip.currency.symbol;
        }
    }
    let destination = null;
    if (circuit_info.destination !== undefined && circuit_info.destination !== null) {
        destination = localeText(current_locale.id, circuit_info.destination.data.localization, circuit_info.destination.data.international_name !== undefined ? circuit_info.destination.data.international_name : circuit_info.destination.data.name, 'name').split(',');
    }
    let keys = Object.keys(circuit_info.lowest_price);
    let is_agency = quotation_code === 'visiteurs' && user.client_full.type === 2;
    return (
        <Grid item sm={['visiteurs'].includes(quotation_code) ? 3 : 4} xs={12}>
            <Card className={`${quotation_code === 'visiteurs' ? classes.cardContainerV : classes.cardContainer} ${classes.genericText}`} >
                <CardActionArea className={classes.cardImg} onClick={() => {
                    //dispatch(SetTripToDuplicateAndOpenDuplicateModalAndSetStep(iti, true, 1));
                    let tmp_obj = {
                        name: circuit_info.name,
                        data: circuit_info,
                        need_redirect: true
                    };
                    dispatch(AddHistorydest(tmp_obj));
                    // dispatch(SetItineraryTypeDetailsDataAndDayAndNight(null, 0, 0));
                    dispatch(SetItineraryTypeDetailsDataAndDayAndNight(circuit_info, 0, 0));
                    let a = replaceAll(circuit_info.name, ' ', '-');
                    let clean_name = replaceAll(a, '/', '');
                    router.push(`/${window.url_name}/circuits-details/${encodeURIComponent(clean_name)}/${circuit_info.id}`);
                }}>
                    <CardMedia
                        image={circuit_info !== null ? ((cover_picture !== undefined && cover_picture !== null) ? GetPictureBySize(cover_picture, "BIG") : "/Img/default_dest1.jpeg") : "/Img/default_dest1.jpeg"} className={"ft-cover-img ft-card-img"}
                        title={localeText(current_locale.id, circuit_info.localization, circuit_info.name, 'name')}
                    />
                </CardActionArea>
                <CardActions className={classes.cardTitle}>
                    <Grid container direction={"column"} justify={"center"} alignItems={"flex-start"}>
                        <Grid item style={{ width: "100%" }}>
                            <Grid container direction={"column"} justify={"space-between"}>
                                <Grid item xs={12}>
                                    {
                                        circuit_info.localization !== undefined &&
                                        <Tooltip title={circuit_info.name}>
                                            <Typography variant="body2" noWrap component="p" style={{ fontWeight: "bold", fontSize: 16, paddingLeft: 4 }}>
                                                {localeText(current_locale.id, circuit_info.localization, circuit_info.name, 'name')}
                                            </Typography>
                                        </Tooltip>
                                    }
                                </Grid>
                                <Grid item style={{ height: 20, width: '100%' }}>
                                    <Grid container>
                                        {
                                            destination !== undefined && destination !== null && (
                                                <Grid item xs={(is_agency || circuit_info.is_custom) ? 12 : 6}>
                                                    <Typography variant="body2" component="p" style={{ fontSize: 14, textAlign: "left", paddingLeft: 4 }}>
                                                        { destination[0]}
                                                    </Typography>
                                                </Grid>
                                            )
                                        }
                                        {
                                            !is_agency && !circuit_info.is_custom &&
                                            <Grid item xs={6}>
                                                <Tooltip title={circuit_info.contracts[0].provider.provider.name}>
                                                    <Typography variant="body2" noWrap component="p" style={{ fontSize: 14, textAlign: "right", paddingLeft: 4 }}>
                                                        { circuit_info.contracts[0].provider.provider.name }
                                                    </Typography>
                                                </Tooltip>
                                            </Grid>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item style={{ width: "100%" }}>
                            <Grid container direction={"row"} justify={"space-between"} alignItems={"center"} spacing={fullScreen ? 1 : 0}>
                                <Grid item xs={12}>
                                    {
                                        circuit_info.is_custom &&
                                        <>
                                            <ItineraryIcon fontSize={"small"} style={{ marginLeft: 5, marginRight: 2 }}/>
                                            {
                                                ['visiteurs'].includes(quotation_code) &&
                                                <span> { t('itineraryType.circuits') } <InfoOutlinedIcon style={{ marginLeft: 5, verticalAlign: 'bottom' }} onClick={handleMoreInfo}/></span>
                                            }
                                            {
                                                !['visiteurs'].includes(quotation_code) &&
                                                <Tooltip title={
                                                    circuit_info.categories.length !== 0 ? localeText(current_locale.id, circuit_info.categories[0].localization, circuit_info.categories[0].name, 'name') : (circuit_info.category !== null ? circuit_info.category.name : "")
                                                }>
                                                    <span style={{ display: 'inline-block', textOverflow: 'ellipsis', textWrap: 'nowrap', width: '86%', overflow: 'hidden'}}>
                                                        { circuit_info.categories.length !== 0 ? localeText(current_locale.id, circuit_info.categories[0].localization, circuit_info.categories[0].name, 'name') : (circuit_info.category !== null ? circuit_info.category.name : "") }
                                                    </span>
                                                </Tooltip>
                                            }
                                        </>
                                    }
                                    {
                                        !circuit_info.is_custom &&
                                        <>
                                            <CloudCircle fontSize={'small'} style={{ marginLeft: 5, verticalAlign: 'text-bottom' }}/>
                                            <span>{`${t('itineraryType.circuits')} ${t('accommodation.connected')}`}</span>
                                        </>
                                    }
                                </Grid>
                                {
                                    circuit_info.guide_languages !== undefined && circuit_info.guide_languages !== null && circuit_info.guide_languages.length !== 0 &&
                                    <Grid item xs={6} style={{ paddingLeft: 5 }}>
                                        <span className={`flag-icon flag-icon-${circuit_info.guide_languages[0].language_code !== 'en' ? circuit_info.guide_languages[0].language_code : 'us'}`}/>
                                    </Grid>
                                }
                                <Grid item xs={ circuit_info.guide_languages !== undefined && circuit_info.guide_languages !== null && circuit_info.guide_languages.length !== 0 ? 6 : 12} style={{ textAlign: "right" }}>
        							<span style={{ paddingLeft: 4, fontSize: 14 }}>
                                        { total_days + " " + (total_days > 1 ? t("global.days") : t("global.day")) } / { tmp_total_nights + " " + (tmp_total_nights > 1 ? t("itinerary.nights") : t("itinerary.night")) }
                                    </span>
                                </Grid>
                            </Grid>
                        </Grid>
                        
                        <Grid item style={{ width: "100%" }}>
                            <Grid container direction={"row"} justify={"space-between"} alignItems={"center"} spacing={fullScreen ? 1 : 0}>
                                <Grid item xs={5}>
                                    {
                                        (!circuit_info.lock_agency && !circuit_info.product_locked) ? (
                                            <Fragment>
                                                <LockOpen style={{ verticalAlign: "text-bottom", fontSize: 18 }}/>
                                                <span style={{ paddingLeft: 5, fontSize: 12 }}>{ t('itineraryType.unlocked-trip') }</span>
                                            </Fragment>
                                        ) : (   
                                            <Fragment>
                                                <Lock style={{ verticalAlign: "text-bottom", fontSize: 18 }}/>
                                                <span style={{ paddingLeft: 5, fontSize: 12 }}>{ t('itineraryType.locked-trip') }</span>
                                            </Fragment>
                                        )
                                    }
                                </Grid>
                                {
                                    circuit_info.lowest_price[keys[0]] !== undefined && circuit_info.lowest_price[keys[0]] !== null && (
                                        <Grid item xs={7} style={{ textAlign: "right" }}>
                                            <span>
                                                <span style={{ fontSize: 11 }}>{ t("global.starting_from") + " " }</span>
                                                <span className={ `${classes.pink} lower-price` }>{ Math.round(circuit_info.lowest_price[keys[0]]) }</span>
                                                { " " + currency_symbol }
                                                <span style={{ fontSize: 11 }}>{` ${t('flight_search.per_travelers')}`}</span>
                                            </span>
                                        </Grid>
                                    )
							    }
                            </Grid>
                        </Grid>
                    </Grid>
                </CardActions>
            </Card>
            <VisiteursMoreInfo info={circuit_info} moreInfoModal={moreInfoModal} setMoreInfoModal={setMoreInfoModal} type={'circuit'}/>
        </Grid>
    );
};
export default MaterialCircuitCard;

