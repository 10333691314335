import { useTranslation } from "react-i18next";
import { ManualProduct } from "../../../Reducers/objects/manualProduct";

type Callback = (product: Pick<ManualProduct, 'product_type'>) => string

export function useGetManualProductType(): Callback {
    const { t } = useTranslation();
    const quotation_code = JSON.parse(localStorage.getItem('config') ?? '{}').quotation_code;

    return (product) => {
        let type = '';
        switch (product.product_type) {
            case 12: type = t('providers.activities'); break;
            case 8: type = t('providers.insurances'); break;
            case 11: {
                if (quotation_code !== 'verdie' && quotation_code !== 'cercledesvoyages') {
                    type = t('providers.other_product');
                } else {
                    type = t('providers.other_visible_product');
                }
                break;
            }
            case -1: type = t('providers.other_invisible_product'); break;
            case 7: type = t('providers.accommodations'); break;
            case 17: type = t('providers.travel_book'); break;
            case 9: type = t('providers.cruises'); break;
            case 1: type = t('providers.excursions'); break;
            case 13: type = t('providers.ferries'); break;
            case 16: {
                if (quotation_code !== 'cercledesvoyages') {
                    type = t('providers.administration_fees');
                } else {
                    type = t('providers.administration_fees_other');
                }
                break;
            }
            case 10: type = t('providers.touristic_guide'); break;
            case 0: type = t('providers.hotels'); break;
            case 18: type = t('providers.reduction'); break;
            case 3: type = t('providers.restaurant'); break;
            case 5: type = t('providers.trains'); break;
            case 4: type = t('providers.transfers'); break;
            case 2: type = t('providers.rental_car'); break;
            case 61: type = t('providers.manual_flight'); break;
            case 20: type = t("header.adjustment_product_name"); break;
            case 21: type = t("providers.manual_margin_adjustment"); break;
            case 22: type = t("providers.tva_margin"); break;
        }
        return type;
    };
}
