import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles, useTheme, useMediaQuery } from "@material-ui/core";
import { useSnackbar } from "notistack";
import useWindowDimensions from "../../Common/Functions/useWindowDimensions";
import moment from "moment";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Table from '@material-ui/core/Table';
import TableHead from "@material-ui/core/TableHead";
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import WorkIcon from "@material-ui/icons/Work";
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import WorkOffOutlinedIcon from '@material-ui/icons/WorkOffOutlined';

import { AddHoverToMatrix, ResetHover, SetMatrixSelectedDay, SetFetchedMatrixStatus,
    SetFetchedDataStatus, ClearFilter, SetShowMoreResults, ResetProvider } from "../../../Actions/FlightSearch";
import { SetMultiDestStartDate, SetOnewayOriginDate, SetRoundtripReturnDate } from "../../../Actions/Flight";

import CountAgePaxType from "../Functions/CountAgePaxType";
import ProcessSearchResults from "../Functions/ProcessSearchResults";
import GetMinimalPrices from "../Functions/GetMinimalPrices";

const main_color = JSON.parse(localStorage.getItem("config")).main_color;

const useStyles = makeStyles({
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: '#0000008A',
        letterSpacing: 1.25
    },
    fontWeight500: {
        fontWeight: 500
    },
    fontWeight900: {
        fontWeight: 900
    },
    fontSize9: {
        fontSize: 9
    },
    fontSize10: {
        fontSize: 10
    },
    fontSize12: {
        fontSize: 12
    },
    fontSize14: {
        fontSize: 14
    },
    fontSize18: {
        fontSize: 18
    },
    fontSize20: {
        fontSize: 20
    },
    uppercase: {
        textTransform: "uppercase"
    },
    textLeft: {
        textAlign: "left"
    },
    textCenter: {
        textAlign: "center"
    },
    textRight: {
        textAlign: "right"
    },
    blue: {
        color: "#319FE3"
    },
    white: {
        color: "#FFFFFF"
    },
    lightgrey: {
        color: "#0000008A"
    },
    lightergrey: {
        color: "#0000005C"
    },
    backgroundHover: {
        background: `${main_color}AA`
    },
    backgroundHoverSelected: {
        background: `${main_color}CA`
    },
    backgroundSelected: {
        background: `${main_color}`
    },
    backgroundBlue: {
        background: "#319FE3"
    },
    genericBorder: {
        // background: "#FFFFFF",
        border: "1px solid #DDDDDD",
        boxSizing: "border-box"
    },
    formShadow: {
        borderRadius: 15,
        boxShadow: '0 1px 6px rgba(32,33,36,0.28)'
    },
    tableHeadMargin: {
        width: 1077,
        marginLeft: 147
    },
    tableMargin: {
        width: 1077,
        marginLeft: 177,
        marginTop: 8
    },
    tableHeadMarginTablet: {
        width: "fit-content",
        marginLeft: "auto"
    },
    tableColumnMargin: {
        margin: "4px 30px 4px 0px"
    },
    tableColumnMarginTablet: {
        margin: "4px 4px 0px 0px"
    },
    lowMargin: {
        margin: "16px 0px"
    },
    paddingTableCell: {
        padding: "0px 5px"
    },
    cellHeight: {
        height: 75,
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    lowestPriceCell: {
        background: "#DBD7D7",
        borderRadius: "12px 12px 0px 0px"
    },
    iconMargin: {
        marginBottom: -4
    },
    smallSpacer: {
        padding: "4px 0px"
    },
    seeButton: {
        border: "2px solid #319FE3",
        padding: "0px 40px"
    },
    pointer: {
        cursor: "pointer"
    }
});

const FlightMatrix = (({ use_cache = true, searched_group, setSearchGroup, setLoadingRequest }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.between(600, 960));
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { height, width } = useWindowDimensions();

    const language = useSelector(store => store.header.language);
    const flight_groups = useSelector(store => store.flight.flight_groups);
    const index_group = useSelector(store => store.flight.index_group);
    const filter_time = useSelector(store => store.flight_search.filter_time);
    const flight_providers = useSelector(store => store.flight.provider_list);
    const lowest_price = useSelector(store => store.flight_search.lowest_price);
    const matrix_data = useSelector(store => store.flight_search.matrix_data);
    const matrix_flight = useSelector(store => store.flight_search.matrix_flight);
    const matrix_outbound_date = useSelector(store => store.flight_search.matrix_outbound_date);
    const matrix_return_date = useSelector(store => store.flight_search.matrix_return_date);
    const end_date = useSelector(store => store.trip.end_date);
    const trip_id = useSelector(store => store.trip.trip_id);
    const trip_currency = useSelector(store => store.trip.currency);
    const user = useSelector(store => store.user.user);

    const [selectedChanged, setSelectedChanged] = useState(false);
    const [selectedFlight, setSelectedFlight] = useState(null);
    const [hoverStartDate, setHoverStartDate] = useState(null);
    const [hoverReturnDate, setHoverReturnDate] = useState(null);
    const [matrix_flight_dates, setMatrixFlightDates] = useState([]);
    let is_return = matrix_return_date !== null && matrix_return_date.length !== 0;

    moment.locale(language);

    // 1280 / 8 = 160 => 5px margin
    const cellWidth = Math.min(145, (width / 8) - 5);

    useEffect(() => {
        if (matrix_flight !== null && selectedFlight === null) {
            setSelectedFlight(matrix_flight[Math.floor(matrix_flight.length / 2)]);
        }
    }, [matrix_flight]);

    useEffect(() => {
        if (selectedChanged && matrix_data !== null && index_group !== null) {
            let group = flight_groups[index_group];
            let pax_passenger = CountAgePaxType(group.travelers, group.journey.slice(-1)[0].start_date);
            let data_flight = {
                airlines: matrix_data[0].airlines,
                airports: matrix_data[0].airports,
                currency: matrix_data[0].currency,
                currency_symbol: undefined,
                distance_unit: matrix_data[0].distance_unit,
                flight: [],
                stations: matrix_data[0].stations,
                trace_id: matrix_data[0].trace_id
            };
            let matrice_flights = matrix_data[0].flight;

            let tmp_flight = [];
            Object.keys(matrice_flights).map((column) => {
                if (column === selectedFlight.start_date) {
                    if (group.journey.length > 1) {
                        Object.keys(matrice_flights[column]).map((row) => {
                            if (row === selectedFlight.arrival_date) {
                                matrice_flights[column][row].map((flight) => {
                                    console.log('flight:', flight);
                                    if (flight.total_price !== null && flight.total_price.toFixed(2) === selectedFlight.total_price.toFixed(2)) {
                                        tmp_flight.push(flight);
                                    }
                                });
                            }
                        });
                    } else {
                        tmp_flight.push(matrice_flights[column][0]);
                    }
                }
            });
            data_flight.flight = tmp_flight;
            // put flight for selected date in flight_list
            ProcessSearchResults(group, dispatch, data_flight, pax_passenger, "matrix_flight", JSON.parse(localStorage.getItem("config")));
            setSelectedChanged(false);
        }
    }, [selectedChanged]);

    useEffect(() => {
        if (matrix_return_date !== null && matrix_return_date.length !== 0) {
            setMatrixFlightDates(matrix_return_date);
        } else if (matrix_outbound_date !== null && matrix_outbound_date.length !== 0) {
            setMatrixFlightDates(matrix_outbound_date);
        }
    }, [matrix_outbound_date, matrix_return_date]);

    const enterMatrixCell = (flight) => {
        setHoverStartDate(flight.start_date);
        setHoverReturnDate(flight.arrival_date);
        dispatch(AddHoverToMatrix(flight.index_start_date, flight.index_return_date));
    };

    const leaveMatrixCell = () => {
        setHoverStartDate(null);
        setHoverReturnDate(null);
        dispatch(ResetHover());
    };

    const selectDate = (flight) => {
        dispatch(SetMatrixSelectedDay(flight.index_start_date, flight.index_return_date));
        dispatch(SetFetchedMatrixStatus("Done"));
        dispatch(SetFetchedDataStatus(null));
        dispatch(ResetProvider());
        dispatch(SetShowMoreResults(false));
        
        let default_filter_time = filter_time.slice();
        default_filter_time.map((journey_time) => {
            journey_time.departure = [0, 24];
            journey_time.arrival = [0, 24];
        });
        dispatch(ClearFilter(default_filter_time));

        // change group journey dates 
        let group_index = index_group !== null ? index_group : 0;
        let group = flight_groups[group_index];
        if (group.journey_type === 1) {
            dispatch(SetRoundtripReturnDate(moment(flight.arrival_date).utc(), group_index));
        } else if (group.journey_type === 2) {
            dispatch(SetMultiDestStartDate(moment(flight.arrival_date).utc(), group_index, group.journey.length - 1));
        }

        dispatch(SetOnewayOriginDate(moment(flight.start_date).utc(), group_index)); 
        enqueueSnackbar(t("flight_groups.warning.date_change"), { variant: "warning" });
        setSelectedFlight(flight);
        setSelectedChanged(true);
    };

    const changeOutboundDate = (before_or_after) => {
        let group_index = index_group !== null ? index_group : 0;
        let copy_groups = flight_groups.slice();
        let group = copy_groups[group_index];
        let new_date = moment(group.journey[0].start_date);
        if (before_or_after === "before") {
            new_date = new_date.subtract(7, "days");
            let today_diff = moment(new_date).diff(moment(), "days");
            if (today_diff < 0) {
                enqueueSnackbar(t("flight_groups.warning.calendar_date_past"), { variant: "warning" });
                return;
            }
        } else if (before_or_after === "after") {
            let return_date = moment(group.journey[1].start_date);
            new_date = moment(new_date).add(7, "days");
            let return_diff = moment(return_date).diff(new_date, "days");
            if (return_diff <= 0) {
                enqueueSnackbar(t("flight_groups.warning.start_after_return"), { variant: "warning" });
                return;
            }
        }
        dispatch(SetFetchedMatrixStatus(null));
        dispatch(SetFetchedDataStatus(null));
        dispatch(SetShowMoreResults(false));

        if (filter_time !== null) {
            let default_filter_time = filter_time.slice();
            default_filter_time.map((journey_time) => {
                journey_time.departure = [0, 24];
                journey_time.arrival = [0, 24];
            });
            dispatch(ClearFilter(default_filter_time));
        }

        dispatch(SetOnewayOriginDate(new_date.utc(), group_index));
        copy_groups[group_index].journey[0].start_date = new_date;
        setSearchGroup(copy_groups[group_index]);
        setLoadingRequest(false);
        enqueueSnackbar(t("flight_groups.warning.departure_date_change"), { variant: "warning" });
        GetMinimalPrices(flight_groups, flight_providers, group_index, trip_id, trip_currency, end_date, user, use_cache, "matrix", dispatch);
    };

    const changeReturnDate = (before_or_after) => {
        let group_index = index_group !== null ? index_group : 0;
        let copy_groups = flight_groups.slice();
        let group = copy_groups[group_index];
        let new_date = moment(group.journey[1].start_date);

        if (before_or_after === "before") {
            new_date = new_date.subtract(7, "days");
            let today_diff = moment(new_date).diff(moment(), "days");
            if (today_diff < 1) {
                enqueueSnackbar(t("flight_groups.warning.calendar_date_past"), { variant: "warning" });
                return;
            }
            let outbound_date = moment(group.journey[0].start_date);
            let outbound_diff = moment(new_date).diff(outbound_date, "days");
            if (outbound_diff <= 0) {
                enqueueSnackbar(t("flight_groups.warning.return_before_start"), { variant: "warning" });
                return;
            }
        } else if (before_or_after === "after") {
            new_date = moment(new_date).add(7, "days");
        }
        dispatch(SetFetchedMatrixStatus(null));
        dispatch(SetFetchedDataStatus(null));
        dispatch(SetShowMoreResults(false));

        if (filter_time !== null) {
            let default_filter_time = filter_time.slice();
            default_filter_time.map((journey_time) => {
                journey_time.departure = [0, 24];
                journey_time.arrival = [0, 24];
            });
            dispatch(ClearFilter(default_filter_time));
        }
        dispatch(SetRoundtripReturnDate(new_date.utc(), group_index));
        copy_groups[group_index].journey[1].start_date = new_date;
        setSearchGroup(copy_groups[group_index]);
        setLoadingRequest(false);
        enqueueSnackbar(t("flight_groups.warning.return_date_change"), { variant: "warning" });
        GetMinimalPrices(copy_groups, flight_providers, group_index, trip_id, trip_currency, end_date, user, use_cache, "matrix", dispatch);
    };

    return (
        <Grid style={{ marginLeft: 4, marginBottom: 20 }}>
            <Paper elevation={0} variant="outlined" className={`${classes.formShadow} ${isTablet ? classes.tableHeadMarginTablet : classes.tableHeadMargin}`}>
                <Grid container alignItems="center" justify="center">
                    <Grid item>
                        <IconButton size={'small'} >
                            <NavigateBeforeIcon className={isTablet ? classes.fontSize18 : ""} onClick={() => changeOutboundDate("before")} />
                        </IconButton>
                    </Grid>
                    {
                        matrix_outbound_date.map((date, date_index) => {
                            let is_selected = selectedFlight !== null ? (date === selectedFlight.start_date) : false;
                            let is_hovered = date === hoverStartDate;
                            return (
                                <Grid item
                                    key={date_index}
                                    className={`${classes.cellHeight} ${classes.paddingTableCell} ${classes.textCenter} ${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.uppercase} ${is_selected ? classes.backgroundSelected : (is_hovered ? classes.backgroundHoverSelected : "")}`}
                                    style={{ width: cellWidth }}
                                >
                                    <Grid>
                                        { 
                                            is_selected && (
                                                <div className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.uppercase} ${classes.white}`} style={{ paddingBottom: 3, marginTop: -15 }}>
                                                    { t("filters.go") }
                                                </div>
                                            )
                                        }
                                        {
                                            <div className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${(is_selected || is_hovered) ? classes.white : ""}`}>
                                                { moment(date).format("ddd DD MMM YY") }
                                            </div>
                                        }
                                    </Grid>
                                </Grid>
                            );
                        })
                    }
                    <Grid item>
                        <IconButton size={'small'} >
                            <NavigateNextIcon className={isTablet ? classes.fontSize18 : ""} onClick={() => changeOutboundDate("after")} />
                        </IconButton>
                    </Grid>
                </Grid>
            </Paper>
            <Grid container alignItems="center" justify="flex-start" className={is_return ? "" : classes.tableMargin} style={(isTablet || !is_return) ? {} : { marginTop: -6 }}>
                {
                    matrix_return_date !== null && matrix_return_date.length !== 0 && (
                        <Grid item>
                            <Paper elevation={0} variant="outlined" className={`${classes.formShadow} ${isTablet ? classes.tableColumnMarginTablet : classes.tableColumnMargin}`} style={{ height: 585 }}>
                                <Grid container direction="column" alignItems="center" justify="flex-end">
                                    <Grid item className={classes.textCenter}>
                                        <IconButton size={'small'}>
                                            <ExpandLessIcon className={isTablet ? classes.fontSize18 : ""} onClick={() => changeReturnDate("before")} />
                                        </IconButton>
                                    </Grid>
                                    {
                                        matrix_return_date.map((date, date_index) => {
                                            let is_selected = selectedFlight !== null ? (date === selectedFlight.arrival_date) : false;
                                            let is_hovered = date === hoverReturnDate;
                                            return (
                                                <Grid item
                                                    key={date_index}
                                                    className={`${classes.cellHeight} ${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.uppercase} ${is_selected ? classes.backgroundSelected : (is_hovered ? classes.backgroundHoverSelected : "")}`}
                                                    style={{ width: cellWidth }}
                                                >
                                                    <Grid>
                                                        {
                                                            is_selected && (
                                                                <div className={`${classes.genericText} ${classes.textCenter} ${classes.fontWeight900} ${classes.fontSize12} ${classes.uppercase} ${classes.white}`} style={{ marginTop: -20, paddingBottom: 2 }}>
                                                                    { t("filters.return") }
                                                                </div>
                                                            )
                                                        }
                                                        <div className={`${classes.genericText} ${isTablet ? classes.textCenter : ""} ${classes.fontWeight900} ${classes.fontSize12} ${(is_selected || is_hovered) ? classes.white : ""}`}>
                                                            { moment(date).format("ddd DD MMM YY") }
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            );
                                        })
                                    }
                                    <Grid item className={classes.textCenter}>
                                        <IconButton size={'small'}>
                                            <ExpandMoreIcon className={isTablet ? classes.fontSize18 : ""} onClick={() => changeReturnDate("after")} />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    )
                }
                <Grid item>
                    <Paper elevation={0} variant="outlined" className={`${classes.formShadow}`} style={{ height: is_return ? 525 : 75 }}>
                        <Grid container={!is_return} alignItems="center" justify="flex-end">
                            {
                                matrix_flight_dates.map((date, date_index) => {
                                    return (
                                        <Grid container={is_return} item={!is_return} key={date_index} alignItems="center" justify="flex-end">
                                            {
                                                matrix_flight.map((flight, flight_index) => {
                                                    let textColorClass = classes.lightgrey;
                                                    let luggageColorClass = classes.lightergrey;
                                                    let backgroundColor = "";
                                                    if (flight.hover_selected || flight.selected || flight.hover || flight.total_price === lowest_price) {
                                                        textColorClass = classes.white;
                                                        luggageColorClass = classes.white;
                                                    }


                                                    if (flight.selected) {
                                                        backgroundColor = classes.backgroundSelected;
                                                    } else if (flight.hover_selected) {
                                                        backgroundColor = classes.backgroundHoverSelected;
                                                    } else if (flight.hover) {
                                                        backgroundColor = classes.backgroundHover;
                                                    } else if (flight.total_price === lowest_price) {
                                                        backgroundColor = classes.backgroundBlue;
                                                    }

                                                    if ((is_return && flight.arrival_date === date) || (!is_return && flight.start_date === date)) {
                                                        return (
                                                            <Grid item={is_return}
                                                                key={flight_index}
                                                                onMouseEnter={() => enterMatrixCell(flight)}
                                                                onMouseLeave={() => leaveMatrixCell()}
                                                                className={`${classes.cellHeight} ${classes.paddingTableCell} ${backgroundColor} ${classes.pointer} ${classes.genericBorder}`}
                                                                style={{ display: "grid", width: cellWidth }}
                                                                onClick={() => selectDate(flight)}
                                                            >
                                                                <div className={`${classes.genericText} ${classes.fontWeight500} ${isTablet ? classes.fontSize12 : classes.fontSize14} ${textColorClass}`} >
                                                                    { flight.total_price !== null ? new Intl.NumberFormat(language, {style: 'currency', currency: trip_currency.iso_code, minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(flight.total_price) : "-"}
                                                                </div>
                                                                {
                                                                    flight.total_price !== null && (
                                                                        <Grid container alignItems='center' justify="center">
                                                                            <Grid item className={classes.textCenter}>
                                                                                { flight.nb_luggage === 0 && <WorkOffOutlinedIcon className={luggageColorClass} /> }
                                                                                {
                                                                                    flight.nb_luggage !== 0 && (
                                                                                        <WorkOutlineIcon className={luggageColorClass} />
                                                                                    )
                                                                                }
                                                                            </Grid>
                                                                            { flight.nb_luggage !== 0 && (
                                                                                <Grid item className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize10} ${textColorClass}`} style={{ marginLeft: -18, marginTop: -3 }}>
                                                                                    { "x" + flight.nb_luggage }
                                                                                </Grid>
                                                                            )}
                                                                        </Grid>
                                                                    )
                                                                }
                                                                {
                                                                    is_return && flight.total_price !== null && (flight.selected || flight.hover_selected) && (
                                                                        <div className={`${classes.textCenter} ${classes.genericText} ${classes.fontWeight500} ${classes.fontSize14} ${classes.white}`} style={{ marginTop: -10 }}>
                                                                            {flight.days_diff + " "} {flight.days_diff > 1 ? t("global.days") : t("global.day")}
                                                                        </div>
                                                                    )
                                                                }
                                                            </Grid>
                                                        );
                                                    }
                                                })
                                            }
                                        </Grid>
                                    );
                                })
                            }
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </Grid>
    );
});

export default React.memo(FlightMatrix);
