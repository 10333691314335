import React, { useEffect, useState, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { makeStyles, useTheme } from "@material-ui/core";
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from "@material-ui/core/IconButton";
import PublishOutlinedIcon from '@material-ui/icons/PublishOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import SaveAltOutlinedIcon from '@material-ui/icons/SaveAltOutlined';
import RefreshOutlinedIcon from '@material-ui/icons/RefreshOutlined';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import Close from "@material-ui/icons/Close";

import Check from "@material-ui/icons/Check";
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import Switch from '@material-ui/core/Switch';

import CheckBeforeRequest from "../../Common/CheckBeforeRequest";

import { DeleteDocumentId, SetTripDocsDoc } from "../../../Actions/Menu";

import GetProductType from "./Functions/GetProductType.js";
import { ModalDeleteCustomVoucher } from "./ModalDeleteCustomVoucher";

import axios from "axios";

const useStyles = makeStyles((theme) => ({
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
    fontWeight400: {
        fontWeight: 400
    },
    fontWeight700: {
        fontWeight: 700
    },
    fontWeight900: {
        fontWeight: 900
    },
    fontSize10: {
        fontSize: 10
    },
    fontSize12: {
        fontSize: 12
    },
    fontSize16: {
        fontSize: 16
    },
    fontSize20: {
        fontSize: 20
    },
    fontSize24: {
        fontSize: 24
    },
    upperCase: {
        textTransform: "uppercase"
    },
    capitalize: {
        textTransform: "capitalize"
    },
    letterSpacing: {
        letterSpacing: 1.25
    },
    textCenter: {
        textAlign: "center"
    },
    genericBorder: {
        border: "0.5px solid rgba(0, 0, 0, 0.25)",
        boxSizing: "border-box"
    },
    genericPadding: {
        padding: "0px 20px"
    },
    genericPaddingMobile: {
        padding: "0px 8px"
    },
    categoryPadding: {
        padding: 8
    },
    spaceTop: {
        marginTop: 20
    },
    spaceTopMobile: {
        marginTop: 10
    },
    spacer: {
        margin: "16px 0px"
    },
    spacerMobile: {
        margin: "8px 0px"
    },
    indentation: {
        paddingLeft: 16
    },
    borderTableCell: {
        borderBottom: "none"
    },
    checkboxCellWidth: {
        width: 150
    },
    colorBlue: {
        color: '#4A75DA'
    },
    colorRed: {
        color: 'red'
    },
    colorGrey: {
        color: "#808080"
    },
    iconBorder: {
        paddingRight: 4,
        marginLeft: 8,
        verticalAlign: "sub",
        borderRadius: 20
    },
    checkBorder: {
        border: "1px solid #808080"
    },
    paddingLeft60: {
        paddingLeft: '60px !important'
    }
}));

const getVoucherUrl = (type, voucher) => {
    let url = `${API_HREF}`;
    if (type === 1) {
        url += voucher.url;
    }
    if (type === 2) {
        url += voucher.url_pdf;
    }
    return url;
};

const getVoucherHotelsUrl = (type, trip_info, version, ids) => {
    // let url = `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/voucher/?`;
    let url = `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/versions/${version}/voucher/?`;
    url += type === 1 ? 'preview=y' : 'preview=f';
    ids.map(id => url += `&product_id=${id}`);
    return url;
};

const getQuotationUrl = (preview, trip_info, version) => {
    const quotation_code = JSON.parse(localStorage.getItem("config")).quotation_code;
    let url = `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/versions/${version}/quotation/?price_by_product=n`;
    url += !['visiteurs'].includes(quotation_code) ? "&generate_for_target=y" : "&generate_for_target=n";
    url += preview === true ? "&preview=y" : "&preview=n";
    if (['cercledesvoyages'].includes(quotation_code)) {
        url += "&generate_with_practical_information=y&price_by_category=n";
    } else {
        url += "&price_by_category=y";
    }
    return url;
};

const getIndexNbForVersion = (array, nb) => {
    for (let i = 0; i < array.length; i++) {
        if (array[i].id === nb) {
            return (i + 1);
        }
    }
    return 1;
};

const getFileName = (trip_info, trip_displayed_version, doc, voucher, traveler, label, t) => {
    let index_version = getIndexNbForVersion(trip_info.data, trip_displayed_version);
    let voucher_name = null;
    if (voucher !== null) {
        voucher_name = voucher.name.replaceAll("/", "-");
    }
    let name_split = doc.file_name !== null ? doc.file_name.split(".") : [];
    let extention = name_split.length !== 0 ? name_split[name_split.length - 1] : "";
    switch (doc.type) {
        case "CARD":
            return t("menu.file_nb") + trip_info.trip_reference + " " + t("menu.trip_list.nb_version") + index_version + " " + t("menu.trip_list.id_card") + " " + t("global.of") + " " + t(`title_value.${traveler.title}`) + " " + traveler.first_name + " " + traveler.last_name + "." + extention;
        case "PASSPORT":
            return t("menu.file_nb") + trip_info.trip_reference + " " + t("menu.trip_list.nb_version") + index_version + " " + t("menu.trip_list.passport") + " " + t("global.of") + " " + t(`title_value.${traveler.title}`) + " " + traveler.first_name + " " + traveler.last_name + "." + extention;
        case "E-VISA":
            return t("menu.file_nb") + trip_info.trip_reference + " " + t("menu.trip_list.nb_version") + index_version + " " + "e-Visa" + " " + t("global.of") + " " + t(`title_value.${traveler.title}`) + " " + traveler.first_name + " " + traveler.last_name + "." + extention;
        case "VOUCHER_FLIGHT":
            return t("menu.file_nb") + trip_info.trip_reference + " " + t("menu.trip_list.nb_version") + index_version + " " + t("global.plane_ticket") + " " + t("global.of") + " " + t(`title_value.${traveler.title}`) + " " + traveler.first_name + " " + traveler.last_name + "." + extention;
        case "VOUCHER":
            return t("menu.file_nb") + trip_info.trip_reference + " " + t("menu.trip_list.nb_version") + index_version + " " + t("global.voucher") + " " + t("providers." + GetProductType(voucher.product_type)) + " : " + voucher_name + "." + extention;
        case "VOUCHER_HOTELS":
            return t("menu.file_nb") + trip_info.trip_reference + " " + t("menu.trip_list.nb_version") + index_version + " " + t("global.voucher") + " " + t('accommodation.hotels') + "." + extention;
        case "QUOTATION":
            return t("menu.trip_list.quotation_title") + trip_info.trip_reference + " " + t("menu.trip_list.nb_version") + index_version + "." + extention;
        case "CONDITIONS":
            return t("menu.file_nb") + trip_info.trip_reference + " " + t("menu.trip_list.nb_version") + index_version + " " + t("menu.trip_list.conditions") + "." + extention;
        case "TRAVELBOOK":
            return t("menu.file_nb") + trip_info.trip_reference + " " + t("menu.trip_list.nb_version") + index_version + " " + t("menu.trip_list.travelbook") + "." + extention;
        case "HOTELCARD":
            return t("menu.file_nb") + trip_info.trip_reference + " " + t("menu.trip_list.nb_version") + index_version + " " + label + "." + extention;
        case "CONTRACT":
            return t("menu.file_nb") + trip_info.trip_reference + " " + t("menu.trip_list.nb_version") + index_version + " " + t("menu.trip_list.contract") + "." + extention;
        default:
            return doc.file_name;
    }
};
const DocumentActionBar = (({ mode, traveler, label, documentObject, trip_displayed_version, voucher, update, setUpdate, ids }) =>{
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down(768));

    const quotation_code = JSON.parse(localStorage.getItem("config")).quotation_code;

    const trip_info = useSelector(store => store.menu.trip_info);
    const user = useSelector(store => store.user.user);

    const { enqueueSnackbar } = useSnackbar();

    const [requested, setRequested] = useState(false);
    const [received, setReceived] = useState(false);
    const [displayVoucher, setDisplayVoucher] = useState(false);
    const [displayMinisite, setDisplayMinisite] = useState(false);
    const bucketName = "facilitatrip-documents";
    const [uploadSpinner, setUploadSpinner] = useState(false);
    const [seeSpinner, setSeeSpinner] = useState(false);
    const [downloadSpinner, setDownloadSpinner] = useState(false);
    const [editFileName, setEditFileName] = useState(false);
    const [customFileName, setCustomFileName] = useState(documentObject.file_name !== null ? documentObject.file_name : "");
    const [modalDelete, setModalDelete] = useState(false);
    useEffect(() =>{
        if (mode !== "other" && mode !== "add_document") {
            setReceived(documentObject.received);
            setRequested(documentObject.requested);
        }
    }, [documentObject.received, documentObject.requested]);
    useEffect(() =>{
        setDisplayVoucher(documentObject.display_voucher);
        setDisplayMinisite(documentObject.display_minisite);
    }, [documentObject.display_minisite, documentObject.display_voucher]);
    useEffect(() =>{
        if (update && mode !== "other" && mode !== "add_document") {
            setReceived(documentObject.received);
            setRequested(documentObject.requested);
            setUpdate(false);
        }
    }, [update]);

    useEffect(() => {
        if (bucketName !== "") {
            if ((mode === "voucher_hotels" || mode === "voucher" || mode === "quotation") && !documentObject.received && documentObject.object_name === '') {
                //auto generate voucher or quotation if not received yet in AWS
                updateDocument();
            }
        }
    }, [bucketName]);

    const handleRequested = () => {
        let { pass_check, headers } = CheckBeforeRequest();
        if (pass_check) {
            let request = {
                requested: !requested
            };
            let url = '';
            if (['PASSPORT', 'E-VISA', 'CUSTOM_FILE', 'OTHER', 'CARD'].includes(documentObject.type)) {
                url = `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/file/${documentObject.id}/`;
            } else {
                url = `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/versions/${trip_displayed_version}/file/${documentObject.id}/`;
            }
            axios({
                method: "PATCH",
                url: url,
                headers: headers,
                data: JSON.stringify(request)
            }).then(function (response) {
                setRequested(!requested);
                dispatch(SetTripDocsDoc(response.data));
                if (documentObject.type === "TRAVELBOOK" && quotation_code === "cercledesvoyages") {
                    let data_version = trip_info.data.find((version) => version.id === trip_displayed_version);
                    let request1 = {
                        has_router: !data_version.has_router
                    };
                    axios({
                        method: "PATCH",
                        url: `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/versions/${trip_displayed_version}/`,
                        headers: headers,
                        data: JSON.stringify(request1)
                    }).then((versionResponse) => {
                        dispatch({
                            type: "MENU_SET_TRIP_INFO_VERSION",
                            payload: {
                                version: versionResponse.data
                            }
                        });
                        dispatch({
                            type: "TRIP_SET_DATA_TRIP",
                            payload: {
                                data_trip: versionResponse.data
                            }
                        });
                    }).catch((error) => {
                        console.log('error patch version:', error);
                    });
                }
            }).catch((error) => {
                console.log('error patch request:', error);
            });
        }
    };

    const handleReceived = () => {
        let { pass_check, headers } = CheckBeforeRequest();
        if (pass_check) {
            let request = {
                received: !received
            };
            let url = '';
            if (['PASSPORT', 'E-VISA', 'CUSTOM_FILE', 'OTHER', 'CARD'].includes(documentObject.type)) {
                url = `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/file/${documentObject.id}/`;
            } else {
                url = `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/versions/${trip_displayed_version}/file/${documentObject.id}/`;
            }
            axios({
                method: "PATCH",
                url: url,
                headers: headers,
                data: JSON.stringify(request)
            }).then(function (response) {
                setReceived(!received);
                dispatch(SetTripDocsDoc(response.data));
                if (documentObject.type === "TRAVELBOOK" && quotation_code === "cercledesvoyages") {
                    let data_version = trip_info.data.find((version) => version.id === trip_displayed_version);
                    let request1 = {
                        has_roadbook: !data_version.has_roadbook
                    };
                    axios({
                        method: "PATCH",
                        url: `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/versions/${trip_displayed_version}/`,
                        headers: headers,
                        data: JSON.stringify(request1)
                    }).then((versionResponse) => {
                        dispatch({
                            type: "MENU_SET_TRIP_INFO_VERSION",
                            payload: {
                                version: versionResponse.data
                            }
                        });
                        dispatch({
                            type: "TRIP_SET_DATA_TRIP",
                            payload: {
                                data_trip: versionResponse.data
                            }
                        });
                    }).catch((error) => {
                        console.log('error patch version:', error);
                    });
                }
            }).catch((error) => {
                console.log('error patch request:', error);
            });
        }
    };
    const handleDisplayVoucher = () => {
        let { pass_check, headers } = CheckBeforeRequest();
        if (pass_check) {
            let request = {
                display_voucher: !displayVoucher
            };
            let url = '';
            if (['PASSPORT', 'E-VISA', 'CUSTOM_FILE', 'OTHER', 'CARD'].includes(documentObject.type)) {
                url = `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/file/${documentObject.id}/`;
            } else {
                url = `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/versions/${trip_displayed_version}/file/${documentObject.id}/`;
            }
            axios({
                method: "PATCH",
                url: url,
                headers: headers,
                data: JSON.stringify(request)
            }).then(function (response) {
                setDisplayVoucher(!displayVoucher);
                dispatch(SetTripDocsDoc(response.data));
            }).catch((error) => {
                console.log('error patch request:', error);
            });
        }
    };
    const handleDisplayMinisite = () => {
        let { pass_check, headers } = CheckBeforeRequest();
        if (pass_check) {
            let request = {
                display_minisite: !displayMinisite
            };
            let url = '';
            if (['PASSPORT', 'E-VISA', 'CUSTOM_FILE', 'OTHER', 'CARD'].includes(documentObject.type)) {
                url = `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/file/${documentObject.id}/`;
            } else {
                url = `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/versions/${trip_displayed_version}/file/${documentObject.id}/`;
            }
            axios({
                method: "PATCH",
                url: url,
                headers: headers,
                data: JSON.stringify(request)
            }).then(function (response) {
                setDisplayMinisite(!displayMinisite);
                dispatch(SetTripDocsDoc(response.data));
            }).catch((error) => {
                console.log('error patch request:', error);
            });
        }
    };
    const checkFile = (file) => {
        const trim_file = file.target.value.replaceAll(" ", "").toLowerCase();
        //const allowedFiles = [".pdf"];
        const allowedFiles = [".png", ".jpg", ".jpeg", ".pdf", ".doc", ".docx", ".heic", ".heif"];
        const allowedExtensions = new RegExp("([a-zA-Z0-9\s_\\.\-:])+(" + allowedFiles.join('|') + ")$");
        if (!allowedExtensions.exec(trim_file)) {
            return false;
        }
        return true;
    };
    const uploadDocument = (e) => {
        setUploadSpinner(true);
        let selected_file = e.target.files[0];
        let check = checkFile(e);
        if (check) {
            let formData = new FormData();
            let doc_name_split = documentObject.file_name !== null ? documentObject.file_name.split(".") : [];
            let name_split = selected_file.name !== null ? selected_file.name.split(".") : [];
            let extention = name_split.length !== 0 ? name_split[name_split.length - 1] : "";
            const domain = window.location.host.replace("www.", "").split('.');
            formData.append("file_object", selected_file);
            formData.append("bucket_name", bucketName);
            formData.append("prefix", `${domain[0]}-${domain[2] === 'com' ? 'prod' : 'pre-prod'}`);
            formData.append("type", documentObject.type);
            formData.append("tripdocument_id", documentObject.id);
            formData.append("file_name", doc_name_split.length !== 0 ? `${doc_name_split[0]}.${extention}` : selected_file.name);
            formData.append("document", documentObject.id);
            if (documentObject.product !== null) {
                formData.append("product", documentObject.product);
            }
            if (traveler !== null) {
                formData.append("traveler", traveler.id);
            } else if (documentObject.product !== null) {
                formData.append("product", documentObject.product);
            } else if (documentObject.hotel !== null) {
                formData.append("hotel", documentObject.hotel);
            }

            let { pass_check, headers } = CheckBeforeRequest();
            headers["Content-Type"] = "multipart/form-data";
            let url = '';
            if (['PASSPORT', 'E-VISA', 'CUSTOM_FILE', 'OTHER', 'CARD'].includes(documentObject.type)) {
                url = `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/file/upload_to_aws/`;
            } else {
                url = `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/versions/${trip_displayed_version}/file/upload_to_aws/`;
            }
            if (pass_check) {
                axios({
                    method: "POST",
                    url: url,
                    headers: headers,
                    data: formData
                }).then(function (response) {
                    enqueueSnackbar(t("menu.trip_list.success_doc_upload"), { variant: "success", disableWindowBlurListener: true });
                    setReceived(true);
                    dispatch(SetTripDocsDoc(response.data));
                    if (response.data.type === "CUSTOM_FILE") {
                        setCustomFileName(response.data.file_name);
                    }
                    setUploadSpinner(false);
                }).catch(function (error) {
                    console.log(error.responseJSON);
                    enqueueSnackbar(t("menu.trip_list.failed_doc_upload"), { variant: "error", disableWindowBlurListener: true });
                    setUploadSpinner(false);
                });
            }
            //clear value to trigger onChange even when uploading same file
            e.target.value = null;
        } else {
            e.target.value = null;
            setUploadSpinner(false);
            enqueueSnackbar(t("menu.trip_list.failed_doc_upload_pdf"), { variant: "error", disableWindowBlurListener: true });
        }
    };

    const downloadDocument = () => {
        setDownloadSpinner(true);
        if (received || !!documentObject.bucket_name) {
            let object_name = `${documentObject.type}-TRIP%23${trip_info.id}-VER%23${trip_displayed_version}`;
            if (traveler !== null) {
                object_name += `-ID-${traveler.id}`;
            } else if (documentObject.product !== null) {
                object_name += `-ID-${documentObject.product}`;
            } else if (documentObject.hotel !== null) {
                object_name += `-ID-${documentObject.hotel}`;
            }

            //let file_name = documentObject.file_name;
            let file_name = getFileName(trip_info, trip_displayed_version, documentObject, voucher, traveler, label, t);
            let { pass_check, headers } = CheckBeforeRequest();
            if (pass_check) {
                const domain = window.location.host.replace("www.", "").split('.');
                let url = '';
                if (['PASSPORT', 'E-VISA', 'CUSTOM_FILE', 'OTHER', 'CARD'].includes(documentObject.type)) {
                    url = `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/file/download_from_aws/?bucket_name=${bucketName}&object_name=${encodeURIComponent(documentObject.object_name)}&file_name=${file_name}`;
                } else {
                    url = `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/versions/${trip_displayed_version}/file/download_from_aws/?bucket_name=${bucketName}&object_name=${encodeURIComponent(documentObject.object_name)}&file_name=${file_name}`;
                }
                axios({
                    method: "GET",
                    url: url,
                    //url: `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/versions/${trip_displayed_version}/file/download_from_aws/?bucket_name=${bucketName}&object_name=${documentObject.object_name.replaceAll("#", "%23")}&file_name=${file_name}`,
                    headers: headers,
                    responseType: 'blob'
                }).then(function (response) {
                    console.log('response.data:', response.data);
                    let blob = new Blob([response.data], { type: response.data.type });
                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = file_name;
                    link.click();
                    setDownloadSpinner(false);
                }).catch(function (error) {
                    console.log("Error download file", error);
                    enqueueSnackbar(t("menu.trip_list.failed_doc_download"), { variant: "warning", disableWindowBlurListener: true });
                    setDownloadSpinner(false);
                });
            }
        }
    };

    const seeDocument = () => {
        setSeeSpinner(true);
        if (received || !!documentObject.bucket_name) {
            if (voucher !== null && !!voucher.voucher_url) {
                window.open(voucher.voucher_url, "_blank");
                setSeeSpinner(false);
            } else {
                let object_name = `${documentObject.type}-TRIP%23${trip_info.id}-VER%23${trip_displayed_version}`;
                if (traveler !== null) {
                    object_name += `-ID-${traveler.id}`;
                } else if (documentObject.product !== null) {
                    object_name += `-ID-${documentObject.product}`;
                } else if (documentObject.hotel !== null) {
                    object_name += `-ID-${documentObject.hotel}`;
                }
            }

            let file_name = documentObject.file_name;
            //let file_name = getFileName(trip_info, trip_displayed_version, documentObject, voucher, traveler, label, t);

            let { pass_check, headers } = CheckBeforeRequest();
            if (pass_check) {
                const domain = window.location.host.replace("www.", "").split('.');
                let url = '';
                if (['PASSPORT', 'E-VISA', 'CUSTOM_FILE', 'OTHER', 'CARD'].includes(documentObject.type)) {
                    url = `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/file/download_from_aws/?bucket_name=${bucketName}&object_name=${encodeURIComponent(documentObject.object_name)}&file_name=${file_name}`;
                } else {
                    url = `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/versions/${trip_displayed_version}/file/download_from_aws/?bucket_name=${bucketName}&object_name=${encodeURIComponent(documentObject.object_name)}&file_name=${file_name}`;
                }
                axios({
                    method: "GET",
                    url: url,
                    //url: `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/versions/${trip_displayed_version}/file/download_from_aws/?bucket_name=${bucketName}&object_name=${documentObject.object_name.replaceAll("#", "%23")}&file_name=${file_name}`,
                    //url: `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/versions/${trip_displayed_version}/file/download_from_aws/?bucket_name=${bucketName}&object_name=${domain[0]}-${domain[2] === 'com' ? 'prod' : 'pre-prod'}/${object_name}&file_name=${file_name}`,
                    headers: headers,
                    responseType: 'blob'
                }).then(function (response) {
                    let blob = new Blob([response.data], { type: response.data.type });
                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    window.open(link);
                    setSeeSpinner(false);
                }).catch(function (error) {
                    console.log("Error see file", error);
                    enqueueSnackbar(t("menu.trip_list.failed_doc_preview"), { variant: "warning", disableWindowBlurListener: true });
                    setSeeSpinner(false);
                });
            }
        }
    };

    const updateDocument = () =>{
        setUploadSpinner(true);
        let doc_url = "";
        if (mode === "voucher" || mode === "voucher_activity") {
            doc_url = getVoucherUrl(2, voucher);
        } else if (mode === 'voucher_hotels') {
            doc_url = getVoucherHotelsUrl(2, trip_info, trip_displayed_version, ids);
        } else if (mode === "quotation") {
            doc_url = getQuotationUrl(false, trip_info, trip_displayed_version);
        }

        let { pass_check, headers } = CheckBeforeRequest();
        if (pass_check) {
            axios({
                method: "GET",
                url: doc_url,
                headers: headers,
                responseType: 'arraybuffer'
            }).then(function (response) {
                let blob = new Blob([response.data], { type: "application/pdf" });
                let formData = new FormData();
                const domain = window.location.host.replace("www.", "").split('.');
                formData.append("file_object", blob);
                formData.append("bucket_name", bucketName);
                formData.append("prefix", `${domain[0]}-${domain[2] === 'com' ? 'prod' : 'pre-prod'}`);
                formData.append("type", documentObject.type);
                formData.append("tripdocument_id", documentObject.id);
                if (traveler !== null) {
                    formData.append("traveler", traveler.id);
                } else if (documentObject.product !== null) {
                    formData.append("product", documentObject.product);
                }

                let { pass_check, headers } = CheckBeforeRequest();
                headers["Content-Type"] = "multipart/form-data";

                if (pass_check) {
                    let url = '';
                    if (['PASSPORT', 'E-VISA', 'CUSTOM_FILE', 'OTHER', 'CARD'].includes(documentObject.type)) {
                        url = `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/file/upload_to_aws/`;
                    } else {
                        url = `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/versions/${trip_displayed_version}/file/upload_to_aws/`;
                    }
                    axios({
                        method: "POST",
                        url: url,
                        headers: headers,
                        data: formData
                    }).then(function (response) {
                        enqueueSnackbar(t("menu.trip_list.success_doc_upload"), { variant: "success", disableWindowBlurListener: true });
                        setReceived(true);
                        dispatch(SetTripDocsDoc(response.data));
                        setUploadSpinner(false);
                    }).catch(function (error) {
                        console.log(error.responseJSON);
                        enqueueSnackbar(t("menu.trip_list.failed_doc_upload"), { variant: "error", disableWindowBlurListener: true });
                        setUploadSpinner(false);
                    });
                }
            }).catch(function (error) {
                console.log(error.responseJSON);
                enqueueSnackbar(t("menu.trip_list.failed_doc_upload"), { variant: "error", disableWindowBlurListener: true });
                setUploadSpinner(false);
            });
        }
    };
    const handleEditFileName = () => {
        setEditFileName(!editFileName);
    };
    const handleValidateFileName = () => {
        const { headers, pass_check } = CheckBeforeRequest();
        if (pass_check) {
            let request = {
                file_name: customFileName
            };
            let url = '';
            if (['PASSPORT', 'E-VISA', 'CUSTOM_FILE', 'OTHER', 'CARD'].includes(documentObject.type)) {
                url = `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/file/${documentObject.id}/`;
            } else {
                url = `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/versions/${trip_displayed_version}/file/${documentObject.id}/`;
            }
            axios({
                method: "PATCH",
                url: url,
                headers: headers,
                data: request
            }).then((response) => {
                console.log('change custom file name:', response.data);
                dispatch({
                    type: "MENU_EDIT_CUSTOM_FILE_NAME",
                    payload: response.data
                });
                handleEditFileName();
            }).catch((error) => {
                console.log('error:', error);
            });
        }
    };
    const deleteDocument = () => {
        let { pass_check, headers } = CheckBeforeRequest();
        if (pass_check) {
            let url = '';
            if (['PASSPORT', 'E-VISA', 'CUSTOM_FILE', 'OTHER', 'CARD'].includes(documentObject.type)) {
                url = `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/file/${documentObject.id}/`;
            } else {
                url = `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/versions/${trip_displayed_version}/file/${documentObject.id}/`;
            }
            axios({
                method: "DELETE",
                url: url,
                headers: headers
            }).then(function () {
                dispatch(DeleteDocumentId(documentObject.id));
                setModalDelete(false);
                enqueueSnackbar(t("menu.trip_list.success_doc_delete"), { variant: "success", disableWindowBlurListener: true });
            }).catch(function (error) {
                console.log(error);
                enqueueSnackbar(t("menu.trip_list.failed_doc_delete"), { variant: "warning", disableWindowBlurListener: true });
            });
        }
    };
    const handleChangeName = (e) => {
        setCustomFileName(e.target.value);
    };
    if (mode === "traveler") {
        return (
            <TableRow>
                <TableCell className={`${!mobile ? classes.indentation : ""} ${classes.borderTableCell} ${classes.genericText} ${!mobile ? classes.fontWeight700 : classes.fontWeight400} ${!mobile ? classes.fontSize12 : classes.fontSize10}`}>
                    {documentObject.type === "VOUCHER_FLIGHT" && t("global.plane_ticket") + " : "}
                    {t(`title_value.${traveler.title}`) + " " + traveler.first_name + " " + traveler.last_name}
                </TableCell>
                <TableCell className={`${classes.borderTableCell} ${!mobile ? classes.checkboxCellWidth : ""} ${classes.textCenter}`}>
                    {documentObject.file_name !== null && documentObject.modified_date !== null ? moment(documentObject.modified_date).format("L") : ""}
                </TableCell>
                <TableCell className={`${classes.borderTableCell} ${!mobile ? classes.checkboxCellWidth : ""} ${classes.textCenter}`}>
                    {/* <CheckBox checked={requested} onChange={handleRequested} /> */}
                    <Switch color={"primary"} checked={requested} onChange={handleRequested}/>
                </TableCell>
                <TableCell className={`${classes.borderTableCell} ${!mobile ? classes.checkboxCellWidth : ""} ${classes.textCenter}`}>
                    {/* <CheckBox checked={received} onChange={handleReceived} /> */}
                    <Switch color={"primary"} checked={received} onChange={handleReceived}/>
                </TableCell>
                <TableCell className={`${classes.borderTableCell} ${classes.textCenter}`} style={{ width: 0 }}>
                    <Tooltip title={t("global.upload")}>
                        <span>
                            <IconButton size="small" className={classes.colorBlue} component="label">
                                { uploadSpinner ? <CircularProgress size={20} className={classes.colorBlue} /> : <PublishOutlinedIcon /> }
                                <input hidden type="file" onChange={(e) => uploadDocument(e)} />
                            </IconButton>
                        </span>
                    </Tooltip>
                </TableCell>
                <TableCell className={`${classes.borderTableCell} ${classes.textCenter}`} style={{ width: 0 }}>
                    <Tooltip title={t("menu.trip_list.see_document")}>
                        <span>
                            <IconButton size="small" className={received ? classes.colorBlue : classes.colorGrey} onClick={seeDocument} disabled={!received}>
                                { seeSpinner ? <CircularProgress size={20} className={classes.colorBlue} /> : <VisibilityOutlinedIcon /> }
                            </IconButton>
                        </span>
                    </Tooltip>
                </TableCell>
                <TableCell className={`${classes.borderTableCell} ${classes.textCenter}`} style={{ width: 0 }}>
                    <Tooltip title={t("global.download")}>
                        <span>
                            <IconButton size="small" className={received ? classes.colorBlue : classes.colorGrey} onClick={downloadDocument} disabled={!received}>
                                { downloadSpinner ? <CircularProgress size={20} className={classes.colorBlue} /> : <SaveAltOutlinedIcon /> }
                            </IconButton>
                        </span>
                    </Tooltip>
                </TableCell>
                <TableCell className={classes.borderTableCell} style={{ width: 30 }}/>
            </TableRow>
        );
    } else if (mode === 'manual_voucher') {
        return (
            <TableRow>
                <TableCell className={`${!mobile ? classes.indentation : ""} ${classes.borderTableCell} ${classes.genericText} ${!mobile ? classes.fontWeight700 : classes.fontWeight400} ${!mobile ? classes.fontSize12 : classes.fontSize10}`}>
                    {documentObject.type === "VOUCHER_FLIGHT" && t("global.plane_ticket") + " : "}
                    {t(`title_value.${traveler.title}`) + " " + traveler.first_name + " " + traveler.last_name}
                </TableCell>
                <TableCell className={`${classes.borderTableCell} ${!mobile ? classes.checkboxCellWidth : ""} ${classes.textCenter}`}>
                    {
                        documentObject.file_name !== null && documentObject.modified_date !== null ? moment(documentObject.modified_date).format("L") :   ''
                    }
                </TableCell>
                {
                    ['cercledesvoyages', 'continentsinsolites'].includes(quotation_code) &&
                    <TableCell className={`${classes.borderTableCell} ${!mobile ? classes.checkboxCellWidth : ""} ${classes.textCenter}`}>
                        {/* <CheckBox checked={displayVoucher} onChange={handleDisplayVoucher} /> */}
                        <Switch color={"primary"} checked={displayVoucher} onChange={handleDisplayVoucher}/>
                    </TableCell>
                }
                {
                    ['cercledesvoyages', 'continentsinsolites', 'americavoyages', 'tropicalementvotre'].includes(quotation_code) &&
                    <TableCell className={`${classes.borderTableCell} ${!mobile ? classes.checkboxCellWidth : ""} ${classes.textCenter}`}>
                        {/* <CheckBox checked={displayMinisite} onChange={handleDisplayMinisite} /> */}
                        <Switch color={"primary"} checked={displayMinisite} onChange={handleDisplayMinisite}/>
                    </TableCell>
                }
                <TableCell className={`${classes.borderTableCell} ${classes.textCenter}`} style={{ width: 0 }}>
                    <Tooltip title={t("global.upload")}>
                        <span>
                            <IconButton size="small" className={classes.colorBlue} component="label">
                                { uploadSpinner ? <CircularProgress size={20} className={classes.colorBlue} /> : <PublishOutlinedIcon /> }
                                <input hidden type="file" onChange={(e) => uploadDocument(e)} />
                            </IconButton>
                        </span>
                    </Tooltip>
                </TableCell>
                <TableCell className={`${classes.borderTableCell} ${classes.textCenter}`} style={{ width: 0 }}>
                    <Tooltip title={t("menu.trip_list.see_document")}>
                        <span>
                            <IconButton size="small" className={received ? classes.colorBlue : classes.colorGrey} onClick={seeDocument} disabled={!received}>
                                { seeSpinner ? <CircularProgress size={20} className={classes.colorBlue} /> : <VisibilityOutlinedIcon /> }
                            </IconButton>
                        </span>
                    </Tooltip>
                </TableCell>
                <TableCell className={`${classes.borderTableCell} ${classes.textCenter}`} style={{ width: 0 }}>
                    <Tooltip title={t("global.download")}>
                        <span>
                            <IconButton size="small" className={received ? classes.colorBlue : classes.colorGrey} onClick={downloadDocument} disabled={!received}>
                                { downloadSpinner ? <CircularProgress size={20} className={classes.colorBlue} /> : <SaveAltOutlinedIcon /> }
                            </IconButton>
                        </span>
                    </Tooltip>
                </TableCell>
                <TableCell className={classes.borderTableCell} style={{ width: 30 }}/>
            </TableRow>
        );
    } else if (mode === 'voucher_hotels' || mode === "voucher") {
        let product_type = GetProductType(voucher.product_type);
        return (
            <TableRow>
                <TableCell className={`${!mobile ? classes.indentation : ""} ${classes.borderTableCell} ${classes.genericText} ${!mobile ? classes.fontWeight700 : classes.fontWeight400} ${!mobile ? classes.fontSize12 : classes.fontSize10}`}>
                    {
                        mode !== 'voucher_hotels' ?
                            t('global.voucher') + " " + t('providers.' + product_type) + " : " + voucher.name :
                            !mobile ? t("global.voucher") + " " + t("providers." + product_type) + " : " + t('global.all-accommodations-vouchers') : t('global.all-accommodations-vouchers')
                    }
                </TableCell>
                <TableCell className={`${classes.borderTableCell} ${!mobile ? classes.checkboxCellWidth : ""} ${classes.textCenter}`}>
                    {documentObject.received && documentObject.modified_date !== null ? moment(documentObject.modified_date).format("L") : ""}
                </TableCell>
                {
                    ['cercledesvoyages', 'continentsinsolites'].includes(quotation_code) &&
                    <TableCell className={`${classes.borderTableCell} ${!mobile ? classes.checkboxCellWidth : ""} ${classes.textCenter}`}>
                        {/* <CheckBox checked={displayVoucher} onChange={handleDisplayVoucher} /> */}
                        <Switch color={"primary"} checked={displayVoucher} onChange={handleDisplayVoucher}/>
                    </TableCell>
                }
                {
                    ['cercledesvoyages', 'continentsinsolites', 'americavoyages', 'tropicalementvotre'].includes(quotation_code) &&
                    <TableCell className={`${classes.borderTableCell} ${!mobile ? classes.checkboxCellWidth : ""} ${classes.textCenter}`}>
                        {/* <CheckBox checked={displayMinisite} onChange={handleDisplayMinisite} /> */}
                        <Switch color={"primary"} checked={displayMinisite} onChange={handleDisplayMinisite}/>
                    </TableCell>
                }
                <TableCell className={`${classes.borderTableCell} ${classes.textCenter}`} style={{ width: 0 }}>
                    <Tooltip title={documentObject.received ? t("menu.trip_list.update_doc") : t("menu.trip_list.generate_doc")}>
                        <span>
                            <IconButton size="small" className={classes.colorBlue} onClick={updateDocument}>
                                { uploadSpinner ? <CircularProgress size={20} className={classes.colorBlue} /> : <RefreshOutlinedIcon /> }
                            </IconButton>
                        </span>
                    </Tooltip>
                </TableCell>
                <TableCell className={`${classes.borderTableCell} ${classes.textCenter}`} style={{ width: 0 }}>
                    <Tooltip title={t("menu.trip_list.see_document")}>
                        <span>
                            <IconButton size="small" className={received ? classes.colorBlue : classes.colorGrey} onClick={seeDocument} disabled={!received}>
                                { seeSpinner ? <CircularProgress size={20} className={classes.colorBlue} /> : <VisibilityOutlinedIcon /> }
                            </IconButton>
                        </span>
                    </Tooltip>
                </TableCell>
                <TableCell className={`${classes.borderTableCell} ${classes.textCenter}`} style={{ width: 0 }}>
                    <Tooltip title={t("global.download")}>
                        <span>
                            <IconButton size="small" className={received ? classes.colorBlue : classes.colorGrey} onClick={downloadDocument} disabled={!received}>
                                { downloadSpinner ? <CircularProgress size={20} className={classes.colorBlue} /> : <SaveAltOutlinedIcon /> }
                            </IconButton>
                        </span>
                    </Tooltip>
                </TableCell>
                <TableCell className={classes.borderTableCell} style={{ width: 30 }}/>
            </TableRow>
        );  
    } else if (mode === "custom_voucher") {
        return (
            <TableRow key={`custom-voucher-${documentObject.id}`}>
                <TableCell className={`${!mobile ? classes.indentation : ""} ${classes.borderTableCell} ${classes.genericText} ${!mobile ? classes.fontWeight700 : classes.fontWeight400} ${!mobile ? classes.fontSize12 : classes.fontSize10}`}>
                    {documentObject.file_name !== null ? documentObject.file_name : t("menu.trip_list.document")}
                </TableCell>
                <TableCell className={`${classes.borderTableCell} ${!mobile ? classes.checkboxCellWidth : ""} ${classes.textCenter}`}>
                    {
                        documentObject.file_name !== null && documentObject.modified_date !== null ? moment(documentObject.modified_date).format("L") :   ''
                    }
                </TableCell>
                {
                    ['cercledesvoyages', 'continentsinsolites'].includes(quotation_code) &&
                    <TableCell className={`${classes.borderTableCell} ${!mobile ? classes.checkboxCellWidth : ""} ${classes.textCenter}`}>
                        {/* <CheckBox checked={displayVoucher} onChange={handleDisplayVoucher} /> */}
                        <Switch color={"primary"} checked={displayVoucher} onChange={handleDisplayVoucher}/>
                    </TableCell>
                }
                {
                    ['cercledesvoyages', 'continentsinsolites', 'americavoyages', 'tropicalementvotre'].includes(quotation_code) &&
                    <TableCell className={`${classes.borderTableCell} ${!mobile ? classes.checkboxCellWidth : ""} ${classes.textCenter}`}>
                        {/* <CheckBox checked={displayMinisite} onChange={handleDisplayMinisite} /> */}
                        <Switch color={"primary"} checked={displayMinisite} onChange={handleDisplayMinisite}/>
                    </TableCell>
                }
                <TableCell className={`${classes.borderTableCell} ${classes.textCenter}`} style={{ width: 0 }}>
                    {
                        documentObject.type === "CUSTOM_VOUCHER" && (
                            <Tooltip title={t("global.upload")}>
                                <IconButton size="small" className={classes.colorBlue} component="label">
                                    { uploadSpinner ? <CircularProgress size={20} className={classes.colorBlue} /> : <PublishOutlinedIcon /> }
                                    <input hidden type="file" onChange={(e) => uploadDocument(e)} />
                                </IconButton>
                            </Tooltip>
                        )
                    }
                </TableCell>
                <TableCell className={`${classes.borderTableCell} ${classes.textCenter}`} style={{ width: 0 }}>
                    {
                        // documentObject.object_name.includes(".pdf") && (
                        <Tooltip title={t("menu.trip_list.see_document")}>
                            <span>
                                <IconButton size="small" className={classes.colorBlue} onClick={seeDocument}>
                                    { seeSpinner ? <CircularProgress size={20} className={classes.colorBlue} /> : <VisibilityOutlinedIcon /> }
                                </IconButton>
                            </span>
                        </Tooltip>
                        // )
                    }
                </TableCell>
                <TableCell className={`${classes.borderTableCell} ${classes.textCenter}`} style={{ width: 0 }}>
                    <Tooltip title={t("global.download")}>
                        <span>
                            <IconButton size="small" className={classes.colorBlue} onClick={downloadDocument}>
                                { downloadSpinner ? <CircularProgress size={20} className={classes.colorBlue} /> : <SaveAltOutlinedIcon /> }
                            </IconButton>
                        </span>
                    </Tooltip>
                </TableCell>
                <TableCell className={`${classes.borderTableCell} ${classes.textCenter}`} style={{ width: 0 }}>
                    <Tooltip title={t("global.delete")}>
                        <span>
                            <IconButton size="small" className={classes.colorRed} onClick={() => { setModalDelete(true); }}>
                                <Close />
                            </IconButton>
                        </span>
                    </Tooltip>
                </TableCell>
                <ModalDeleteCustomVoucher deleteDocument={deleteDocument} modalDelete={modalDelete} setModalDelete={setModalDelete}/>
            </TableRow>
        );
    } else if (mode === "quotation") {
        return (
            <Grid container alignItems="center" justify="space-between" style={{ height: 42 }}>
                <Grid item className={classes.textCenter}>
                    <Tooltip title={documentObject.received ? t("menu.trip_list.update_doc") : t("menu.trip_list.generate_doc")}>
                        <span>
                            <IconButton size="small" className={classes.colorBlue} onClick={updateDocument}>
                                { uploadSpinner ? <CircularProgress size={20} className={classes.colorBlue} /> : <RefreshOutlinedIcon /> }
                            </IconButton>
                        </span>
                    </Tooltip>
                </Grid>
                <Grid item className={classes.textCenter}>
                    <Tooltip title={t("menu.trip_list.see_document")} >
                        <span>
                            <IconButton size="small" className={received || !!documentObject.bucket_name ? classes.colorBlue : classes.colorGrey} onClick={seeDocument} disabled={!received && !documentObject.object_name}>
                                { seeSpinner ? <CircularProgress size={20} className={classes.colorBlue} /> : <VisibilityOutlinedIcon /> }
                            </IconButton>
                        </span>
                    </Tooltip>
                </Grid>
                <Grid item className={classes.textCenter}>
                    <Tooltip title={t("global.download")}>
                        <span>
                            <IconButton size="small" className={received || !!documentObject.bucket_name ? classes.colorBlue : classes.colorGrey} onClick={downloadDocument} disabled={(!received && !documentObject.object_name) || (voucher !== null && voucher.voucher_url !== undefined && voucher.voucher_url !== null)}>
                                { downloadSpinner ? <CircularProgress size={20} className={classes.colorBlue} /> : <SaveAltOutlinedIcon /> }
                            </IconButton>
                        </span>
                    </Tooltip>
                </Grid>
                <TableCell className={classes.borderTableCell} style={{ width: 30 }}/>
            </Grid>
        );
    } else if (mode === "row") {
        return (
            <TableRow>
                <TableCell className={`${!mobile ? classes.indentation : ""} ${classes.borderTableCell} ${classes.genericText} ${!mobile ? classes.fontWeight700 : classes.fontWeight400} ${!mobile ? classes.fontSize12 : classes.fontSize10}`}>
                    {label}
                </TableCell>
                <TableCell className={`${classes.borderTableCell} ${!mobile ? classes.checkboxCellWidth : ""} ${classes.textCenter}`}>
                    {documentObject.file_name !== null && documentObject.modified_date !== null ? moment(documentObject.modified_date).format("L") : ""}
                </TableCell>
                <TableCell className={`${classes.borderTableCell} ${!mobile ? classes.checkboxCellWidth : ""} ${classes.textCenter}`}>
                    {/* <CheckBox checked={requested} onChange={handleRequested } /> */}
                    <Switch color={"primary"} checked={requested} onChange={handleRequested}/>
                </TableCell>
                <TableCell className={`${classes.borderTableCell} ${!mobile ? classes.checkboxCellWidth : ""} ${classes.textCenter}`}>
                    {/* <CheckBox checked={received} onChange={handleReceived} /> */}
                    <Switch color={"primary"} checked={received} onChange={handleReceived}/>
                </TableCell>
                <TableCell className={`${classes.borderTableCell} ${classes.textCenter}`} style={{ width: 0 }}>
                    <Tooltip title={t("global.upload")}>
                        <span>
                            <IconButton size="small" className={classes.colorBlue} component="label">
                                <input hidden type="file" onChange={(e) => uploadDocument(e)}/>
                                { uploadSpinner ? <CircularProgress size={20} className={classes.colorBlue} /> : <PublishOutlinedIcon /> }
                            </IconButton>
                        </span>
                    </Tooltip>
                </TableCell>
                <TableCell className={`${classes.borderTableCell} ${classes.textCenter}`} style={{ width: 0 }}>
                    <Tooltip title={t("menu.trip_list.see_document")}>
                        <span>
                            <IconButton size="small" className={received && documentObject.file_name !== null ? classes.colorBlue : classes.colorGrey} onClick={seeDocument} disabled={!received || documentObject.file_name === null}>
                                { seeSpinner ? <CircularProgress size={20} className={classes.colorBlue} /> : <VisibilityOutlinedIcon /> }
                            </IconButton>
                        </span>
                    </Tooltip>
                </TableCell>
                <TableCell className={`${classes.borderTableCell} ${classes.textCenter}`} style={{ width: 0 }}>
                    <Tooltip title={t("global.download")}>
                        <span>
                            <IconButton size="small" className={received && documentObject.file_name !== null ? classes.colorBlue : classes.colorGrey} onClick={downloadDocument} disabled={!received || documentObject.file_name === null}>
                                { downloadSpinner ? <CircularProgress size={20} className={classes.colorBlue} /> : <SaveAltOutlinedIcon /> }
                            </IconButton>
                        </span>
                    </Tooltip>
                </TableCell>
                <TableCell className={classes.borderTableCell} style={{ width: 30 }}/>
            </TableRow>
        );
    } else if (mode === "custom_file") {
        return (
            <TableRow>
                <TableCell className={`${!mobile ? classes.indentation : ""} ${classes.borderTableCell} ${classes.genericText} ${!mobile ? classes.fontWeight700 : classes.fontWeight400} ${!mobile ? classes.fontSize12 : classes.fontSize10}`}>
                    {
                        !editFileName && (
                            <Fragment>
                                {documentObject.file_name !== null ? documentObject.file_name : t("menu.trip_list.document")}
                                <IconButton size={"small"} component="label" className={`${classes.checkBorder} ${classes.iconBorder}`} onClick={handleEditFileName}>
                                    <CreateOutlinedIcon fontSize={"small"}/>
                                </IconButton>
                            </Fragment>
                        )
                    }
                    {
                        editFileName && (
                            <Fragment>
                                <FormControl variant={"standard"}>
                                    <InputLabel htmlFor={"custom-file-name-input"}>
                                        {t("menu.trip_list.document_name")}
                                    </InputLabel>
                                    <Input
                                        id={"custom-file-name-input"}
                                        type={'text'}
                                        onChange={handleChangeName}
                                        label={t("menu.trip_list.document_name")}
                                        value={customFileName}
                                        autoComplete="off"
                                    />
                                </FormControl>
                                <IconButton size={"small"} component="label" style={{ verticalAlign: "bottom", marginLeft: 5 }} onClick={handleEditFileName}>
                                    <Close fontSize={"small"}/>
                                </IconButton>
                                <IconButton size={"small"} component="label" style={{ verticalAlign: "bottom", marginLeft: 5 }} onClick={handleValidateFileName}>
                                    <Check fontSize={"small"}/>
                                </IconButton>
                            </Fragment>
                        )
                    }
                </TableCell>
                <TableCell className={`${classes.borderTableCell} ${!mobile ? classes.checkboxCellWidth : ""} ${classes.textCenter}`}>
                    {documentObject.file_name !== null && documentObject.modified_date !== null ? moment(documentObject.modified_date).format("L") : ""}
                </TableCell>
                <TableCell className={`${classes.borderTableCell} ${!mobile ? classes.checkboxCellWidth : ""} ${classes.textCenter}`}>
                    {/* <CheckBox checked={requested} onChange={handleRequested } /> */}
                    <Switch color={"primary"} checked={requested} onChange={handleRequested}/>
                </TableCell>
                <TableCell className={`${classes.borderTableCell} ${!mobile ? classes.checkboxCellWidth : ""} ${classes.textCenter}`}>
                    {/* <CheckBox checked={received} onChange={handleReceived} /> */}
                    <Switch color={"primary"} checked={received} onChange={handleReceived}/>
                </TableCell>
                <TableCell className={`${classes.borderTableCell} ${classes.textCenter}`} style={{ width: 0 }}>
                    <Tooltip title={t("global.upload")}>
                        <span>
                            <IconButton size="small" className={classes.colorBlue} component="label">
                                <input hidden type="file" onChange={(e) => uploadDocument(e)}/>
                                { uploadSpinner ? <CircularProgress size={20} className={classes.colorBlue} /> : <PublishOutlinedIcon /> }
                            </IconButton>
                        </span>
                    </Tooltip>
                </TableCell>
                <TableCell className={`${classes.borderTableCell} ${classes.textCenter}`} style={{ width: 0 }}>
                    <Tooltip title={t("menu.trip_list.see_document")}>
                        <span>
                            <IconButton size="small" className={received ? classes.colorBlue : classes.colorGrey} onClick={seeDocument} disabled={!received}>
                                { seeSpinner ? <CircularProgress size={20} className={classes.colorBlue} /> : <VisibilityOutlinedIcon /> }
                            </IconButton>
                        </span>
                    </Tooltip>
                </TableCell>
                <TableCell className={`${classes.borderTableCell} ${classes.textCenter}`} style={{ width: 0 }}>
                    <Tooltip title={t("global.download")}>
                        <span>
                            <IconButton size="small" className={received ? classes.colorBlue : classes.colorGrey} onClick={downloadDocument} disabled={!received}>
                                { downloadSpinner ? <CircularProgress size={20} className={classes.colorBlue} /> : <SaveAltOutlinedIcon /> }
                            </IconButton>
                        </span>
                    </Tooltip>
                </TableCell>
                <TableCell className={classes.borderTableCell} style={{ width: 30 }}/>
            </TableRow>
        );
    } else if (mode === 'condition') {
        return (
            <Grid container alignItems="center" justify="space-between" style={{ height: 42 }}>
                <Grid item className={classes.textCenter}>
                    <Tooltip title={t("global.upload")}>
                        <span>
                            <IconButton size="small" className={classes.colorBlue} component="label">
                                { uploadSpinner ? <CircularProgress size={20} className={classes.colorBlue} /> : <PublishOutlinedIcon /> }
                                <input hidden type="file" onChange={(e) => uploadDocument(e)} />
                            </IconButton>
                        </span>
                    </Tooltip>
                </Grid>
                <Grid item className={classes.textCenter}>
                    <Tooltip title={t("menu.trip_list.see_document")} >
                        <span>
                            <IconButton size="small" className={received ? classes.colorBlue : classes.colorGrey} onClick={seeDocument} disabled={!received}>
                                { seeSpinner ? <CircularProgress size={20} className={classes.colorBlue} /> : <VisibilityOutlinedIcon /> }
                            </IconButton>
                        </span>
                    </Tooltip>
                </Grid>
                <Grid item className={classes.textCenter}>
                    <span>
                        <Tooltip title={t("global.download")}>
                            <span>
                                <IconButton size="small" className={received ? classes.colorBlue : classes.colorGrey} onClick={downloadDocument} disabled={!received}>
                                    { downloadSpinner ? <CircularProgress size={20} className={classes.colorBlue} /> : <SaveAltOutlinedIcon /> }
                                </IconButton>
                            </span>
                        </Tooltip>
                    </span>
                </Grid>
                <Grid item style={{ width: 30 }}/>
            </Grid>
        );
    }
});
export default DocumentActionBar;
