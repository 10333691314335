import React, { useMemo } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Tooltip from '@material-ui/core/Tooltip';
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Commute from "@material-ui/icons/Commute";
import Hotel from "@material-ui/icons/Hotel";
import AccountBalance from "@material-ui/icons/AccountBalance";
import green from "@material-ui/core/colors/green";
import grey from "@material-ui/core/colors/grey";
import moment from "moment";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { StepsDatesManager } from "../Itinerary/utils/stepsDatesManager";

const useColorlibStepIconStyles = makeStyles(theme => ({
    root: {
        backgroundColor: "white",
        zIndex: 1,
        color: grey[400],
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: `2px solid ${grey[400]}`,
        cursor: 'pointer'
    },
    active: {
        color: "#e6592f",
        boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)"
    },
    defaultContainer: {
        padding: '4px 8px',
        width: 186,
        height: 64
    },
    destinationContainer: {
        padding: '4px 8px',
        width: 160,
        [theme.breakpoints.down('sm')]: {
            width: 186
        }
    },
    iconContainer: {
        borderLeft: `2px solid ${grey[400]}`,
        backgroundColor: "white",
        padding: 2,
        width: 26
    },
    iconActive: {
        color: green[500],
        cursor: "pointer",
        float: 'left'
    },
    iconInactive: {
        color: grey[400],
        float: 'left'
    },
    italic: {
        fontStyle: 'italic'
    },
    itiIndex: {
        fontStyle: 'italic',
        borderRadius: '50%',
        border: '1px solid',
        padding: '0px 8px 0px 7px'
    },
    itiIndexActive: {
        backgroundColor: 'white',
        color: '#e6592f'
    },
    marginRight: {
        marginRight: 4
    },
    ellipsis: {
        "overflow": 'hidden',
        "textOverflow": 'ellipsis',
        "display": '-webkit-box',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical'
    },
    startEndDestination: {
        textAlign: 'center',
        color: 'black',
        fontWeight: 'bold'
    },
    alignCenter: {
        textAlign: 'center'
    },
    black: {
        color: 'black'
    },
    bold: {
        fontWeight: 'bold'
    },
    smallBubble: {
        padding: '0px 5px 0px 4px'
    },
    smallText: {
        fontSize: '10px'
    }
}));

const MaterialTimelineItem = ({ index, itinerary_step }) => {
    console.log("timeline item render");
    const dispatch = useDispatch();
    const user = useSelector(state => state.user.user);
    const manual_cart = useSelector(state => state.cart.manual_item_list);
    const car_cart = useSelector(state => state.cars_search.cart);
    const accommodation_cart = useSelector(state => state.accommodation.cart);
    const poi_cart = useSelector(state => state.poi.cart);
    const departure_destination = useSelector(state => state.itinerary.departure_destination);
    const return_destination = useSelector(state => state.itinerary.return_destination);
    const itinerary_list = useSelector(state => state.itinerary.itinerary_list);
    const active = useSelector(state => state.base.active);
    const classes = useColorlibStepIconStyles();
    const moment_start_date = moment.utc(itinerary_step.start_date);
    const moment_end_date = moment.utc(itinerary_step.end_date);
    const tripStartDate = useSelector(state => state.trip.start_date);
    const tripEndDate = useSelector(state => state.trip.end_date);
    const theme = useTheme();
    const tablet = useMediaQuery(theme.breakpoints.down('sm'));
    const quotation_code = JSON.parse(localStorage.getItem('config')).quotation_code;
    const datesManager = useMemo(() => {
        if (tripStartDate && tripEndDate) {
            return new StepsDatesManager(tripStartDate, tripEndDate);
        }
        return null;
    }, [tripStartDate, tripEndDate]);
    let has_poi = false;
    let has_accommodation = false;
    let has_car = false;
    let step_poi = [];
    let step_accommodation = [];
    let step_car = [];
    if (itinerary_step.destination !== null) {
        poi_cart.map((poi) => {
            if ((poi.booking_status === null || poi.booking_status.status_booking !== "CANCELLED") && (moment.utc(poi.start_date).isAfter(moment.utc(moment_start_date)) || moment.utc(poi.start_date).isSame(moment.utc(moment_start_date), "d")) && (moment.utc(poi.start_date).isBefore(moment.utc(moment_end_date)) || moment.utc(poi.start_date).isSame(moment.utc(moment_end_date), "d"))) {
                step_poi.push(poi);
                has_poi = true;
            }
        });
        accommodation_cart.map((accommodation) => {
            if ((accommodation.booking_status === null || accommodation.booking_status.status_booking !== "CANCELLED") && moment.utc(accommodation.start_date).isBetween(moment_start_date, moment_end_date, 'd', '[]') && moment.utc(accommodation.end_date).isBetween(moment_start_date, moment_end_date, 'd', '[]')) {
                step_accommodation.push(accommodation);
                has_accommodation = true;
            }
        });
        car_cart.map((car) => {
            if ((car.booking_status === null || car.booking_status.status_booking !== "CANCELLED") && (moment_start_date.isBetween(moment.utc(car.start_date), moment.utc(car.end_date)) || moment.utc(car.start_date).isBetween(moment_start_date, moment_end_date))) {
                step_car.push(car);
                has_car = true;
            }
        });
        manual_cart.map((item) => {
            if ((user.client_full.type === 2 || (item.creator !== null && item.creator.client.type !== 2) || quotation_code === 'verdie') && (item.product_type === 0 || item.product_type === 7) && moment.utc(item.start_date).isBetween(moment_start_date, moment_end_date, 'd', '[]') && moment.utc(item.end_date).isBetween(moment_start_date, moment_end_date, 'd', '[]')) {
                step_accommodation.push(item);
                has_accommodation = true;
            } else if ((user.client_full.type === 2 || (item.creator !== null && item.creator.client.type !== 2) || quotation_code === 'verdie') && (item.product_type === 2 || item.product_type === 4) && (moment_start_date.isBetween(moment.utc(item.start_date), moment.utc(item.end_date), 'd', '[]') || moment.utc(item.start_date).isBetween(moment_start_date, moment_end_date, 'd', '[]'))) {
                step_car.push(item);
                has_car = true;
            } else if ((user.client_full.type === 2 || (item.creator !== null && item.creator.client.type !== 2) || quotation_code === 'verdie') && !item.auto_product && (item.product_type === 1 || item.product_type === 3 || item.product_type === 9 || item.product_type === 10 || item.product_type === 11 || item.product_type === 12 || item.product_type === 13) && moment.utc(item.start_date).isBetween(moment_start_date, moment_end_date, 'd', '[]') && moment.utc(item.end_date).isBetween(moment_start_date, moment_end_date, 'd', '[]')) {
                step_poi.push(item);
                has_poi = true;
            }
        });
    }
    const setActiveDestination = () => {
        if (itinerary_step.destination !== null) {
            const step_index = itinerary_list.findIndex(iti => iti.id === itinerary_step.id);
            let hasLateArrival = false;
            let hasLateDeparture = false;
            let start_date = itinerary_step.start_date.split('T')[0];
            let end_date = itinerary_step.end_date.split('T')[0];
            if (step_index !== -1 && step_index !== 0) {
                hasLateArrival = datesManager?.isLateArrival(itinerary_list[step_index].start_date);
                hasLateDeparture = datesManager?.isLateArrival(itinerary_list[step_index].end_date);
                if (hasLateArrival) {
                    start_date = (moment(itinerary_list[step_index].start_date).subtract(1, 'days')).format("YYYY-MM-DD");
                }
                if (hasLateDeparture) {
                    end_date = (moment(itinerary_list[step_index].end_date).subtract(1, 'days')).format("YYYY-MM-DD");
                }
            }
            const bounds = JSON.parse(itinerary_step.destination.data.bounds.replace(/'/g, '"'));
            dispatch({
                type: "MATERIAL_SET_ACTIVE_DESTINATION",
                payload: {
                    active: itinerary_step.id,
                    bounds: {
                        north: bounds.north,
                        east: bounds.east,
                        south: bounds.south,
                        west: bounds.west
                    },
                    zoom: itinerary_step.destination.data.zoom_level,
                    start_date: start_date,
                    end_date: end_date,
                    destination: itinerary_step,
                    reset: true
                }
            });
            dispatch({ type: 'ACCOMMODATION_LATE_ARRIVAL_DEPARTURE', payload: { hasLateArrival: hasLateArrival, hasLateDeparture: hasLateDeparture } });
        }
    };
    const setTimelineCart = (type, cart) => {
        dispatch({
            type: "MATERIAL_SET_TIMELINE_CART",
            payload: {
                type: type,
                cart: cart
            }
        });
    };
    return (
        <div className={ clsx(classes.root, { [classes.active]: active === itinerary_step.id }) }>
            <Grid container>
                <Grid className={ itinerary_step.destination === null ? classes.defaultContainer : classes.destinationContainer } container item onClick={ setActiveDestination }>
                    <Grid container item direction={ "column" } justify={ itinerary_step.destination !== null ? "space-between" : "center" }>
                        <Grid container item>
                            {
                                itinerary_step.destination !== null && (
                                    <Grid className={ classes.marginRight } item>
                                        <Typography className={ clsx(classes.itiIndex, { [classes.itiIndexActive]: active === itinerary_step.id, [classes.smallText]: tablet, [classes.smallBubble]: tablet }) }>{ index }</Typography>
                                    </Grid>
                                )
                            }
                            <Grid item xs>
                                <Tooltip title={ itinerary_step.destination !== null ? itinerary_step.destination.data.name !== undefined ? itinerary_step.destination.data.name : itinerary_step.destination.data.international_name : itinerary_step.step_type === "START" ? (departure_destination !== null && departure_destination.city !== null ? `${departure_destination.city}, ${departure_destination.country}` : '-') : itinerary_step.step_type === "END" ? (return_destination !== null && return_destination.city !== null ? `${return_destination.city}, ${return_destination.country}` : '-') : '' } arrow>
                                    <Typography className={ clsx(classes.italic, classes.ellipsis, { [classes.startEndDestination]: itinerary_step.destination === null, [classes.smallText]: tablet }) }>{ itinerary_step.destination !== null ? itinerary_step.destination.data.name !== undefined ? itinerary_step.destination.data.name : itinerary_step.destination.data.international_name : itinerary_step.step_type === "START" ? `${departure_destination.city}, ${departure_destination.country}` : itinerary_step.step_type === "END" ? `${return_destination.city}, ${return_destination.country}` : '' }</Typography>
                                </Tooltip>
                            </Grid>
                        </Grid>
                        {
                            itinerary_step.destination !== null && (
                                <Grid container item>
                                    <Grid item xs={ 12 }>
                                        <Typography className={ clsx(classes.italic, classes.alignCenter, { [classes.smallText]: tablet }) } noWrap={ true } variant={ "body2" }>Du { moment.utc(itinerary_step.start_date).format("D MMM") } au { moment.utc(itinerary_step.end_date).format("D MMM") }</Typography>
                                    </Grid>
                                </Grid>
                            )
                        }
                    </Grid>
                </Grid>
                {
                    itinerary_step.destination !== null && !tablet && (
                        <Grid className={ classes.iconContainer } item container direction={ "column" } justify={ "space-between" }>
                            <Grid item>
                                <Commute className={ (has_car ? classes.iconActive : classes.iconInactive) } fontSize={ "small" } onClick={() => {
                                    setTimelineCart(2, step_car); 
                                }}/>
                            </Grid>
                            <Grid item>
                                <Hotel className={ (has_accommodation ? classes.iconActive : classes.iconInactive) } fontSize={ "small" } onClick={() => {
                                    setTimelineCart(0, step_accommodation); 
                                }}/>
                            </Grid>
                            <Grid item>
                                <AccountBalance className={ (has_poi ? classes.iconActive : classes.iconInactive) } fontSize={ "small" } onClick={() => {
                                    setTimelineCart(1, step_poi); 
                                }}/>
                            </Grid>
                        </Grid>
                    )
                }
            </Grid>
        </div>
    );
};

export default React.memo(MaterialTimelineItem);
