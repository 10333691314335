import { Itinerary } from "../objects/itinerary";

export function sortItinerary(a: Pick<Itinerary, 'step_type' | 'start_date'>, b: Pick<Itinerary, 'step_type' | 'start_date'>): number {
    if (a.step_type === 'START') {
        return -1;
    } else if (a.step_type === 'END') {
        return 1;
    } else if (b.step_type === 'START') {
        return 1;
    } else if (b.step_type === 'END') {
        return -1;
    }

    const aDate = window.moment.utc(a.start_date);
    const bDate = window.moment.utc(b.start_date);
    return aDate.isBefore(window.moment.utc(bDate)) ?
        -1 :
        1;
}
