import React, { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Avatar from '@material-ui/core/Avatar';
import Paper from '@material-ui/core/Paper';
import Fab from '@material-ui/core/Fab';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MenuIcon from '@mui/icons-material/Menu';

import FolderOpenOutlinedIcon from '@material-ui/icons/FolderOpenOutlined';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import StorefrontOutlinedIcon from '@material-ui/icons/StorefrontOutlined';
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import SupervisedUserCircleOutlinedIcon from '@mui/icons-material/SupervisedUserCircleOutlined';

import HasPermission from "../Common/Functions/HasPermission.js";
import CheckBeforeRequest from "../Common/CheckBeforeRequest";
import axios from "axios";

const useStyles = makeStyles(theme => ({
    formControlRoot: {
        borderRadius: "20px",
        textTransform: "capitalize",
        marginTop: 2,
        padding: 7
    },
    orangeButton: {
        backgroundColor: "#E6592F",
        color: "white"
    },
    whiteButton: {
        backgroundColor: "white",
        color: "black"
    },
    customSize: {
        // width: 41,
        height: 41,
        marginTop: 4,
        backgroundColor: "transparent",
        boxShadow: 'none'
    },
    paperStepper: {
        position: "absolute !important",
        top: "100% !important",
        //marginLeft: "-232px",
        right: "16px",
        zIndex: "1 !important",
        background: "rgb(255, 255, 255) !important",
        borderRadius: "32px !important",
        // boxShadow: "rgb(0 0 0 / 20%) 0px 6px 20px !important",
        marginTop: "12px !important",
        maxHeight: "calc(100vh - 220px) !important",
        overflow: "hidden auto !important",
        padding: "16px 32px !important"
    },
    active: {
        backgroundColor: "rgba(0, 0, 0, 0.04)"
    },
    avatar: {
        marginLeft: '16px !important'
    }
}));

const AvatarHeader = ({ router, setIsOpen, isOpen }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
	// let current_location = router.getCurrentLocation().pathname;
    const user = useSelector((store) => {
        return store.user.user;
    });
    const permissions = useSelector((store) => {
        return store.user.permissions;
    });
    const data_trip = useSelector((store) => {
        return store.trip.data_trip;
    });
    const config = JSON.parse(localStorage.getItem("config"));
    const quotation_code = config.quotation_code;
    const handleOpen = () => {
        if (isOpen === null || isOpen !== "avatarHeader") {
            setIsOpen("avatarHeader");
        } else {
            setIsOpen(null);
        }
    };
    const openHelp = () => {
        const { headers } = CheckBeforeRequest();
        axios({
            method: "GET",
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/user/access_support/`
        }).then(function (response) {
            handleOpen();
            let win = window.open(response.data, '_blank');
            win.focus();
        }).catch(function (error) {
            //TODO: show snackbar error
            console.log('error:', error);
        });
    };
    const openVisiteursContact = () => {
        let win = window.open('https://www.visiteurspro.com/pages/a-la-carte', '_blank');
        win.focus();
    };
    let current_location = router.getCurrentLocation();
    return (
        <Fragment>
            {/*<Fab className={`${classes.customSize}`} disableRipple={true} onClick={() => {*/}
            {/*    handleOpen();*/}
            {/*}}>*/}
            {/*    <Avatar alt={user.first_name} src={user.avatar !== null ? (user.avatar.thumbnail_little !== null ? user.avatar.thumbnail_little : user.avatar.url) : ""}/>*/}
            {/*</Fab>*/}
            {
                // checkUrl() &&
                // <Fab className={`${classes.customSize}`} variant={'extended'} disableRipple={true} onClick={() => {
                //     handleOpen()
                // }}>
                //     <MenuIcon />
                //     <Avatar className={classes.avatar} alt={user.first_name} src={user.avatar !== null ? (user.avatar.thumbnail_little !== null ? user.avatar.thumbnail_little : user.avatar.url) : ""}/>
                // </Fab>
            }
            {
                // !checkUrl() &&
                <Avatar style={{cursor: 'pointer'}} className={classes.avatar} alt={user.first_name} src={user.avatar !== null ? (user.avatar.thumbnail_little !== null ? user.avatar.thumbnail_little : user.avatar.url) : ""} onClick={() => {handleOpen()}}/>
            }
            {
                isOpen === "avatarHeader" && (
                    <ClickAwayListener onClickAway={handleOpen}>
                        <Paper
                            className={classes.paperStepper}
                            elevation={5}
                            children={
                                <List>
                                    {
                                        (data_trip === null || (data_trip !== null && data_trip.trip !== undefined && !data_trip.trip.itinerary_type && !data_trip.trip.package_type && !data_trip.trip.circuit_type)) && (
                                            <Fragment>
                                                <ListItem button onClick={() => {
                                                    handleOpen(); current_location.pathname.includes("trip-list") ? dispatch({ type: "MENU_CHANGE_TRIP_INFO_PAGE_AND_SET_INFO", payload: { trip_info_page: 1, trip_info: null } }) : router.push(`/${window.url_name}/menu/trip-list`);
                                                }} className={`${current_location.pathname.includes("trip-list") ? classes.active : ""}`}>
                                                    <ListItemIcon>
                                                        <FolderOpenOutlinedIcon/>
                                                    </ListItemIcon>
                                                    <ListItemText primary={t("header.menu.list_trip")} />
                                                </ListItem>
                                                <Divider/>
                                                <ListItem button onClick={() => {
                                                    handleOpen(); router.push(`/${window.url_name}/menu/profile`);
                                                }} className={`${current_location.pathname.includes("profile") ? classes.active : ""}`}>
                                                    <ListItemIcon>
                                                        <PersonOutlineOutlinedIcon />
                                                    </ListItemIcon>
                                                    <ListItemText primary={t("header.menu.profile")} />
                                                </ListItem>
                                                <Divider/>
                                                {
                                                    quotation_code !== "verdie" && quotation_code !== "marcovasco" && user.client_full.type !== 1 && HasPermission(permissions, "view_client") === true && (
                                                        <Fragment>
                                                            <ListItem button onClick={() => {
                                                                handleOpen(); router.push(`/${window.url_name}/menu/agency-user-management`);
                                                            }} className={`${current_location.pathname.includes("agency-user-management") ? classes.active : ""}`}>
                                                                <ListItemIcon>
                                                                    <GroupOutlinedIcon />
                                                                </ListItemIcon>
                                                                <ListItemText primary={t("header.menu.agency_users")} />
                                                            </ListItem>
                                                            <Divider/>
                                                        </Fragment>
                                                    )
                                                }
                                                {
                                                    quotation_code !== "verdie" && quotation_code !== "marcovasco" && user.client_full.type !== 1 && HasPermission(permissions, "change_marginprice") === true && (
                                                        <Fragment>
                                                            <ListItem button onClick={() => {
                                                                handleOpen(); router.push(`/${window.url_name}/menu/agency-margin`);
                                                            }} className={`${current_location.pathname.includes("agency-margin") ? classes.active : ""}`}>
                                                                <ListItemIcon>
                                                                    <StorefrontOutlinedIcon />
                                                                </ListItemIcon>
                                                                <ListItemText primary={t("header.menu.agency_margin")} />
                                                            </ListItem>
                                                            <Divider/>
                                                        </Fragment>
                                                    )
                                                }
                                                {
                                                    quotation_code !== "verdie" && quotation_code !== "marcovasco" && user.client_full.type !== 1 && HasPermission(permissions, "view_user") === true && (
                                                        <Fragment>
                                                            <ListItem button onClick={() => {
                                                                handleOpen(); router.push(`/${window.url_name}/menu/agency-profile`);
                                                            }} className={`${current_location.pathname.includes("agency-profile") ? classes.active : ""}`}>
                                                                <ListItemIcon>
                                                                    <AccountBalanceIcon />
                                                                </ListItemIcon>
                                                                <ListItemText primary={t("header.menu.agency_profile")} />
                                                            </ListItem>
                                                            <Divider/>
                                                        </Fragment>
                                                    )
                                                }
                                            </Fragment>
                                        )
                                    }
                                    {
                                        quotation_code === 'visiteurs' && user.client_full.type === 2 &&
                                        <>
                                            <ListItem button onClick={openVisiteursContact}>
                                                <ListItemIcon>
                                                    <AlternateEmailIcon/>
                                                </ListItemIcon>
                                                <ListItemText primary={t('global.visiteurs_contact')} />
                                            </ListItem>
                                            <Divider/>
                                        </>
                                    }
                                    <ListItem button onClick={openHelp}>
                                        <ListItemIcon>
                                            <HelpOutlineOutlinedIcon/>
                                        </ListItemIcon>
                                        <ListItemText primary={quotation_code === 'visiteurs' && user.client_full.type === 2 ? t("global.tutorial") : t('global.help')} />
                                    </ListItem>
                                    <Divider/>
                                    <ListItem button onClick={ () => {
                                        let cookies = document.cookie.split("; ");
                                        for (let c = 0; c < cookies.length; c++) {
                                            let d = window.location.hostname.split(".");
                                            while (d.length > 0) {
                                                let cookieBase = encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) + "=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=" + d.join(".") + " ;path=";
                                                let p = location.pathname.split("/");
                                                document.cookie = cookieBase + "/";
                                                while (p.length > 0) {
                                                    document.cookie = cookieBase + p.join("/");
                                                    p.pop();
                                                }
                                                d.shift();
                                            }
                                        }
                                        handleOpen();
                                        router.push(`/home`);
                                        dispatch({
                                            type: "USER_SET_CONNECTION",
                                            payload: {
                                                modal_connection: false,
                                                is_connected: false,
                                                user: null,
                                                token: null
                                            }
                                        });
                                    } }>
                                        <ListItemIcon>
                                            <ExitToAppOutlinedIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={t("header.menu.logout")} />
                                    </ListItem>
                                </List>
                            }
                        />
                    </ClickAwayListener>
                )
            }
        </Fragment>
    );
};
export default React.memo(AvatarHeader);
