//---- Dependencies ----//
import React, { Fragment } from "react";
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

//---- Functions ----//
import GetPricesId from "../../Cart/Functions/Margin/GetPricesId.js";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

const InfoReloadAccommodation = () => {
	const { t } = useTranslation();
	const user = useSelector(state => state.user.user);
	const currency = useSelector(state => state.trip.currency);
	const language = useSelector(state => state.header.tmp_language);
	const update_infos = useSelector(state => state.cart.update_infos);
	let rows = [];
	update_infos.accommodations.sort((a, b) => {
		if (a.old !== undefined && a.old !== null && (b.old === undefined || b.old === null)) {
			return -1;
		}
		return 1;
	});
	update_infos.accommodations.map((accommodation) => {
		let hotel = rows.find((hotel) => {
			if (accommodation.old !== undefined && accommodation.old !== null && accommodation.old.hotel[0] !== undefined && accommodation.new !== undefined && accommodation.new !== null && accommodation.new.hotel !== undefined) {
				return hotel.old_name === accommodation.old.hotel[0].name && hotel.old_start_date === accommodation.old.start_date && hotel.new_name === accommodation.new.hotel[0].name && hotel.new_start_date === accommodation.new.start_date
			} else if (accommodation.new !== undefined && accommodation.new !== null && accommodation.new.hotel !== undefined) {
				return hotel.new_name === accommodation.new.hotel[0].name && hotel.new_start_date === accommodation.new.start_date;
			} else if (accommodation.old !== undefined && accommodation.old !== null && accommodation.old.hotel !== undefined) {
				return hotel.old_name === accommodation.old.hotel[0].name && hotel.old_start_date === accommodation.old.start_date && (accommodation.new === undefined || accommodation.new === null)
			}
			return false;
		});
		if (hotel !== undefined && hotel !== null) {
			if (accommodation.old !== undefined && accommodation.old !== null && accommodation.old.rooms !== undefined && accommodation.old.rooms.length > 0) {
				hotel.diff_price -= accommodation.old.prices !== undefined ? parseFloat(accommodation.old.prices[GetPricesId(accommodation.old.prices, user.client, user)].selling_price) : 0;
				accommodation.old.rooms.map((room) => {
					hotel.old_rooms.push(room);
				});
			} else {
				hotel.old_rooms.push(null);
			}
			if (accommodation.new !== undefined && accommodation.new !== null && accommodation.new.hotel !== undefined && accommodation.new.rooms !== undefined && accommodation.new.rooms.length > 0) {
				hotel.diff_price += accommodation.new.prices !== undefined ? parseFloat(accommodation.new.prices[GetPricesId(accommodation.new.prices, user.client, user)].selling_price) : 0;
				accommodation.new.rooms.map((room) => {
					hotel.new_rooms.push(room);
				});
			} else {
				hotel.new_rooms.push(null);
			}
			hotel.new_error = accommodation.new !== undefined && accommodation.new !== null && accommodation.new.error !== undefined && accommodation.new.error !== null && accommodation.new.error !== "" ? accommodation.new.error : null;
		} else {
			let row = {
				diff_price: 0,
				old_rooms: [],
				new_rooms: []
			};
			if (accommodation.old !== undefined && accommodation.old !== null && accommodation.old.hotel[0] !== undefined && accommodation.old.rooms !== undefined && accommodation.old.rooms.length > 0) {
				row.old_name = accommodation.old.hotel[0].name;
				row.old_city_name = accommodation.old.hotel[0].city_name;
				row.old_start_date = accommodation.old.start_date;
				row.old_end_date = accommodation.old.end_date;
				row.diff_price -= accommodation.old.prices !== undefined ? parseFloat(accommodation.old.prices[GetPricesId(accommodation.old.prices, user.client, user)].selling_price) : 0;
				accommodation.old.rooms.map((room) => {
					row.old_rooms.push(room);
				});
			} else {
				row.old_name = null;
				row.old_city_name = null;
				row.old_start_date = null;
				row.old_end_date = null;
				row.old_error = null;
				row.old_rooms.push(null);
			}
			if (accommodation.new !== undefined && accommodation.new !== null && accommodation.new.hotel !== undefined && accommodation.new.rooms !== undefined && accommodation.new.rooms.length > 0) {
				row.new_name = accommodation.new.hotel[0].name;
				row.new_city_name = accommodation.new.hotel[0].city_name;
				row.new_start_date = accommodation.new.start_date;
				row.new_end_date = accommodation.new.end_date;
				row.diff_price += accommodation.new.prices !== undefined ? parseFloat(accommodation.new.prices[GetPricesId(accommodation.new.prices, user.client, user)].selling_price) : 0;
				accommodation.new.rooms.map((room) => {
					row.new_rooms.push(room);
				});
			} else {
				row.new_name = null;
				row.new_city_name = null;
				row.new_start_date = null;
				row.new_end_date = null;
				row.new_rooms.push(null);
			}
			row.new_error = accommodation.new !== undefined && accommodation.new !== null && accommodation.new.error !== undefined && accommodation.new.error !== null && accommodation.new.error !== "" ? accommodation.new.error : null;
			rows.push(row);
		}
	});
	rows.sort((a, b) => {
		if (moment(a.old_start_date).isBefore(b.old_start_date)) {
			return -1;
		}
		return 1;
	});
	return (
		<Fragment>
			{
				rows.map((hotel, index_hotel) => {
					let old_night_diff = hotel.old_start_date !== null && hotel.old_end_date !== null ? moment.utc(hotel.old_end_date).diff(moment.utc(hotel.old_start_date), "d") : null;
					let new_night_diff = hotel.new_start_date !== null && hotel.new_end_date !== null ? moment.utc(hotel.new_end_date).diff(moment.utc(hotel.new_start_date), "d") : null;
					return (
						<TableRow key={ index_hotel }>
							<TableCell>{ hotel.new_start_date !== null ? moment.utc(hotel.new_start_date).format("DD-MM-YYYY") : '-' }</TableCell>
							<TableCell>{ hotel.new_city_name !== null ? hotel.new_city_name : (hotel.old_city_name !== null ? hotel.old_city_name : '-') }</TableCell>
							<TableCell><strong>{ t("global.hotel") } { hotel.old_name !== null ? hotel.old_name : '-' }{ old_night_diff !== null ? ` ${old_night_diff} ${old_night_diff > 1 ? t('itinerary.nights') : t('itinerary.night')}` : '' }</strong>
								<div className={ "italic" }>
									{ hotel.old_rooms.map((room, index_room) => <div key={ index_room }>{ room !== null ? `- ${t("global.room")} ${index_room + 1}: ${room}` : '-' }</div>) }
								</div>
							</TableCell>
							<TableCell padding={ "none" }><strong>{ t("global.hotel") } { hotel.new_name !== null ? hotel.new_name : '-' }{ new_night_diff !== null ? ` ${new_night_diff} ${new_night_diff > 1 ? t('itinerary.nights') : t('itinerary.night')}` : '' }</strong>
								{
									hotel.new_error === null ? (
										<div className={ "italic" }>
											{ hotel.new_rooms.map((room, index_room) => <div key={ index_room }>{ room !== null ? `- ${t("global.room")} ${index_room + 1}: ${room}` : '-' }</div>) }
										</div>
									) : (
										<div className={ "italic" }><div>{ hotel.new_error }</div></div>
									)
								}
							</TableCell>
							<TableCell>
								<br/>
								<div className={ hotel.diff_price === 0 ? null : (hotel.diff_price < 0 ? "ft-green" : "ft-red")}>
									{ hotel.diff_price > 0 ? "+" + Intl.NumberFormat(language, { style: "currency", currency: currency.iso_code }).format(hotel.diff_price.toFixed(2)) : Intl.NumberFormat(language, { style: "currency", currency: currency.iso_code }).format(hotel.diff_price.toFixed(2)) }
								</div>
							</TableCell>
						</TableRow>
					)
				})
			}
		</Fragment>
	);
};

export default React.memo(InfoReloadAccommodation);