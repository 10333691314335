import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
    Tab, 
    Tabs
} from "@mui/material";
import { CartConstructionProductsHeader } from "./CartConstructionProductsHeader";
import {
    setTextsView
} from "./redux/cartConstructionReducer";
import { AppState } from "../../Reducers/Reducers";

type Props = {
    stickedActions?: (content: React.ReactNode) => React.ReactNode,
    extraStickedActions?: () => React.ReactNode,
    stickyOffset?: number,
    minimal?: boolean
}

export function CartConstructionContentsHeader(props: Props): JSX.Element {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const textsView = useSelector((state: AppState) => state.cartConstruction.textsView);
    const quotationCode = JSON.parse(localStorage.getItem('config') ?? '{}').quotation_code;
    const isRoadbookModuleEnabled = quotationCode === 'cercledesvoyages' && !props.minimal;

    const onChangeTextsView = (value: typeof textsView) => {
        dispatch(setTextsView(value));
    };

    return (
        <>
            <CartConstructionProductsHeader
                stickedActions={props.stickedActions}
                extraStickedActions={props.extraStickedActions}
                cartDisplay="product"
                page="contents"
                onToggleCartDisplay={() => null}
                stickyOffset={props.stickyOffset}
                enabledButtons={['traveler-website']}
                fullHeight
                disableProductsFilters
            />
            {
                isRoadbookModuleEnabled &&
                <Tabs
                    value={textsView}
                    onChange={(_, value) => onChangeTextsView(value)}
                    sx={{ marginBottom: 3 }}
                    centered
                >
                    <Tab
                        label={t<string>('cart-material.cart-construction-quotation')}
                        value="quotation"
                    />
                    <Tab
                        label={t<string>('cart-material.cart-construction-roadbook')}
                        value="roadbook"
                    />
                </Tabs>
            }
        </>
    );
}
