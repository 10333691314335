import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';
import { useSnackbar } from "notistack";
import { makeStyles, useTheme } from "@material-ui/core";

import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Button from "@material-ui/core/Button";

import CheckBeforeRequest from "../Common/CheckBeforeRequest";

import RefreshToBook from './Functions/RefreshToBook';
import { AppState } from "../../../Reducers/Reducers";

import axios from "axios";
import GetCookie from '../Common/Functions/GetCookie';

const useStyles = makeStyles((theme) => ({
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
    fontWeight400:{
        fontWeight: 400
    },
    fontSize12: {
        fontSize: 12
    },
    letterSpacing: {
        letterSpacing: 1.25
    },
    textCenter:{
        textAlign: "center"
    },
    genericPadding:{
        padding: "8px 0px"
    },
    formControlRootSmall: {
        width: "100%",
        "& .mui-jss-MuiOutlinedInput-input": {
            padding: '7px 20px;'
        },
        "&& .mui-jss-MuiSvgIcon-root" : {
            color: "black"
        },
        "& .mui-jss-MuiFormLabel-root": {
            fontWeight: 400,
            fontSize: 12,
            zIndex: -1
        },
        "&& .mui-jss-MuiInputLabel-outlined": {
	    	transform: 'translate(20px, 10px) scale(1)'
	    },
        "& .mui-jss-MuiInputLabel-outlined.mui-jss-MuiInputLabel-shrink": {
	    	transform: 'translate(14px, -6px) scale(0.75)'
	    }
    },
    formControlRootSmallRed: {
        width: "100%",
        "& .mui-jss-MuiOutlinedInput-input": {
            padding: '7px 20px;'
        },
        "& .mui-jss-MuiOutlinedInput-notchedOutline": {
            borderColor: "red"
        },
        "&& .mui-jss-MuiSvgIcon-root" : {
            color: "black"
        },
        "& .mui-jss-MuiFormLabel-root": {
            fontWeight: 400,
            fontSize: 12,
            zIndex: -1
        },
        "&& .mui-jss-MuiInputLabel-outlined": {
	    	transform: 'translate(20px, 10px) scale(1)'
	    },
        "& .mui-jss-MuiInputLabel-outlined.mui-jss-MuiInputLabel-shrink": {
	    	transform: 'translate(14px, -6px) scale(0.75)'
	    }
    },
    circleBorder:{
        borderRadius: 8
    },
    validateButton: {
        color: "#FFFFFF",
        backgroundColor: "#2C98F0",
        float: "right",
        marginTop: 8
    },
    errorInfo:{
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: 900,
        letterSpacing: 1.25,
        color: "red",
        width: "100%",
        fontSize: 10
    }
}))
type HotelRequiredProps = {
    product: any,
}
export function HotelRequired (props: HotelRequiredProps): JSX.Element {
    const { product } = props;
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme();
    const downDesktop = useMediaQuery(theme.breakpoints.down('sm'));
    const checks_passed = useSelector((state: AppState) => state.booking.checks_passed);
    const end_date = useSelector((state: AppState) => state.trip.end_date);

    const [hotelName, setHotelName] = useState<string>("");

    const handleHotelName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setHotelName(event.target.value);
    };

    const confirmRequiredHotel = () => {
        let { pass_check, headers } = CheckBeforeRequest();
        // let custom_product = {...product.custom_product};
        // custom_product.hotel = hotelName;
        if(pass_check){
            axios({
                method: "PATCH",
                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/custom-products/${product.id}/`,
                headers: headers,
                data: JSON.stringify({
                    hotel: hotelName
                })
            }).then(function(response){
                dispatch({type: 'POI_EDIT_CART_BY_ID', payload: response.data});
                RefreshToBook(product, end_date, dispatch, "RequiredHotel");
            }).catch(function(error){
                if(error.response.status === 409 && error.response.data.detail !== undefined){
                    enqueueSnackbar(error.response.data.detail, { variant: "error", disableWindowBlurListener: true, autoHideDuration: 6000 });  
                }else {
                    enqueueSnackbar(t<string>("cart.product_fail"), { variant: "error", disableWindowBlurListener: true, autoHideDuration: 3500 });
                }
            })
        }

    }

    return(
        <Grid>
            <Grid container alignItems="baseline" justify="space-between" className={classes.genericPadding} style={!downDesktop ? { paddingTop: 16 } : {}}>
                <Grid item md={6} xs={12} className={`${!downDesktop ? "" : classes.textCenter} ${classes.genericText} ${classes.fontWeight400} ${classes.fontSize12} ${classes.letterSpacing}`}>
                    { t<string>('booking.required_hotel')}
                </Grid>
                <Grid item md={6} xs={12}>
                    <FormControl variant="outlined" className={checks_passed === false && (product.custom_product.hotel === null || product.custom_product.hotel === '') ? classes.formControlRootSmallRed : classes.formControlRootSmall}>
                        <InputLabel id={`required-hotel-name-label`} htmlFor={"required-hotel-name-input"}>
                            { t<string>("booking.required_hotel_label") }
                        </InputLabel>   
                        <OutlinedInput
                            id={`required-hotel-name-input`}
                            value={hotelName}
                            className={classes.circleBorder}
                            onChange={handleHotelName}
                            label={t<string>("booking.required_hotel_label")}
                        />
                    </FormControl>
                </Grid>
            </Grid>
            
            <Button variant="contained" className={classes.validateButton} onClick={() => { confirmRequiredHotel() }} size="small">
                {t<string>("global.validate")}
            </Button>
        </Grid>
    )
}