//Dependencies
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import React, { useContext, useMemo, useState } from 'react';
import {
    findLast,
    mapValues,
    uniq
} from 'lodash';
//Core
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {
    Box,
    Button,
    Checkbox,
    Chip,
    Collapse,
    Divider,
    IconButton,
    Stack
} from '@mui/material';
import { Close, CloudCircle, Send } from '@mui/icons-material';
//Components
import { CartConstructionProductsContext } from './utils/cartConstructionProductsContext';
import CartManualProduct from './CartManualProduct';
import { CartSendProviderQuotationDemandModal } from "./CartSendProviderQuotationDemandModal";
import { CartConstructionProductsTableItemStatus } from './CartConstructionProductsTableItemStatus';
import { CartProductCardProviderLogo } from './CartProductCardProviderLogo';
import { CartAccommodationCard } from "./CartAccommodationCard";
import { CartPoiCard } from "./CartPoiCard";
import { CartCarCard } from "./CartCarCard";
import { CartTransferCard } from './CartTransferCard';
import { CartFlightCard } from './CartFlightCard';
import { CartConstructionPackageMargin } from './CartConstructionPackageMargin';
import { CartStackedCancellationRules } from './CartStackedCancellationRules';
import CartDeleteModal from './CartDeleteModal';
import { isProductSamePackage } from './utils/isProductSamePackage';
import { isProductPackaged } from './utils/isProductPackaged';
import { usePackagedProducts } from './utils/packagedProducts';
import { usePackagePrice } from './utils/packagePrice';
import { useCartPackagesUpdate } from "./network/cartPackagesUpdate";
import { useShowError } from '../Utils/showError';
import { useCartSummarizedProducts } from './utils/cartSummarizedProducts';
import { useDeleteProduct } from './utils/deleteProduct';
import { changePackageBooking } from './redux/cartConstructionReducer';
import GetCookie from '../Common/Functions/GetCookie';
//Icons
import DashboardRounded from '@material-ui/icons/DashboardRounded';
import { StatusBooking } from '../Itinerary/objects/statusBooking';

const useStyles = makeStyles({
    lockedItemsContainer: {
        padding: 16,
        border: '2px solid #2F80ED',
        borderRadius: 16,
        marginTop: 16,
        marginBottom: 16
    },
    packageHeader: {
        justifyContent: 'space-between',
        marginBottom: 16
    },
    packageTotalCost: {
        fontWeight: 'bold',
        fontSize: 24,
        marginBottom: 8
    },
    validateButton: {
        backgroundColor: '#E6592F',
        color: 'white'
    },
    lockedItemsTag: {
        textAlign: 'center',
        color: '#2F80ED',
        border: '2px solid #2F80ED',
        fontWeight: 'bold',
        borderRadius: 16,
        padding: '2px 30px'
    }
});

const LockedItems = ({ collapse }) => {
    const packagedProducts = usePackagedProducts();
    const grouppedPois = mapValues(
        packagedProducts,
        (item) => item.pois.filter((item) => {
            return item.type === 'normal';
        }).map((item) => {
            return item.poi;
        })
    );
    const grouppedFlights = mapValues(
        packagedProducts,
        (item) => item.flights.filter((item) => {
            return item.type === 'normal';
        }).map((item) => {
            return item.flight;
        })
    );
    const grouppedCars = mapValues(
        packagedProducts,
        (item) => item.cars.filter((item) => {
            return item.type === 'normal';
        }).map((item) => {
            return item.car;
        })
    );
    const grouppedTransfers = mapValues(
        packagedProducts,
        (item) => item.transfers.filter((item) => {
            return item.type === 'normal';
        }).map((item) => {
            return item.transfer;
        })
    );
    const grouppedAccommodations = mapValues(
        packagedProducts,
        (item) => item.accommodations.filter((item) => {
            return item.type === 'normal';
        }).map((item) => {
            return item.accommodation;
        })
    );
    const grouppedManualProducts = mapValues(
        packagedProducts,
        (item) => item.manualProducts.concat(
            item.pois.filter((item) => {
                return item.type === 'manual';
            }).map((item) => {
                return item.poi;
            })
        ).concat(
            item.flights.filter((item) => {
                return item.type === 'manual';
            }).map((item) => {
                return item.flight;
            })
        ).concat(
            item.cars.filter((item) => {
                return item.type === 'manual';
            }).map((item) => {
                return item.car;
            })
        ).concat(
            item.transfers.filter((item) => {
                return item.type === 'manual';
            }).map((item) => {
                return item.transfer;
            })
        ).concat(
            item.accommodations.filter((item) => {
                return item.type === 'manual';
            }).map((item) => {
                return item.accommodation;
            })
        )
    );
    const packageKeys = uniq(
        Object.keys(grouppedPois).concat(
            Object.keys(grouppedFlights)
        ).concat(
            Object.keys(grouppedCars)
        ).concat(
            Object.keys(grouppedTransfers)
        ).concat(
            Object.keys(grouppedAccommodations)
        ).concat(
            Object.keys(grouppedManualProducts)
        )
    );

    return packageKeys.map((key, index) => {
        return (
            <>
                <Package
                    key={key}
                    stackNumber={key.split('-')[0] ? parseInt(key.split('-')[0]) : null}
                    stackInfoId={key.split('-')[1] ? parseInt(key.split('-')[1]) : null}
                    flights={grouppedFlights[key] ?? []}
                    cars={grouppedCars[key] ?? []}
                    transfers={grouppedTransfers[key] ?? []}
                    pois={grouppedPois[key] ?? []}
                    accommodations={grouppedAccommodations[key] ?? []}
                    manual_products={grouppedManualProducts[key] ?? []}
                    isFirst={index === 0}
                    collapse={collapse}
                />
            </>
        );
    });
};

const Package = ({
    stackInfoId,
    stackNumber,
    flights,
    cars,
    transfers,
    pois,
    accommodations,
    manual_products,
    isFirst,
    collapse
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    //const [hasLockedItems, setHasLockedItems] = useState(false);
    const [openQuotationDemandModal, setOpenQuotationDemandModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [deletingPackage, setDeletingPackage] = useState(false);
    const user = useSelector(state => state.user.user);
    const language = useSelector(state => state.header.tmp_language);
    const trip = useSelector(state => state.trip.data_trip);
    const step = useSelector((state) => state.cart.step);
    const providers = useSelector((state) => state.trip.providers);
    const manualProviders = useSelector((state) => state.trip.manual_providers);
    const allProviders = useMemo(() => {
        return providers.concat(manualProviders);
    }, [providers, manualProviders]);
    const checkedForBooking = useSelector((state) => {
        return state.cartConstruction.bookablePackages.findIndex((item) => {
            return isProductSamePackage(
                item,
                {
                    stack_number: stackNumber,
                    stack_info_id: stackInfoId
                }
            );
        }) >= 0;
    });
    const context = useContext(CartConstructionProductsContext);
    const stackInfo = useMemo(() => {
        return trip?.stack_info?.find((item) => {
            return isProductSamePackage(
                {
                    stack_info_id: item.id,
                    stack_number: item.stack_number
                },
                {
                    stack_number: stackNumber,
                    stack_info_id: stackInfoId
                }
            );
        });
    }, [trip, stackNumber, stackInfo]);
    const provider = useMemo(() => {
        return providers.concat(manualProviders).find((item) => {
            return item.provider.id === stackInfo?.provider;
        })?.provider;
    }, [stackInfo, providers, manualProviders]);
    const prices = usePackagePrice({ stackNumber, stackInfoId });
    const showError = useShowError();
    const products = useCartSummarizedProducts();
    const updatePackages = useCartPackagesUpdate({
        onError(error) {
            console.error(error);
            showError(error);
        }
    });
    const {
        onDeleteAccommodation,
        onDeleteCar,
        onDeleteFlight,
        onDeleteManualProduct,
        onDeletePoi,
        onDeleteTransfer
    } = useDeleteProduct();

    const onShowBookingDetail = () => {
        dispatch({
            type: 'CART_TOGGLE_BOOKING_DETAIL',
            payload: {
                ...stackInfo,
                isConnectedPackage: true,
                product_type: 24
            }
        });
    };

    const onChangeBooking = (checked) => {
        dispatch(
            changePackageBooking({
                stack_number: stackNumber,
                stack_info_id: stackInfoId,
                checked
            })
        );
    };

    const onDeletePackage = async () => {
        const terrestrialPrice = findLast(
            trip.prices_terrestrial,
            (item) => {
                return isProductSamePackage(
                    item,
                    {
                        stack_number: stackNumber,
                        stack_info_id: stackInfoId
                    }
                ) &&
                    item.master_price;
            }
        ) ?? findLast(
            trip.prices_stack_product,
            (item) => {
                return item.master_price && stackNumber === 1;
            }
        );
        const flightPrice = findLast(
            trip.prices_flight,
            (item) => {
                return isProductSamePackage(
                    item,
                    {
                        stack_number: stackNumber,
                        stack_info_id: stackInfoId
                    }
                ) &&
                    item.master_price;
            }
        );
        const provider = allProviders.find((item) => {
            return item.provider.id === terrestrialPrice?.provider;
        });

        const packageItem = {
            openInfo: false,
            openProducts: false,
            provider: provider ?? null,
            flightPrice: {
                currency: flightPrice?.purchase_currency ?? 47,
                price: parseFloat(flightPrice?.purchase_price ?? '0'),
                marginType: flightPrice?.custom_rate_type ?? 'PER',
                margin: parseFloat(flightPrice?.custom_value ?? '0')
            },
            terrestrialPrice: {
                currency: terrestrialPrice?.purchase_currency ?? 47,
                price: parseFloat(terrestrialPrice?.purchase_price ?? '0'),
                marginType: terrestrialPrice?.custom_rate_type ?? 'PER',
                margin: parseFloat(terrestrialPrice?.custom_value ?? '0')
            },
            products: products.filter((item) => {
                return isProductSamePackage(
                    {
                        stack_number: item.stackNumber,
                        stack_info_id: item.stackInfoId
                    },
                    {
                        stack_number: stackNumber,
                        stack_info_id: stackInfoId
                    }
                );
            })
        };

        setDeletingPackage(true);
        await updatePackages(
            parseInt(GetCookie('trip_id_version') ?? '-1'),
            {
                [`${stackNumber ?? ''}-${stackInfoId ?? ''}`]: packageItem
            },
            products.filter((item) => {
                return isProductSamePackage(
                    item,
                    {
                        stack_number: stackNumber,
                        stack_info_id: stackInfoId
                    }
                );
            })
        );

        await Promise.all(
            accommodations.map((item) => {
                return onDeleteAccommodation(item);
            }).concat(
                cars.map((item) => {
                    return onDeleteCar(item);
                })
            ).concat(
                flights.map((item) => {
                    return onDeleteFlight(item);
                })
            ).concat(
                transfers.map((item) => {
                    return onDeleteTransfer(item);
                })
            ).concat(
                pois.map((item) => {
                    return onDeletePoi(item);
                })
            ).concat(
                manual_products.map((item) => {
                    return onDeleteManualProduct(item);
                })
            )
        );

        setDeletingPackage(false);
        setOpenDeleteModal(false);
    };

    return (
        <>
            <Box
                className={classes.lockedItemsContainer}
                sx={{
                    '& > *': {
                        marginBottom: 3
                    }
                }}
            >
                <Grid container className={classes.packageHeader}>
                    <Grid item>
                        <Stack direction="row" alignItems="center" columnGap={1}>
                            {
                                context.enableBooking &&
                                !stackInfo?.booking_status &&
                                <Checkbox
                                    onChange={(_, checked) => onChangeBooking(checked)}
                                    checked={checkedForBooking}
                                />
                            }
                            <div className={classes.lockedItemsTag}>
                                <DashboardRounded style={{ verticalAlign: 'top', marginRight: 3 }} />
                                {
                                    !isProductPackaged({
                                        product: {
                                            stack_number: stackNumber,
                                            stack_info_id: stackInfoId
                                        },
                                        stackInfos: trip?.stack_info ?? null,
                                        connected: true
                                    }) &&
                                    t(
                                        'cart-material.cart-construction-package-name',
                                        { name: stackNumber }
                                    )
                                }
                                {
                                    isProductPackaged({
                                        product: {
                                            stack_number: stackNumber,
                                            stack_info_id: stackInfoId
                                        },
                                        stackInfos: trip?.stack_info ?? null,
                                        connected: true
                                    }) &&
                                    t('cart-material.cart-construction-connected-package')
                                }
                            </div>
                            {
                                isProductPackaged({
                                    product: {
                                        stack_number: stackNumber,
                                        stack_info_id: stackInfoId
                                    },
                                    stackInfos: trip?.stack_info ?? null,
                                    connected: true
                                }) &&
                                stackInfo &&
                                user?.client_full?.type !== 2 &&
                                <>
                                    <CartConstructionProductsTableItemStatus
                                        type="package"
                                        item={stackInfo}
                                        bookingProcessState={stackInfo.booking_process_state}
                                        bookingStatus={stackInfo.booking_status}
                                        agencyNeedToBook={false}
                                    />
                                    {
                                        (
                                            stackInfo?.booking_status?.status_booking === StatusBooking.CONFIRMED ||
                                            stackInfo?.booking_status?.status_booking === StatusBooking.PENDING ||
                                            stackInfo?.booking_status?.status_booking === StatusBooking.WAITING
                                        ) &&
                                        <Typography fontWeight={100} fontSize="0.75rem">
                                            {
                                                t(
                                                    'cart-material.cart-construction-reference',
                                                    { ref: stackInfo?.booking_status.item_reference ?? '' }
                                                )
                                            }
                                        </Typography>
                                    }
                                    <Divider orientation="vertical" flexItem />
                                    <Chip
                                        label={t('cart-material.flux')}
                                        size="small"
                                        sx={{
                                            backgroundColor: '#2ACAEA',
                                            color: '#fff'
                                        }}
                                        icon={<CloudCircle color="inherit" />}
                                    />
                                </>
                            }
                        </Stack>
                    </Grid>
                    {
                        isProductPackaged({
                            product: {
                                stack_number: stackNumber,
                                stack_info_id: stackInfoId
                            },
                            stackInfos: trip?.stack_info ?? null,
                            connected: true
                        }) &&
                        provider &&
                        <Grid item>
                            <Stack
                                direction="row"
                                alignItems="center"
                                spacing={1.5}
                            >
                                <CartProductCardProviderLogo
                                    logoUrl={
                                        provider?.logo?.url ??
                                        provider?.logo?.thumbnail_big ??
                                        provider?.logo?.thumbnail_medium ??
                                        provider?.logo?.thumbnail_little ??
                                        null
                                    }
                                    name={provider?.name ?? null}
                                    minimal
                                    enableClientLogoSwitchMode
                                />
                                <IconButton onClick={() => setOpenDeleteModal(true)}>
                                    <Close />
                                </IconButton>
                            </Stack>
                        </Grid>
                    }
                    {
                        isProductPackaged({
                            product: {
                                stack_number: stackNumber,
                                stack_info_id: stackInfoId
                            },
                            stackInfos: trip?.stack_info ?? null,
                            connected: true
                        }) &&
                        !provider &&
                        <Grid item>
                            <IconButton onClick={() => setOpenDeleteModal(true)}>
                                <Close />
                            </IconButton>
                        </Grid>
                    }
                    {
                        isFirst &&
                        step !== 4 &&
                        !isProductPackaged({
                            product: {
                                stack_number: stackNumber,
                                stack_info_id: stackInfoId
                            },
                            stackInfos: trip?.stack_info ?? null,
                            connected: true
                        }) &&
                        <Grid item>
                            {
                                user?.client_full?.type !== 2 &&
                                <Button
                                    variant="outlined"
                                    endIcon={
                                        <Send />
                                    }
                                    onClick={() => setOpenQuotationDemandModal(true)}
                                >
                                    {t('cart-material.provider-quotation-send-demand')}
                                </Button>
                            }
                            {
                                openQuotationDemandModal &&
                                <CartSendProviderQuotationDemandModal
                                    onClose={() => setOpenQuotationDemandModal(false)}
                                />
                            }
                        </Grid>
                    }
                </Grid>
                {
                    isProductPackaged({
                        product: {
                            stack_number: stackNumber,
                            stack_info_id: stackInfoId
                        },
                        stackInfos: trip?.stack_info ?? null,
                        connected: true
                    }) &&
                    stackInfo &&
                    user?.client_full?.type !== 2 &&
                    <Button
                        sx={{
                            padding: 0,
                            textTransform: 'none',
                            color: '#000',
                            textDecoration: 'underline'
                        }}
                        onClick={onShowBookingDetail}
                    >
                        {t('cart-material.cart-construction-details-and-cancellation')}
                    </Button>
                }
                {
                    prices.length !== 0 &&
                    <Typography className={classes.packageTotalCost}>
                        {t('cart-material.total-package')} : {
                            prices.map((item) => {
                                return new Intl.NumberFormat(
                                    language,
                                    {
                                        style: 'currency',
                                        currency: item.currency?.iso_code ?? 'EUR',
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 2
                                    }
                                ).format(item.cost);
                            }).join(' + ')
                        }
                    </Typography>
                }
                <CartStackedCancellationRules
                    stackNumber={stackNumber}
                    stackInfoId={stackInfoId}
                />
                <MarginWrapper
                    stackNumber={stackNumber}
                    stackInfoId={stackInfoId}
                />
                {
                    flights.map(flight => (
                        <CartFlightCard
                            key={flight.id}
                            type="normal"
                            flight={flight}
                        />
                    ))
                }
                {
                    cars.map((car, index) => (
                        <CartCarCard
                            key={car.id}
                            index={index}
                            car={car}
                        />
                    ))
                }
                {
                    transfers.map(transfer => (
                        <CartTransferCard
                            key={transfer.id}
                            transfer={transfer}
                        />
                    ))
                }
                {
                    pois.map((poi) => (
                        <CartPoiCard
                            key={poi.id}
                            poi={poi}
                        />
                    ))
                }
                {
                    accommodations.map((accommodation) => (
                        <CartAccommodationCard
                            key={accommodation.id}
                            type="accommodation"
                            accommodation={accommodation}
                            accommodationsList={accommodations}
                        />
                    ))
                }
                {manual_products.map(manual_product => <CartManualProduct manual_product={manual_product} collapse={collapse} />)}
            </Box>
            <CartDeleteModal
                loading={deletingPackage}
                deleteModal={openDeleteModal}
                setDeleteModal={setOpenDeleteModal}
                callback={onDeletePackage}
            />
        </>
    );
};

function MarginWrapper(props) {
    const seeAllProductsMargins = useSelector((state) => state.cartConstruction.seeProductsMargins);

    return (
        <Collapse in={seeAllProductsMargins}>
            <CartConstructionPackageMargin
                stackNumber={props.stackNumber}
                stackInfoId={props.stackInfoId}
            />
        </Collapse>
    );
}

export default React.memo(LockedItems);
