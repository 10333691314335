import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router";
import { Chip, IconButton, Stack, Tooltip } from "@mui/material";
import {
    Block,
    Cancel,
    CheckCircle,
    Error,
    PanToolOutlined,
    Pending,
    Sync
} from "@mui/icons-material";
import { useSnackbar } from "notistack";
import axios from "axios";
import { isBoolean } from "lodash";
import { LoadingBackDrop } from "../Common/LoadingBackdrop";
import { useShowError } from "../Utils/showError";
import CheckBeforeRequest from "../Common/CheckBeforeRequest";
import GetCookie from "../Common/Functions/GetCookie";
import { SetDataTrip } from "../../Actions/Trip";
import { EditFlightStatus } from "../../Actions/Flight";
import { StatusBooking } from "../Itinerary/objects/statusBooking";
import { BookingStatus } from "../Itinerary/objects/bookingStatus";
import { CarCart } from "../Itinerary/objects/carCart";
import { AccommodationCart } from "../Itinerary/objects/accommodationCart";
import { PoiCart } from "../Itinerary/objects/poiCart";
import { TransferCart } from "../Itinerary/objects/transferCart";
import { Flight } from "../Itinerary/network/flight";
import { ManualProduct } from "../../Reducers/objects/manualProduct";
import { AssistanceCart } from "../Itinerary/objects/assistanceCart";
import { TripVersion } from "../Menu/MaterialTripList/objects/tripVersion";
import { AppState } from "../../Reducers/Reducers";

type Props = {
    bookingProcessState: string,
    bookingStatus: BookingStatus | null,
    agencyNeedToBook: boolean
} & ({
    type: 'car',
    item: CarCart
} | {
    type: 'assistance',
    item: AssistanceCart
} | {
    type: 'accommodation',
    item: AccommodationCart,
} | {
    type: 'poi',
    item: PoiCart
} | {
    type: 'transfer',
    item: TransferCart
} | {
    type: 'flight',
    item: Flight
} | {
    type: 'manual',
    item: ManualProduct
} | {
    type: 'package',
    item: NonNullable<TripVersion['stack_info']>[number]
})

export const CartConstructionProductsTableItemStatus = withRouter<Props>(
    function CartConstructionProductsTableItemStatus(props): JSX.Element {
        const { t } = useTranslation();
        const dispatch = useDispatch();
        const { enqueueSnackbar } = useSnackbar();
        const user = useSelector((state: AppState) => state.user.user);
        const [refreshStatusRunning, setRefreshStatusRunning] = useState(false);
        const color = useMemo(() => {
            if (
                props.bookingProcessState !== 'NOTHING' &&
                (
                    props.bookingStatus?.status_booking !== StatusBooking.CONFIRMED ||
                    props.bookingProcessState === 'CANCEL'
                )
            ) {
                return '#ED6C02';
            }

            if (!props.item.is_available) {
                return '#ed3c3f';
            }

            switch (props.bookingStatus?.status_booking) {
                case StatusBooking.CONFIRMED: return '#5fa463';
                case StatusBooking.REFUSED:
                case StatusBooking.ERROR:
                case StatusBooking.UNAVAILABLE: return '#ed3c3f';
                case StatusBooking.CANCEL_WAITING:
                case StatusBooking.PENDING:
                case StatusBooking.WAITING: return '#ED6C02';
                default: return '#000';
            }
        }, [
            props.bookingProcessState,
            props.bookingStatus?.status_booking,
            props.item
        ]);
        const showError = useShowError();
        const quotationCode = JSON.parse(localStorage.getItem('config') ?? '{}').quotation_code;
        const enableStatusRefresh = (
            user?.client_full?.type !== 2 ||
            quotationCode === 'beachcomber'
        ) &&
        (
            (
                (props.bookingProcessState === 'BOOK' && props.bookingStatus === null) ||
                (
                    props.bookingStatus &&
                    (
                        (
                            (
                                props.bookingStatus.status_booking === 'WAITING' ||
                                props.bookingStatus.status_booking === 'PENDING' ||
                                props.bookingStatus.status_booking === 'CANCEL_WAITING'
                            ) &&
                            props.bookingProcessState !== 'CANCEL'
                        ) ||
                        (props.bookingProcessState === 'BOOK' && props.bookingStatus.status_booking !== 'CONFIRMED')
                    )
                )
            ) ||
            props.bookingProcessState === 'CANCEL'
        );
        let isManuallySet = false;
    
        if (props.type === 'accommodation') {
            isManuallySet = props.item.rooms.findIndex((room) => {
                return room.booking_manually_set;
            }) >= 0;
        } else if (props.type !== 'manual') {
            isManuallySet = props.item.booking_manually_set;
        }
    
        const onRefreshAccommodationStatus = () => {
            if (props.type === 'accommodation') {
                const { headers } = CheckBeforeRequest();
                setRefreshStatusRunning(true);
                props.item.rooms.map(room => {
                    axios({
                        method: 'POST',
                        headers: headers,
                        url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/booking/check_status/?product_types=${room.product_type}`,
                        data: {
                            items: [room.id]
                        }
                    }).then(response => {
                        dispatch({ type: 'ACCOMMODATION_UPDATE_CART', payload: response.data });
                        setRefreshStatusRunning(false);
                        if (isBoolean(response.data.booking_status_change) && response.data.booking_status_change === false) {
                            enqueueSnackbar(t<string>('cart.check_booking_status_waiting'), { variant: 'warning' });
                        } else {
                            enqueueSnackbar(t<string>('cart.check_booking_status_success'), { variant: 'warning' });
                        }
                    }).catch(error => {
                        console.log(error);
                        if (error.detail) {
                            enqueueSnackbar(error.detail, { variant: 'error' });
                        }
                        setRefreshStatusRunning(false);
                    });
                });
            }
        };
    
        const onRetryBooking = () => {
            dispatch({ type: 'CART_SET_TO_BOOK', payload: [] });
            dispatch({
                type: 'CART_TOGGLE_FOR_BOOKING',
                payload: {
                    ...props.item,
                    is_manual: props.type === 'manual'
                }
            });
            dispatch({ type: 'CART_SET_STEP', payload: { step: 4 } });
            props.router.push(`/${window.url_name}/apps/booking`);
        };
    
        const onRefreshCarStatus = () => {
            if (props.type === 'car') {
                const { headers } = CheckBeforeRequest();
                setRefreshStatusRunning(true);
                axios({
                    method: 'POST',
                    headers: headers,
                    url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/booking/check_status/?product_types=${props.item.product_type}`,
                    data: {
                        items: [props.item.id]
                    }
                }).then(response => {
                    dispatch({ type: 'CARS_UPDATE_CART', payload: response.data });
                    setRefreshStatusRunning(false);
                    if (response.data.booking_status_change && response.data.booking_status_change === false) {
                        enqueueSnackbar(t<string>('cart.check_booking_status_waiting'), { variant: 'warning' });
                    } else {
                        enqueueSnackbar(t<string>('cart.check_booking_status_success'), { variant: 'warning' });
                    }
                }).catch(error => {
                    console.log(error);
                    if (error.detail) {
                        enqueueSnackbar(error.detail, { variant: 'error' });
                    }
                    setRefreshStatusRunning(false);
                });
            }
        };
    
        const onRefreshPoiStatus = () => {
            if (props.type === 'poi') {
                const { headers } = CheckBeforeRequest();
                setRefreshStatusRunning(true);
                axios({
                    method: 'POST',
                    headers: headers,
                    url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/booking/check_status/?product_types=${props.item.product_type}`,
                    data: {
                        items: [props.item.id]
                    }
                }).then(response => {
                    dispatch({ type: 'POI_UPDATE_CART', payload: response.data });
                    setRefreshStatusRunning(false);
                    if (response.data.booking_status_change && response.data.booking_status_change === false) {
                        enqueueSnackbar(t<string>('cart.check_booking_status_waiting'), { variant: 'warning' });
                    } else {
                        enqueueSnackbar(t<string>('cart.check_booking_status_success'), { variant: 'warning' });
                    }
                }).catch(error => {
                    console.log(error);
                    if (error.detail) {
                        enqueueSnackbar(error.detail, { variant: 'error' });
                    }
                    setRefreshStatusRunning(false);
                });
            }
        };
    
        const onRefreshTransferStatus = () => {
            if (props.type === 'transfer') {
                const { headers } = CheckBeforeRequest();
                setRefreshStatusRunning(true);
                axios({
                    method: 'POST',
                    headers: headers,
                    url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/booking/check_status/?product_types=${props.item.product_type}`,
                    data: {
                        items: [props.item.id]
                    }
                }).then(response => {
                    dispatch({ type: 'TRANSFERS_UPDATE_CART', payload: response.data });
                    setRefreshStatusRunning(false);
                    if (response.data.booking_status_change && response.data.booking_status_change === false) {
                        enqueueSnackbar(t<string>('cart.check_booking_status_waiting'), { variant: 'warning' });
                    } else {
                        enqueueSnackbar(t<string>('cart.check_booking_status_success'), { variant: 'warning' });
                    }
                }).catch(error => {
                    console.log(error);
                    if (error.detail) {
                        enqueueSnackbar(error.detail, { variant: 'error' });
                    }
                    setRefreshStatusRunning(false);
                });
            }
        };
    
        const onRefreshFlightStatus = () => {
            if (props.type === 'flight') {
                const { headers } = CheckBeforeRequest();
                setRefreshStatusRunning(true);
                axios({
                    method: 'POST',
                    headers: headers,
                    url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/booking/check_status/?product_types=${props.item.product_type}`,
                    data: {
                        items: [props.item.id]
                    }
                }).then(response => {
                    dispatch(EditFlightStatus(response.data[0][0]));
                    setRefreshStatusRunning(false);
                    if (response.data.booking_status_change && response.data.booking_status_change === false) {
                        enqueueSnackbar(t<string>('cart.check_booking_status_waiting'), { variant: 'warning' });
                    } else {
                        enqueueSnackbar(t<string>('cart.check_booking_status_success'), { variant: 'warning' });
                    }
                }).catch(error => {
                    console.log(error);
                    if (error.detail) {
                        enqueueSnackbar(error.detail, { variant: 'error' });
                    }
                    setRefreshStatusRunning(false);
                });
            }
        };
    
        const onRefreshAssistanceStatus = () => {
            if (props.type === 'assistance') {
                const { headers } = CheckBeforeRequest();
                setRefreshStatusRunning(true);
                axios({
                    method: 'POST',
                    headers: headers,
                    url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/booking/check_status/?product_types=${props.item.product_type}`,
                    data: {
                        items: [props.item.id]
                    }
                }).then(response => {
                    dispatch({ type: 'CART_ASSISTANCE_EDIT_BY_ID', payload: response.data });
                    setRefreshStatusRunning(false);
                    if (response.data.booking_status_change && response.data.booking_status_change === false) {
                        enqueueSnackbar(t<string>('cart.check_booking_status_waiting'), { variant: 'warning' });
                    } else {
                        enqueueSnackbar(t<string>('cart.check_booking_status_success'), { variant: 'warning' });
                    }
                }).catch(error => {
                    console.log(error);
                    if (error.detail) {
                        enqueueSnackbar(error.detail, { variant: 'error' });
                    }
                    setRefreshStatusRunning(false);
                });
            }
        };
    
        const onRefreshStatus = () => {
            switch (props.type) {
                case 'accommodation': {
                    onRefreshAccommodationStatus();
                    break;
                }
                case 'car': {
                    onRefreshCarStatus();
                    break;
                }
                case 'poi': {
                    onRefreshPoiStatus();
                    break;
                }
                case 'transfer': {
                    onRefreshTransferStatus();
                    break;
                }
                case 'flight': {
                    onRefreshFlightStatus();
                    break;
                }
                case 'assistance': {
                    onRefreshAssistanceStatus();
                    break;
                }
                case 'package': {
                    let { headers } = CheckBeforeRequest();
                    axios({
                        method: "GET",
                        url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/?token=${GetCookie("trip_token")}`,
                        headers
                    }).then(function (response) {
                        dispatch(SetDataTrip(response.data));
                        enqueueSnackbar(t<string>('cart.check_booking_status_success'), { variant: 'warning' });
                    }).catch((error) => {
                        showError(error);
                    });
                    break;
                }
            }
        };
    
        return (
            <>
                <Stack
                    direction="row"
                    columnGap={1}
                    rowGap={0.5}
                    flexWrap="wrap"
                    alignItems="center"
                >
                    {
                        props.item.is_available &&
                        (
                            props.bookingProcessState === 'NOTHING' ||
                            (
                                props.bookingStatus?.status_booking === StatusBooking.CONFIRMED &&
                                props.bookingProcessState !== 'CANCEL'
                            )
                        ) &&
                        <>
                            {
                                isManuallySet &&
                                <Tooltip title={ t<string>('cart-material.booking-manually-set') }>
                                    <PanToolOutlined
                                        fontSize="small"
                                        sx={{ color }}
                                    />
                                </Tooltip>
                            }
                            {
                                !props.bookingStatus &&
                                <Chip
                                    size="small"
                                    label={t<string>('cart-material.cart-construction-unbooked')}
                                    sx={{ color: 'inherit' }}
                                    avatar={
                                        enableStatusRefresh ?
                                            <Tooltip title={ t<string>('cart-material.refresh-status') }>
                                                <IconButton
                                                    onClick={onRefreshStatus}
                                                    sx={{ fontSize: '1.25em !important' }}
                                                >
                                                    <Sync fontSize="inherit" sx={{ color: '#fff !important' }} />
                                                </IconButton>
                                            </Tooltip> :
                                            undefined
                                    }
                                />
                            }
                            {
                                !props.bookingStatus &&
                                props.agencyNeedToBook &&
                                <Tooltip
                                    title={
                                        user?.client_full?.type !== 2 ?
                                            t<string>('cart-material.agency-need-to-book-to') :
                                            t<string>('cart-material.agency-need-to-book-agency')
                                    }
                                >
                                    <Chip
                                        size="small"
                                        label={
                                            user?.client_full?.type !== 2 ?
                                                t<string>('cart-material.agency-need-to-book-to') :
                                                t<string>('cart-material.agency-need-to-book-agency')
                                        }
                                        sx={{ color: 'green' }}
                                    />
                                </Tooltip>
                            }
                            {
                                props.bookingStatus?.status_booking === StatusBooking.NONE &&
                                <Chip
                                    size="small"
                                    label={t<string>('cart-material.cart-construction-no-status')}
                                    sx={{ color: 'inherit' }}
                                    avatar={
                                        enableStatusRefresh ?
                                            <Tooltip title={ t<string>('cart-material.refresh-status') }>
                                                <IconButton
                                                    onClick={onRefreshStatus}
                                                    sx={{ fontSize: '1.25em !important' }}
                                                >
                                                    <Sync fontSize="inherit" sx={{ color: '#fff !important' }} />
                                                </IconButton>
                                            </Tooltip> :
                                            undefined
                                    }
                                />
                            }
                            {
                                props.bookingStatus?.status_booking === StatusBooking.CONFIRMED &&
                                <Chip
                                    label={
                                        (() => {
                                            if (
                                                props.type === 'flight' &&
                                            (props.item.issued_ticket?.length ?? 0) > 0
                                            ) {
                                                return (
                                                    props.item.issued_ticket?.findIndex((ticket) => {
                                                        return ticket.status === 'VOID';
                                                    }) ?? -1
                                                ) >= 0 ?
                                                    'VOID' :
                                                    t<string>('cart-material.cart-construction-issued-flight');
                                            }
                                            return t<string>('cart-material.cart-construction-confirmed');
                                        })()
                                    }
                                    size="small"
                                    sx={(theme) => ({
                                        backgroundColor: color,
                                        color: theme.palette.getContrastText(color)
                                    })}
                                    avatar={
                                        enableStatusRefresh ?
                                            <Tooltip title={ t<string>('cart-material.refresh-status') }>
                                                <IconButton
                                                    onClick={onRefreshStatus}
                                                    sx={{ fontSize: '1.25em !important' }}
                                                >
                                                    <Sync fontSize="inherit" sx={{ color: '#fff !important' }} />
                                                </IconButton>
                                            </Tooltip> :
                                            undefined
                                    }
                                    icon={<CheckCircle color="inherit" />}
                                />
                            }
                            {
                                props.bookingStatus?.status_booking === StatusBooking.CANCELLED &&
                                <Chip
                                    label={
                                        quotationCode === 'cercledesvoyages' ?
                                            t<string>('cart-material.cart-construction-cancelled-with-hint') :
                                            t<string>('cart-material.cart-construction-cancelled')
                                    }
                                    size="small"
                                    sx={(theme) => ({
                                        backgroundColor: color,
                                        color: theme.palette.getContrastText(color)
                                    })}
                                    avatar={
                                        enableStatusRefresh ?
                                            <Tooltip title={ t<string>('cart-material.refresh-status') }>
                                                <IconButton
                                                    onClick={onRefreshStatus}
                                                    sx={{ fontSize: '1.25em !important' }}
                                                >
                                                    <Sync fontSize="inherit" sx={{ color: '#fff !important' }} />
                                                </IconButton>
                                            </Tooltip> :
                                            undefined
                                    }
                                    icon={<Block color="inherit" />}
                                />
                            }
                            {
                                (
                                    props.bookingStatus?.status_booking === StatusBooking.PENDING ||
                                    props.bookingStatus?.status_booking === StatusBooking.WAITING
                                ) &&
                                <Chip
                                    label={t<string>('cart-material.cart-construction-waiting')}
                                    size="small"
                                    sx={(theme) => ({
                                        backgroundColor: color,
                                        color: theme.palette.getContrastText(color)
                                    })}
                                    avatar={
                                        enableStatusRefresh ?
                                            <Tooltip title={ t<string>('cart-material.refresh-status') }>
                                                <IconButton
                                                    onClick={onRefreshStatus}
                                                    sx={{ fontSize: '1.25em !important' }}
                                                >
                                                    <Sync fontSize="inherit" sx={{ color: '#fff !important' }} />
                                                </IconButton>
                                            </Tooltip> :
                                            undefined
                                    }
                                    icon={<Pending color="inherit" />}
                                />
                            }
                            {
                                props.bookingStatus?.status_booking === StatusBooking.CANCEL_WAITING &&
                                <Chip
                                    label={t<string>('cart-material.cart-construction-cancel-waiting')}
                                    size="small"
                                    sx={(theme) => ({
                                        backgroundColor: color,
                                        color: theme.palette.getContrastText(color)
                                    })}
                                    avatar={
                                        enableStatusRefresh ?
                                            <Tooltip title={ t<string>('cart-material.refresh-status') }>
                                                <IconButton
                                                    onClick={onRefreshStatus}
                                                    sx={{ fontSize: '1.25em !important' }}
                                                >
                                                    <Sync fontSize="inherit" sx={{ color: '#fff !important' }} />
                                                </IconButton>
                                            </Tooltip> :
                                            undefined
                                    }
                                    icon={<Pending color="inherit" />}
                                />
                            }
                            {
                                props.bookingStatus?.status_booking === StatusBooking.REFUSED &&
                                <Chip
                                    label={t<string>('cart-material.cart-construction-refused')}
                                    size="small"
                                    sx={(theme) => ({
                                        backgroundColor: color,
                                        color: theme.palette.getContrastText(color)
                                    })}
                                    avatar={
                                        enableStatusRefresh ?
                                            <Tooltip title={ t<string>('cart-material.refresh-status') }>
                                                <IconButton
                                                    onClick={onRefreshStatus}
                                                    sx={{ fontSize: '1.25em !important' }}
                                                >
                                                    <Sync fontSize="inherit" sx={{ color: '#fff !important' }} />
                                                </IconButton>
                                            </Tooltip> :
                                            undefined
                                    }
                                    icon={<Cancel color="inherit" />}
                                />
                            }
                            {
                                props.bookingStatus?.status_booking === StatusBooking.ERROR &&
                                <Chip
                                    label={t<string>('cart-material.cart-construction-error')}
                                    size="small"
                                    sx={(theme) => ({
                                        backgroundColor: color,
                                        color: theme.palette.getContrastText(color)
                                    })}
                                    avatar={
                                        <Tooltip title={ t<string>('cart-material.retry-booking') }>
                                            <IconButton
                                                onClick={onRetryBooking}
                                                sx={{ fontSize: '1.25em !important' }}
                                            >
                                                <Sync fontSize="inherit" sx={{ color: '#fff !important' }} />
                                            </IconButton>
                                        </Tooltip>
                                    }
                                    icon={<Error color="inherit" />}
                                />
                            }
                        </>
                    }
                    {
                        props.item.is_available &&
                        props.bookingProcessState === 'BOOK' &&
                        props.bookingStatus?.status_booking !== StatusBooking.CONFIRMED &&
                        <Chip
                            label={t<string>('cart-material.booking-in-progress')}
                            size="small"
                            sx={(theme) => ({
                                backgroundColor: color,
                                color: theme.palette.getContrastText(color)
                            })}
                            avatar={
                                enableStatusRefresh ?
                                    <Tooltip title={ t<string>('cart-material.refresh-status') }>
                                        <IconButton
                                            onClick={onRefreshStatus}
                                            sx={{ fontSize: '1.25em !important' }}
                                        >
                                            <Sync fontSize="inherit" sx={{ color: '#fff !important' }} />
                                        </IconButton>
                                    </Tooltip> :
                                    undefined
                            }
                            icon={<Pending color="inherit" />}
                        />
                    }
                    {
                        props.item.is_available &&
                        props.bookingProcessState === 'CANCEL' &&
                        <Chip
                            label={t<string>('cart-material.cancellation-in-progress')}
                            size="small"
                            sx={(theme) => ({
                                backgroundColor: color,
                                color: theme.palette.getContrastText(color)
                            })}
                            avatar={
                                enableStatusRefresh ?
                                    <Tooltip title={ t<string>('cart-material.refresh-status') }>
                                        <IconButton
                                            onClick={onRefreshStatus}
                                            sx={{ fontSize: '1.25em !important' }}
                                        >
                                            <Sync fontSize="inherit" sx={{ color: '#fff !important' }} />
                                        </IconButton>
                                    </Tooltip> :
                                    undefined
                            }
                            icon={<Pending color="inherit" />}
                        />
                    }
                    {
                        (
                            props.bookingStatus?.status_booking === StatusBooking.UNAVAILABLE ||
                            !props.item.is_available
                        ) &&
                        <Chip
                            label={t<string>('cart-material.cart-construction-unavailable')}
                            size="small"
                            sx={(theme) => ({
                                backgroundColor: color,
                                color: theme.palette.getContrastText(color)
                            })}
                            avatar={
                                enableStatusRefresh ?
                                    <Tooltip title={ t<string>('cart-material.refresh-status') }>
                                        <IconButton
                                            onClick={onRefreshStatus}
                                            sx={{ fontSize: '1.25em !important' }}
                                        >
                                            <Sync fontSize="inherit" sx={{ color: '#fff !important' }} />
                                        </IconButton>
                                    </Tooltip> :
                                    undefined
                            }
                            icon={<Block color="inherit" />}
                        />
                    }
                </Stack>
                <LoadingBackDrop open={refreshStatusRunning} />
            </>
        );
    }
);
