import { useSnackbar } from 'notistack';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";

import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { makeStyles } from "@material-ui/core/styles";

import Typography from '@material-ui/core/Typography';
import DialogActions from '@material-ui/core/DialogActions';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import axios from 'axios';
import DragColumn from './DragColumn.tsx';
import CheckBeforeRequest from '../../Common/CheckBeforeRequest';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

// CSS
import '../../../Style/Base.css';

const useStyles = makeStyles({
    formControlRoot: {
        borderRadius: '20px',
        textTransform: 'capitalize',
        marginTop: 2,
        padding: 7
    },
    orangeButton: {
        backgroundColor: '#E6592F',
        color: 'white'
    },
    whiteButton: {
        backgroundColor: 'white',
        color: 'black'
    },
    customSize: {
        width: 41,
        height: 41,
        marginTop: 4
    },
    // paperStepper: {
    //     position: 'absolute !important',
    //     zIndex: '2 !important',
    //     background: 'rgb(255, 255, 255) !important',
    //     boxShadow: 'rgb(0 0 0 / 20%) 0px 6px 20px !important',
    //     marginTop: '12px !important',
    //     maxHeight: 'calc(100vh - 260px) !important',
    //     overflow: 'hidden auto !important',
    //     padding: '0 8px 0 8px !important'
    // },
    active: {
        backgroundColor: 'rgba(0, 0, 0, 0.04)'
    },
    buttonContainer: {
        position: 'sticky',
        bottom: 0,
        backgroundColor: 'white'
    },
    columnContainer: {
        paddingLeft: 16
    },
    fixedWidth: {
        width: 50
    },
    stickyTitle: {
        position: 'sticky',
        top: 0,
        paddingTop: 16,
        backgroundColor: 'white',
        zIndex: 1,
        paddingBottom: 8,
        paddingLeft: 16
    },
    lastMoved: {
        backgroundColor: 'rgb(235, 242, 249)'
    }
});

const DialogColumn = ({ handleOpenColumns, openColumns, columns, setColumns, column_type, order, orderBy, user }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const handleChange = (index) => {
        let tmp_columns = [...columns];
        if (tmp_columns[index].active) {
            tmp_columns[index].active = false;
        } else {
            tmp_columns[index].active = true;
        }
        setColumns(tmp_columns);
    };
    const handleSave = () => {
        let { pass_check, headers } = CheckBeforeRequest();
        let tmp_columns = [...columns];
        tmp_columns.map((column) => {
            Object.keys(column_type).map((el) => {
                if (column_type[el] === column.field_name) {
                    column.field_name = el;
                }
            });
        });
        let request = {
            trip_profile: tmp_columns,
            ordering: orderBy,
            ordering_ascend: order === 'asc'
        };
        if (pass_check) {
            axios({
                method: "PATCH",
                headers: headers,
                url: `${API_HREF}client/${user.client}/user/${user.id}/`,
                data: JSON.stringify(request)
            }).then(function (response) {
                let data = Object.assign({}, response.data);
                dispatch({
                    type: "USER_SET_USER",
                    payload: {
                        user: data
                    }
                });
                enqueueSnackbar(t("notify.changed_success"), {
                    variant: "success"
                });
            }).catch(function (error) {
                //TODO: show snackbar error
                console.log('error:', error);
                enqueueSnackbar(t("notify.changed_failed"), {
                    variant: "error"
                });
            });
        }
    };
    return (
        <Fragment>
            {
                openColumns && (
                    <ClickAwayListener onClickAway={ handleOpenColumns }>
                        <Paper className={'ft-listColumnConfigurator-columnContainer'}>
                            <Typography className={ classes.stickyTitle }>{ t('menu.column-title') }</Typography>
                            <DndProvider backend={HTML5Backend}>
                                {
                                    columns.map((column, column_index) => (
                                        <DragColumn key={`trip-column-configurator-${column_index}`} column={column} column_index={column_index} columns={columns} setColumns={setColumns} handleChangeCheck={handleChange} />
                                    ))
                                }
                            </DndProvider>
                            <DialogActions className={ classes.buttonContainer }>
                                <Button color={ 'primary' } onClick={ handleSave }>{ t("menu.trip_list.save_columns") }</Button>
                            </DialogActions>
                        </Paper>
                    </ClickAwayListener>
                )
            }
        </Fragment>
    );
};

export default React.memo(DialogColumn);
