import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";

import AvailabilityCard from "./AvailabilityCard";
import AvailabilityCalendar from "./AvailabilityCalendar";
import MoreAvailabilityDates from "./MoreAvailabilityDates";

const useStyles = makeStyles(theme => ({
    spacer: {
        padding: "8px 0px" 
    },
    bold: {
        fontWeight: "bold"
    },
    dateSpacer: {
        paddingTop: 16
    },
    formControlRoot1: {
        "width": "100%",
        "& .mui-jss-MuiOutlinedInput-input": {
            padding: '18.5px 14px;'
        },
        "&& .mui-jss-MuiSvgIcon-root": {
            color: "black"
        },
        "& .mui-jss-MuiFormLabel-root": {
            fontWeight: 400,
            fontSize: 15
        }
    },
    circleBorder: {
        borderRadius: 8
    },
    orangeBorderButton: {
        color: "#E6592F",
        borderColor: "#E6592F",
        backgroundColor: "#FFFFFF"
    }
}));

const ConnectedPoiAvailability = ({ arrivalDate, departureDate, availabilities, assignedGroups, view, displayCalendarDate, setDisplayCalendarDate, seeAllAvailabilities, setSeeAllAvailabilities, variantChosen, setVariantChosen, selectActivity, poi_type, current_groups }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    // const current_groups = useSelector(state => state.poi.current_groups);

    const variantLabel = useRef(null);
    const variantLabelWidth = variantLabel.current ? variantLabel.current.clientWidth : 172;

    return (
        <Grid>
            {
                availabilities.length !== 0 && assignedGroups.length !== current_groups.length && (
                    <Typography className={`${classes.bold} ${classes.dateSpacer}`} variant="body2"> { t("poi.select_date") + " :" } </Typography>
                )
            }
            {
                //show 3 availabilities for activities with no variants
                availabilities.length !== 0 && assignedGroups.length !== current_groups.length && view === "card" &&
                (availabilities[assignedGroups.length].choices === undefined || availabilities[assignedGroups.length].choices.length === 0) && availabilities[assignedGroups.length].scheduled_prices.map((day_prices, availability_index) => {
                    if (availability_index < 3) {
                        return (
                            <AvailabilityCard key={`${day_prices.date}-${day_prices.hour.length !== 0 ? day_prices.hour[0] : ""}`} day_prices={day_prices} selectActivity={selectActivity} poi_type={poi_type}/>
                        );
                    }
                })
            }
            {
                //select variant if choices are given
                availabilities.length !== 0 && assignedGroups.length !== current_groups.length && view === "card" &&
                availabilities[assignedGroups.length].choices.length !== 0 && (
                    <Grid>
                        <br />
                        <FormControl variant="outlined" className={classes.formControlRoot1} fullWidth>
                            <InputLabel ref={variantLabel}>
                                { t("poi.pick_variant") }
                            </InputLabel>
                            <Select
                                value={variantChosen}
                                className={classes.circleBorder}
                                onChange={(event) => setVariantChosen(event.target.value)}
                                MenuProps={{ disableScrollLock: true }}
                                input={
                                    <OutlinedInput
                                        labelWidth={variantLabelWidth}
                                        autoComplete="off"
                                    />}
                            >
                                {
                                    availabilities[assignedGroups.length].choices.map((variant) => {
                                        return (
                                            <MenuItem key={`${variant.name}`} value={variant}>
                                                { variant.name }
                                            </MenuItem>
                                        );
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                )
            }
            {
                //show variant availabilities
                availabilities.length !== 0 && assignedGroups.length !== current_groups.length && view === "card" &&
                availabilities[assignedGroups.length].choices.length !== 0 && variantChosen !== "" && variantChosen.scheduled_prices.map((day_prices, availability_index) => {
                    if (availability_index < 3) {
                        return (
                            <AvailabilityCard key={`${day_prices.date}-${day_prices.hour.length !== 0 ? day_prices.hour[0] : ""}`} day_prices={day_prices} selectActivity={selectActivity} poi_type={poi_type}/>
                        );
                    }
                })
            }
            {
                //Button to show all available dates
                availabilities.length !== 0 && assignedGroups.length !== current_groups.length && view === "card" &&
                ((availabilities[assignedGroups.length].choices.length === 0 && availabilities[assignedGroups.length].scheduled_prices.length > 3) ||
                (variantChosen !== "" && variantChosen.scheduled_prices.length > 3)) && (
                    <Grid className={classes.spacer} style={{ textAlign: "center" }}>
                        <Button variant="outlined" size="small" className={classes.orangeBorderButton} onClick={() => setSeeAllAvailabilities(true)}>
                            { t("poi.see_more_dates") }
                        </Button>
                    </Grid>
                )
            }
            {
                //all available dates for product without variants
                availabilities.length !== 0 && assignedGroups.length !== current_groups.length && view === "card" && availabilities[assignedGroups.length].choices.length === 0 && (
                    <MoreAvailabilityDates openModal={seeAllAvailabilities} setOpenModal={setSeeAllAvailabilities} scheduled_prices={availabilities[assignedGroups.length].scheduled_prices} selectActivity={selectActivity} />
                )
            }
            {
                //all available dates for variant
                availabilities.length !== 0 && assignedGroups.length !== current_groups.length && view === "card" && availabilities[assignedGroups.length].choices.length !== 0 && variantChosen !== "" && (
                    <MoreAvailabilityDates openModal={seeAllAvailabilities} setOpenModal={setSeeAllAvailabilities} scheduled_prices={variantChosen.scheduled_prices} selectActivity={selectActivity} />
                )
            }
            {
                //calendar view
                availabilities.length !== 0 && assignedGroups.length !== current_groups.length && view === "calendar" && (
                    <AvailabilityCalendar
                        arrivalDate={arrivalDate}
                        departureDate={departureDate}
                        availabilities={availabilities}
                        assignedGroups={assignedGroups}
                        displayCalendarDate={displayCalendarDate}
                        setDisplayCalendarDate={setDisplayCalendarDate}
                        selectActivity={selectActivity}
                        poi_type={poi_type}
                    />
                )
            }
        </Grid>
    );
};

export default React.memo(ConnectedPoiAvailability);
