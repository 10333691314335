import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import clsx from 'clsx';
import axios from "axios";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import green from "@material-ui/core/colors/green";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "@material-ui/lab/Alert";
import Pagination from "@material-ui/lab/Pagination";
import PaginationItem from "@material-ui/lab/PaginationItem";
import Add from '@material-ui/icons/Add';
import Apps from '@material-ui/icons/Apps';
import List from '@material-ui/icons/List';
import Widgets from '@material-ui/icons/Widgets';
import Search from "@material-ui/icons/Search";
import Settings from "@material-ui/icons/Settings";
import Favorite from '@material-ui/icons/Favorite';
import AccommodationListHotelOne from "./FunctionalComponent/AccommodationListHotelOne";
import AccommodationListHotelTwo from "./FunctionalComponent/AccommodationListHotelTwo";
import AccommodationListHotelThree from "./FunctionalComponent/AccommodationListHotelThree";
import AccommodationListSorts from "./FunctionalComponent/AccommodationListSorts";
import AccommodationListFilters from "./FunctionalComponent/AccommodationListFilters";
import AccommodationListSkeletonOne from "./FunctionalComponent/AccommodationListSkeletonOne";
import AccommodationListSkeletonTwo from "./FunctionalComponent/AccommodationListSkeletonTwo";
import AccommodationListSkeletonThree from "./FunctionalComponent/AccommodationListSkeletonThree";
import AccommodationListParameters from "./FunctionalComponent/AccommodationListParameters";
import CheckBeforeRequest from "../Common/CheckBeforeRequest";
import { StepsDatesManager } from "../Itinerary/utils/stepsDatesManager";

const useStyles = makeStyles({
    inline: {
        display: "inline-block"
    },
    containerFirst: {
        padding: '16px 16px 0 16px'
    },
    containerSecond: {
        padding: '0 16px 16px 16px'
    },
    containerSecondMarginBottom: {
        marginBottom: 63
    },
    settingsButton: {
        fontStyle: "italic",
        textTransform: "inherit"
    },
    filterButton: {
        textTransform: 'capitalize',
        width: '100%',
        borderRadius: 40,
        padding: '8px 16px'
    },
    filterButtonActive: {
        borderColor: 'black'
    },
    mobileWidth: {
        width: '25%',
        minWidth: 100
    },
    maxWidth: {
        maxWidth: 'calc(50% - 8px)'
    },
    active: {
        color: green[500]
    },
    alignCenter: {
        textAlign: "center"
    },
    alignRight: {
        textAlign: "right"
    },
    burger: {
        padding: 0
    },
    marginRight: {
        marginRight: 4
    },
    marginLeft: {
        marginLeft: 4
    },
    settingIcon: {
        position: "absolute"
    },
    recommendedWarning: {
        marginBottom: 8,
        width: '100%'
    },
    filterWarning: {
        width: '100%'
    },
    noResults: {
        width: "100%",
        padding: "50px 0",
        textAlign: "center"
    },
    marginBottom: {
        marginBottom: 8
    },
    paginationItemActive: {
        outline: "5px auto -webkit-focus-ring-color"
    },
    pagination: {
        "& button:focus": {
            outline: "none !important"
        }
    },
    providerRunning: {
        width: '16px !important',
        height: '16px !important',
        float: 'left',
        marginTop: 6,
        marginRight: 8
    }
});

let search_timeout = null;

const AccommodationList = ({ router }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { headers } = CheckBeforeRequest();
    const { enqueueSnackbar } = useSnackbar();
    const traveler_groups = useSelector(state => state.trip.traveler_groups);
    const providers = useSelector(state => state.trip.providers);
    const currency = useSelector(state => state.trip.currency);
    const trip_id = useSelector(state => state.trip.trip_id);
    const manual_providers = useSelector(state => state.trip.manual_providers);
    const all_data = useSelector(state => state.trip.all_data);
    const user = useSelector(state => state.user.user);
    const itinerary_list = useSelector(state => state.itinerary.itinerary_list);
    const active = useSelector(state => state.base.active);
    const full_list = useSelector(state => state.accommodation.full_list);
    const page_list = useSelector(state => state.accommodation.page_list);
    const only_static = useSelector(state => state.accommodation.only_static);
    const bounds = useSelector(state => state.accommodation.bounds);
    const active_page = useSelector(state => state.accommodation.active_page);
    const count = useSelector(state => state.accommodation.count);
    const destination = useSelector(state => state.accommodation.destination);
    const start_date = useSelector(state => state.accommodation.start_date);
    const end_date = useSelector(state => state.accommodation.end_date);
    const duration_days = useSelector(state => state.accommodation.duration_days);
    const current_groups = useSelector(state => state.accommodation.current_groups);
    const recommended = useSelector(state => state.accommodation.recommended);
    const active_filters = useSelector(state => state.accommodation.active_filters);
    const providers_running_count = useSelector(state => state.accommodation.providers_running_count);
    const grid_type = useSelector(state => state.accommodation.grid_type);
    const search = useSelector(state => state.accommodation.search);
    const wait = useSelector(state => state.accommodation.wait);
    const run_small = useSelector(state => state.accommodation.run_small);
    const run_big = useSelector(state => state.accommodation.run_big);
    const run_dynamic = useSelector(state => state.accommodation.run_dynamic);
    const traveler_number = useSelector(state => state.accommodation.traveler_number);
    const room_number = useSelector(state => state.accommodation.room_number);
    const accommodation_cart = useSelector(state => state.accommodation.cart);
    const late_arrival = useSelector(state => state.accommodation.late_arrival);
    const late_departure = useSelector(state => state.accommodation.late_departure);
    const choose_late_departure = useSelector(state => state.accommodation.choose_late_departure);
    const choose_late_arrival = useSelector(state => state.accommodation.choose_late_arrival);
    const tripStartDate = useSelector(state => state.trip.start_date);
    const tripEndDate = useSelector(state => state.trip.end_date);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));
    const smallmobile = useMediaQuery(theme.breakpoints.down('xs'));
    const dispatch = useDispatch();
    const [lockCart, setLockCart] = useState(false);
    const datesManager = useMemo(() => {
        if (tripStartDate && tripEndDate) {
            return new StepsDatesManager(tripStartDate, tripEndDate);
        }
        return null;
    }, [tripStartDate, tripEndDate]);

    const accommodationListRef = useRef(null);

    const toggleParameters = () => {
        dispatch({ type: "MATERIAL_TOGGLE_PARAMETERS" });
    };
    const toggleFilters = () => {
        dispatch({ type: "MATERIAL_TOGGLE_FILTERS" });
    };
    const toggleSorts = () => {
        dispatch({ type: "MATERIAL_TOGGLE_SORTS" });
    };
    const toggleRecommended = () => {
        dispatch({ type: "MATERIAL_TOGGLE_RECOMMENDED" });
    };
    const onValidateLateArrival = () => {
        dispatch({
            type: "MATERIAL_UPDATE_PARAMETERS",
            payload: {
                start_date: start_date,
                end_date: end_date,
                current_groups: current_groups,
                late: "arrival",
            }
        });
    };
    const onCancelLateArrival = () => {
        dispatch({
            type: "MATERIAL_UPDATE_PARAMETERS",
            payload: {
                start_date: (moment(start_date).add(1, 'days')).format("YYYY-MM-DD"),
                end_date: end_date,
                current_groups: current_groups,
                late: "arrival",
            }
        });
    };
    const onValidateLateDeparture = () => {
        dispatch({
            type: "MATERIAL_UPDATE_PARAMETERS",
            payload: {
                start_date: start_date,
                end_date: end_date,
                current_groups: current_groups,
                late: "departure",
            }
        });
    };
    const onCancelLateDeparture = () => {
        dispatch({
            type: "MATERIAL_UPDATE_PARAMETERS",
            payload: {
                start_date: start_date,
                end_date: (moment(end_date).add(1, 'days')).format("YYYY-MM-DD"),
                current_groups: current_groups,
                late: "departure",
            }
        });
    };
    const setSearch = elem => {
        clearTimeout(search_timeout);
        elem.persist();
        search_timeout = setTimeout(() => {
            dispatch({ type: "MATERIAL_SEARCH", payload: { value: elem.target.value } });
        }, 250);
    };
    const setActivePage = (elem, page) => {
        accommodationListRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
        dispatch({ type: "MATERIAL_SET_ACTIVE_PAGE", payload: { active_page: page } });
    };
    const onGridTypeChange = mobile => {
        dispatch({ type: "MATERIAL_TOGGLE_GRID_TYPE", payload: mobile });
    };
    const getDynamicPrices = (custom, provider, provider_id, rooms, start_destination, full_list) => {
        let hotels = [];
        full_list.map((hotel) => {
            if ((search === "" || (hotel.name !== null && hotel.name.match(new RegExp(search, 'i')))) && bounds.north > parseFloat(hotel.latitude) && parseFloat(hotel.latitude) > bounds.south && bounds.east > parseFloat(hotel.longitude) && parseFloat(hotel.longitude) > bounds.west) {
                hotel[(custom ? "potential_custom_providers" : "potential_providers")].map((potential_provider) => {
                    if ((custom && potential_provider.provider_id === provider_id) || (!custom && potential_provider.id === provider_id)) {
                        let already_requested = false;
                        if (hotel.providers !== undefined) {
                            hotel.providers.map((hotel_provider) => {
                                if (hotel_provider.provider.provider.id === provider_id) {
                                    already_requested = true;
                                }
                            });
                        }
                        if (!already_requested) {
                            hotels.push(hotel.id);
                        }
                    }
                });
            }
        });
        if (rooms !== "" && hotels.length > 0) {
            dispatch({ type: "MATERIAL_PROVIDER_RUNNING" });
            if (provider.provider.code === 'expedia_rapid' && hotels.length > 250) {
                axios({
                    method: "POST",
                    headers: headers,
                    url: `${API_HREF}client/${window.id_owner}/accommodations/minimal_prices/?${rooms}`,
                    params: {
                        arrival_date: start_date,
                        departure_date: end_date,
                        custom_provider: custom,
                        provider_id: provider_id,
                        currency: currency.id,
                        trip: trip_id,
                        start_destination: start_destination
                    },
                    data: {
                        hotels_identifiers: hotels.slice(0, 245)
                    }
                }).then((response) => {
                    dispatch({
                        type: "MATERIAL_SET_PRICES",
                        payload: {
                            prices: response.data,
                            provider: provider,
                            provider_id: provider_id
                        }
                    });
                }).catch((error) => {
                    console.log(error);
                    dispatch({ type: "MATERIAL_PROVIDER_FAILED" });
                });
                axios({
                    method: "POST",
                    headers: headers,
                    url: `${API_HREF}client/${window.id_owner}/accommodations/minimal_prices/?${rooms}`,
                    params: {
                        arrival_date: start_date,
                        departure_date: end_date,
                        custom_provider: custom,
                        provider_id: provider_id,
                        currency: currency.id,
                        trip: trip_id,
                        start_destination: start_destination
                    },
                    data: {
                        hotels_identifiers: hotels.slice(245)
                    }
                }).then((response) => {
                    dispatch({
                        type: "MATERIAL_SET_PRICES",
                        payload: {
                            prices: response.data,
                            provider: provider,
                            provider_id: provider_id
                        }
                    });
                }).catch((error) => {
                    console.log(error);
                    dispatch({ type: "MATERIAL_PROVIDER_FAILED" });
                });
            } else {
                axios({
                    method: "POST",
                    headers: headers,
                    url: `${API_HREF}client/${window.id_owner}/accommodations/minimal_prices/?${rooms}`,
                    params: {
                        arrival_date: start_date,
                        departure_date: end_date,
                        custom_provider: custom,
                        provider_id: provider_id,
                        currency: currency.id,
                        trip: trip_id,
                        start_destination: start_destination
                    },
                    data: {
                        hotels_identifiers: hotels
                    }
                }).then((response) => {
                    dispatch({
                        type: "MATERIAL_SET_PRICES",
                        payload: {
                            prices: response.data,
                            provider: provider,
                            provider_id: provider_id
                        }
                    });
                }).catch((error) => {
                    console.log(error);
                    dispatch({ type: "MATERIAL_PROVIDER_FAILED" });
                });
            }
        }
    };
    const mapProviders = (current_groups, start_destination, full_list) => {
        let rooms = "";
        current_groups.map((current_group) => {
            rooms += `&rooms=${current_group.id}`;
        });
        providers.map((provider) => {
            let weight = "100";
            if (user.client_full.type === 2) {
                provider.configuration.extra_params.map((param) => {
                    if (param.key === "weight") {
                        weight = param.value;
                    }
                });
            }
            if (weight !== "null" && all_data.branch_code === provider.branch_code) {
                getDynamicPrices(false, provider, provider.provider.id, rooms, start_destination, full_list);
            }
        });
        manual_providers.map((manual_provider) => {
            getDynamicPrices(true, manual_provider, manual_provider.provider.id, rooms, start_destination, full_list);
        });
    };
    const getAccommodations = limit => {
        axios({
            method: "GET",
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/accommodations/`,
            params: {
                limit: limit,
                format: "json",
                latitude_max: bounds?.north.toFixed(6) ?? '90.000000',
                longitude_max: bounds?.east.toFixed(6) ?? '180.000000',
                latitude_min: bounds?.south.toFixed(6) ?? '-90.000000',
                longitude_min: bounds?.west.toFixed(6) ?? '180.000000',
                search: search !== null ? search : undefined,
                trip: trip_id
            }
        }).then((response) => {
            if (response.data.results.length > 0) {
                dispatch({
                    type: "MATERIAL_UPDATE_LIST",
                    payload: {
                        full_list: response.data.results,
                        limit: limit,
                        start_date: start_date,
                        end_date: end_date
                    }
                });
            }
        }).catch((error) => {
            console.log(error);
        });
    };

    useEffect(() => {
        if (itinerary_list !== null && itinerary_list.length > 0) {
            let has_step = false;
            for (let i = 0; i < itinerary_list.length; i++) {
                if (itinerary_list[i].step_type === "STEP") {
                    has_step = true;
                    break;
                }
            }
            if (!has_step) {
                enqueueSnackbar(t("apps_navigation.no_destination"), {
                    variant: "warning"
                });
                router.push(`/${window.url_name}/apps/itinerary`);
            }
        } else {
            enqueueSnackbar(t("apps_navigation.no_destination"), {
                variant: "warning"
            });
            router.push(`/${window.url_name}/apps/itinerary`);
        }
    }, [itinerary_list]);
    useEffect(() => {
        if (!wait && run_small && bounds !== null && !lockCart) {
            getAccommodations(40);
        }
    }, [run_small, bounds]);
    useEffect(() => {
        if (!wait && run_big && bounds !== null && !lockCart) {
            getAccommodations(500);
        }
    }, [run_big, bounds]);
    useEffect(() => {
        if (!wait && run_dynamic && full_list !== null && !lockCart) {
            dispatch({ type: "MATERIAL_DYNAMIC_RAN" });
            mapProviders(current_groups, destination.destination.id, full_list);
        }
    }, [run_dynamic]);
    useEffect(() => {
        if (destination !== null) {
            let lock_cart = accommodation_cart.find((cart) => {
                return cart.start_destination !== undefined && cart.start_destination !== null && cart.start_destination.id === destination.destination.id && cart.deletion_restriction;
            });
            setLockCart(user.client_full.type === 2 && lock_cart !== undefined);
        }
    }, [destination]);
    useEffect(() => {
        if (destination === null || current_groups === null || start_date === null || end_date === null || (bounds === null && fullScreen)) {
            let active_itinerary_step = destination;
            let new_current_groups = current_groups;
            if (active_itinerary_step === null) {
                for (let i = 0; i < itinerary_list.length; i++) {
                    if (itinerary_list[i].step_type === "STEP") {
                        if (active_itinerary_step === null) {
                            active_itinerary_step = itinerary_list[i];
                        }
                        if (itinerary_list[i].id === active) {
                            active_itinerary_step = itinerary_list[i];
                            break;
                        }
                    }
                }
            }
            if (new_current_groups === null) {
                new_current_groups = [];
                traveler_groups.map((traveler_group) => {
                    if (traveler_group.default) {
                        new_current_groups.push({ ...traveler_group });
                    }
                });
            }
            const step_index = itinerary_list.findIndex(iti => iti.id === active);      
            let tmp_start_date = (start_date !== null ? start_date : moment.utc(active_itinerary_step.start_date).format("YYYY-MM-DD"));
            let tmp_end_date = (end_date !== null ? end_date : moment.utc(active_itinerary_step.end_date).format("YYYY-MM-DD"));
            let hasLateArrival = false;
            let hasLateDeparture = false;
            if (step_index !== -1 && step_index !== 0) {
                hasLateArrival = datesManager?.isLateArrival(itinerary_list[step_index].start_date);
                hasLateDeparture = datesManager?.isLateArrival(itinerary_list[step_index].end_date);
                if (hasLateArrival) {
                    tmp_start_date = (moment(itinerary_list[step_index].start_date).subtract(1, 'days')).format("YYYY-MM-DD");
                }
                if (hasLateDeparture) {
                    tmp_end_date = (moment(itinerary_list[step_index].end_date).subtract(1, 'days')).format("YYYY-MM-DD");
                }
            }
            const active_bounds = JSON.parse(active_itinerary_step.destination.data.bounds.replace(/'/g, '"'));
            dispatch({ type: 'ACCOMMODATION_LATE_ARRIVAL_DEPARTURE', payload: { hasLateArrival: hasLateArrival, hasLateDeparture: hasLateDeparture } });
            dispatch({
                type: "MATERIAL_SET_BASIC_DATA",
                payload: {
                    active: active_itinerary_step.id,
                    destination: active_itinerary_step,
                    current_groups: new_current_groups,
                    start_date: tmp_start_date,
                    end_date: tmp_end_date,
                    bounds: fullScreen ? {
                        north: active_bounds.north,
                        east: active_bounds.east,
                        south: active_bounds.south,
                        west: active_bounds.west
                    } : null
                }
            });
        }
    });
    useEffect(() => {
        const element = document.getElementById('accommodation-search');
        if (element.value !== search) {
            element.value = search;
        }
    }, [search]);
    return (
        <Fragment>
            <Grid className={ classes.containerFirst } container spacing={ 1 }>
                <Button className={ `${classes.settingsButton}` } endIcon={ <Settings/> } onClick={ toggleParameters }>
                    <Typography variant={ "body2" }>{ t("accommodation_list.from") } { moment.utc(start_date).format("DD MMMM") } { t("accommodation_list.to") } { moment.utc(end_date).format("DD MMMM") } { grid_type === 2 && (<Fragment>{ duration_days } nuits, { traveler_number } voyageurs, { room_number } chambres</Fragment>)}</Typography>
                </Button>
                <Grid item xs={ 12 }>
                    <Typography variant={ "h5" }>{ t("accommodation_list.accommodation") } : { (destination !== undefined && destination !== null && destination.destination !== undefined && destination.destination !== null && destination.destination.data !== undefined && destination.destination.data !== null ? destination.destination.data.name !== undefined ? destination.destination.data.name : destination.destination.data.international_name : "") }</Typography>
                </Grid>
            </Grid>
            <Grid ref={accommodationListRef} className={ clsx(classes.containerSecond, { [classes.containerSecondMarginBottom]: mobile }) } container justify={ "space-between" } spacing={ 1 }>
                <Grid item container alignItems={ "center" } spacing={ 2 } xs>
                    <Grid item xs={ smallmobile ? 12 : 'auto' }>
                        <TextField defaultValue={ search } label={ t('shared.search') } id={ "accommodation-search" } fullWidth={ smallmobile } size={ 'small' } InputProps={{ startAdornment: <InputAdornment position={ "start" }><Search/></InputAdornment> }} variant={ "outlined" } placeholder={ t('shared.accommodation-name') } onChange={ setSearch }/>
                    </Grid>
                    <Grid item>
                        <Button className={ `${classes.filterButton}${recommended ? ` ${classes.active}` : ""}` } variant={ "outlined" } size={ 'small' } startIcon={ <Favorite/> } onClick={ toggleRecommended }>{ t("accommodation_list.recommended") }</Button>
                    </Grid>
                    <Grid item className={ clsx({ [classes.mobileWidth]: mobile })}>
                        <Button className={ clsx(classes.filterButton, { [classes.filterButtonActive]: active_filters > 0 }) } variant={ "outlined" } size={ 'small' } startIcon={ <Add/> } onClick={ toggleFilters }>{ t("accommodation_list.filters") } { active_filters > 0 ? ` - ${active_filters}` : '' }</Button>
                    </Grid>
                    <Grid item className={ clsx({ [classes.mobileWidth]: mobile })}>
                        <Button className={ classes.filterButton } variant={ "outlined" } size={ 'small' } onClick={ toggleSorts }>{ t("accommodation_list.sort") }</Button>
                    </Grid>
                    {
                        mobile &&
                        <Grid className={ classes.alignRight } item xs>
                            { providers_running_count > 0 && <CircularProgress className={ classes.providerRunning }/> }
                            <Typography variant={ "caption" }>{ count } {count > 1 ? t('accommodation.results') : t('accommodation.result')} </Typography>
                        </Grid>
                    }
                </Grid>
                {
                    !mobile &&
                    <Grid item className={ classes.alignCenter }>
                        <IconButton className={ classes.burger } onClick={(event) => {
                            onGridTypeChange(mobile); 
                        } }>
                            { grid_type === 1 ? <List/> : grid_type === 2 ? <Widgets/> : grid_type === 3 ? <Apps/> : <List/> }
                        </IconButton>
                        <br/>
                        { providers_running_count > 0 && <CircularProgress className={ classes.providerRunning }/> }
                        <Typography variant={ "caption" }>{ count } {count > 1 ? t('accommodation.results') : t('accommodation.result')}</Typography>
                    </Grid>
                }
                <Grid item xs={ 12 }>
                    <Divider/>
                </Grid>
                {
                    recommended &&
                    <Grid item xs={ 12 }>
                        <Alert className={ classes.recommendedWarning } severity={ "warning" } variant={ "filled" }>{ t("accommodation.recommended_warning") }</Alert>
                        <Divider/>
                    </Grid>
                }
                {
                    start_date !== null && end_date !== null && duration_days === 0 &&
                    <Grid item xs={ 12 }>
                        <Alert className={ classes.recommendedWarning } severity={ "warning" } variant={ "filled" }>{ t("accommodation_list.same_day_warning") }</Alert>
                        <Divider/>
                    </Grid>
                }
                {
                    start_date !== null && moment.utc(start_date).isBefore(moment()) &&
                    <Grid item xs={ 12 }>
                        <Alert className={ classes.recommendedWarning } severity={ "warning" } variant={ "filled" }>{ t("accommodation_list.past_date_warning") }</Alert>
                        <Divider/>
                    </Grid>
                }
                {
                    late_arrival && !choose_late_arrival &&
                    <Grid item xs={ 12 }>
                        <Alert severity={'warning'} action={
                            <>
                                <Button className={ classes.blueButton } size={ 'small' } onClick={ onValidateLateArrival }>
                                    { t("global.yes") }
                                </Button>
                                <Button className={ classes.blueButton } size={ 'small' } onClick={ onCancelLateArrival }>
                                    { t("global.no") }
                                </Button>
                            </>
                        }>{t('itinerary.late-arrival-help')}</Alert>
                    </Grid>
                }
                {
                    late_departure && !choose_late_departure &&
                    <Grid item xs={ 12 }>
                        <Alert severity={'warning'} action={
                            <>
                                <Button className={ classes.blueButton } size={ 'small' } onClick={ onValidateLateDeparture }>
                                    { t("global.yes") }
                                </Button>
                                <Button className={ classes.blueButton } size={ 'small' } onClick={ onCancelLateDeparture }>
                                    { t("global.no") }
                                </Button>
                            </>
                        }>{t('itinerary.late-departure-help')}</Alert>
                    </Grid>
                }
                {
                    duration_days !== 0 &&
                    <Grid item container xs={ 12 }>
                        {
                            !lockCart ? (
                                <Fragment>
                                    { page_list === null || (page_list.length === 0 && (providers_running_count > 0 || only_static)) ? grid_type === 1 ? <AccommodationListSkeletonOne/> : grid_type === 2 ? <AccommodationListSkeletonThree/> : <AccommodationListSkeletonTwo/> :
                                        <Fragment>
                                            {
                                                page_list !== null && page_list.length > 0 ? (
                                                    <Fragment>
                                                        {
                                                            page_list.map((item, index_item) => (
                                                                <Fragment key={ index_item }>
                                                                    { (grid_type === 1 || grid_type === 2) && index_item > 0 && <Divider/> }
                                                                    { grid_type === 1 ? <AccommodationListHotelOne index={ index_item } hotel={ item } router={ router }/> : grid_type === 2 ? <AccommodationListHotelThree index={ index_item } hotel={ item } router={ router }/> : <AccommodationListHotelTwo index={ index_item } hotel={ item } router={ router }/> }
                                                                </Fragment>
                                                            ))
                                                        }
                                                    </Fragment>
                                                ) : <Alert className={ classes.filterWarning } severity={ "warning" } variant={ "filled" }>{ t("accommodation_list.no_filter_message") }</Alert>
                                            }
                                        </Fragment>
                                    }
                                </Fragment>
                            ) : <Alert className={ classes.filterWarning } severity={ "warning" } variant={ "filled" }>{ t('accommodation_list.lock_accommodation') }</Alert>
                        }
                    </Grid>
                }
                <Grid item xs={ 12 }>
                    <Divider className={ classes.marginBottom }/>
                </Grid>
                <Grid item xs={ 12 } container justify={ "center" }>
                    <Grid item>
                        <Pagination className={ classes.pagination } count={ Math.ceil(count / 30) } variant={ "outlined" } hideNextButton={ (page_list === null || page_list.length === 0 || active_page === Math.ceil(count / 30)) } hidePrevButton={ active_page === 1 } disabled={ page_list === null || page_list.length === 0 } onChange={ setActivePage } renderItem={(item) => <PaginationItem className={ `${item.selected ? classes.paginationItemActive : ""}` } {...item}/>}/>
                    </Grid>
                </Grid>
            </Grid>
            <AccommodationListSorts/>
            <AccommodationListFilters/>
            { current_groups !== null && start_date !== null && end_date !== null && <AccommodationListParameters/> }
        </Fragment>
    );
};

export default React.memo(AccommodationList);
