import axios from "axios";
import memoizee from "memoizee";
import { sortItinerary } from "./sortItinerary";
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import { Itinerary } from "../objects/itinerary";

async function get(tripId: number, tripVersion: number): Promise<Itinerary[] | null> {
    let { pass_check, headers } = CheckBeforeRequest();

    if (pass_check) {
        const response = await axios.get<Itinerary[]>(
            `${API_HREF}client/${window.id_owner}/trip/${tripId}/versions/${tripVersion}/itinerary/`,
            { headers }
        );
        return [...response.data].sort(sortItinerary);
    }

    return null;
}

export const getItinerary = memoizee(
    get,
    { promise: true, primitive: true }
);
