import { useCallback } from "react";
import { useSelector } from "react-redux";
import axios, { AxiosResponse } from "axios";
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import {
    findTripRoadbookRubrics
} from "../../Menu/MaterialTripList/utils/findTripRoadbookRubrics";
import GetCookie from "../../Common/Functions/GetCookie";
import { Rubric } from "../../Menu/MaterialTripList/objects/rubric";
import { AppState } from "../../../Reducers/Reducers";

export type RubricData = Omit<Rubric, 'id' | 'trip'>

type Callback = (rubrics: RubricData[]) => Promise<RubricData[]>

type Options = Partial<{
    onTrigger: () => void,
    onSuccess: (rubrics: RubricData[]) => void,
    onError: (error: Error) => void,
    onFinally: () => void
}>

export function useTripRoadbookRubricsUpdate(options: Options): Callback {
    const tripId = useSelector((state: AppState) => state.trip.trip_id);

    return useCallback(
        async (rubrics) => {
            if (tripId) {
                try {
                    if (options.onTrigger) {
                        options.onTrigger();
                    }
                    const response = await makeRequest({
                        tripId,
                        rubrics
                    });
                    if (options.onSuccess) {
                        options.onSuccess(response?.data ?? []);
                    }
                    return response?.data ?? [];
                } catch (error: any) {
                    if (options.onError) {
                        options.onError(error);
                    }
                } finally {
                    if (options.onFinally) {
                        options.onFinally();
                    }
                }
            }
            return [];
        },
        [tripId]
    );
}

type RequestOptions = {
    tripId: number,
    rubrics: RubricData[]
}

async function makeRequest(options: RequestOptions): Promise<AxiosResponse<RubricData[]> | null> {
    const { pass_check, headers } = CheckBeforeRequest();

    if (pass_check) {
        const rubrics = await findTripRoadbookRubrics(options.tripId) ?? [];
        const ids = rubrics.map((item) => item.id);
        const result = options.rubrics.map((item) => {
            const id = ids.pop();
            return {
                id,
                trip: options.tripId,
                parent: item.parent,
                localization: item.localization
            };
        });
        const response = await axios.post<RubricData[]>(
            `${API_HREF}client/${window.id_owner}/roadbook-rubrics/array_patch/`,
            [
                ...ids.map((item) => ({
                    id: item,
                    delete: true
                })),
                ...result
            ],
            {
                headers,
                params: {
                    trip: options.tripId,
                    token: GetCookie('trip_token')
                }
            }
        );
        return response;
    }

    return null;
}
