import { flatten } from "lodash";
import { Transport } from "../objects/transport";

export function computeDaysCountForPlane(transport: Transport['alternatives'][number]): number {
    const airSteps = transport.steps.filter((item) => {
        return item.vehicle.kind === 'plane';
    });
    const times = flatten(
        airSteps.map((item) => {
            return [item.departureTime, item.arrivalTime];
        })
    );
    const result = times.reduce((prev, current) => {
        const lastItem = prev[prev.length - 1];

        if (lastItem && lastItem > current) {
            return prev.concat([0, current]);
        }

        return prev.concat([current]);
    }, [] as number[]).reduce((prev, current) => {
        const lastItem = prev[prev.length - 1];
        if (lastItem === current) {
            return prev;
        }
        return prev.concat([current]);
    }, [] as number[]).filter((item) => item === 0).length;
    return result;
}
