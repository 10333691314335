//---- Dependencies ----//
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from "@material-ui/core/useMediaQuery";

import Grid from "@material-ui/core/Grid";
import i18n from '../../../i18n';
import LocaleText from '../../Utils/localeText/localeText';

const useStyles = makeStyles(theme => ({
    alignRight: {
        textAlign: "right"
    },
    alignLeft: {
        textAlign: "left"
    },
    alignCenter: {
        textAlign: "center"
    },
    justify: {
        textAlign: "justify"
    },
    floatRight: {
        float: "right"
    },
    pink: {
        color: "#E0368D"
    },
    bold: {
        fontWeight: "bold"
    },
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
    listContainer: {
        "&& ul": {
            "listStyleType": "'-'",
            "paddingInlineStart": "0px",
            "marginBottom": 4,
            "& li": {
                paddingLeft: 5
            }
        }
    },
    font12: {
        fontSize: 12
    },
    italic: {
        fontStyle: "italic"
    },
    border: {
        border: '1px solid #ebebeb',
        borderRadius: 13,
        padding: 10,
        marginBottom: 10,
        backgroundColor: 'white',
        marginRight: 7,
        marginLeft: 0,
        marginTop: 10
    }
}));

const RenderExtra = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const itinerary_type_details_data = useSelector(store => store.itinerary_type.itinerary_type_details_data) ??
        props.itinerary_type_details_data;
    const language = useSelector(store => store.header.tmp_language);
    const user = useSelector(store => store.user.user);
    const locales = useSelector(state => state.user.locales);

    const current_locale = locales.find((el) => {
        return el.language_code === i18n.language;
    });
    let currency_symbol = "-";
    let currency_iso = null;
    let localization = itinerary_type_details_data.localization.find(localization => localization.locale === current_locale.id);
    if (itinerary_type_details_data !== null) {
        flight_included = itinerary_type_details_data.flight_included;
        if (itinerary_type_details_data?.trip?.currency !== undefined && itinerary_type_details_data?.trip?.currency !== null) {
            currency_symbol = itinerary_type_details_data.trip.currency.symbol;
            currency_iso = itinerary_type_details_data.trip.currency.iso_code;
        }
        if (itinerary_type_details_data?.currency !== undefined && itinerary_type_details_data?.currency !== null) {
            currency_symbol = itinerary_type_details_data.currency.symbol;
            currency_iso = itinerary_type_details_data.currency.iso_code;
        }
    }
    let included_in_price = [];
    let excluded_in_price = [];
    let additional_information = '';
    if (localization !== undefined && localization.included_in_price !== undefined && localization.included_in_price !== null && localization.included_in_price.length > 0) {
        included_in_price = [...localization.included_in_price];
    } else if (itinerary_type_details_data.included_in_price !== undefined && itinerary_type_details_data.included_in_price !== null && itinerary_type_details_data.included_in_price.length > 0) {
        included_in_price = [...itinerary_type_details_data.included_in_price];
    }
    if (localization !== undefined && localization.excluded_in_price !== undefined && localization.excluded_in_price !== null && localization.excluded_in_price.length > 0) {
        excluded_in_price = [...localization.excluded_in_price];
    } else if (itinerary_type_details_data.excluded_in_price !== undefined && itinerary_type_details_data.excluded_in_price !== null && itinerary_type_details_data.excluded_in_price.length > 0) {
        excluded_in_price = [...itinerary_type_details_data.excluded_in_price];
    }
    if (localization !== undefined && localization.additional_information !== undefined && localization.additional_information !== null) {
        additional_information = localization.additional_information;
    } else if (itinerary_type_details_data.additional_information !== undefined && itinerary_type_details_data.additional_information !== null && itinerary_type_details_data.additional_information !== '') {
        additional_information = itinerary_type_details_data.additional_information;
    }
    return (
        <Fragment>
            <Grid container direction={"column"} className={`${classes.genericText} ${classes.border}`}spacing={2} style={{ marginTop: 10, marginBottom: 10 }}>
                {
                    itinerary_type_details_data.advantage !== undefined && itinerary_type_details_data.advantage !== null && itinerary_type_details_data.advantage.length > 0 && (
                        <Grid item>
                            <p style={{ fontSize: 15, fontWeight: "bold", marginBottom: 2 }}>{t("itineraryType.avantages")}</p>
                            {
                                itinerary_type_details_data.advantage.map((advantage, advantage_index) => {
                                    return (
                                        <div key={ advantage_index } className={"col-md-12"}>
                                            <div className={`${classes.font12} ${classes.listContainer}`}>
                                                <ul style={{ float: "inherit" }}><li>{ advantage.title }</li></ul>
                                            </div>
                                            <div className={`${classes.font12} ${classes.italic} ${classes.justify}`} style={{ marginLeft: 4, marginBottom: 10, marginTop: "-8px" }}> {advantage.description} </div>
                                        </div>
                                    );
                                })
                            }
                        </Grid>
                    )
                }
                {
                    included_in_price.length !== 0 && (
                        <Grid item>
                            <p style={{ fontSize: 15, fontWeight: "bold", marginBottom: 2 }}>{t("itineraryType.price_includes")}</p>
                            {
                                included_in_price.map((include, include_index) => {
                                    return (
                                        <div key={ include_index } className={"col-md-12"}>
                                            <div className={`${classes.font12} ${classes.listContainer}`}>
                                                <ul style={{ float: "inherit" }}><li>{ include }</li></ul>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </Grid>
                    )
                }
                {
                    excluded_in_price.length !== 0 && (
                        <Grid item>
                            <p style={{ fontSize: 15, fontWeight: "bold", marginBottom: 2 }}>{t("itineraryType.price_excludes")}</p>
                            {
                                excluded_in_price.map((exclude, exclude_index) => {
                                    return (
                                        <div key={ exclude_index } className={"col-md-12"}>
                                            <div className={`${classes.font12} ${classes.listContainer}`}>
                                                <ul style={{ float: "inherit" }}><li>{ exclude }</li></ul>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </Grid>
                    )
                }
                {
                    itinerary_type_details_data.extra !== undefined && itinerary_type_details_data.extra !== null && itinerary_type_details_data.extra.length > 0 && (
                        <Grid item>
                            <p style={{ fontSize: 15, fontWeight: "bold", marginBottom: 2 }}>{t("itineraryType.supplements")}</p>
                            {
                                itinerary_type_details_data.extra.map((extra, extra_index) => {
                                    return (
                                        <div key={ extra_index } className={"col-md-12"}>
                                            <div className={`${classes.font12} ${classes.listContainer}`}>
                                                <ul style={{ float: "inherit" }}>
                                                    <li>
                                                        { extra.description }
                                                        <span className={`${classes.pink} ${classes.alignRight} ${classes.floatRight} ${classes.bold}`}>
                                                            {new Intl.NumberFormat(i18n.language, { style: 'currency', currency: currency_iso, minimumFractionDigits: 0 }).format(parseFloat(extra.price))}
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </Grid>
                    )
                }
                {
                    itinerary_type_details_data.child_reduction !== undefined && itinerary_type_details_data.child_reduction !== null && itinerary_type_details_data.child_reduction.length > 0 && (
                        <Grid item>
                            <p style={{ fontSize: 15, fontWeight: "bold", marginBottom: 2 }}>{t("itineraryType.child_reduction")}</p>
                            {
                                itinerary_type_details_data.child_reduction.map((child_reduction, child_reduction_index) => {
                                    return (
                                        <div key={ child_reduction_index } className={"col-md-12"}>
                                            <div className={`${classes.font12} ${classes.listContainer}`}>
                                                <ul style={{ float: "inherit" }}>
                                                    <li>
                                                        { child_reduction.description }
                                                        <span className={`${classes.pink} ${classes.alignRight} ${classes.floatRight} ${classes.bold}`}>
                                                            {new Intl.NumberFormat(i18n.language, { style: 'currency', currency: currency_iso, minimumFractionDigits: 0 }).format(child_reduction.price)}
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </Grid>
                    )
                }
                {
                    itinerary_type_details_data.guide_languages !== undefined && itinerary_type_details_data.guide_languages !== null && itinerary_type_details_data.guide_languages.length > 0 &&
                    <Grid item>
                        <p style={{ fontSize: 15, fontWeight: "bold", marginBottom: 2 }}>{t("itineraryType.guide_language")}</p>
                        {
                            itinerary_type_details_data.guide_languages.map((guide_languages, guide_languages_index) => {
                                let guide_language = guide_languages.LocalTranslation.find((el) => el.localeTranslate === current_locale.id);
                                if (guide_language !== undefined) {
                                    return (
                                        <div key={ guide_languages_index } className={"col-md-12"}>
                                            <div className={`${classes.font12} ${classes.listContainer}`}>
                                                <ul style={{ float: "inherit" }}>
                                                    <li>{ guide_language.translated_name }</li>
                                                </ul>
                                            </div>
                                        </div>
                                    );
                                }
                            })
                        }
                    </Grid>
                }
                {
                    itinerary_type_details_data.keywords !== undefined && itinerary_type_details_data.keywords !== null && itinerary_type_details_data.keywords !== '' &&
                    <Grid item>
                        <p style={{ fontSize: 15, fontWeight: "bold", marginBottom: 2 }}>{t("itineraryType.related_destination")}</p>
                        {
                            itinerary_type_details_data.keywords.split(',').map((destination, destination_index) => {
                                return (
                                    <div key={ destination_index } className={"col-md-12"}>
                                        <div className={`${classes.font12} ${classes.listContainer}`}>
                                            <ul style={{ float: "inherit" }}>
                                                <li>{ destination }</li>
                                            </ul>
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </Grid>
                }
                {
                    additional_information !== '' &&
                    <Grid item>
                        <p style={{ fontSize: 15, fontWeight: "bold", marginBottom: 2 }}>{t("itineraryType.additional_description")}</p>
                        <div className={classes.font12} dangerouslySetInnerHTML={{ __html: additional_information }}/>
                    </Grid>
                }
            </Grid>
            <Grid container ref={props.pricesRef}/>

        </Fragment>
    );
};
export default React.memo(RenderExtra);
