//Dependencies
import axios from 'axios';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
//Core
import { Button, Stack } from '@mui/material';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
//Common
import GetCookie from '../Common/Functions/GetCookie';
import CheckBeforeRequest from '../Common/CheckBeforeRequest';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles({
    validateButton: {
        backgroundColor: '#E6592F',
        color: 'white'
    },
    cancelButton: {
        backgroundColor: 'white',
        border: '1px solid #E6592F',
        color: '#E6592F'
    }
});

const CartManualProductsManualBooking = ({ manual_product, onManualBooking }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const [status, setStatus] = useState(manual_product.booking_status !== null ? manual_product.booking_status.status_booking : 'DEFAULT');
    const [reference, setReference] = useState(manual_product.booking_status !== null && manual_product.booking_status.item_reference !== null ? manual_product.booking_status.item_reference : '');
    const onStatusChange = event => {
        setStatus(event.target.value);
    };
    const onReferenceChange = event => {
        setReference(event.target.value);
    };
    const onValidateManualBooking = () => {
        if (reference.length > 50) {
            enqueueSnackbar(t('cart-material.reference-length-error'), { variant: 'error' });
        } else {
            const { headers } = CheckBeforeRequest();
            axios({
                method: 'POST',
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/status-manager/${manual_product.id}/status/`,
                data: {
                    status_booking: status === 'DEFAULT' ? null : status,
                    item_reference: reference
                }
            }).then(response => {
                dispatch({ type: 'CART_EDIT_MANUAL_ITEM', payload: response.data });
                onManualBooking();
            }).catch(error => {
                console.log(error);
            });
        }
    };
    const onCancelManualBooking = () => {
        onManualBooking();
    };
    return (
        <Grid container spacing={ 4 }>
            <Grid item xs={ 6 }>
                <FormControl fullWidth size="small" variant={ 'outlined' }>
                    <InputLabel htmlFor={ 'status' }>{ t('cart-material.status') }</InputLabel>
                    <Select id={ 'status' } value={ status } label={ t('cart-material.status') } onChange={ onStatusChange }>
                        <MenuItem value={ 'DEFAULT' }>{ t('cart-material.none') }</MenuItem>
                        <MenuItem value={ 'CONFIRMED' }>{ t('cart-material.confirmed') }</MenuItem>
                        <MenuItem value={ 'WAITING' }>{ t('cart-material.pending') }</MenuItem>
                        <MenuItem value={ 'CANCELLED' }>{ t('cart-material.cancelled') }</MenuItem>
                        <MenuItem value={ 'ERROR' }>{ t('cart-material.error') }</MenuItem>
                        <MenuItem value={ 'REFUSED'}> { t("cart-material.refused")} </MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={ 6 }>
                <FormControl fullWidth size="small" variant={ 'outlined' }>
                    <InputLabel htmlFor={ 'note' }>{ t('cart-material.reference') }</InputLabel>
                    <OutlinedInput
                        id={ 'note' }
                        type={ 'text' }
                        onChange={ onReferenceChange }
                        label={ t('cart-material.reference') }
                        value={ reference }
                    />
                </FormControl>
            </Grid>
            <Grid item xs={ 12 }>
                <Stack direction="row" justifyContent="flex-end" spacing={1}>
                    <Button onClick={ onCancelManualBooking }>{ t('global.cancel') }</Button>
                    <Button onClick={ onValidateManualBooking }>{ t('global.validate') }</Button>
                </Stack>
            </Grid>
        </Grid>
    );
};

export default CartManualProductsManualBooking;
