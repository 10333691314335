import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    Box,
    Button,
    CardContent,
    Chip,
    Collapse,
    Stack,
    Tooltip,
    Typography
} from "@mui/material";
import {
    ArrowRightAltOutlined,
    CloudCircle,
    CloudOff,
    DateRangeOutlined,
    ErrorOutline,
    PeopleOutlineOutlined,
    ReportProblemOutlined,
    SupportAgentOutlined
} from "@mui/icons-material";
import axios from "axios";
import { useSnackbar } from "notistack";
import { CartProductCard, CartProductCardProps } from "./CartProductCard";
import { CartProductIconText } from "./CartProductIconText";
import { CartConstructionProductsTableItemStatus } from "./CartConstructionProductsTableItemStatus";
import { CartConstructionProductsTableItemMargin } from "./CartConstructionProductsTableItemMargin";
import { CartProductCardName } from "./CartProductCardName";
import { CartAssistanceCardDetails } from "./CartAssistanceCardDetails";
import { CartProductCardProviderLogo } from "./CartProductCardProviderLogo";
import { getAssistancePicture } from "./utils/getAssistancePicture";
import { findPictureUrl } from "./utils/findPictureUrl";
import { insertDivider } from "./utils/insertDivider";
import { useGetPrice } from "./utils/getPrice";
import GetCookie from "../Common/Functions/GetCookie";
import CheckBeforeRequest from "../Common/CheckBeforeRequest";
import { StatusBooking } from "../Itinerary/objects/statusBooking";
import { AssistanceCart } from "../Itinerary/objects/assistanceCart";
import { AppState } from "../../Reducers/Reducers";
import { isProductPackaged } from "./utils/isProductPackaged";
import { useCartTotalPrices } from './utils/cartTotalPrices';

type Props = {
    assistance: AssistanceCart,
} & Pick<CartProductCardProps, 'alwaysOpen'>

const DEFAULT_PICTURE = '/Img/hotel.png';

export function CartAssistanceCard(props: Props): JSX.Element | null {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const language = useSelector((state: AppState) => state.header.tmp_language);
    const locale = useSelector((state: AppState) => {
        return state.user.locales.find((item) => {
            return item.language_code === i18n.language;
        })?.id ?? 1;
    });
    const providers = useSelector((state: AppState) => state.trip.providers);
    const manualProviders = useSelector((state: AppState) => state.trip.manual_providers);
    const travelers = useSelector((state: AppState) => state.trip.travelers);
    const prices = useCartTotalPrices();
    const trip = useSelector((state: AppState) => state.trip.data_trip);
    const user = useSelector((state: AppState) => state.user?.user);
    const pictures = useMemo(() => {
        const pictures = getAssistancePicture(props.assistance);

        return pictures.length > 0 ?
            pictures.map((picture) => {
                return findPictureUrl(picture);
            }) :
            [DEFAULT_PICTURE];
    }, [props.assistance]);
    const provider = useMemo(() => {
        return providers.concat(manualProviders).find((item) => {
            return item.provider.id === props.assistance.provider;
        })?.provider;
    }, [props.assistance, providers, manualProviders]);
    const getPrice = useGetPrice();
    const totalCost = getPrice(props.assistance.prices);

    const confirmTravelerModification = () => {
        const { headers } = CheckBeforeRequest();
        axios({
            method: 'PATCH',
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/${provider?.code === "gritchen" ? 'insurance' : 'assistance'}/${props.assistance.id}/`,
            data: {
                traveler_modification: null
            }
        }).then((response) => {
            dispatch({ type: 'POI_EDIT_CART_BY_ID', payload: response.data });
        }).catch((error) => {
            console.log(error);
        });
    };

    const onShowBookingDetail = () => {
        dispatch({ type: 'CART_TOGGLE_BOOKING_DETAIL', payload: props.assistance });
    };

    const onUpdateAssistance = () => {
        const { headers } = CheckBeforeRequest();
        axios({
            method: 'PATCH',
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/${provider?.code === "gritchen" ? 'insurance' : 'assistance'}/${props.assistance.id}/update_booking/`,
            data: props.assistance.product_type === 8 ? {
                contract_reference: props.assistance.contract_reference,
                trip_price: Math.round((((prices.totalCartCost ?? 0) - getPrice(props.assistance.prices).cost) / (travelers?.length ?? 1) * props.assistance.group_passenger.travelers.length)) * (provider?.code === "gritchen" ? 100 : 1)
            } : undefined
        }).then(response => {
            dispatch({ type: 'CART_ASSISTANCE_EDIT_BY_ID', payload: response.data });
            enqueueSnackbar(t<string>('cart-material.assistance-updated'), { variant: 'success' });
        }).catch(error => {
            console.log(error);
            enqueueSnackbar(t<string>('cart-material.assistance-updated-error'), { variant: 'error' });
        });
    };

    return (
        <CartProductCard
            type="assistance"
            item={props.assistance}
            mainContent={
                <Stack>
                    <CartProductCardName
                        type="assistance"
                        content={
                            props.assistance.localization.find((item) => {
                                return item.locale === locale;
                            })?.name ??
                            props.assistance.contract_name ??
                            t<string>('cart-material.assistance')
                        }
                        modifiedName={
                            props.assistance.localization.find((item) => {
                                return item.locale === locale;
                            })?.name ??
                            props.assistance.contract_name ??
                            t<string>('cart-material.assistance')
                        }
                        providerName={
                            props.assistance.contract_name ??
                            t<string>('cart-material.assistance')
                        }
                        localization={props.assistance.localization}
                        id={props.assistance.id}
                        isCustom={props.assistance.is_custom}
                        startIcon={SupportAgentOutlined}
                    />
                    <CartProductIconText
                        text={
                            t<string>(
                                'cart-material.cart-construction-products-table-date',
                                {
                                    from: window.moment.utc(props.assistance.start_date).format('DD MMMM'),
                                    to: window.moment.utc(props.assistance.end_date).format('DD MMMM')
                                }
                            )
                        }
                        startIcon={DateRangeOutlined}
                    />
                    <CartProductIconText
                        text={
                            [
                                t<string>(
                                    'roadbook.adults-count',
                                    {
                                        count: props.assistance.group_passenger.travelers_list?.filter((traveler) => {
                                            const result = window.moment.utc().diff(
                                                window.moment.utc(traveler.birth_date),
                                                'years'
                                            );
                                            return result >= 18;
                                        }).length ?? 0
                                    }
                                ),
                                (() => {
                                    const childrenCount = props.assistance.group_passenger.travelers_list?.filter((traveler) => {
                                        const result = window.moment.utc().diff(
                                            window.moment.utc(traveler.birth_date),
                                            'years'
                                        );
                                        return result < 18;
                                    }).length ?? 0;
                                    return childrenCount > 0 ?
                                        t<string>(
                                            'roadbook.children-count',
                                            { count: childrenCount }
                                        ) :
                                        null;
                                })()
                            ].filter((item) => item).join(', ')
                        }
                        startIcon={PeopleOutlineOutlined}
                    />
                </Stack>
            }
            sideContent={
                <Stack spacing={1} alignItems="center">
                    <Typography variant="caption" sx={{ opacity: 0.6 }}>
                        {t<string>('cart-material.cart-construction-accommodation-stay-amount')}
                    </Typography>
                    <Typography fontWeight="bold" textAlign="center">
                        {
                            new Intl.NumberFormat(
                                language,
                                {
                                    style: 'currency', 
                                    currency: totalCost.currency?.iso_code ?? 'EUR'
                                }
                            ).format(totalCost.cost)
                        }
                        {
                            props.assistance.price_change &&
                            props.assistance.price_change.price_variation !== 0 &&
                            <Stack direction="row" alignItems="center" justifyContent="center">
                                <Typography
                                    variant="caption"
                                    sx={{
                                        color: props.assistance.price_change.price_variation < 0 ? 'green' : 'red',
                                        fontSize: '10px'
                                    }}
                                >
                                    {
                                        new Intl.NumberFormat(
                                            language,
                                            {
                                                style: 'currency', 
                                                currency: totalCost.currency?.iso_code ?? 'EUR'
                                            }
                                        ).format(props.assistance.price_change.price_variation)
                                    }
                                </Typography>
                                <ArrowRightAltOutlined
                                    sx={{
                                        transform: props.assistance.price_change.price_variation < 0 ?
                                            'rotateZ(90deg)' :
                                            'rotateZ(-90deg)',
                                        color: 'green',
                                        fontSize: '14px'
                                    }}
                                />
                            </Stack>
                        }
                    </Typography>
                    <CartProductCardProviderLogo
                        logoUrl={
                            provider?.logo?.url ??
                            provider?.logo?.thumbnail_big ??
                            provider?.logo?.thumbnail_medium ??
                            provider?.logo?.thumbnail_little ??
                            null
                        }
                        name={provider?.name ?? null}
                    />
                </Stack>
            }
            footer={
                <div>
                    <Stack direction="row" alignItems="center" spacing={2}>
                        {
                            insertDivider(
                                [
                                    !isProductPackaged({
                                        product: props.assistance,
                                        stackInfos: trip?.stack_info ?? null,
                                        connected: true
                                    }) &&
                                    <>
                                        <CartConstructionProductsTableItemStatus
                                            type="assistance"
                                            item={props.assistance}
                                            bookingProcessState={props.assistance.booking_process_state}
                                            bookingStatus={props.assistance.booking_status}
                                            agencyNeedToBook={props.assistance.agency_need_to_book}
                                        />
                                        {
                                            (props.assistance.booking_status?.status_booking === StatusBooking.CONFIRMED ||
                                            props.assistance.booking_status?.status_booking === StatusBooking.PENDING ||
                                            props.assistance.booking_status?.status_booking === StatusBooking.WAITING) &&
                                            <Typography fontWeight={100} fontSize="0.75rem">
                                                {
                                                    t<string>(
                                                        'cart-material.cart-construction-reference',
                                                        { ref: props.assistance.booking_status.item_reference }
                                                    )
                                                }
                                            </Typography>
                                        }
                                    </>,
                                    !isProductPackaged({
                                        product: props.assistance,
                                        stackInfos: trip?.stack_info ?? null,
                                        connected: true
                                    }) &&
                                    (
                                        user?.client_full?.type !== 2 ||
                                        props.assistance.creator?.client?.type === user.client_full.type
                                    ) &&
                                    <>
                                        {
                                            !props.assistance.is_custom ?
                                                <Chip
                                                    label={t<string>('cart-material.flux')}
                                                    size="small"
                                                    sx={{
                                                        backgroundColor: '#2ACAEA',
                                                        color: '#fff'
                                                    }}
                                                    icon={<CloudCircle color="inherit" />}
                                                /> :
                                                <Chip
                                                    size="small"
                                                    label={t<string>('cart-material.offline')}
                                                    sx={{
                                                        backgroundColor: '#6A329F',
                                                        color: '#fff'
                                                    }}
                                                    icon={<CloudOff color="inherit" />}
                                                />
                                        },
                                    </>,
                                    user?.client_full?.type !== 2 &&
                                    props.assistance.traveler_modification &&
                                    <Chip
                                        color="warning"
                                        label={
                                            props.assistance.traveler_modification === 'SET_IN_TRIP' ?
                                                t<string>('cart-material.add-to-cart-traveler') :
                                                t<string>('cart-material.add-to-option-traveler')
                                        }
                                        size="small"
                                        icon={<ErrorOutline color="inherit" />}
                                        onDelete={confirmTravelerModification}
                                    />,
                                    !isProductPackaged({
                                        product: props.assistance,
                                        stackInfos: trip?.stack_info ?? null,
                                        connected: true
                                    }) &&
                                    !props.assistance.is_cancellable &&
                                    <Box sx={{ whiteSpace: 'nowrap' }}>
                                        <CartProductIconText
                                            text={t<string>('cart-material.not-cancellable')}
                                            startIcon={ReportProblemOutlined}
                                            color="#FF8100"
                                        />
                                    </Box>,
                                    props.assistance.product_type !== 8 &&
                                    props.assistance.booking_status &&
                                    props.assistance.booking_status.status_booking === 'CONFIRMED' &&
                                    props.assistance.booking_process_state !== 'CANCEL' &&
                                    <>
                                        <Tooltip
                                            title={ t<string>('cart-material.assistance-update-helper') }
                                            arrow
                                        >
                                            <ReportProblemOutlined sx={{ color: '#FF8100' }} />
                                        </Tooltip>
                                        <Button
                                            size="small"
                                            variant="outlined"
                                            sx={{
                                                "color": '#FF8100',
                                                "borderColor": '#FF8100',
                                                '&:hover': {
                                                    borderColor: '#FF8100'
                                                }
                                            }}
                                            onClick={ onUpdateAssistance }
                                        >
                                            { t<string>('cart-material.assistance-update') }
                                        </Button>
                                    </>,
                                    props.assistance.product_type === 8 &&
                                    (props.assistance.booking_status === null || props.assistance.booking_status.status_booking !== "CANCELLED") &&
                                    (
                                        props.assistance.trip_price > Math.round((((prices.totalCartCost ?? 0) - getPrice(props.assistance.prices).cost) / (travelers?.length ?? 1) * props.assistance.group_passenger.travelers.length)) + 1 ||
                                        props.assistance.trip_price < Math.round((((prices.totalCartCost ?? 0) - getPrice(props.assistance.prices).cost) / (travelers?.length ?? 1) * props.assistance.group_passenger.travelers.length)) - 1
                                    ) &&
                                    <>
                                        <Tooltip
                                            title={
                                                <>
                                                    { t<string>('cart-material.insurance-update-helper') }
                                                    <br/>
                                                    { t<string>('cart-material.insurance-update-helper-selling-price') } :{' '}
                                                    { new Intl.NumberFormat(language).format(props.assistance.trip_price) }
                                                </>
                                            }
                                            arrow
                                        >
                                            <ReportProblemOutlined sx={{ color: '#FF8100' }} />
                                        </Tooltip>
                                        {
                                            props.assistance.booking_status === null &&
                                            <Button
                                                size="small"
                                                variant="outlined"
                                                sx={{
                                                    "color": '#FF8100',
                                                    "borderColor": '#FF8100',
                                                    '&:hover': {
                                                        borderColor: '#FF8100'
                                                    }
                                                }}
                                                onClick={ onUpdateAssistance }
                                            >
                                                { t<string>('cart-material.insurance-update') }
                                            </Button>
                                        }
                                    </>
                                ]
                            )
                        }
                    </Stack>
                    {
                        props.assistance.booking_status &&
                        (props.assistance.product_type !== 8 || provider?.code === "gritchen") &&
                        <Button
                            sx={{
                                padding: 0,
                                textTransform: 'none',
                                color: '#000',
                                textDecoration: 'underline'
                            }}
                            onClick={onShowBookingDetail}
                        >
                            {t<string>('cart-material.cart-construction-details-and-cancellation')}
                        </Button>
                    }
                </div>
            }
            margin={<MarginWrapper assistance={props.assistance} />}
            pictures={pictures}
            alwaysOpen={props.alwaysOpen}
        >
            <CartAssistanceCardDetails assistance={props.assistance} />
        </CartProductCard>
    );
}


type MarginWrapperProps = {
    assistance: AssistanceCart
}

function MarginWrapper(props: MarginWrapperProps): JSX.Element {
    const trip = useSelector((state: AppState) => state.trip.data_trip);
    const seeAllProductsMargins = useSelector((state: AppState) => state.cartConstruction.seeProductsMargins);

    return (
        <Collapse
            in={
                seeAllProductsMargins &&
                !isProductPackaged({
                    product: props.assistance,
                    stackInfos: trip?.stack_info ?? null
                })
            }
        >
            <CardContent
                sx={{
                    borderTop: seeAllProductsMargins ?
                        '1px solid rgba(0, 0, 0, 0.25)' :
                        undefined
                }}
            >
                <CartConstructionProductsTableItemMargin
                    type="assistance"
                    item={props.assistance}
                />
            </CardContent>
        </Collapse>
    );
}
