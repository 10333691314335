import {useTranslation} from "react-i18next";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import React from "react";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

const AccommodationMissingTravelers = ({missing_travelers, setNewGroups}) => {
    const { t } = useTranslation();
    const onValidate = () => {
        setNewGroups([{
            travelers: missing_travelers,
            travelers_list: missing_travelers
        }], () => {});
    };
    return (
        <Dialog open={ missing_travelers !== null } fullWidth maxWidth={ "md" } disableEscapeKeyDown>
            <DialogTitle>{ t("accommodation.missing-travelers") }</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    { t("accommodation.missing-travelers-info") }
                    <br/>
                    { t("accommodation.missing-travelers-info-sup") }
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={ onValidate } color={ "primary" }>{ t("global.yes") }</Button>
                <Button color={ "primary" }>{ t("global.no") }</Button>
            </DialogActions>
        </Dialog>
    );
};

export default AccommodationMissingTravelers;